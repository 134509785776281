'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasRoom', [
    '$rootScope',
    'BAS_API',
    'BAS_ROOM',
    'ICONS',
    'BAS_IMAGE',
    'RoomsHelper',
    'UiBasRoom',
    'CurrentBasCore',
    'BasCurrentAppProfile',
    'BasRoomScenes',
    'BasRoomScheduler',
    'BasRoomMusic',
    'BasRoomVideo',
    'BasRoomCoreClientDevices',
    'BasRoomLights',
    'BasRoomShades',
    'BasRoomGenericDevices',
    'BasRoomOpenCloseDevices',
    'BasRoomCameras',
    'BasRoomEnergy',
    'BasRoomTimers',
    'BasImageTrans',
    'BasImage',
    'BasRoomDoorPhoneGateways',
    'BasRoomDoorPhones',
    'BasRoomThermostats',
    'BasRoomWeatherStations',
    'BasString',
    'SourcesHelper',
    'BasUtilities',
    basRoomFactory
  ])

/**
 * @typedef {Object} TBasRoomParseOrDestroy
 * @property {?BasRoom} basRoom
 * @property {boolean} changed
 */

/**
 * @typedef {Object} TBasRoomOptions
 * @property {Room} [room]
 * @property {Zone} [zone]
 * @property {string} [sourceUuid]
 */

/**
 * @param $rootScope
 * @param BAS_API
 * @param {BAS_ROOM} BAS_ROOM
 * @param {ICONS} ICONS
 * @param {BAS_IMAGE} BAS_IMAGE
 * @param {RoomsHelper} RoomsHelper
 * @param UiBasRoom
 * @param {CurrentBasCore} CurrentBasCore
 * @param {BasCurrentAppProfile} BasCurrentAppProfile
 * @param BasRoomScenes
 * @param BasRoomScheduler
 * @param BasRoomMusic
 * @param BasRoomVideo
 * @param BasRoomCoreClientDevices
 * @param BasRoomLights
 * @param BasRoomShades
 * @param BasRoomGenericDevices
 * @param BasRoomOpenCloseDevices
 * @param BasRoomCameras
 * @param BasRoomEnergy
 * @param BasRoomTimers
 * @param BasImageTrans
 * @param BasImage
 * @param BasRoomDoorPhoneGateways
 * @param BasRoomDoorPhones
 * @param BasRoomThermostats
 * @param BasRoomWeatherStations
 * @param BasString
 * @param {SourcesHelper} SourcesHelper
 * @param {BasUtilities} BasUtilities
 * @returns BasRoom
 */
function basRoomFactory (
  $rootScope,
  BAS_API,
  BAS_ROOM,
  ICONS,
  BAS_IMAGE,
  RoomsHelper,
  UiBasRoom,
  CurrentBasCore,
  BasCurrentAppProfile,
  BasRoomScenes,
  BasRoomScheduler,
  BasRoomMusic,
  BasRoomVideo,
  BasRoomCoreClientDevices,
  BasRoomLights,
  BasRoomShades,
  BasRoomGenericDevices,
  BasRoomOpenCloseDevices,
  BasRoomCameras,
  BasRoomEnergy,
  BasRoomTimers,
  BasImageTrans,
  BasImage,
  BasRoomDoorPhoneGateways,
  BasRoomDoorPhones,
  BasRoomThermostats,
  BasRoomWeatherStations,
  BasString,
  SourcesHelper,
  BasUtilities
) {
  var CSS_HAS_THERMOSTAT = 'bas-room--thermostat--has'
  var CSS_HAS_LIGHTS = 'bas-room--lights--has'
  var CSS_HAS_GENERIC_DEVICES = 'bas-room--devices--has'
  var CSS_HAS_SCENES = 'bas-room--scenes--has'
  var CSS_HAS_TITLE = 'bas-room--has-title'
  var CSS_ACTIVE_LIGHTS = 'bas-room--lights--has-active'
  var CSS_ACTIVE_GENERIC_DEVICES = 'bas-room--devices--has-active'

  var biSvgOpts = {
    customClass: [
      BAS_IMAGE.C_BG_CONTAIN,
      BAS_IMAGE.C_COLOR_LIGHT_CONTROL,
      BAS_IMAGE.C_SIZE_60,
      BAS_IMAGE.C_TOP_40
    ]
  }

  var biImgOpts = {
    customClass: [
      BAS_IMAGE.C_BG_COVER
    ]
  }

  var biBgOpts = {
    customClass: [
      BAS_IMAGE.C_BG_COVER
    ]
  }

  var biDefaultRoomType = new BasImage(ICONS.roomType_custom, biSvgOpts)

  /**
   * @type {string[]}
   */
  var ROOM_FUNCTIONS_UI_DASHBOARD = [
    BAS_API.Room.FUNCTIONS.AUDIO,
    BAS_API.Room.FUNCTIONS.GENERIC,
    BAS_API.Room.FUNCTIONS.LIGHTS,
    BAS_API.Room.FUNCTIONS.SCENES,
    BAS_API.Room.FUNCTIONS.THERMOSTAT,
    BAS_API.Room.FUNCTIONS.WINDOW_TREATMENTS
  ]

  /**
   * @type {string[]}
   */
  var ROOM_FUNCTIONS_UI_DASHBOARD_SOURCE_API = [
    BAS_API.Room.FUNCTIONS.GENERIC,
    BAS_API.Room.FUNCTIONS.LIGHTS,
    BAS_API.Room.FUNCTIONS.SCENES,
    BAS_API.Room.FUNCTIONS.THERMOSTAT,
    BAS_API.Room.FUNCTIONS.WINDOW_TREATMENTS
  ]

  /**
   * Represents a Room with properties
   * ready to be used in services and templates
   *
   * @constructor
   * @param {TBasRoomOptions} options
   */
  function BasRoom (options) {

    /**
     * @type {string}
     */
    this.id = ''

    /**
     * @type {string}
     */
    this.name = ''

    /**
     * @type {string}
     */
    this.variant = BAS_ROOM.VAR_ROOM

    /**
     * Represents translationId + ID_SEPARATOR + name
     *
     * @type {string}
     */
    this.nameId = ''

    /**
     * @type {number}
     */
    this.level = BAS_API.Room.LEVELS.LVL_ROOM

    /**
     * @type {string}
     */
    this.buildingId = ''

    /**
     * @type {string}
     */
    this.floorId = ''

    /**
     * LEGACY Music Zone building
     *
     * @deprecated
     * @type {string}
     */
    this.building = ''

    /**
     * LEGACY Music Zone floor
     *
     * @deprecated
     * @type {string}
     */
    this.floor = ''

    /**
     * @type {string[]}
     */
    this.tags = []

    /**
     * @type {number}
     */
    this.order = BAS_API.Room.DEFAULT_ORDER

    /**
     * @type {number}
     */
    this.type = 0

    /**
     * @type {?BasRoomScenes}
     */
    this.scenes = null

    /**
     * @type {?BasRoomTimers}
     */
    this.timers = null

    /**
     * @type {?BasRoomScheduler}
     */
    this.scheduler = null

    /**
     * @type {?BasRoomMusic}
     */
    this.music = null

    /**
     * @type {?BasRoomVideo}
     */
    this.video = null

    /**
     * @type {?BasRoomCoreClientDevices}
     */
    this.coreClientDevices = null

    /**
     * @type {?BasRoomLights}
     */
    this.lights = null

    /**
     * @type {?BasRoomShades}
     */
    this.shades = null

    /**
     * @type {?BasRoomGenericDevices}
     */
    this.genericDevices = null

    /**
     * @type {?BasRoomOpenCloseDevices}
     */
    this.openCloseDevices = null

    /**
     * @type {?BasRoomCameras}
     */
    this.cameras = null

    /**
     * @type {?BasRoomDoorPhoneGateways}
     */
    this.doorPhoneGateways = null

    /**
     * @type {?BasRoomDoorPhones}
     */
    this.doorPhones = null

    /**
     * @type {?BasRoomThermostats}
     */
    this.thermostats = null

    /**
     * @type {?BasRoomWeatherStations}
     */
    this.weatherStations = null

    /**
     * @type {?BasRoomEnergy}
     */
    this.energy = null

    /**
     * @type {?Object}
     */
    this.images = null

    /**
     * Tile art
     *
     * @type {BasImageTrans}
     */
    this.bitTile = new BasImageTrans({
      transitionType: BasImageTrans.TRANSITION_TYPE_FADE,
      defaultImage: biDefaultRoomType
    })

    /**
     * Background art
     *
     * @type {BasImageTrans}
     */
    this.bitBg = new BasImageTrans({
      transitionType: BasImageTrans.TRANSITION_TYPE_FADE,
      debounceMs: 1000,
      debounceMsNull: 200
    })

    /**
     * @type {string}
     */
    this.uiTitle = ''

    /**
     * @type {BasString}
     */
    this.basTitle = new BasString()

    /**
     * @type {UiBasRoom}
     */
    this.uiRoom = new UiBasRoom(this)

    /**
     * @type {?(string[])}
     */
    this.uiFunctionsDashboard = null

    /**
     * If room only has a single ui function, this function.
     * If room has multiple ui functions, empty string.
     * If room functions have not (yet) been parsed, null.
     *
     * @type {?string}
     */
    this.singleUiFunctionDashboard = null

    /**
     * @type {Object<string, boolean>}
     */
    this.css = {}
    this.resetCss()

    /**
     * @type {?Room}
     */
    this.room = null

    /**
     * @type {string}
     */
    this.sourceUuid = ''

    this._roomListeners = []

    this._handleSceneCtrlUpdated = this.onSceneCtrlUpdated.bind(this)
    this._handleTimerCtrlUpdated = this.onTimerCtrlUpdated.bind(this)
    this._handleElliesUpdated = this.onElliesUpdated.bind(this)
    this._handleLisasUpdated = this.onLisasUpdated.bind(this)
    this._handleLenasUpdated = this.onLenasUpdated.bind(this)
    this._handleThermostatUpdated = this.onThermostatUpdated.bind(this)
    this._handleEnergyUpdated = this.onEnergyUpdated.bind(this)
    this._handleLightsUpdated = this.onLightsUpdated.bind(this)
    this._handleShadesUpdated = this.onShadesUpdated.bind(this)
    this._handleGenericDevicesUpdated =
      this.onGenericDevicesUpdated.bind(this)
    this._handleOpenCloseDevicesUpdated =
      this._onOpenCloseDevicesUpdated.bind(this)
    this._handleSecurityUpdated = this.onSecurityUpdated.bind(this)
    this._handleDoorPhoneGatewaysUpdated =
      this.onDoorPhoneGatewaysUpdated.bind(this)
    this._handleDoorPhonesUpdated = this.onDoorPhonesUpdated.bind(this)
    this._handleWeatherStationsUpdated =
      this.onWeatherStationsUpdated.bind(this)
    this._handleImagesUpdated = this.onImagesUpdated.bind(this)

    this.parseOptions(options)
  }

  // region Check functions

  /**
   * @param {BasRoom} room
   * @param {string} buildingId
   * @returns {boolean}
   */
  BasRoom.checkBuilding = function (room, buildingId) {
    return (room.getBuildingId() === buildingId)
  }

  /**
   * @param {BasRoom} room
   * @param {string} floorId
   * @returns {boolean}
   */
  BasRoom.checkFloor = function (room, floorId) {
    return (room.getFloorId() === floorId)
  }

  /**
   * @param {BasRoom} room
   * @param {string} tag
   * @returns {boolean}
   */
  BasRoom.checkTag = function (room, tag) {
    return (room.tags.indexOf(tag) !== -1)
  }

  /**
   * @param {BasRoom} room
   * @returns {boolean}
   */
  BasRoom.checkActivities = function (room) {
    return (
      room.lights instanceof BasRoomLights &&
      room.lights.areLightsActive()
    )
  }

  /**
   * @param {BasRoom} room
   * @returns {boolean}
   */
  BasRoom.checkMusic = function (room) {
    return (room.music instanceof BasRoomMusic)
  }

  /**
   * @param {BasRoom} room
   * @returns {boolean}
   */
  BasRoom.checkMusicActive = function (room) {
    return (BasRoom.checkMusic(room) && room.music.on)
  }

  /**
   * @param {BasRoom} room
   * @param {string} building
   * @returns {boolean}
   */
  BasRoom.checkMusicBuilding = function (room, building) {
    return (
      BasRoom.checkMusic(room) &&
      BasRoom.checkBuilding(room, building)
    )
  }

  /**
   * @param {BasRoom} room
   * @param {string} floor
   * @returns {boolean}
   */
  BasRoom.checkMusicFloor = function (room, floor) {
    return (
      BasRoom.checkMusic(room) &&
      BasRoom.checkFloor(room, floor)
    )
  }

  /**
   * @param {BasRoom} room
   * @param {string} tag
   * @returns {boolean}
   */
  BasRoom.checkMusicTag = function (room, tag) {
    return (
      BasRoom.checkMusic(room) &&
      BasRoom.checkTag(room, tag)
    )
  }

  /**
   * @param {BasRoom} room
   * @returns {boolean}
   */
  BasRoom.checkVideo = function (room) {
    return (room.video instanceof BasRoomVideo)
  }

  /**
   * @param {BasRoom} room
   * @returns {boolean}
   */
  BasRoom.checkVideoActive = function (room) {
    return (
      BasRoom.checkVideo(room) &&
      room.video.on
    )
  }

  /**
   * @param {BasRoom} room
   * @returns {boolean}
   * @param {string} building
   */
  BasRoom.checkVideoBuilding = function (room, building) {
    return (
      BasRoom.checkVideo(room) &&
      BasRoom.checkBuilding(room, building)
    )
  }

  /**
   * @param {BasRoom} room
   * @param {string} floor
   * @returns {boolean}
   */
  BasRoom.checkVideoFloor = function (room, floor) {
    return (
      BasRoom.checkVideo(room) &&
      BasRoom.checkFloor(room, floor)
    )
  }

  /**
   * @param {BasRoom} room
   * @param {string} tag
   * @returns {boolean}
   */
  BasRoom.checkVideoTag = function (room, tag) {
    return (
      BasRoom.checkVideo(room) &&
      BasRoom.checkTag(room, tag)
    )
  }

  /**
   * @param {BasRoom} room
   * @returns {boolean}
   */
  BasRoom.checkGroup = function (room) {
    return (
      BasRoom.checkMusic(room) &&
      room.music.isGroup()
    )
  }

  /**
   * @param {BasRoom} room
   * @returns {boolean}
   */
  BasRoom.checkSourceGroup = function (room) {
    return (
      BasRoom.checkMusic(room) &&
      room.music.isActiveSourceGroup() &&
      room.music.isAvailable
    )
  }

  /**
   * @param {BasRoom} room
   * @returns {boolean}
   */
  BasRoom.checkThermostats = function (room) {
    return (room.thermostats instanceof BasRoomThermostats)
  }

  /**
   * @param {BasRoom} room
   * @param {string} building
   * @returns {boolean}
   */
  BasRoom.checkThermostatBuilding = function (room, building) {
    return (
      RoomsHelper.roomHasFunctionThermostat(room) &&
      BasRoom.checkBuilding(room, building)
    )
  }

  /**
   * @param {BasRoom} room
   * @param {string} floor
   * @returns {boolean}
   */
  BasRoom.checkThermostatFloor = function (room, floor) {
    return (
      RoomsHelper.roomHasFunctionThermostat(room) &&
      BasRoom.checkFloor(room, floor)
    )
  }

  /**
   * @param {BasRoom} room
   * @param {string} tag
   * @returns {boolean}
   */
  BasRoom.checkThermostatTag = function (room, tag) {
    return (
      RoomsHelper.roomHasFunctionThermostat(room) &&
      BasRoom.checkTag(room, tag)
    )
  }

  /**
   * @param {BasRoom} room
   * @returns {boolean}
   */
  BasRoom.checkOpenClose = function (room) {
    return (room.openCloseDevices instanceof BasRoomOpenCloseDevices)
  }

  /**
   * @param {BasRoom} room
   * @returns {boolean}
   */
  BasRoom.checkOpenCloseWithFeedback = function (room) {
    return (
      BasRoom.checkOpenClose(room) &&
      room.openCloseDevices.hasOpenCloseDevicesWithFeedback()
    )
  }

  /**
   * @param {BasRoom} room
   * @param {string} building
   * @returns {boolean}
   */
  BasRoom.checkOpenCloseBuilding = function (room, building) {
    return (
      RoomsHelper.roomHasFunctionOpenClose(room) &&
      BasRoom.checkBuilding(room, building)
    )
  }

  /**
   * @param {BasRoom} room
   * @param {string} floor
   * @returns {boolean}
   */
  BasRoom.checkOpenCloseFloor = function (room, floor) {
    return (
      RoomsHelper.roomHasFunctionOpenClose(room) &&
      BasRoom.checkFloor(room, floor)
    )
  }

  /**
   * @param {BasRoom} room
   * @param {string} tag
   * @returns {boolean}
   */
  BasRoom.checkOpenCloseTag = function (room, tag) {
    return (
      RoomsHelper.roomHasFunctionOpenClose(room) &&
      BasRoom.checkTag(room, tag)
    )
  }

  /**
   * @param {BasRoom} room
   * @returns {boolean}
   */
  BasRoom.checkIntercomFunction = function (room) {
    return (
      RoomsHelper.roomHasFunctionIntercom(room) &&
      !BasCurrentAppProfile.isCurrentIntercomRoomUuid(room.id)
    )
  }

  /**
   * @param {BasRoom} room
   * @param {string} building
   * @returns {boolean}
   */
  BasRoom.checkIntercomBuilding = function (room, building) {
    return (
      BasRoom.checkIntercomFunction(room) &&
      BasRoom.checkBuilding(room, building)
    )
  }

  /**
   * @param {BasRoom} room
   * @param {string} tag
   * @returns {boolean}
   */
  BasRoom.checkIntercomTag = function (room, tag) {
    return (
      BasRoom.checkIntercomFunction(room) &&
      BasRoom.checkTag(room, tag)
    )
  }

  /**
   * Check if room has functions that display a tile in the room dashboard
   *
   * @param {BasRoom} room
   * @returns {boolean}
   */
  BasRoom.checkUiFunction = function (room) {

    // Legacy zones
    if (!room ||
      !room.room ||
      !BasUtil.isObject(room.room.functions)) {

      return true
    }

    return (
      room.room.functions[BAS_API.Room.FUNCTIONS.AUDIO] ||
      room.room.functions[BAS_API.Room.FUNCTIONS.SCENES] ||
      room.room.functions[BAS_API.Room.FUNCTIONS.THERMOSTAT] ||
      room.room.functions[BAS_API.Room.FUNCTIONS.LIGHTS] ||
      room.room.functions[BAS_API.Room.FUNCTIONS.GENERIC] ||
      room.room.functions[BAS_API.Room.FUNCTIONS.WINDOW_TREATMENTS] ||
      (
        room.room.av &&
        (
          room.room.av.audio ||
          room.room.av.video
        )
      )
    )
  }

  /**
   * Check if room has functions that display a tile in the room dashboard
   * And is part of building
   *
   * @param {BasRoom} room
   * @param {string} building
   * @returns {boolean}
   */
  BasRoom.checkUiBuilding = function (room, building) {
    return (
      BasRoom.checkUiFunction(room) &&
      BasRoom.checkBuilding(room, building)
    )
  }

  /**
   * Check if a room has functions that display a tile in the room dashboard
   * And contains tag
   *
   * @param {BasRoom} room
   * @param {string} tag
   * @returns {boolean}
   */
  BasRoom.checkUiTag = function (room, tag) {
    return (
      BasRoom.checkUiFunction(room) &&
      BasRoom.checkTag(room, tag)
    )
  }

  /**
   * @param {?BasRoom} basRoom
   * @param {TBasRoomOptions} options
   * @returns {TBasRoomParseOrDestroy}
   */
  BasRoom.parseOrDestroy = function (
    basRoom,
    options
  ) {
    var result

    /**
     * @type {TBasRoomParseOrDestroy}
     */
    result = {
      basRoom: null,
      changed: false
    }

    if (basRoom instanceof BasRoom) {

      if (basRoom.isSameRoom(options)) {

        result.basRoom = basRoom
        result.changed = true

        basRoom.parseOptions(options)

      } else {

        basRoom.destroy()

        result.basRoom = new BasRoom(options)
        result.changed = true
      }

    } else {

      result.basRoom = new BasRoom(options)
      result.changed = true
    }

    return result
  }

  /**
   * @param {number} roomType
   * @returns {string}
   */
  BasRoom.getTranslationIdForRoomType = function (roomType) {

    switch (roomType) {
      case BAS_API.Room.TYPES.R_RACK:
        return 'room_rack'
      case BAS_API.Room.TYPES.R_LIVING_ROOM:
        return 'room_living_room'
      case BAS_API.Room.TYPES.R_KITCHEN:
        return 'room_kitchen'
      case BAS_API.Room.TYPES.R_POOL:
        return 'room_pool'
      case BAS_API.Room.TYPES.R_DINING_ROOM:
        return 'room_dining_room'
      case BAS_API.Room.TYPES.R_FAMILY_ROOM:
        return 'room_family_room'
      case BAS_API.Room.TYPES.R_HOME_CINEMA:
        return 'room_home_cinema'
      case BAS_API.Room.TYPES.R_TERRACE:
        return 'room_terrace'
      case BAS_API.Room.TYPES.R_BAR:
        return 'room_bar'
      case BAS_API.Room.TYPES.R_HALLWAY:
        return 'room_hallway'
      case BAS_API.Room.TYPES.R_GUEST_ROOM:
        return 'room_guest_room'
      case BAS_API.Room.TYPES.R_MASTER_BEDROOM:
        return 'room_master_bedroom'
      case BAS_API.Room.TYPES.R_HOME_OFFICE:
        return 'room_home_office'
      case BAS_API.Room.TYPES.R_BEDROOM:
        return 'room_bedroom'
      case BAS_API.Room.TYPES.R_BATHROOM:
        return 'room_bathroom'
      case BAS_API.Room.TYPES.R_GARAGE:
        return 'room_garage'
      case BAS_API.Room.TYPES.R_GARDEN:
        return 'room_garden'
      case BAS_API.Room.TYPES.R_PATIO:
        return 'room_patio'
      case BAS_API.Room.TYPES.R_DRESSING_ROOM:
        return 'room_dressing_room'
      case BAS_API.Room.TYPES.R_SPA:
        return 'room_spa'
      case BAS_API.Room.TYPES.R_BASEMENT:
        return 'room_basement'
      case BAS_API.Room.TYPES.R_GYM:
        return 'room_gym'
      case BAS_API.Room.TYPES.R_ATTIC:
        return 'room_attic'
      case BAS_API.Room.TYPES.R_HOBBY_ROOM:
        return 'room_hobby_room'
      case BAS_API.Room.TYPES.R_WINE_CELLAR:
        return 'room_wine_cellar'
      case BAS_API.Room.TYPES.R_TOILET:
        return 'room_toilet'
      case BAS_API.Room.TYPES.R_LAUNDRY_ROOM:
        return 'room_laundry_room'
      case BAS_API.Room.TYPES.R_PANTRY:
        return 'room_pantry'
      case BAS_API.Room.TYPES.R_LIBRARY:
        return 'room_library'
      case BAS_API.Room.TYPES.R_BALL_ROOM:
        return 'room_ball_room'
      case BAS_API.Room.TYPES.R_DRIVEWAY:
        return 'room_driveway'
      case BAS_API.Room.TYPES.R_WORKSHOP:
        return 'room_workshop'
      case BAS_API.Room.TYPES.R_CORRIDOR:
        return 'room_corridor'
      case BAS_API.Room.TYPES.R_MEETING_ROOM:
        return 'room_meeting_room'
      case BAS_API.Room.TYPES.R_NURSERY:
        return 'room_nursery'
      case BAS_API.Room.TYPES.R_PARKING_PLACE:
        return 'room_parking_place'
      case BAS_API.Room.TYPES.R_STAIRCASE:
        return 'room_staircase'
      case BAS_API.Room.TYPES.R_UTILITY_ROOM:
        return 'room_utility_room'
      case BAS_API.Room.TYPES.R_POOL_HOUSE:
        return 'room_pool_house'
      case BAS_API.Room.TYPES.R_FOYER:
        return 'room_foyer'
      case BAS_API.Room.TYPES.R_ENTRY:
        return 'room_entry'
      case BAS_API.Room.TYPES.R_NOOK:
        return 'room_nook'
      case BAS_API.Room.TYPES.R_MEDIA_ROOM:
        return 'room_media_room'
      case BAS_API.Room.TYPES.R_STABLE:
        return 'room_stable'
      case BAS_API.Room.TYPES.R_ROOM:
        return 'room_room'
      case BAS_API.Room.TYPES.F_GROUND_FLOOR:
        return 'floor_lvl_0'
      case BAS_API.Room.TYPES.F_FIRST_FLOOR:
        return 'floor_lvl_1'
      case BAS_API.Room.TYPES.F_SECOND_FLOOR:
        return 'floor_lvl_2'
      case BAS_API.Room.TYPES.F_THIRD_FLOOR:
        return 'floor_lvl_3'
      case BAS_API.Room.TYPES.F_FOURTH_FLOOR:
        return 'floor_lvl_4'
      case BAS_API.Room.TYPES.F_FIFTH_FLOOR:
        return 'floor_lvl_5'
      case BAS_API.Room.TYPES.F_BASEMENT:
        return 'floor_basement'
      case BAS_API.Room.TYPES.F_GARDEN:
        return 'floor_garden'
      case BAS_API.Room.TYPES.F_FLOOR:
        return 'floor_floor'
      case BAS_API.Room.TYPES.F_ATTIC:
        return 'floor_attic'
      case BAS_API.Room.TYPES.F_LOWER:
        return 'floor_lower'
      case BAS_API.Room.TYPES.F_UPPER:
        return 'floor_upper'
      case BAS_API.Room.TYPES.F_LOFT:
        return 'floor_loft'
      case BAS_API.Room.TYPES.B_MAIN_HOUSE:
        return 'building_main_house'
      case BAS_API.Room.TYPES.B_POOL_HOUSE:
        return 'building_pool_house'
      case BAS_API.Room.TYPES.B_GARAGE:
        return 'building_garage'
      case BAS_API.Room.TYPES.B_STABLES:
        return 'building_stables'
      case BAS_API.Room.TYPES.B_BUILDING:
        return 'building_building'
      default:
        return ''
    }
  }

  /**
   * @param {number} level
   * @returns {string}
   */
  BasRoom.getGenericTranslationIdForLevel = function (level) {

    switch (level) {
      case BAS_API.Room.LEVELS.LVL_ROOM:
        return 'room_room'
      case BAS_API.Room.LEVELS.LVL_FLOOR:
        return 'floor_floor'
      case BAS_API.Room.LEVELS.LVL_BUILDING:
        return 'building_building'
      case BAS_API.Room.LEVELS.LVL_HOME:
        return 'home'
      default:
        // Should not occur
        return 'room_room'
    }
  }

  /**
   * @param {number} roomType
   * @returns {Object}
   */
  BasRoom.getIconForRoomType = function (roomType) {

    switch (roomType) {
      case BAS_API.Room.TYPES.R_RACK:
        return ICONS.roomType_deviceRack
      case BAS_API.Room.TYPES.R_LIVING_ROOM:
        return ICONS.roomType_livingRoom
      case BAS_API.Room.TYPES.R_KITCHEN:
        return ICONS.roomType_kitchen2
      case BAS_API.Room.TYPES.R_POOL:
        return ICONS.roomType_swimmingPool2
      case BAS_API.Room.TYPES.R_DINING_ROOM:
        return ICONS.roomType_diningRoom2
      case BAS_API.Room.TYPES.R_FAMILY_ROOM:
        return ICONS.roomType_familyRoom
      case BAS_API.Room.TYPES.R_HOME_CINEMA:
        return ICONS.roomType_homeCinema2
      case BAS_API.Room.TYPES.R_TERRACE:
        return ICONS.roomType_terrace
      case BAS_API.Room.TYPES.R_BAR:
        return ICONS.roomType_bar
      case BAS_API.Room.TYPES.R_HALLWAY:
        return ICONS.roomType_hallway
      case BAS_API.Room.TYPES.R_MASTER_BEDROOM:
        return ICONS.roomType_masterBedroom
      case BAS_API.Room.TYPES.R_HOME_OFFICE:
        return ICONS.roomType_homeOffice
      case BAS_API.Room.TYPES.R_BEDROOM:
        return ICONS.roomType_bedroom
      case BAS_API.Room.TYPES.R_BATHROOM:
        return ICONS.roomType_bathroom
      case BAS_API.Room.TYPES.R_GARAGE:
        return ICONS.roomType_garage
      case BAS_API.Room.TYPES.R_GARDEN:
        return ICONS.roomType_garden
      case BAS_API.Room.TYPES.R_PATIO:
        return ICONS.roomType_patio
      case BAS_API.Room.TYPES.R_DRESSING_ROOM:
        return ICONS.roomType_dressingRoom
      case BAS_API.Room.TYPES.R_SPA:
        return ICONS.roomType_spa
      case BAS_API.Room.TYPES.R_BASEMENT:
        return ICONS.roomType_basement
      case BAS_API.Room.TYPES.R_GYM:
        return ICONS.roomType_gym
      case BAS_API.Room.TYPES.R_ATTIC:
        return ICONS.roomType_attic
      case BAS_API.Room.TYPES.R_HOBBY_ROOM:
        return ICONS.roomType_hobbyRoom
      case BAS_API.Room.TYPES.R_WINE_CELLAR:
        return ICONS.roomType_wineCellar
      case BAS_API.Room.TYPES.R_TOILET:
        return ICONS.roomType_toilet
      case BAS_API.Room.TYPES.R_LAUNDRY_ROOM:
        return ICONS.roomType_laundryRoom
      case BAS_API.Room.TYPES.R_PANTRY:
        return ICONS.roomType_pantry
      case BAS_API.Room.TYPES.R_LIBRARY:
        return ICONS.roomType_library2
      case BAS_API.Room.TYPES.R_BALL_ROOM:
        return ICONS.roomType_ballroom
      case BAS_API.Room.TYPES.R_DRIVEWAY:
        return ICONS.roomType_driveway
      case BAS_API.Room.TYPES.R_WORKSHOP:
        return ICONS.roomType_workshop
      case BAS_API.Room.TYPES.R_MEETING_ROOM:
        return ICONS.roomType_meetingRoom
      case BAS_API.Room.TYPES.R_NURSERY:
        return ICONS.roomType_nursery
      case BAS_API.Room.TYPES.R_PARKING_PLACE:
        return ICONS.roomType_parking
      case BAS_API.Room.TYPES.R_STAIRCASE:
        return ICONS.roomType_staircase
      case BAS_API.Room.TYPES.R_UTILITY_ROOM:
        return ICONS.roomType_utilityRoom
      case BAS_API.Room.TYPES.R_CORRIDOR:
      case BAS_API.Room.TYPES.R_ENTRY:
      case BAS_API.Room.TYPES.R_FOYER:
      case BAS_API.Room.TYPES.R_GUEST_ROOM:
      case BAS_API.Room.TYPES.R_MEDIA_ROOM:
      case BAS_API.Room.TYPES.R_NOOK:
      case BAS_API.Room.TYPES.R_POOL_HOUSE:
      case BAS_API.Room.TYPES.R_STABLE:
      default:
        return null
    }
  }

  // endregion

  BasRoom.prototype._getTag = function () {
    var result, length

    result = 'BasRoom'

    // ID

    if (BasUtil.isString(this.id)) {

      length = this.id.length

      if (length) {

        result += ' ('
        result += length > 7 ? this.id.slice(-8) : this.id
        result += ')'
      }
    }

    // Type

    if (this.level !== BAS_API.Room.LEVELS.LVL_ROOM) {

      result += ' ['
      switch (this.level) {
        case BAS_API.Room.LEVELS.LVL_HOME:
          result += 'HOME'
          break
        case BAS_API.Room.LEVELS.LVL_BUILDING:
          result += 'BUILDING'
          break
        case BAS_API.Room.LEVELS.LVL_FLOOR:
          result += 'FLOOR'
          break
        default:
          result += 'UNKNOWN (' + this.level + ')'
      }
      result += ']'
    }

    // Name

    result += ' ' + this.nameId

    return result
  }

  /**
   * Checks if this instance represents a room and not a floor/building/home
   *
   * @returns {boolean}
   */
  BasRoom.prototype.isRoom = function () {
    return this.level === BAS_API.Room.LEVELS.LVL_ROOM
  }

  /**
   * Checks if this instances represents a "Home"
   *
   * @returns {boolean}
   */
  BasRoom.prototype.isHome = function () {
    return this.level === BAS_API.Room.LEVELS.LVL_HOME
  }

  /**
   * Checks if this instances represents a "Source group" and qualifies to
   * be shown in the groups overview (minimum of listening rooms, ...)
   *
   * @returns {boolean}
   */
  BasRoom.prototype.isActiveSourceGroup = function () {
    return (
      this.isSourceGroup() &&
      this.music &&
      this.music.isActiveSourceGroup()
    )
  }

  /**
   * Checks if this instances represents a "Source group" but is NOT qualified
   * to be shown in the groups overview (minimum of listening rooms, ...)
   *
   * @returns {boolean}
   */
  BasRoom.prototype.isInactiveSourceGroup = function () {
    return (
      this.isSourceGroup() &&
      this.music &&
      !this.music.isActiveSourceGroup()
    )
  }

  /**
   * Checks if this instances represents a "Source group"
   *
   * @returns {boolean}
   */
  BasRoom.prototype.isSourceGroup = function () {
    return !!this.sourceUuid
  }

  /**
   * @param {TBasRoomOptions} options
   * @returns {boolean}
   */
  BasRoom.prototype.isSameRoom = function (options) {

    if (options.room instanceof BAS_API.Room) {

      return this.id === options.room.uuid

    } else if (options.zone instanceof BAS_API.Zone) {

      return this.id === options.zone.id

    } else if (BasUtil.isNEString(options.sourceUuid)) {

      return this.id === options.sourceUuid
    }

    return false
  }

  /**
   * Checks if API room is valid room level
   *
   * @returns {boolean}
   */
  BasRoom.prototype.apiRoomIsLvlRoom = function () {

    if (this.room) {

      return BAS_API.Room.isLvlRoom(this.room)

    } else {

      if (this.sourceUuid) return true

      if (this.music && this.music.hasLegacyMusic) {

        return this.music.hasLegacyMusic()
      }
    }

    return false
  }

  /**
   * Returns the building ID if there is an ID otherwise return legacy name
   *
   * @returns {string}
   */
  BasRoom.prototype.getBuildingId = function () {
    return BasUtil.isNEString(this.buildingId)
      ? this.buildingId
      : this.building
  }

  /**
   * Returns the floor ID if there is an ID otherwise return legacy name
   *
   * @returns {string}
   */
  BasRoom.prototype.getFloorId = function () {
    return BasUtil.isNEString(this.floorId)
      ? this.floorId
      : this.floor
  }

  /**
   * Checks for a valid scenes property
   *
   * @returns {boolean}
   */
  BasRoom.prototype.hasScenes = function () {
    return (this.scenes instanceof BasRoomScenes)
  }

  /**
   * Checks for a valid scenes property
   *
   * @returns {boolean}
   */
  BasRoom.prototype.hasTimers = function () {
    return (this.timers instanceof BasRoomTimers)
  }

  /**
   * Checks for a valid energy property
   *
   * @returns {boolean}
   */
  BasRoom.prototype.hasEnergy = function () {
    return (this.energy instanceof BasRoomEnergy)
  }

  /**
   * Checks for a valid scheduler property
   *
   * @returns {boolean}
   */
  BasRoom.prototype.hasScheduler = function () {
    return (this.scheduler instanceof BasRoomScheduler)
  }

  /**
   * Checks for a valid lights property
   *
   * @returns {boolean}
   */
  BasRoom.prototype.hasLights = function () {
    return (this.lights instanceof BasRoomLights)
  }

  /**
   * Checks for a valid shades property
   *
   * @returns {boolean}
   */
  BasRoom.prototype.hasShades = function () {
    return (this.shades instanceof BasRoomShades)
  }

  /**
   * Checks for a valid genericDevices property
   *
   * @returns {boolean}
   */
  BasRoom.prototype.hasGenericDevices = function () {
    return (this.genericDevices instanceof BasRoomGenericDevices)
  }

  /**
   * Checks for a valid openCloseDevices property
   *
   * @returns {boolean}
   */
  BasRoom.prototype.hasOpenCloseDevices = function () {
    return (this.openCloseDevices instanceof BasRoomOpenCloseDevices)
  }

  /**
   * Checks for a valid cameras property
   *
   * @returns {boolean}
   */
  BasRoom.prototype.hasCameras = function () {
    return (this.cameras instanceof BasRoomCameras)
  }

  /**
   * Checks for a valid thermostats property
   *
   * @returns {boolean}
   */
  BasRoom.prototype.hasThermostats = function () {
    return (this.thermostats instanceof BasRoomThermostats)
  }

  /**
   * Checks for a valid music property
   *
   * @returns {boolean}
   */
  BasRoom.prototype.hasMusic = function () {
    return (this.music instanceof BasRoomMusic)
  }

  /**
   * Checks for a valid video property
   *
   * @returns {boolean}
   */
  BasRoom.prototype.hasVideo = function () {
    return (this.video instanceof BasRoomVideo)
  }

  /**
   * Checks for a valid weatherstations property
   *
   * @returns {boolean}
   */
  BasRoom.prototype.hasWeatherStations = function () {
    return (this.weatherStations instanceof BasRoomWeatherStations)
  }

  /**
   * @returns {?BasSource}
   */
  BasRoom.prototype.getMusicBasSource = function () {
    return (this.music && this.music.basSource && this.music.basSource.play)
      ? this.music.basSource
      : null
  }

  /**
   * @returns {?BasSource}
   */
  BasRoom.prototype.getVideoBasSource = function () {
    return (this.video && this.video.basSource && this.video.basSource.play)
      ? this.video.basSource
      : null
  }

  /**
   * @returns {?BasSource}
   */
  BasRoom.prototype.getCurrentBasSource = function () {

    return (this.video && this.video.getCompatibleSources && this.video.on)
      ? this.getVideoBasSource()
      : this.getMusicBasSource()
  }

  /**
   * @returns {boolean}
   */
  BasRoom.prototype.isSourcePlayerOrBarp = function () {
    return (
      this.music &&
      this.music.isSourcePlayerOrBarp &&
      this.music.isSourcePlayerOrBarp()
    )
  }

  /**
   * @returns {boolean}
   */
  BasRoom.prototype.isSourceEmpty = function () {
    return (
      this.music &&
      this.music.isSourceEmpty &&
      this.music.isSourceEmpty()
    )
  }

  /**
   * This set the images property in API and syncs after setting
   *
   * Only use this if server will update after this method else a desync
   * could occur
   *
   * @param {?Object} images
   */
  BasRoom.prototype.setImages = function (images) {

    if (this.room) {

      this.room.setImages(images)
      this.onImagesUpdated()
    }
  }

  /**
   * This updates the room images in server, expects 'ok' message of
   * server and is followed by a async image update.
   *
   * Could fail if image is to big, or is a not supported extension (svg)
   *
   * @param {string} image
   * @returns {Promise}
   */
  BasRoom.prototype.updateImage = function (image) {

    return this.room
      ? this.room.updateImage(image)
      : Promise.reject(new Error('no room'))
  }

  /**
   * @param {TBasRoomOptions} options
   */
  BasRoom.prototype.parseOptions = function (options) {

    if (options.room) this.parseRoom(options.room)
    if (options.zone) this.parseRoom(options.zone)
    if (options.sourceUuid) this.setSource(options.sourceUuid)
  }

  /**
   * Parse a Room or Zone object
   *
   * @param {(Room|Zone)} room
   * @param {TBasEmitterOptions} [options]
   */
  BasRoom.prototype.parseRoom = function (
    room,
    options
  ) {
    if (room instanceof BAS_API.Room) {

      this.clearRoom()
      this.resetRoomInfo()

      this.room = room
      this.id = room.uuid
      this.name = room.name
      this.level = room.level
      this.images = room.images

      this.variant = this.isRoom() ? BAS_ROOM.VAR_ROOM : BAS_ROOM.VAR_AREA

      if (BasUtil.isNEString(room.buildingId)) {

        this.buildingId = room.buildingId
      }

      if (BasUtil.isNEString(room.floorId)) {

        this.floorId = room.floorId
      }

      this.tags = room.tags

      if (BasUtil.isVNumber(room.order)) {

        this.order = room.order
      }

      if (BasUtil.isVNumber(room.type)) {

        this.type = room.type
      }

      this.nameId = this.type + BAS_ROOM.ID_SEPARATOR + this.name

      this.syncImages()

      this.setRoomListeners()

      this.syncMusic(null, options)
      this.syncVideo(options)
      this.syncCoreClients(options)
      this.syncSceneCtrl(options)
      this.syncTimerCtrl(options)
      this.syncThermostats(options)
      this.syncEnergy(options)
      this.syncLights(options)
      this.syncShades(options)
      this.syncGenericDevices(options)
      this.syncOpenCloseDevices(options)
      this.syncCameras(options)
      this.syncDoorPhoneGateways(options)
      this.syncDoorPhones(options)
      this.syncWeatherStations(options)
      this.parseUIFunctions()

    } else if (room instanceof BAS_API.Zone) {

      this.id = room.id

      this.variant = room.isGroup()
        ? BAS_ROOM.VAR_MUSIC_GROUP
        : BAS_ROOM.VAR_ROOM

      if (BasUtil.isNEString(room.name)) {

        this.name = room.name
      }

      if (BasUtil.isNEString(room.building)) {

        this.building = room.building
      }

      if (BasUtil.isNEString(room.floor)) {

        this.floor = room.floor
      }

      if (BasUtil.isVNumber(room.order)) {

        if (room.order < this.order) this.order = room.order
      }

      if (BasUtil.isNEArray(room.tags)) {

        this.tags = room.tags
      }

      if (!BasUtil.isNEString(this.nameId)) {

        this.nameId = this.type + BAS_ROOM.ID_SEPARATOR + this.name
      }

      this.syncMusic(room, options)
    }

    this.syncBasTitle()
    this.updateTranslation()
    this.uiRoom.sync()
  }

  /**
   * Sets a source UUID for this room,
   * so it will act as a virtual room that represents all rooms
   * listening to the given source
   *
   * @param {string} sourceUuid
   */
  BasRoom.prototype.setSource = function (sourceUuid) {

    if (BasUtil.isNEString(sourceUuid)) {

      this.sourceUuid = sourceUuid
      this.variant = BAS_ROOM.VAR_AV_GROUP
      this.singleUiFunctionDashboard = BAS_ROOM.F_AV_AUDIO
      this.parseSource()
    }
  }

  /**
   * If this BasRoom instance is a virtual room representing a source, sync it
   *
   * Only applicable for Audio sources
   */
  BasRoom.prototype.parseSource = function () {

    var source = this._getBasSource()

    if (source) {

      this.id = source.uuid
      this.syncMusic()

    } else {

      this._destroyMusic()
    }
  }

  /**
   * If this BasRoom instance is a virtual room representing a source, returns
   * that source.
   *
   * @private
   * @returns {?BasSource}
   */
  BasRoom.prototype._getBasSource = function () {

    if (BasUtil.isNEString(this.sourceUuid)) {

      return SourcesHelper.getBasSource(this.sourceUuid)
    }

    return null
  }

  BasRoom.prototype.updateTranslation = function () {

    var _translationId

    this.uiTitle = ''

    _translationId = BasRoom.getTranslationIdForRoomType(this.type)

    if (_translationId) {

      this.uiTitle = BasUtilities.translate(_translationId)
    }

    // Check if separator is needed
    if (this.uiTitle.length > 0 &&
      BasUtil.isNEString(this.name)) {

      this.uiTitle += ' '
    }

    this.uiTitle += this.name

    // If there is no title, use a generic title
    if (!this.uiTitle) {

      _translationId =
        BasRoom.getGenericTranslationIdForLevel(this.level)
      this.uiTitle = BasUtilities.translate(_translationId)
    }

    this.basTitle.updateTranslation()

    this.uiRoom.updateTranslations()
    if (this.hasMusic()) this.music.updateTranslation()
    if (this.hasScenes()) this.scenes.updateTranslation()
    if (this.hasTimers()) this.timers.updateTranslation()
    if (this.hasScheduler()) this.scheduler.updateTranslation()
    if (this.hasLights()) this.lights.updateTranslation()
    if (this.hasShades()) this.shades.updateTranslation()
    if (this.hasGenericDevices()) this.genericDevices.updateTranslation()
    if (this.hasOpenCloseDevices()) {
      this.openCloseDevices.updateTranslation()
    }
    if (this.hasThermostats()) this.thermostats.updateTranslation()
    if (this.hasEnergy()) this.energy.updateTranslation()

    this.cssSyncUiTitle()
  }

  BasRoom.prototype.updateTemperatureUnit = function () {

    if (this.hasScenes()) this.scenes.updateTemperatureUnit()
    if (this.hasThermostats()) this.thermostats.updateTemperatureUnit()
  }

  BasRoom.prototype.onElliesUpdated = function onElliesUpdated () {

    this.syncCoreClients()

    $rootScope.$emit(BAS_ROOM.EVT_CORE_CLIENTS_UPDATED, this.id)
  }

  BasRoom.prototype.onLisasUpdated = function onLisasUpdated () {

    this.syncCoreClients()

    $rootScope.$emit(BAS_ROOM.EVT_CORE_CLIENTS_UPDATED, this.id)
  }
  BasRoom.prototype.onLenasUpdated = function onLenasUpdated () {

    this.syncCoreClients()

    $rootScope.$emit(BAS_ROOM.EVT_CORE_CLIENTS_UPDATED, this.id)
  }

  BasRoom.prototype.onSceneCtrlUpdated = function onScenesCtrlUpdated () {

    this.syncSceneCtrl()

    this.uiRoom.sync()
  }

  BasRoom.prototype.onTimerCtrlUpdated = function onTimerCtrlUpdated () {

    this.syncTimerCtrl()

    this.uiRoom.sync()
  }

  BasRoom.prototype.onThermostatUpdated = function onThermostatUpdated () {

    this.syncThermostats()

    this.uiRoom.sync()
  }

  BasRoom.prototype.onEnergyUpdated = function onEnergyUpdated () {

    this.syncEnergy()
  }

  BasRoom.prototype.onLightsUpdated = function onLightsUpdated () {

    this.syncLights()

    this.uiRoom.sync()
  }

  BasRoom.prototype.onShadesUpdated = function onShadesUpdated () {

    this.syncShades()

    this.uiRoom.sync()
  }

  BasRoom.prototype.onGenericDevicesUpdated = function () {

    this.syncGenericDevices()

    this.uiRoom.sync()
  }

  BasRoom.prototype._onOpenCloseDevicesUpdated = function () {

    this.syncOpenCloseDevices()

    $rootScope.$emit(
      BAS_ROOM.EVT_OPEN_CLOSE_DEVICES_UPDATED,
      this.id
    )
  }

  BasRoom.prototype.onSecurityUpdated = function onSecurityUpdated () {

    this.syncCameras()

    // Propagate on rootScope
    $rootScope.$emit(BAS_ROOM.EVT_SECURITY_UPDATED, this.id)
  }

  BasRoom.prototype.onDoorPhoneGatewaysUpdated = function () {

    this.syncDoorPhoneGateways()

    $rootScope.$emit(BAS_ROOM.EVT_DOOR_PHONE_GATEWAYS_UPDATED, this.id)
  }

  BasRoom.prototype.onDoorPhonesUpdated = function () {

    this.syncDoorPhones()

    $rootScope.$emit(BAS_ROOM.EVT_DOOR_PHONES_UPDATED, this.id)
  }

  BasRoom.prototype.onWeatherStationsUpdated = function () {

    this.syncWeatherStations()

    $rootScope.$emit(BAS_ROOM.EVT_WEATHER_STATIONS_UPDATED, this.id)
  }

  BasRoom.prototype.onImagesUpdated = function () {

    this.syncImages()

    $rootScope.$emit(BAS_ROOM.EVT_IMAGES_UPDATED, this.id)
  }

  BasRoom.prototype.hasAV = function () {

    return (
      this.hasAVMusic() ||
      this.hasAVSource()
    )
  }

  BasRoom.prototype.hasAVMusic = function () {

    return BasRoomMusic.hasAVMusic(this)
  }

  BasRoom.prototype.hasAVVideo = function () {

    return BasRoomVideo.hasAVVideo(this)
  }

  BasRoom.prototype.hasAVSource = function () {

    return BasRoomMusic.hasAVSource(this)
  }

  /**
   * @param {Zone} [zone]
   * @param {TBasEmitterOptions} [options]
   */
  BasRoom.prototype.syncMusic = function (
    zone,
    options
  ) {

    if (
      BasRoomMusic.hasAVMusic(this) ||
      BasUtil.isNEString(this.sourceUuid)
    ) {

      if (this.music) {

        // TODO Improve?
        this.music.parseRoom(options)

      } else {

        this.music = new BasRoomMusic(this)
      }

    } else if (zone) {

      if (this.music) {

        this.music.setZone(zone, options)

      } else {

        this.music = new BasRoomMusic(this, zone)
      }

    } else {

      if (this.music) {

        if (this.music.hasLegacyMusic()) {

          // TODO Improve?
          // Do nothing

        } else {

          this._destroyMusic()
        }
      }
    }
  }

  /**
   * @param {TBasEmitterOptions} [options]
   */
  BasRoom.prototype.syncVideo = function (options) {

    if (BasRoomVideo.hasAVVideo(this)) {

      if (this.video) {

        this.video.parseRoom(options)

      } else {

        this.video = new BasRoomVideo(this)
      }
    }
  }

  BasRoom.prototype.syncCoreClients = function syncCoreClients () {

    this._destroyCoreClients()

    if (BasRoomCoreClientDevices.hasCoreClients(this)) {

      this.coreClientDevices = new BasRoomCoreClientDevices(this)
    }
  }

  BasRoom.prototype.syncSceneCtrl = function syncScenes () {

    // Scenes

    if (BasRoomScenes.hasScenes(this)) {

      if (this.scenes && this.scenes.parseRoom) {

        this.scenes.parseRoom()

      } else {

        this._destroyScenes()

        this.scenes = new BasRoomScenes(this)
      }

      $rootScope.$emit(BAS_ROOM.EVT_SCENES_INITIALIZED, this)

      // Notify home scene(s) of potential scene step properties
      $rootScope.$emit(BAS_ROOM.EVT_SCENE_STEP_DEVICES_ADDED, this)

    } else if (this.scenes && RoomsHelper.roomHasFunctionScenes(this)) {

      // Don't destroy scenes when room has scenes function and when sceneCtrl
      //  device is not yet received -> To prevent flicker

    } else {

      this._destroyScenes()
    }

    this.cssSetHasScenes(RoomsHelper.roomHasFunctionScenes(this))

    // Schedules

    if (BasRoomScheduler.hasSchedules(this)) {

      if (this.scheduler && this.scheduler.syncDevice) {

        this.scheduler.syncDevice()

      } else {

        this._destroyScheduler()
        this.scheduler = new BasRoomScheduler(this)

        if (this.level === BAS_API.Room.LEVELS.LVL_HOME) {

          $rootScope.$emit(BAS_ROOM.EVT_HOME_JOBS_INITIALIZED)
        }
      }

    } else {

      this._destroyScheduler()
    }
  }

  BasRoom.prototype.syncThermostats = function syncThermostats () {

    this._destroyThermostats()

    if (BasRoomThermostats.hasThermostats(this)) {

      this.thermostats = new BasRoomThermostats(this)
      $rootScope.$emit(BAS_ROOM.EVT_THERMOSTATS_INITIALIZED, this.id)
      $rootScope.$emit(BAS_ROOM.EVT_SCENE_STEP_DEVICES_ADDED)
    }
  }

  BasRoom.prototype.syncTimerCtrl = function syncTimerCtrl () {

    this._destroyTimers()

    if (this.room && BasUtil.isNEString(this.room.timerCtrl)) {

      this.timers = new BasRoomTimers(this)

      if (this.level === BAS_API.Room.LEVELS.LVL_HOME) {

        $rootScope.$emit(BAS_ROOM.EVT_HOME_TIMERS_INITIALIZED)
      }
    }
  }

  BasRoom.prototype.syncEnergy = function syncEnergy () {

    this._destroyEnergy()

    if (BasRoomEnergy.hasEnergy(this)) {

      this.energy = new BasRoomEnergy(this)
      $rootScope.$emit(BAS_ROOM.EVT_ENERGY_INITIALIZED, this.id)
      $rootScope.$emit(BAS_ROOM.EVT_ENERGY_ADDED)
    }
  }

  BasRoom.prototype.syncLights = function syncLights () {

    this._destroyLights()

    if (BasRoomLights.hasLights(this)) {

      this.lights = new BasRoomLights(this)
      $rootScope.$emit(BAS_ROOM.EVT_LIGHTS_INITIALIZED, this.id)
      $rootScope.$emit(BAS_ROOM.EVT_SCENE_STEP_DEVICES_ADDED)
    }
  }

  BasRoom.prototype.syncShades = function syncShades () {

    this._destroyShades()

    if (BasRoomShades.hasShades(this)) {

      this.shades = new BasRoomShades(this)
      $rootScope.$emit(BAS_ROOM.EVT_SHADES_INITIALIZED, this.id)
      $rootScope.$emit(BAS_ROOM.EVT_SCENE_STEP_DEVICES_ADDED)
    }
  }

  BasRoom.prototype.syncGenericDevices = function syncGenericDevices () {

    this._destroyGenericDevices()

    if (BasRoomGenericDevices.hasDevices(this)) {

      this.genericDevices = new BasRoomGenericDevices(this)

      // Scene steps need to know the type of the numeric input controls,
      //  so they can correctly interpret the control value
      const scenes = this.scenes?.scenes
      if (scenes) {
        for (const scene of Object.values(scenes)) {
          scene.syncSteps()
        }
      }

      $rootScope.$emit(
        BAS_ROOM.EVT_GENERIC_DEVICES_INITIALIZED,
        this.id
      )
      $rootScope.$emit(BAS_ROOM.EVT_SCENE_STEP_DEVICES_ADDED)
    }
  }

  BasRoom.prototype.syncOpenCloseDevices = function () {

    this._destroyOpenCloseDevices()

    if (BasRoomOpenCloseDevices.hasOpenCloseDevices(this)) {

      this.openCloseDevices = new BasRoomOpenCloseDevices(this)
      $rootScope.$emit(
        BAS_ROOM.EVT_OPEN_CLOSE_DEVICES_INITIALIZED,
        this.id
      )
    }
  }

  BasRoom.prototype.syncCameras = function syncCameras () {

    this._destroyCameras()

    if (BasRoomCameras.hasCameras(this)) {

      this.cameras = new BasRoomCameras(this)
    }
  }

  BasRoom.prototype.syncDoorPhoneGateways = function () {

    this._destroyDoorPhoneGateways()

    if (BasRoomDoorPhoneGateways.hasDoorPhoneGateways(this)) {

      this.doorPhoneGateways = new BasRoomDoorPhoneGateways(this)
    }
  }

  BasRoom.prototype.syncDoorPhones = function () {

    this._destroyDoorPhones()

    if (BasRoomDoorPhones.hasDoorPhones(this)) {

      this.doorPhones = new BasRoomDoorPhones(this)
    }
  }

  BasRoom.prototype.syncWeatherStations = function () {

    this._destroyWeatherStations()

    if (BasRoomWeatherStations.hasWeatherStations(this)) {

      this.weatherStations = new BasRoomWeatherStations(this)
    }
  }

  BasRoom.prototype.syncBasTitle = function () {

    var _translationId, _key

    _translationId = BasRoom.getTranslationIdForRoomType(this.type)

    if (_translationId && this.name) {

      _key = '${' + _translationId + '}' + ' ' + this.name

    } else if (_translationId) {

      _key = _translationId

    } else if (this.name) {

      _key = '$$' + this.name

    } else {

      _key = BasRoom.getGenericTranslationIdForLevel(this.level)
    }

    this.basTitle.setKey(_key)
  }

  BasRoom.prototype.syncImages = function () {

    var _basImage, _icon

    if (BasUtil.isNEObject(this.room.images)) {

      this.images = BasUtil.copyObject(this.room.images)

      // Set tile image
      _basImage = new BasImage('', biImgOpts)
      _basImage.setMultipleUrls(this.images)
      this.bitTile.setImage(_basImage)

      // Set Background image
      _basImage = new BasImage('', biBgOpts)
      _basImage.setMultipleUrls(this.images)
      this.bitBg.setDefaultImage(_basImage)

    } else if (BasUtil.isVNumber(this.type)) {

      this.images = null

      _icon = BasRoom.getIconForRoomType(this.type)

      if (_icon) {

        _basImage = new BasImage(_icon, biSvgOpts)

        this.bitTile.setDefaultImage(_basImage)
      }

      this.bitTile.setImage(null)
      this.bitBg.setDefaultImage(null)
    }
  }

  BasRoom.prototype.cssSyncUiTitle = function () {

    this.css[CSS_HAS_TITLE] = BasUtil.isNEString(this.uiTitle)
  }

  /**
   * @param {boolean} value
   */
  BasRoom.prototype.cssSetHasThermostat = function (value) {

    this.css[CSS_HAS_THERMOSTAT] = value
  }

  /**
   * @param {boolean} value
   */
  BasRoom.prototype.cssSetHasLights = function (value) {

    this.css[CSS_HAS_LIGHTS] = value
  }

  /**
   * @param {boolean} value
   */
  BasRoom.prototype.cssSetHasScenes = function (value) {

    this.css[CSS_HAS_SCENES] = value
  }

  /**
   * @param {boolean} value
   */
  BasRoom.prototype.cssSetHasGenericDevices = function (value) {

    this.css[CSS_HAS_GENERIC_DEVICES] = value
  }

  /**
   * @param {boolean} value
   */
  BasRoom.prototype.cssSetActiveLights = function (value) {

    this.css[CSS_ACTIVE_LIGHTS] = value
  }

  /**
   * @param {boolean} value
   */
  BasRoom.prototype.cssSetActiveGenericDevices = function (value) {

    this.css[CSS_ACTIVE_GENERIC_DEVICES] = value
  }

  /**
   * Resets the general class object
   */
  BasRoom.prototype.resetCss = function () {

    this.css[CSS_HAS_TITLE] = false
    this.css[CSS_HAS_THERMOSTAT] = false
    this.css[CSS_HAS_LIGHTS] = false
    this.css[CSS_HAS_SCENES] = false
    this.css[CSS_HAS_GENERIC_DEVICES] = false
    this.css[CSS_ACTIVE_LIGHTS] = false
    this.css[CSS_ACTIVE_GENERIC_DEVICES] = false
  }

  BasRoom.prototype.setRoomListeners = function () {

    this.clearRoomListeners()

    if (this.room) {

      this._roomListeners.push(BasUtil.setEventListener(
        this.room,
        BAS_API.Room.EVT_ELLIES_UPDATED,
        this._handleElliesUpdated
      ))

      this._roomListeners.push(BasUtil.setEventListener(
        this.room,
        BAS_API.Room.EVT_LISAS_UPDATED,
        this._handleLisasUpdated
      ))

      this._roomListeners.push(BasUtil.setEventListener(
        this.room,
        BAS_API.Room.EVT_LENAS_UPDATED,
        this._handleLenasUpdated
      ))

      this._roomListeners.push(BasUtil.setEventListener(
        this.room,
        BAS_API.Room.EVT_SCENE_CTRL_UPDATED,
        this._handleSceneCtrlUpdated
      ))

      this._roomListeners.push(BasUtil.setEventListener(
        this.room,
        BAS_API.Room.EVT_TIMER_CTRL_UPDATED,
        this._handleTimerCtrlUpdated
      ))

      this._roomListeners.push(BasUtil.setEventListener(
        this.room,
        BAS_API.Room.EVT_THERMOSTATS_UPDATED,
        this._handleThermostatUpdated
      ))

      this._roomListeners.push(BasUtil.setEventListener(
        this.room,
        BAS_API.Room.EVT_ENERGY_UPDATED,
        this._handleEnergyUpdated
      ))

      this._roomListeners.push(BasUtil.setEventListener(
        this.room,
        BAS_API.Room.EVT_LIGHTS_UPDATED,
        this._handleLightsUpdated
      ))

      this._roomListeners.push(BasUtil.setEventListener(
        this.room,
        BAS_API.Room.EVT_SHADES_UPDATED,
        this._handleShadesUpdated
      ))

      this._roomListeners.push(BasUtil.setEventListener(
        this.room,
        BAS_API.Room.EVT_GENERIC_DEVICES_UPDATED,
        this._handleGenericDevicesUpdated
      ))

      this._roomListeners.push(BasUtil.setEventListener(
        this.room,
        BAS_API.Room.EVT_OPEN_CLOSE_DEVICES_UPDATED,
        this._handleOpenCloseDevicesUpdated
      ))

      this._roomListeners.push(BasUtil.setEventListener(
        this.room,
        BAS_API.Room.EVT_SECURITY_UPDATED,
        this._handleSecurityUpdated
      ))

      this._roomListeners.push(BasUtil.setEventListener(
        this.room,
        BAS_API.Room.EVT_DOOR_PHONE_GATEWAYS_UPDATED,
        this._handleDoorPhoneGatewaysUpdated
      ))

      this._roomListeners.push(BasUtil.setEventListener(
        this.room,
        BAS_API.Room.EVT_DOOR_PHONES_UPDATED,
        this._handleDoorPhonesUpdated
      ))

      this._roomListeners.push(BasUtil.setEventListener(
        this.room,
        BAS_API.Room.EVT_WEATHER_STATIONS_UPDATED,
        this._handleWeatherStationsUpdated
      ))

      this._roomListeners.push(BasUtil.setEventListener(
        this.room,
        BAS_API.Room.EVT_IMAGES_UPDATED,
        this._handleImagesUpdated
      ))
    }
  }

  BasRoom.prototype.clearRoomListeners = function () {

    BasUtil.executeArray(this._roomListeners)
    this._roomListeners = []
  }

  BasRoom.prototype.clearRoom = function clearRoom () {

    this.clearRoomListeners()
    this.room = null
  }

  /**
   * Resets room info (name, building, order, ...)
   */
  BasRoom.prototype.resetRoomInfo = function resetRoomInfo () {

    this.name = ''
    this.level = BAS_API.Room.LEVELS.LVL_ROOM
    this.uiTitle = ''
    this.basTitle.clear()
    this.type = BAS_API.Room.TYPES.CUSTOM
    this.buildingId = ''
    this.floorId = ''
    this.building = ''
    this.floor = ''
    this.tags = []
    this.order = BAS_API.Room.DEFAULT_ORDER
  }

  /**
   * Clears all information (except id)
   */
  BasRoom.prototype.reset = function () {

    this.resetRoomInfo()
    this.uiRoom.reset()
  }

  /**
   * Handles time format changes
   */
  BasRoom.prototype.onTimeFormatChanged = function () {

    if (this.hasScheduler()) {

      this.scheduler.onTimeFormatChanged()
    }
  }

  /**
   * Populates list of available functions for this room, that are relevant
   * to UI.
   */
  BasRoom.prototype.parseUIFunctions = function () {

    var uiFunctions, functions, functionsKeys, filteredFunctions, length, i, key

    if (this.room) {

      uiFunctions = CurrentBasCore.hasAVFullSupport()
        ? ROOM_FUNCTIONS_UI_DASHBOARD_SOURCE_API
        : ROOM_FUNCTIONS_UI_DASHBOARD

      functions = this.room.functions
      functionsKeys = Object.keys(functions)
      filteredFunctions = []

      length = functionsKeys.length
      for (i = 0; i < length; i++) {

        key = functionsKeys[i]
        if (
          functions[key] === true &&
          uiFunctions.indexOf(key) !== -1
        ) {
          filteredFunctions.push(key)
        }
      }

      if (CurrentBasCore.hasAVFullSupport()) {

        if (this.room.av) {
          if (this.room.av.audio) filteredFunctions.push(BAS_ROOM.F_AV_AUDIO)
          if (this.room.av.video) filteredFunctions.push(BAS_ROOM.F_AV_VIDEO)
        }

      } else {

        if (this.room.av) {
          if (this.room.av.audio) {
            if (filteredFunctions.indexOf(BAS_API.Room.FUNCTIONS.AUDIO) < 0) {
              filteredFunctions.push(BAS_API.Room.FUNCTIONS.F_AV_AUDIO)
            }
          }
        }
      }

      this.uiFunctionsDashboard = filteredFunctions

      this.singleUiFunctionDashboard = this.uiFunctionsDashboard.length === 1
        ? this.uiFunctionsDashboard[0]
        : ''

    } else {

      this.uiFunctionsDashboard = null
      this.singleUiFunctionDashboard = null
    }
  }

  /**
   * Suspend by clearing the listeners
   */
  BasRoom.prototype.suspend = function () {

    this.clearRoomListeners()

    if (this.scenes && this.scenes.suspend) this.scenes.suspend()
    if (this.scheduler && this.scheduler.suspend) this.scheduler.suspend()
    if (this.music && this.music.suspend) this.music.suspend()
    if (this.video && this.video.suspend) this.video.suspend()
    if (this.lights && this.lights.suspend) this.lights.suspend()
    if (this.shades && this.shades.suspend) this.shades.suspend()
    if (this.genericDevices && this.genericDevices.suspend) {

      this.genericDevices.suspend()
    }
    if (this.openCloseDevices && this.openCloseDevices.suspend) {

      this.openCloseDevices.suspend()
    }
    if (this.energy && this.energy.suspend) this.energy.suspend()
    if (this.timers && this.timers.suspend) this.timers.suspend()

    if (this.thermostats && this.thermostats.suspend) {

      this.thermostats.suspend()
    }
  }

  BasRoom.prototype._destroyMusic = function () {

    if (this.music && this.music.destroy) this.music.destroy()

    this.music = null
  }

  BasRoom.prototype._destroyVideo = function () {

    if (this.video && this.video.destroy) this.video.destroy()

    this.video = null
  }

  BasRoom.prototype._destroyCoreClients = function () {

    if (this.coreClientDevices && this.coreClientDevices.destroy) {

      this.coreClientDevices.destroy()
    }

    this.coreClientDevices = null
  }

  BasRoom.prototype._destroyLights = function () {

    if (this.lights && this.lights.destroy) this.lights.destroy()

    this.lights = null
  }

  BasRoom.prototype._destroyShades = function () {

    if (this.shades && this.shades.destroy) this.shades.destroy()

    this.shades = null
  }

  BasRoom.prototype._destroyGenericDevices = function () {

    if (this.genericDevices && this.genericDevices.destroy) {

      this.genericDevices.destroy()
    }

    this.genericDevices = null
  }

  BasRoom.prototype._destroyOpenCloseDevices = function () {

    if (this.openCloseDevices && this.openCloseDevices.destroy) {

      this.openCloseDevices.destroy()
    }

    this.openCloseDevices = null
  }

  BasRoom.prototype._destroyThermostats = function () {

    if (this.thermostats && this.thermostats.destroy) {

      this.thermostats.destroy()
    }

    this.thermostats = null
  }

  BasRoom.prototype._destroyEnergy = function () {

    if (this.energy && this.energy.destroy) this.energy.destroy()

    this.energy = null
  }

  BasRoom.prototype._destroyTimers = function () {

    if (this.timers && this.timers.destroy) this.timers.destroy()

    this.timers = null
  }

  BasRoom.prototype._destroyScenes = function () {

    if (this.scenes && this.scenes.destroy) this.scenes.destroy()

    this.scenes = null
  }

  BasRoom.prototype._destroyScheduler = function () {

    if (this.scheduler && this.scheduler.destroy) this.scheduler.destroy()

    this.scheduler = null
  }

  BasRoom.prototype._destroyCameras = function () {

    if (this.cameras && this.cameras.destroy) this.cameras.destroy()

    this.cameras = null
  }

  BasRoom.prototype._destroyDoorPhoneGateways = function () {

    if (this.doorPhoneGateways && this.doorPhoneGateways.destroy) {

      this.doorPhoneGateways.destroy()
    }

    this.doorPhoneGateways = null
  }

  BasRoom.prototype._destroyDoorPhones = function () {

    if (this.doorPhones && this.doorPhones.destroy) {

      this.doorPhones.destroy()
    }

    this.doorPhones = null
  }

  BasRoom.prototype._destroyWeatherStations = function () {

    if (this.weatherStations && this.weatherStations.destroy) {

      this.weatherStations.destroy()
    }

    this.weatherStations = null
  }

  /**
   * Destroys all listeners
   */
  BasRoom.prototype.destroy = function () {

    this.suspend()
    this.reset()

    this._destroyMusic()
    this._destroyVideo()
    this._destroyCoreClients()
    this._destroyLights()
    this._destroyShades()
    this._destroyGenericDevices()
    this._destroyOpenCloseDevices()
    this._destroyThermostats()
    this._destroyScheduler()
    this._destroyScenes()
    this._destroyCameras()
    this._destroyDoorPhoneGateways()
    this._destroyDoorPhones()
    this._destroyWeatherStations()
    this._destroyEnergy()
  }

  return BasRoom
}
