'use strict'

angular
  .module('basalteApp')
  .service('BasLisaState', [
    '$rootScope',
    '$uiRouterGlobals',
    'BAS_APP',
    'STATES',
    'BAS_STATE',
    'CurrentRoom',
    'BasAppDevice',
    BasLisaState
  ])

/**
 * @constructor
 * @param $rootScope
 * @param $uiRouterGlobals
 * @param {BAS_APP} BAS_APP
 * @param {STATES} STATES
 * @param {BAS_STATE} BAS_STATE
 * @param {CurrentRoom} CurrentRoom
 * @param {BasAppDevice} BasAppDevice
 */
function BasLisaState (
  $rootScope,
  $uiRouterGlobals,
  BAS_APP,
  STATES,
  BAS_STATE,
  CurrentRoom,
  BasAppDevice
) {

  init()

  function init () {

    if (BasAppDevice.isLisa()) {

      $rootScope.$on(
        BAS_APP.EVT_PAUSE,
        _onPause
      )
    }
  }

  function _onPause () {

    if ($uiRouterGlobals.transition) {

      // Ongoing transition

    } else {

      CurrentRoom.go(STATES.LISA, {
        view: BAS_STATE.S_LISA_VIEW_HOME
      })
    }
  }
}
