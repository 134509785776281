{
  "12_hr": "Zegar 12-godzinny",
  "24_hr": "Zegar 24-godzinny",
  "24_hr_time": "24-godzinny czas",
  "about": "Info",
  "account": "Konto",
  "account_exists": "Konto istnieje",
  "account_exists_l1": "To konto już istnieje.",
  "account_exists_l2": "Wybierz inny adres e-mail lub zaloguj się za pomocą tego adresu e-mail.",
  "account_not_confirmed": "Konto nie zostało potwierdzone",
  "account_not_confirmed_l1": "To konto nie jest jeszcze potwierdzone.",
  "account_not_confirmed_l2": "Sprawdź skrzynkę mailową, wysłaliśmy wiadomość z potwierdzeniem.",
  "account_not_confirmed_l3": "Czy zweryfikować to konto?",
  "act_in": "W",
  "activate": "Aktywuj",
  "active": "Aktywny",
  "act_out": "Na zewnątrz",
  "act_push": "NACIŚNIJ",
  "adaptive_brightness": "Adaptacyjna jasność",
  "add": "Dodaj",
  "add_favourite": "Dodaj ulubione",
  "add_group": "Dodaj grupę",
  "add_home": "Dodaj dom",
  "address": "Adres",
  "add_to_fav": "Dodaj do ulubionych",
  "add_to_playlist": "Dodaj do playlisty",
  "add_to_queue": "Dodaj do kolejki",
  "air_conditioning": "Klimatyzacja",
  "air_pressure": "Ciśnienie powietrza",
  "alarm_at": "Alarm o…",
  "alarm_missing_txt": "Brakuje jednego bądź kilku wymaganych pól.",
  "alarm_not_selected_l1": "Nie wybrano żadnego alarmu.",
  "alarm_not_selected_l2": "Wybierz alarm.",
  "alarms": "Alarmy",
  "album": "Album",
  "albums": "Albumy",
  "alert": "Alert",
  "all": "Wszystko",
  "all_off": "Wyłącz wszystko",
  "all_off_txt_l1": "Wszystkie pokoje zostaną wyłączone.",
  "all_off_txt_l2": "Czy na pewno chcesz kontynuować?",
  "all_on": "Włącz wszystko",
  "almost_done": "Prawie gotowe.",
  "almost_done_txt_l1": "Nie masz jeszcze ulubionych playlist ani stacji radiowych.",
  "almost_done_txt_l2": "Dodaj swoje pierwsze pozycje do listy.",
  "already_linked": "Połączono z kontem Basalte",
  "already_verified": "Konto już zweryfikowane",
  "alt_power": "Zasilanie",
  "alt_user": "Użytkownik",
  "application": "Aplikacja",
  "application_version": "Wersja oprogramowania",
  "app_update_required": "Wymagana aktualizacja aplikacji",
  "app_update_required_android_l2": "proszę zaktualizować aplikację w Play Store.",
  "app_update_required_ios_l2": "proszę zaktualizować aplikację w App Store.",
  "app_update_required_l2": "proszę zaktualizować aplikację w sklepie.",
  "artist": "Wykonawca",
  "artists": "Wykonawcy",
  "attention": "Uwaga!",
  "audio_unavailable": "System audio jest niedostępny",
  "audio_zones": "Strefy audio",
  "author": "Autor",
  "back": "powrót",
  "basalte_account": "Konto Basalte",
  "basalte_info_l1": "Konto Basalte umożliwia kontrolę domu spoza sieci lokalnej.",
  "basalte_link_server_l1": "Połączyć ten dom z Twoim kontem Basalte?",
  "basalte_link_server_l2": "Będziesz mógł kontrolować ten dom spoza sieci lokalnej.",
  "basalte_unlink_server_l1": "Odłączyć ten dom od konta Basalte?",
  "basalte_unlink_server_l2": "Nie będziesz już mieć dostępu do tego domu spoza sieci lokalnej.",
  "bass": "Bas",
  "BE": "Belgia",
  "beta": "beta",
  "bluetooth": "Bluetooth",
  "bluetooth_pairing_txt": "Możesz teraz połączyć swoje urządzenie przy użyciu Bluetooth",
  "brightness": "Jasność",
  "browse": "Przeglądaj",
  "browse_deezer": "Ulubione Deezer",
  "browse_library": "Biblioteka muzki",
  "browser_app_update_required_l2": "proszę odświeżyć stronę lub zaktualizować serwer.",
  "browse_stations": "Stacje radiowe",
  "buffering": "buforowanie",
  "building_building": "Budynek",
  "building_garage": "Garaż",
  "building_main_house": "Dom",
  "building_pool_house": "Domek przy basenie",
  "building_stables": "Stajnie",
  "cameras": "Kamery",
  "cancel": "Anuluj",
  "categories": "Kategorie",
  "category": "Kategoria",
  "celsius": "Celsjusz",
  "change_account": "Zmień konto",
  "change_home": "Zmień dom",
  "change_image": "Zmień obraz",
  "change_password": "Zmień hasło",
  "change_password_l1": "Zamierzasz zmienić swoje hasło.",
  "change_user": "Zmień profil",
  "channel_number": "Numer kanału",
  "charts": "Listy",
  "check_for_updates": "Sprawdź dostępne aktualizacje",
  "checking": "sprawdzam",
  "checking_for_updates": "Sprawdzanie aktualizacji...",
  "choose_favourite": "Czy chcesz wybrać ulubione?",
  "choose_home": "Wybierz dom",
  "choose_music": "Czy chcesz wybrać muzykę?",
  "choose_preset": "Wybierz preset",
  "choose_source": "Czy chcesz wybrać źródło?",
  "clear": "Wyczyść",
  "cleared": "Wyczyszczony",
  "clear_queue_txt_l1": "Kolejka zostanie usunięta.",
  "clear_queue_txt_l2": "Czy na pewno chcesz kontynuować?",
  "clear_selection": "Wyczyść wybrane",
  "clear_selection_txt_l1": "Wybrane pozycje zostaną usunięte.",
  "clear_selection_txt_l2": "Czy na pewno chcesz kontynuować?",
  "close": "Zamknij",
  "closed": "Zamknięte",
  "code": "Kod potwierdzający",
  "color": "Kolor",
  "color_temperature": "Temperatura barwowa",
  "color_theme": "Kolory motywu",
  "color_white": "Biały",
  "comfort": "Komfort",
  "configure": "Konfiguruj",
  "confirmation_code_sent": "Wysłano kod potwierdzający",
  "confirmation_code_sent_l1": "Kod potwierdzający został wysłany na następujący adres:",
  "confirm_continue": "Czy na pewno chcesz kontynuować?",
  "confirm_overwrite": "Czy na pewno chcesz nadpisać istniejące dane?",
  "connect": "Połącz",
  "connected": "Połączony",
  "connecting": "Łączenie",
  "connecting_no_active_servers": "Serwer nie jest online.",
  "connecting_no_integrator_access": "Konfiguracja zdalna nie jest włączona na tym serwerze.",
  "connecting_not_authorized": "Brak uprawnień do połączenia z tym serwerem.",
  "connecting_small": "łączenie",
  "connecting_stop_and_retry_text": "Błąd połączenia z serwerem. Ponowna próba za 10 sekund.",
  "connecting_stop_text": "Błąd połączenia z serwerem.",
  "connecting_text": "Łączenie...",
  "connecting_text_small": "łączenie...",
  "connection_lost": "Brak połączenia",
  "connection_lost_text": "Ponowne łączenie z serwerem",
  "connection_unable": "Nie można połączyć z wybranym serwerem.",
  "connect_offline_server_l1": "Serwer jest offline.",
  "connect_offline_server_l2": "Spróbować połączyć się z serwerem offline?",
  "connect_server": "Połącz z serwerem",
  "consumption": "Zużycie",
  "contact_support": "Skontaktuj się ze wsparciem technicznym",
  "continue_anyway": "Kontynuuj mimo to",
  "continue_without_basalte_account": "Kontynuuj bez konta Basalte",
  "cover_art_background": "Tło okładki",
  "current_password": "Obecne hasło",
  "current_temperature": "Obecnie",
  "custom": "Spersonalizowany",
  "date": "Data",
  "day": "Dzień",
  "day_friday": "Piątek",
  "day_monday": "Poniedziałek",
  "day_saturday": "Sobota",
  "day_short2_friday": "Pt",
  "day_short2_monday": "Pn",
  "day_short2_saturday": "So",
  "day_short2_sunday": "Nd",
  "day_short2_thursday": "Czw",
  "day_short2_tuesday": "Wt",
  "day_short2_wednesday": "Śr",
  "day_sunday": "Niedziela",
  "day_thursday": "Czwartek",
  "day_tuesday": "Wtorek",
  "day_wednesday": "Środa",
  "debug": "Usuń błędy",
  "debug_code": "Kod",
  "debug_remote": "Zdalne usunięcie błędów",
  "debug_remote_enable": "Włącz możliwość zdalnego usuwania błędów",
  "debug_remote_exit_info": "Aby wyłączyć możliwość zdalnego usunięcia błędów, całkowicie zrestartuj aplikację",
  "deezer": "Deezer",
  "deezer_disconnect_txt": "Konto Deezer zostanie odłączone od tego odtwarzacza.",
  "deezer_favourite": "Ulubione Deezer",
  "deezer_flow": "Flow",
  "deezer_flow_description": "Twoja osobista lista utworów.",
  "deezer_flow_full": "Deezer Flow",
  "deezer_no_link": "Żadne konto Deezer nie zostało jeszcze podłączone.",
  "deezer_oauth_error": "Błąd uwierzytelnienia Deezer",
  "default_rooms": "Pokoje domyślne",
  "default_rooms_hint": "Aby funkcja in-home streaming działała poprawnie, upewnij się, że przynajmniej jeden pokój wybrany jest jako pokój domyślny.",
  "default_rooms_info_l1": "Domyślne pomieszczenie dla zewnętrznych serwisów streamingowych (takich jak Spotify Connect).",
  "default_rooms_info_l2": "Jeśli żadna strefa nie jest aktywna, włącz domyślną strefę.",
  "default_rooms_spotify_hint": "Aby funkcja Spotify Connect działała poprawnie, upewnij się, że przynajmniej jeden pokój wybrany jest jako pokój domyślny.",
  "default_rooms_spotify_warning_l1": "Ustaw przynajmniej jeden pokój domyślny dla każdego streamu",
  "default_rooms_spotify_warning_l2": "aby ukończyć konfigurację.",
  "default_rooms_spotify_warning_title": "Funkcja Spotify Connect jest obecnie obsługiwana.",
  "default_view": "Widok domyślny",
  "delay": "Opóźnienie",
  "delay_seconds_b": "Opóźnienie (w sekundach)",
  "delete": "Usuń",
  "delete_account": "Usuń konto",
  "delete_account_l1": "Konto zostanie pernamentnie usunięte.",
  "demo_enter": "Tryb demo",
  "demo_functionality_warning_txt": "Ta funkcja nie jest obsługiwana w trybie demo.",
  "demo_location": "Gandawa",
  "demo_server_name": "Demo Home",
  "demo_title": "Demo",
  "demo_warning_txt": "Zostanie uruchomiony tryb demo.",
  "detail": "szczegóły",
  "device": "Urządzenie",
  "devices": "Urządzenia",
  "device_settings": "Ustawienia urządzenia",
  "device_volume": "Głośność urządzenia",
  "dhcp": "DHCP",
  "direction_east": "Wschód",
  "direction_east-northeast": "Wschód-Północny-wschód",
  "direction_east-southeast": "Wschód-Południowy-wschód",
  "direction_north": "Północ",
  "direction_northeast": "Północny-wschód",
  "direction_north-northeast": "Północ-Północny-wschód",
  "direction_north-northwest": "Północ-Północny-zachód",
  "direction_northwest": "Północny-zachód",
  "direction_short_east": "Wsch.",
  "direction_short_east-northeast": "Wsch-Pn-wsch.",
  "direction_short_east-southeast": "Wsch-Pd-wsch.",
  "direction_short_north": "Pn.",
  "direction_short_northeast": "Pn-wsch.",
  "direction_short_north-northeast": "Pn-Pn-wsch.",
  "direction_short_north-northwest": "Pn-Pn-zach.",
  "direction_short_northwest": "Pn-zach.",
  "direction_short_south": "Pd.",
  "direction_short_southeast": "Pd-wsch.",
  "direction_short_south-southeast": "Pd-Pd-wsch.",
  "direction_short_south-southwest": "Pd-Pd-zach.",
  "direction_short_southwest": "Pd-zach.",
  "direction_short_west": "Zach.",
  "direction_short_west-northwest": "Zach-Pn-zach.",
  "direction_short_west-southwest": "Zach-Pd-zach.",
  "direction_south": "Południe",
  "direction_southeast": "Południowy-wschód",
  "direction_south-southeast": "Południe-Południowy-wschód",
  "direction_south-southwest": "Południe-Południowy-zachód",
  "direction_southwest": "Południowy-zachód",
  "direction_west": "Zachód",
  "direction_west-northwest": "Zachód-Północny-zachód",
  "direction_west-southwest": "Zachód-Połduniowy-zachód",
  "disband_group": "Rozwiąż grupę",
  "dns": "DNS",
  "domain": "Domena",
  "done": "Gotowe",
  "do_not_disturb": "Nie przeszkadzać",
  "do_not_disturb_off_l1": "Wszystkie powiadomienia dźwiękowe są obecnie włączone.",
  "do_not_disturb_on_l1": "Wszystkie powiadomienia dźwiękowe są obecnie wyłączone.",
  "do_not_show_again": "Nie pokazuj ponownie",
  "door_phone": "Domofon",
  "doors": "Drzwi",
  "down": "Na dół",
  "downloading": "pobieram",
  "downloading_updates": "Pobieram aktualizacje",
  "do_you_want_to_continue": "Czy chcesz kontynuować?",
  "drag_and_drop_images": "Wybierz obraz lub upuść go tutaj",
  "drag_and_drop_lights": "Przeciągnij i upuść światła tutaj",
  "drag_to_reorder": "Przeciągnij, aby zmienić kolejność",
  "dsp": "DSP",
  "dsp_configuration": "Konfiguracja DSP",
  "dsp_profile_club": "Klubowa",
  "dsp_profile_custom": "Spersonalizowany",
  "dsp_profile_flat": "Płaska",
  "dsp_profile_jazz": "Jazz",
  "dsp_profile_live": "Live",
  "dsp_profile_pop": "Pop",
  "dsp_profile_rock": "Rock",
  "dsp_shortcut_l1": "Za chwilę uruchomisz ustawienia DSP",
  "echo_cancellation": "Redukcja echa",
  "echo_limit": "Limit echa",
  "edit": "Edytuj",
  "edit_dns": "Edytuj DNS",
  "edit_gateway": "Edytuj brama",
  "edit_ip_address": "Edytuj adres IP",
  "edit_subnet_mask": "Edytuj maskę podsieci",
  "ellie_room": "Pokój Ellie",
  "ellie_system_update": "Aktualizacja systemu Ellie",
  "ellie_system_update_l1": "Wymagany restart Ellie w celu instalacji aktualizacji.",
  "ellie_update": "Aktualizacja Ellie",
  "email": "Adres e-mail",
  "empty": "brak",
  "empty_queue": "Kolejka jest pusta",
  "empty_queue_txt_l1": "Wygląda na to, że w Twojej kolejce nie ma żadnych utworów.",
  "empty_queue_txt_l2": "Dodaj pozycje do kolejki.",
  "empty_song": "Odtwarzacz został zatrzymany",
  "empty_song_txt_l1": "Dotarłeś do końca kolejki.",
  "empty_song_txt_l2": "Naciśnij play aby rozpocząć od nowa.",
  "empty_src": "Nie wybrano żadnego źródła",
  "empty_src_txt_l1": "Wygląda na to, że nie wybrałeś żadnego źródła.",
  "empty_src_txt_l2": "Aby rozpocząć odtwarzanie muzyki wybierz źródło.",
  "empty_zone": "Nie wybrano żadnego pokoju.",
  "empty_zone_txt_l1": "Wygląda na to, że nie wybrałeś żadnego pokoju.",
  "empty_zone_txt_l2": "Aby rozpocząć odtwarzanie muzyki wybierz pokój.",
  "energy": "Energia",
  "energy_meter": "Licznik energii",
  "eq": "EQ",
  "error": "Błąd",
  "external": "Zewnętrzny",
  "extra_permissions_required": "Niektóre funkcje mogą wymagać dodatkowych uprawnień.",
  "ext_src": "Źródło zewnętrzne",
  "fahrenheit": "Fahrenheit",
  "failed": "Niepowodzenie",
  "fan": "Wentylator",
  "fan_auto": "Auto",
  "fan_high": "Wysoki",
  "fan_low": "Niski",
  "fan_medium": "Średni",
  "fan_off": "Wyłączony",
  "favourite": "Ulubiony",
  "favourites": "Ulubione",
  "favourite_scenes_full": "Wybrano maksymalną ilość ulubionych scen",
  "favourite_scenes_full_sub": "Możesz wybrać maksymalnie 4 ulubione sceny w pomieszczeniu",
  "favourite_songs": "Ulubione piosenki",
  "featured_playlists": "Polecane playlisty",
  "floor_attic": "Poddasze",
  "floor_basement": "Piwnica",
  "floor_floor": "Piętro",
  "floor_garden": "Ogród",
  "floor_heating": "Ogrzewanie podłogowe",
  "floor_loft": "Lofty",
  "floor_lower": "Niższe piętro",
  "floor_lvl_0": "Parter",
  "floor_lvl_1": "Pierwsze piętro",
  "floor_lvl_2": "Drugie piętro",
  "floor_lvl_3": "Trzecie piętro",
  "floor_lvl_4": "Czwarte piętro",
  "floor_lvl_5": "piąte piętro",
  "floor_lvl_6": "Szóste piętro",
  "floor_lvl_7": "Siódme piętro",
  "floor_lvl_8": "Ósme piętro",
  "floor_lvl_9": "Dziewiąte piętro",
  "floor_lvl_10": "Dziesiąte piętro",
  "floor_lvl_11": "Jedenaste piętro",
  "floor_lvl_12": "Dwunaste piętro",
  "floor_lvl_13": "Trzynaste piętro",
  "floor_lvl_14": "Czternaste piętro",
  "floor_lvl_15": "Piętnaste piętro",
  "floor_lvl_16": "Szesnaste piętro",
  "floor_lvl_17": "Siedemnaste piętro",
  "floor_lvl_18": "Osiemnaste piętro",
  "floor_lvl_19": "Dziewiętnaste piętro",
  "floor_lvl_20": "Dwudzieste piętro",
  "floor_upper": "Górne piętro",
  "forgot_password": "Nie pamiętasz hasła?",
  "frequency": "Częstotliwość",
  "full_screen": "Pełny ekran",
  "functions": "Funkcje",
  "gas": "Gaz",
  "gateway": "Brama",
  "general": "Ogólne",
  "generic_devices": "Urządzenia",
  "generic_devices_fire": "Ogień",
  "generic_devices_garden": "Ogród",
  "generic_devices_socket": "Gniazdo",
  "genre": "Gatunek muzyki",
  "genre_moods": "Gatunki i nastroje",
  "genres": "Gatunki muzyki",
  "go_to_login_l1": "Zaloguj się lub zarejestruj się za pomocą konta Basalte.",
  "go_to_manage_l1": "Zarządzaj swoim kontem Basalte.",
  "group": "Grupa",
  "groups": "Grupy",
  "home": "Dom",
  "humidity": "Wilgotność",
  "hz": "Hz",
  "images": "Obrazy",
  "image_smaller_than_5mb": "Obraz musi mieć mniej niż 5MB",
  "in": "W",
  "incorrect_album": "Nie znaleziono albumu",
  "incorrect_artist": "Nie znaleziono artysty",
  "install": "Instaluj",
  "installing": "instaluję",
  "install_update": "Instaluj aktualizację",
  "install_updates": "Instaluj aktualizacje",
  "integrator": "Integrator",
  "integrator_access": "Zdalna konfiguracja",
  "integrator_access_allow": "Zezwól na zdalną konfigurację",
  "integrator_access_ask": "Poproś właściciela o włączenie zdalnej konfiguracji.",
  "integrator_access_disabled": "Zdalna konfiguracja jest wyłączona",
  "integrator_access_explanation": "Uruchamiasz (w pełni zaszyfrowany i zabezpieczony) zdalny dostęp do Twojego systemu, dzięki temu integrator może:",
  "integrator_access_o1_program_basalte": "programować i kontrolować system Basalte",
  "integrator_access_o2_program_knx": "programować urządzenia KNX",
  "integrator_access_o3_access_network": "uzyskać pełny dostęp do sieci komputerowej",
  "integrator_access_privacy": "Dla zachowania Twojej prywatności, sugerujemy włączać tę opcję tymczasowo tylko w razie potrzeby",
  "intercom": "Interkom",
  "internal": "Wewnętrzny",
  "invalid_address": "niepoprawny adres",
  "invalid_code": "Niepoprawny kod",
  "invalid_code_l1": "Podany kod jest niepoprawny.",
  "invalid_code_l2": "Upewnij się, że kod jest poprawny lub poproś o nowy.",
  "invalid_input": "niepoprawne wejście",
  "invalid_user": "Nieprawidłowa nazwa użytkownika",
  "invalid_user_l1": "Upewnij się, że adres e-mail jest poprawny.",
  "ios_plist_string_NSCameraUsageDescription": "Kamera może zostać użyta, aby wykonać zdjęcia pomieszczeń",
  "ios_plist_string_NSLocalNetworkUsageDescription": "Dostęp do sieci lokalnej można wykorzystać do znalezienia serwerów, kamer IP, ...",
  "ios_plist_string_NSPhotoLibraryAddUsageDescription": "Biblioteka zdjęć może zostać użyta, aby zapisać zdjęcia pomieszczeń",
  "ios_plist_string_NSPhotoLibraryUsageDescription": "Biblioteka zdjęć może zostać użyta do wybrania zdjęć pomieszczeń",
  "ip": "IP",
  "ip_address": "adres IP",
  "join": "+ Dodaj",
  "join_alt": "+ Dodaj",
  "keep_awake": "Nie wyłączaj iPada",
  "keep_awake_device": "Nie wyłączaj urządzenia",
  "kelvin": "Kelwin",
  "Key": "pl_PL",
  "knx_presets": "Presety KNX",
  "knx_presets_info": "Zmodyfikuj istniejące presety KNX, aby odtworzyć coś innego.",
  "language": "Język",
  "language_system": "Język systemu",
  "last-7-days": "Ostatnie 7 dni",
  "last_hour": "Ostatnia godzina",
  "last_room": "Poprzednie pomieszczenie",
  "last_view": "Ostatni widok",
  "learn_more": "Dowiedz się więcej",
  "leave_group": "Opuść grupę",
  "left": "Lewo",
  "lena_system_update": "Aktualizacja systemu Lena",
  "lena_system_update_l1": "Wymagany restart Lena w celu instalacji aktualizacji.",
  "library": "Biblioteka",
  "library_empty": "Biblioteka jest pusta",
  "library_scanning": "Skanowanie biblioteki utworów",
  "light_accent_light": "Oświetlenie akcentujące",
  "light_art_light": "Oświetlenie artystyczne",
  "light_cabinet": "Szafa",
  "light_ceiling_mounted_fixture": "Oświetlenie sufitowe",
  "light_chandelier": "Żyrandol",
  "light_desk": "Oświetlenie nad biurkiem",
  "light_down": "Oświetlenie punktowe",
  "light_floor_lamp": "Oświetlenie stojące",
  "light_lampshade": "Abażur",
  "light_led": "Oświetlenie LED",
  "light_led_strip": "Pasek LED",
  "light_mirror": "Oświetlenie lustra",
  "light_mood_light": "Oświetlenie nastrojowe",
  "light_pendant": "Oświetlenie wiszące",
  "light_reading_light": "Oświetlenie do czytania",
  "lights": "Oświetlenie",
  "lights_off_all": "Wyłącz całe oświetlenie",
  "light_spot": "Spot",
  "light_table_lamp": "Oświetlenie nad stołem",
  "light_track": "Szyna",
  "light_via": "Via",
  "light_wall_mounted_fixture": "Kinkiet",
  "link": "Połącz",
  "link_deezer_info_l1": "Połącz konto Deezer'a ze strumieniem.",
  "link_deezer_info_l2": "Ta opcja włącza możliwość przeglądania i odtwarzania mediów Deezer'a w wybranym strumieniu.",
  "linked_homes": "Powiązane domy",
  "linked_projects": "Powiązane projekty",
  "linked_with": "Powiązano z",
  "link_home": "Otwórz aplikację Basalte na urządzeniu lokalnym i połącz swój dom w ustawieniach.",
  "link_server": "Powiąż z kontem Basalte",
  "link_server_full_sub": "Czy powiązać ten projekt z tym kontem Basalte?",
  "link_spotify_info_l1": "Połącz konto Spotify ze strumieniem.",
  "link_spotify_info_l2": "Ta opcja włącza możliwość przeglądania i odtwarzania mediów Spotify w wybranym strumieniu.",
  "link_tidal_info_l1": "Połącz konto TIDAL ze strumieniem.",
  "link_tidal_info_l2": "Ta opcja włącza możliwość przeglądania i odtwarzania mediów TIDAL'a w wybranym strumieniu.",
  "lisa_order_hint": "Zmień kolejność i widoczność stron i kafelków.",
  "lisa_system_update": "Aktualizacja systemu Lisa",
  "lisa_system_update_l1": "Wymagany restart Lisy w celu instalacji aktualizacji.",
  "listen_in": "Słuchaj w",
  "liters": "Litry",
  "live_adjustments": "Ustawienia w czasie rzeczywistym",
  "loading": "Ładowanie...",
  "local": "lokalny",
  "location": "Lokalizacja",
  "location_back": "Tył",
  "location_bottom": "Dół",
  "location_center": "Środek",
  "location_chair": "Krzesło",
  "location_corner": "Narożnik",
  "location_desk": "Biurko",
  "location_east": "Wschód",
  "location_front": "Front",
  "location_high": "Wysoko",
  "location_island": "Wyspa",
  "location_left": "Lewo",
  "location_low": "Nisko",
  "location_mid": "Pośrodku",
  "location_niche": "Nisza",
  "location_north": "Północ",
  "location_right": "Prawy",
  "location_shelves": "Półki",
  "location_south": "Południe",
  "location_stove": "Kuchenka",
  "location_table": "Stół",
  "location_top": "Góra",
  "location_west": "Zachód",
  "logged_in_as": "Zalogowany jako",
  "log_out": "Wyloguj",
  "log_out_everywhere": "Wyloguj się wszędzie",
  "log_out_everywhere_l1": "Za chwilę wylogujesz się ze wszystkich aplikacji i urządzeń.",
  "long-press-save-scene": "Długie naciścięcie sceny powoduje jej zapisanie",
  "louver_30": "30 stopni",
  "louver_45": "45 stopni",
  "louver_60": "60 stopni",
  "louver_horizontal": "Poziomo",
  "louver_mode": "Tryb żaluzji",
  "louver_off": "Wyłącz",
  "louver_swing": "Swing",
  "louver_vertical": "Pionowo",
  "mac_address": "Adres MAC",
  "manage": "Zarządzaj",
  "manage_account": "Zarządzaj kontem",
  "manual_conn": "Połączenie ręczne",
  "manual_text": "Wprowadź adres żądanego serwera.",
  "max": "Max",
  "menu": "Menu",
  "mf_device": "{count, plural, =0 {Urządzenie} =1 {Urządzenie} other {Urządzenia}}",
  "mf_feels_like": "Odczuwalna {value}",
  "mf_link_streaming_service": "Połącz konto {streamingService} do tego streamu poprzez kliknięcie \"Połącz\" poniżej.",
  "mf_minute": "{count} {count, plural, =1 {minuta} other {minuty}}",
  "mf_powered_by": "Powered by {value}",
  "mf_second": "{count} {count, plural, =1 {sekunda} other {sekundy}}",
  "mf_server": "{count, plural, =0 {Serwer} =1 {Serwer} other {Serwery}}",
  "mf_song": "{count} {count, plural, =1 {piosenka} other {piosenki}}",
  "mf_source": "Źródło: {value}",
  "microphone_gain": "Wzmocnienie mikrofonu",
  "min": "Min",
  "mix": "Mix",
  "mixed_sources": "Wiele źródeł",
  "mixed_sources_txt_l1": "W wybranej grupie znajdują się pokoje z wieloma źródłami.",
  "mixed_sources_txt_l2": "Wybierz źródło.",
  "mixes": "Mixes",
  "mixes_small": "mixes",
  "mode": "Tryb",
  "mode_auto": "Auto",
  "mode_cooling": "Chłodzenie",
  "mode_drying": "Suszenie",
  "mode_fan_only": "Tylko wentylator",
  "mode_heating": "Ogrzewanie",
  "mode_off": "Wyłączony",
  "month": "Miesiąc",
  "month_april": "Kwiecień",
  "month_august": "Sierpień",
  "month_december": "Grudzień",
  "month_february": "Luty",
  "month_january": "Styczeń",
  "month_july": "Lipiec",
  "month_june": "Czerwiec",
  "month_march": "Marzec",
  "month_may": "Maj",
  "month_november": "Listopad",
  "month_october": "Październik",
  "month_september": "Wrzesień",
  "month_short_april": "Kwi",
  "month_short_august": "Sie",
  "month_short_december": "Gru",
  "month_short_february": "Lut",
  "month_short_january": "Sty",
  "month_short_july": "Lip",
  "month_short_june": "Cze",
  "month_short_march": "Mar",
  "month_short_may": "Maj",
  "month_short_november": "Lis",
  "month_short_october": "Paź",
  "month_short_september": "Wrz",
  "mood": "Nastrój",
  "moods": "Nastroje",
  "music": "Muzyka",
  "music_choice": "Wybór muzyki",
  "music_go_to": "Idź do muzyki",
  "music_is_active": "Muzyka jest aktywna",
  "music_is_playing": "Odtwarzanie muzyki",
  "music_off": "muzyka wyłączona",
  "music_off_all": "Wyłącz wszystkie pokoje",
  "music_off_all_rooms": "Muzyka wyłączona, wszystkie pokoje",
  "music_off_current": "Wyłącz bieżący pokój",
  "music_off_current_room": "Muzyka wyłączona, ten pokój",
  "music_off_txt_l1": "Wszystkie pokoje zostaną wyłączone.",
  "music_off_txt_l2": "Czy na pewno chcesz kontynuować?",
  "music_on_current": "Włącz wybrany pokój",
  "music_server": "Serwer muzyki",
  "mute_area_on_call": "Wycisz muzykę podczas połączenia",
  "my_favorites": "Moje ulubione",
  "my_music": "Moja muzyka",
  "name": "Nazwa",
  "nds": "Wyszukiwanie serwerów",
  "nds_music": "Wyszukiwanie serwerów muzyki",
  "nds_none": "Nie znaleziono żadnego serwera",
  "nds_none_music": "Nie znaleziono żadnych serwerów muzyki!",
  "nds_text_l1": "To może zająć kilka sekund...",
  "nds_text_l2": "Upewnij się czy twoje urządzenie jest podłączone do domowej sieci Wi-Fi.",
  "network": "Sieć",
  "network_input_invalid": "Wszystkie pola muszą być uzupełnione poprawnie",
  "never": "Nigdy",
  "new": "Nowy",
  "new_alarm": "Nowe powiadomienie",
  "new_image": "Nowy wygląd",
  "new_password": "Nowe hasło",
  "new_password_confirmation": "Potwierdź nowe hasło",
  "new_playlist": "nowa lista odtwarzania",
  "new_releases": "Nowe wydania",
  "new_releases_small": "nowe wydania",
  "new_scene": "Nowa scena",
  "new_schedule": "Nowy harmonogram",
  "new_step": "Nowy krok",
  "next": "Dalej",
  "next_song": "następny utwór",
  "no": "Nie",
  "no_active_rooms": "Brak aktywnych pomieszczeń",
  "no_all_off": "Nie, nie wyłączaj",
  "no_av": "Brak wejścia AV",
  "no_configuration": "Brak dostępnej konfiguracji",
  "no_content_available": "Brak dostępnych treści",
  "no_data": "Brak danych",
  "no_deezer_dis": "Nie, nie odłączaj Deezer",
  "no_devices_capabilities": "Brak możliwości zmiany ustawień urządzenia",
  "no_doors_available": "Brak dostępnych drzwi",
  "no_favourites": "Brak ulubionych",
  "no_keep_it": "Nie, zachowaj to",
  "no_lights_capabilities": "Brak regulowanych opraw oświetleniowych",
  "no_linked_homes": "Brak połączonych domów",
  "none": "Żadne",
  "no_network": "Brak połączenia z siecią",
  "no_preview_available": "Podgląd niedostępny",
  "no_projects": "Nie znaleziono żadnych projektów.",
  "no_rooms": "Brak pomieszczeń",
  "no_rooms_available": "Brak dostępnych pomieszczeń",
  "no_room_selected": "Nie wybrano pomieszczenia",
  "no_rooms_listening": "Żaden pokój nie odtwarza tego źródła",
  "no_scene": "Brak sceny",
  "no_scenes_favourites": "Brak ulubionych scen",
  "no_scenes_txt": "Wybierz sceny dla tego widoku",
  "no_search_results": "Brak wyników wyszukiwania",
  "no_servers": "Nie znaleziono żadnych serwerów",
  "no_shades_capabilities": "Brak możliwości sterowania roletą",
  "no_source": "- off -",
  "no_sources_available": "Brak dostępnych źródeł",
  "no_steps": "Ta scena nie zawiera żadnych kroków",
  "not_allowed": "Nie dozwolony",
  "not_allowed_l1": "Nie masz uprawnień",
  "notification": "Powiadomienie",
  "notification_permission_missing": "Basalte Home nie ma uprawnień do wysyłania powiadomień. Możesz włączyć powiadomienia w ustawieniach systemowych swojego urządzenia.",
  "notifications": "Powiadomienia",
  "not_linked": "Niepołączony",
  "not_now": "Nie teraz",
  "not_set": "Nie ustawiony",
  "no_users_l1": "Nie znaleziono żadnych użytkowników.",
  "no_users_l2": "Wybierz serwer, do którego przypisano użytkowników.",
  "now_playing": "Teraz odtwarza",
  "number_of_songs": "piosenki",
  "off": "Off",
  "offline_server": "Serwer offline",
  "offset_minutes": "Przesunięcie (minuty)",
  "ok": "OK",
  "on": "on",
  "onix_black": "Czarny",
  "online": "online",
  "only_png_jpeg_supported": "Można użyć obrazów tylko w formacie JPG lub PNG",
  "open": "Otwórz",
  "open_close_door": "Drzwi",
  "open_close_gate": "Brama",
  "open_close_window": "Okno",
  "open_settings": "Otwórz ustawienia",
  "open_spotify": "Otwórz Spotify",
  "open_spotify_l1": "Zamierzasz otowrzyć aplikację Spotify.",
  "open_state": "Otwarte",
  "or": "Lub",
  "order_cameras_by_holding_and_draging": "Ustaw kamery w odpowiedniej kolejności",
  "other": "Inne",
  "out": "Na zewnątrz",
  "page_order": "Kolejność stron/kafelków",
  "pair": "Sparuj",
  "password": "Hasło",
  "password_changed": "Hasło zostało zmienione",
  "password_confirmation": "Potwierdź hasło",
  "password_requirements": "Twoje hasło powinno składać się z co najmniej 8 znaków i zawierać co najmniej jedną wielką i małą literę oraz jedną cyfrę.",
  "permission_denied": "Odmowa dostępu",
  "permission_denied_go_to_settings_l1": "Ta funkcja wymaga zezwolenia, aby działać poprawnie.",
  "permission_denied_go_to_settings_l2": "Udziel aplikacji zezwolenia na dostęp do ustawień urządzenia, aby włączyć wszystkie funkcje.",
  "photo_from_camera": "Zrób zdjęcie",
  "photo_from_library": "Wybierz zdjęcie z biblioteki",
  "pick_room_function": "Wybierz pokój i funkcję",
  "play": "Play",
  "playback_gain": "Wzmocnienie odtwarzania",
  "playing_in": "odtwarzanie w…",
  "playlist": "Lista odtwarzania",
  "playlists": "Listy odtwarzania",
  "playlists_itunes": "Listy iTunes",
  "playlists_l": "Listy odtwarzania",
  "playlists_local": "Lokalne listy odtwarzania",
  "playlists_shared": "Udostępnione listy odtwarzania",
  "play_next": "Natsępna piosenka",
  "play_now": "Odtwarzaj teraz",
  "please_try_a_different_image": "Spróbuj dodać inny obraz",
  "please_try_again": "Spróbuj ponownie",
  "popular": "Popularne",
  "position": "Pozycja",
  "precipitation": "Opad atmosferyczny",
  "previous": "Wróć",
  "problems_continue_integrator": "Jeśli nadal masz problemy, skontaktuj się ze swoim integratorem.",
  "project": "Projekt",
  "project_image": "Obraz projektu.",
  "projects_network_error": "Nie można wczytać projektów z powodu błędu sieci.",
  "proximity_activation": "Aktywacja zbliżeniowa",
  "proximity_far": "Daleko",
  "proximity_medium": "Średnio",
  "proximity_near": "Blisko",
  "queue": "Kolejka",
  "radio": "Radio",
  "radio_error": "W czasie odzyskiwania stacji radiowych nie wystąpił żaden błąd.",
  "radio_stations": "Stacje radiowe",
  "radio_stations_other": "Inne stacje",
  "recently_played": "Ostatnio odtwarzane",
  "related_artists": "Powiązani artyści",
  "reload": "Przeładuj",
  "remember_me": "Zapamiętaj",
  "remote": "Zdalny",
  "remote_ellie_intercom_outdated": "Ellie nie została zaktualizowana",
  "remote_ellie_intercom_outdated_l1": "Ellie, z którą próbujesz się połączyć, wymaga aktualizacji oprogramowania.",
  "remote_ellie_intercom_outdated_l2": "Spróbuj ponownie po zaktualizowaniu.",
  "remove": "Usuń",
  "remove_alarm": "Usuń alarm",
  "remove_basalte_favourite": "Usuń ulubione Basalte",
  "remove_deezer_favourite": "Usuń ulubione Deezer",
  "remove_from_fav": "Usuń z ulubionych",
  "remove_schedule": "Usuń harmonogramy",
  "remove_spotify_favourite": "Usuń ulubione ze Spotfy",
  "remove_spotify_preset": "Usuń ustawienia Spotify",
  "remove_tidal_favourite": "Usuń ulubione TIDAL",
  "remove_txt_l1": "Zaraz to usuniesz.",
  "remove_txt_l2": "Czy chcesz kontynuować?",
  "rename": "Zmień nazwę",
  "repeat": "Powtórz",
  "replace_queue": "Zamień kolejkę",
  "resend_code": "Wyślij kod ponownie",
  "resend_confirmation_code": "Wyślij ponownie kod potwierdzający",
  "reset_image": "Zresetuj obraz",
  "reset_password": "Zresetuj hasło",
  "reset_password_l1": "Czy na pewno chcesz zresetować swoje hasło?",
  "restart": "Zrestartuj",
  "restart_app": "Zrestartuj aplikację",
  "restart_app_q": "Uruchom ponownie aplikację?",
  "restarting_server": "Restart serwera",
  "restart_music_server": "Zrestartuj serwer muzyczny",
  "restart_music_server_txt_l1": "Zaraz zrestartujesz serwer muzyczny.",
  "restart_server": "Zrestartuj serwer",
  "restart_server_txt_l1": "Zamierzasz zrestartować serwer",
  "restart_server_txt_l2": "Aplikacja połączy się ponownie automatycznie.",
  "retrieving": "Odzyskiwanie…",
  "retrieving_users_stop_text": "Błąd w odzyskaniu uzytkowników, dokonać poprawek w serwerze?",
  "retrieving_users_text": "Odzyskiwanie użytkowników",
  "right": "Dobrze",
  "room": "Pokój",
  "room_attic": "Poddasze",
  "room_ball_room": "Pokój bankietowy",
  "room_bar": "Bar",
  "room_basement": "Piwnica",
  "room_bathroom": "Łazienka",
  "room_bedroom": "Sypialnia",
  "room_corridor": "Korytarz",
  "room_dining_room": "Jadalnia",
  "room_dressing_room": "Garderoba",
  "room_driveway": "Podjazd",
  "room_entry": "Wejście",
  "room_family_room": "Pokój rodzinny",
  "room_foyer": "Foyer",
  "room_garage": "Garaż",
  "room_garden": "Ogród",
  "room_guest_room": "Pokój gościnny",
  "room_gym": "Siłownia",
  "room_hallway": "Korytarz",
  "room_hobby_room": "Pokój gier",
  "room_home_cinema": "Pokój kinowy",
  "room_home_office": "Gabinet",
  "room_images": "Zdjęcia pomieszczeń",
  "room_intercom_lost": "Połączenie utracone",
  "room_intercom_lost_sub": "Połączenie utracone, spróbuj ponownie",
  "room_intercom_unavailable": "Pomieszczenie niedostępne",
  "room_intercom_unavailable_sub": "To pomieszczenie jest obecnie niedostępne",
  "room_intercom_unreachable": "Pomieszczenie jest nieosiągalne",
  "room_intercom_unreachable_sub": "To pomieszczenie jest obecnie nieosiągalne",
  "room_kitchen": "Kuchnia",
  "room_laundry_room": "Pralnia",
  "room_library": "Biblioteka",
  "room_living_room": "Pokój dzienny",
  "room_master_bedroom": "Sypialnia główna",
  "room_media_room": "Pokój medialny",
  "room_meeting_room": "Pokój spotkań",
  "room_nook": "Kącik",
  "room_nursery": "Pokój dziecięcy",
  "room_pantry": "Spiżarnia",
  "room_parking_place": "Parking",
  "room_patio": "Patio",
  "room_pool": "Basen",
  "room_pool_house": "Domek przy basenie",
  "room_rack": "Rozdzielnia elektryczna",
  "room_room": "Pokój",
  "rooms": "Pokoje",
  "room_scene_no_functions": "Ten pokój nie ma żadnych funkcji",
  "room_settings": "Ustawienia pomieszczenia",
  "room_spa": "Spa",
  "room_stable": "Stajnia",
  "room_staircase": "Klatka schodowa",
  "room_terrace": "Taras",
  "room_toilet": "Toaleta",
  "room_utility_room": "Pomieszczenie gospodarcze",
  "room_wine_cellar": "Piwniczka z winami",
  "room_workshop": "Warsztat",
  "rotation": "Obrót",
  "save": "Zapisz",
  "save_as": "zapisz jako",
  "save_basalte_favourite": "Zapisz ulubione Basalte",
  "save_changes": "Zapisz zmiany",
  "save_changes_l1": "Niektóre zmiany nie są jeszcze zapisane",
  "save_changes_l2": "Czy chcesz je zapisać teraz?",
  "save_deezer_favourite": "Zapisz ulubione Deezer",
  "save_spotify_favourite": "Zapisz ulubione Spotify",
  "save_spotify_preset": "Zachowaj ustawienia Spotify",
  "save_tidal_favourite": "Zapisz ulubione TIDAL",
  "scene": "Scena",
  "scene_activated": "Scena aktywowana",
  "scene_away": "Poza domem",
  "scene_cooking": "Gotowanie",
  "scene_dinner": "Kolacja",
  "scene_error": "Błąd sceny",
  "scene_evening": "Wieczór",
  "scene_good_morning": "Codzienna",
  "scene_good_night": "Dobranoc",
  "scene_guests": "Goście",
  "scene_home": "Dom",
  "scene_image_error": "Błąd obrazu sceny",
  "scene_movie": "Kino",
  "scene_not_available": "Nie ma scen.",
  "scene_not_selected_l1": "Nie wybrałeś żadnej sceny.",
  "scene_not_selected_l2": "Wybierz scenę.",
  "scene_party": "Impreza",
  "scene_relax": "Relaks",
  "scenes": "Sceny",
  "scene_select_or_new": "Wybierz scenę lub utwórz nową.",
  "scene_vacation": "Wakacje",
  "scene_welcome": "Powitanie",
  "scene_workout": "Ćwiczenia",
  "schedule": "Timer",
  "schedule_at": "Zaplanuj na",
  "schedule_not_selected_l1": "Nie wybrałeś żadnego harmonogramu.",
  "schedule_not_selected_l2": "Wybierz harmonogram.",
  "scheduler": "Planer",
  "schedules": "Harmonogramy",
  "schedule_select_or_new": "Wybierz harmonogram lub stwórz nowy.",
  "screen_orientation": "Orientacja ekranu",
  "search": "Szukaj",
  "search_again": "Szukaj ponownie",
  "search_ph": "szukaj wykonawcy, piosenki, albumu…",
  "search_result": "Szukaj wyników dla…",
  "security": "Bezpieczeństwo",
  "select_admin": "Wybierz profil administratora, aby kontynuować proces aktualizacji",
  "select_home": "Wybierz swój dom",
  "selection": "Wybór",
  "selection_all": "dodaj wszystkie do wyboru",
  "select_photo": "Wybierz obraz",
  "select_server": "Wybierz serwer",
  "select_user": "Wybierz profil, aby się zalogować",
  "serial_number": "Numer seryjny",
  "server": "Serwer",
  "server_address": "Adres serwera",
  "server_basalte_cloud_no_connection": "Brak połączenia z chmurą serwera",
  "server_exists_already": "Serwer już istnieje!",
  "server_mac": "Adres MAC serwera",
  "servers": "Serwery",
  "server_status": "Status serwera",
  "server_update_info_l2": "proszę zaktualizować serwer Basalte do najnowszej wersji.",
  "server_update_required": "Wymagana aktualizacja serwera",
  "server_version": "Wersja serwera",
  "set": "Ustaw",
  "set_new_password": "Ustaw nowe hasło",
  "setpoint": "Temperatura zadana",
  "settings": "Ustawienia",
  "shade_blind": "Żaluzja",
  "shade_center": "Na środku",
  "shade_curtain": "Zasłona",
  "shade_left": "Lewa",
  "shade_pool_shutter": "Przesłona basenu",
  "shade_right": "Prawa",
  "shade_roman_blind": "Roleta rzymska",
  "shades": "Rolety",
  "shade_shade": "Roleta",
  "shade_shutter": "Przesłona",
  "shade_solar_protection": "Refleksola",
  "shade_venetian_blind": "Roleta wenecka",
  "shade_vertical_blind": "Żaluzja pionowa",
  "share": "udostępnij",
  "shared_playlist": "Udostępniona lista odtwarzania",
  "show_album": "Pokaż albumy",
  "show_artist": "Pokaż artystów",
  "show_background_art": "Pokaż tło",
  "show_more": "Pokaż więcej...",
  "show_status_bar": "Pokaż pasek stanu iPada",
  "show_status_bar_device": "Pokaż pasek stanu",
  "sign_in": "Logowanie",
  "sign_in_info_tidal_l1": "Wprowadź dane logowania do TIDAL'a",
  "sign_up": "Rejestracja",
  "single_room": "Pokój",
  "sleep": "Wygaszenie ekranu",
  "software_update": "Aktualizacja oprogramowania",
  "something_went_wrong": "Coś poszło nie tak",
  "songs": "Piosenki",
  "sonos": "Sonos",
  "sonos_unreachable": "Nie można odnaleźć Sonos'a.",
  "sonos_unreachable_txt_l1": "Sprawdź, czy Twoje urządzenie jest podłączone.",
  "sound_settings": "Ustawienia dźwięku",
  "source": "Źródło",
  "sources": "Źródła",
  "spotify": "Spotify",
  "spotify_beta": "Spotify (beta)",
  "spotify_connect": "Spotify Connect",
  "spotify_connect_instructions": "Spotify Connect",
  "spotify_connect_instructions_1": "Upewnij się, że serwer Core i urządzenie ze Spotify są w tej samej sieci",
  "spotify_connect_instructions_2": "Wybierz strumień serwera Core z wyboru urządzeń w aplikacji Spotify",
  "spotify_connect_instructions_body": "Odtwórz muzykę na swoich głośnikach, wykorzystując aplikację Spotify jako pilota.",
  "spotify_connect_instructions_title": "Chcesz posłuchać muzyki?",
  "spotify_disconnect_txt": "Zamierzasz rozłączyć konto Spotify z tym odtwarzaczem.",
  "spotify_free_user_txt": "Wymagana jest subskrypcja Spotify Premium",
  "spotify_no_link": "Nie połączono z kontem Spotify",
  "spotify_oauth_error": "Błąd uwierzytelnienia Spotify",
  "spotify_preset_error": "Błąd Spotify",
  "spotify_preset_error_l1": "Ustawienia Spotify nie mogą się załadować",
  "spotify_preset_error_l2": "Upewnij się że ważne konto Spotify połaczyło się z tym strumieniem wcześniej",
  "spotify_presets": "Presety Spotify",
  "start": "Początek",
  "start_page": "Strona startowa",
  "start_page_customisation": "Dostosuj stronę startową",
  "start_time_date": "Czas i data",
  "startup_view": "Widok początkowy",
  "startup_volume": "Głośność początkowa",
  "start_weather": "Informacje o pogodzie",
  "state_active": "Aktywny",
  "state_armed": "Uzbrojony",
  "state_closed": "Zamknięte",
  "state_disabled": "Wyłączony",
  "state_disarmed": "Rozbrojony",
  "state_down": "Na dół",
  "state_enabled": "Włączone",
  "state_false": "Fałszywe",
  "state_free": "Wolny",
  "state_high": "Wysoki",
  "state_in": "w",
  "state_inactive": "Nieaktywny",
  "state_left": "Lewo",
  "state_low": "Niski",
  "state_occupied": "Zajęty",
  "state_off": "Off",
  "state_on": "On",
  "state_opened": "Otwarty",
  "state_out": "Na zewnątrz",
  "state_right": "Prawo",
  "state_started": "Uruchomiony",
  "state_stopped": "Zatrzymany",
  "state_true": "Prawdziwe",
  "state_up": "W górę",
  "static": "Statyczny",
  "stations": "Stacje",
  "status": "Status",
  "stop": "Stop",
  "stream": "Strumień",
  "streaming": "Streaming",
  "streaming_services": "Serwisy streamingowe",
  "streams": "Strumienie",
  "streams_beta": "Strumienie (beta)",
  "subnet_mask": "Maska ​​podsieci",
  "sun": "Słońce",
  "sunrise": "Wschód słońca",
  "sunset": "Zachód słońca",
  "swipe_to_pan": "Przesuń palcem widok",
  "switch_server": "Zmień serwer",
  "synchronise": "Skanuj teraz",
  "system": "system",
  "system_setting": "Ustawienia systemowe",
  "temperature": "Temperatura",
  "temperature_unit": "Jednostka temperatury",
  "theme": "Motyw",
  "thermostat": "Termostat",
  "this_cannot_be_undone": "Nie będzie można cofnąć!",
  "this_week": "W tym tygodniu",
  "tidal": "TIDAL",
  "tidal_disconnect_txt": "Konto TIDAL zostanie odłączone od tego odtwarzacza.",
  "tidal_legacy_auth": "Wymagane logowanie TIDAL",
  "tidal_legacy_auth_l1": "Aby kontynuować korzystanie z TIDAL, zaloguj się ponownie.",
  "tidal_no_link": "Nie połączono z kontem TIDAL",
  "tidal_oauth_error": "Błąd uwierzytelnienia TIDAL",
  "tidal_rising": "TIDAL Rising",
  "time": "Czas",
  "time_format": "Format godziny",
  "timer": "Timer",
  "timers": "Timery",
  "title": "Tytuł",
  "today": "Dzisiaj",
  "tomorrow": "Jutro",
  "top_tracks": "Ulubione utwory",
  "treble": "Wysokie tony",
  "try_again": "Spróbuj ponownie",
  "try_again_later": "Spróbuj ponownie później",
  "try_again_later_or_contact_support": "Spróbuj ponownie później lub skontaktuj się z supportem",
  "turned_off": "Wyłączony",
  "turned_on": "Włączony",
  "turn_off": "Wyłącz",
  "turn_on": "Włącz",
  "turn_on_default_rooms": "Włączyć domyślne pomieszczenia dla tego źródła?",
  "turn_on_music": "Włącz muzykę",
  "turn_on_tv": "Włącz telewizor",
  "tv": "Telewizja",
  "tv_off": "Wyłączyć telewizor",
  "tv_off_current_room": "Wyłącz TV w tym pomieszczeniu",
  "unavailable": "Niedostępny",
  "unavailable_src": "Źródło niedostępne",
  "unavailable_src_txt": "Wybrane źródło nie jest dostępne",
  "ungroup": "Rozdziel",
  "unknown": "Nieznany",
  "unknown_account": "Nieznane konto",
  "unknown_account_l1": "Ten adres e-mail nie jest kontem Basalte.",
  "unknown_av": "Nieznane wejście AV",
  "unknown_src": "Źródło nieznane",
  "unknown_src_txt": "Aktualne źródło jest nieznane dla tego serwera.",
  "unlink": "Odłącz",
  "unlink_server": "Odłącz od konta Basalte",
  "unlink_server_full_sub": "Odłączyć ten projekt od konta Basalte? Nie będzie dostępny poza Twoją siecią lokalną.",
  "unreachable": "Nieosiągalny",
  "unshare": "Funkcja udostępnienia wyłączona",
  "unsupported_browser": "Niewspierana przeglądarka",
  "unsupported_browser_l1": "Część funkcji może nie działać poprawnie.",
  "unsupported_browser_l2": "Dla poprawnego działania najlepiej użyj Chrome lub Safari.",
  "unsupported_browser_live": "Przeglądarka nie jest wspierana. Dla poprawnego działania użyj Chrome lub Safari.",
  "up": "W górę",
  "update": "Aktualizuj",
  "update_available": "Aktualizuj",
  "update_available_full": "Dostępna aktualizacja",
  "update_info_l1": "W celu zapewnienia jak najlepszego doświadczenia,",
  "update_in_progress": "W trakcie aktualizacji",
  "update_library": "Skanuj ponownie bibliotekę muzyki",
  "update_music_server": "Aktualizuj serwer muzyczny",
  "update_scene": "Uaktualnij scenę",
  "update_scene_l1": "Zapisz obecne ustawienia sceny",
  "update_server": "Uaktulanij serwer",
  "updating_library": "Skanowanie biblioteki utworów",
  "updating_server": "Aktualizacja serwera",
  "updating_server_info": "Nie wyłączaj serwera",
  "up_to_date": "Aktualny",
  "username": "Użytkownik",
  "uv": "UV",
  "value_invalid": "Podana wartość parametru jest nieprawidłowa",
  "verify": "Zwiryfikuj",
  "verifying": "weryfikuję",
  "version": "Wersja",
  "video_background": "Wideo w tle",
  "video_go_to": "Idź do TV",
  "video_is_active": "TV jest aktywny",
  "video_is_playing": "Wideo jest odtwarzane",
  "video_unavailable": "Telewizor lub system audio nie jest dostępny",
  "volume": "Głośność",
  "warning": "Uwaga",
  "water": "Woda",
  "weather": "Pogoda",
  "weather_daily_data_unavailable": "Obecnie nie są dostępne codzienne informacje o pogodzie",
  "weather_hourly_data_unavailable": "Obecnie nie są dostępne godzinowe informacje o pogodzie",
  "weather_information": "Informacje pogodowe",
  "week": "Tydzień",
  "weekly": "Tygodniowo",
  "what": "Co",
  "what_music": "Jaka muzyka",
  "when": "Kiedy",
  "where": "Gdzie",
  "who": "Kto",
  "wind": "Wiatr",
  "wrong_password": "Nieprawidłowe hasło",
  "wrong_password_txt": "Wprowadzone hasło nie pasuje do hasła użytkownika",
  "wrong_username_and_or_password": "Zła nazwa użytkownika i/lub hasło",
  "year": "Rok",
  "yes": "Tak",
  "yes_all_off": "Tak, wyłącz wszystkie",
  "yes_cur_off": "Tak, wyłącz bieżące",
  "yes_deezer_dis": "Tak, odłącz konto Deezer",
  "yes_delete_it": "Tak, usuń",
  "yesterday": "Wczoraj",
  "zone": "Pokój",
  "zones": "Pokoje"
}
