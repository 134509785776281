// source: common.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.be.basalte.config.common.AlarmClock', null, global);
goog.exportSymbol('proto.be.basalte.config.common.AlarmClock.AlarmTypeCase', null, global);
goog.exportSymbol('proto.be.basalte.config.common.AlarmClock.TimeTypeCase', null, global);
goog.exportSymbol('proto.be.basalte.config.common.Colour', null, global);
goog.exportSymbol('proto.be.basalte.config.common.Credentials', null, global);
goog.exportSymbol('proto.be.basalte.config.common.DPTID', null, global);
goog.exportSymbol('proto.be.basalte.config.common.DataBlob', null, global);
goog.exportSymbol('proto.be.basalte.config.common.DataBlob.DataCase', null, global);
goog.exportSymbol('proto.be.basalte.config.common.IPConfig', null, global);
goog.exportSymbol('proto.be.basalte.config.common.IPConfigv4', null, global);
goog.exportSymbol('proto.be.basalte.config.common.Message', null, global);
goog.exportSymbol('proto.be.basalte.config.common.Message.Content', null, global);
goog.exportSymbol('proto.be.basalte.config.common.Message.MessageOrigin', null, global);
goog.exportSymbol('proto.be.basalte.config.common.Message.MessageType', null, global);
goog.exportSymbol('proto.be.basalte.config.common.Playlist', null, global);
goog.exportSymbol('proto.be.basalte.config.common.Preset', null, global);
goog.exportSymbol('proto.be.basalte.config.common.Preset.PresetTypeCase', null, global);
goog.exportSymbol('proto.be.basalte.config.common.RepeatedTime', null, global);
goog.exportSymbol('proto.be.basalte.config.common.RoomCluster', null, global);
goog.exportSymbol('proto.be.basalte.config.common.SerialConfig', null, global);
goog.exportSymbol('proto.be.basalte.config.common.SerialConfig.Baudrate', null, global);
goog.exportSymbol('proto.be.basalte.config.common.SerialConfig.DataSize', null, global);
goog.exportSymbol('proto.be.basalte.config.common.SerialConfig.FlowControl', null, global);
goog.exportSymbol('proto.be.basalte.config.common.SerialConfig.Parity', null, global);
goog.exportSymbol('proto.be.basalte.config.common.SerialConfig.StopBits', null, global);
goog.exportSymbol('proto.be.basalte.config.common.SunriseSunsetTime', null, global);
goog.exportSymbol('proto.be.basalte.config.common.SunriseSunsetTime.OffsetTypeCase', null, global);
goog.exportSymbol('proto.be.basalte.config.common.Track', null, global);
goog.exportSymbol('proto.be.basalte.config.common.Version', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.common.Preset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.be.basalte.config.common.Preset.oneofGroups_);
};
goog.inherits(proto.be.basalte.config.common.Preset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.common.Preset.displayName = 'proto.be.basalte.config.common.Preset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.common.RepeatedTime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.be.basalte.config.common.RepeatedTime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.common.RepeatedTime.displayName = 'proto.be.basalte.config.common.RepeatedTime';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.common.SunriseSunsetTime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.be.basalte.config.common.SunriseSunsetTime.oneofGroups_);
};
goog.inherits(proto.be.basalte.config.common.SunriseSunsetTime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.common.SunriseSunsetTime.displayName = 'proto.be.basalte.config.common.SunriseSunsetTime';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.common.AlarmClock = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.be.basalte.config.common.AlarmClock.repeatedFields_, proto.be.basalte.config.common.AlarmClock.oneofGroups_);
};
goog.inherits(proto.be.basalte.config.common.AlarmClock, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.common.AlarmClock.displayName = 'proto.be.basalte.config.common.AlarmClock';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.common.Playlist = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.be.basalte.config.common.Playlist.repeatedFields_, null);
};
goog.inherits(proto.be.basalte.config.common.Playlist, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.common.Playlist.displayName = 'proto.be.basalte.config.common.Playlist';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.common.Track = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.be.basalte.config.common.Track, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.common.Track.displayName = 'proto.be.basalte.config.common.Track';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.common.Version = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.be.basalte.config.common.Version, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.common.Version.displayName = 'proto.be.basalte.config.common.Version';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.common.RoomCluster = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.be.basalte.config.common.RoomCluster.repeatedFields_, null);
};
goog.inherits(proto.be.basalte.config.common.RoomCluster, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.common.RoomCluster.displayName = 'proto.be.basalte.config.common.RoomCluster';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.common.IPConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.be.basalte.config.common.IPConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.common.IPConfig.displayName = 'proto.be.basalte.config.common.IPConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.common.IPConfigv4 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.be.basalte.config.common.IPConfigv4, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.common.IPConfigv4.displayName = 'proto.be.basalte.config.common.IPConfigv4';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.common.SerialConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.be.basalte.config.common.SerialConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.common.SerialConfig.displayName = 'proto.be.basalte.config.common.SerialConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.common.Credentials = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.be.basalte.config.common.Credentials, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.common.Credentials.displayName = 'proto.be.basalte.config.common.Credentials';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.common.Message = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.be.basalte.config.common.Message, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.common.Message.displayName = 'proto.be.basalte.config.common.Message';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.common.Message.Content = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.be.basalte.config.common.Message.Content, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.common.Message.Content.displayName = 'proto.be.basalte.config.common.Message.Content';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.common.DPTID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.be.basalte.config.common.DPTID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.common.DPTID.displayName = 'proto.be.basalte.config.common.DPTID';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.common.DataBlob = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.be.basalte.config.common.DataBlob.oneofGroups_);
};
goog.inherits(proto.be.basalte.config.common.DataBlob, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.common.DataBlob.displayName = 'proto.be.basalte.config.common.DataBlob';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.be.basalte.config.common.Preset.oneofGroups_ = [[2,3,5,6,7,8]];

/**
 * @enum {number}
 */
proto.be.basalte.config.common.Preset.PresetTypeCase = {
  PRESET_TYPE_NOT_SET: 0,
  PLAYLIST_UUID: 2,
  TUNEIN_GID: 3,
  DEEZER_ID: 5,
  SPOTIFY_PRESET: 6,
  PRESET_CODE: 7,
  TIDAL_ID: 8
};

/**
 * @return {proto.be.basalte.config.common.Preset.PresetTypeCase}
 */
proto.be.basalte.config.common.Preset.prototype.getPresetTypeCase = function() {
  return /** @type {proto.be.basalte.config.common.Preset.PresetTypeCase} */(jspb.Message.computeOneofCase(this, proto.be.basalte.config.common.Preset.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.common.Preset.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.common.Preset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.common.Preset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.common.Preset.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    playlistUuid: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    tuneinGid: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    deezerId: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    spotifyPreset: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
    presetCode: (f = jspb.Message.getField(msg, 7)) == null ? undefined : f,
    tidalId: (f = jspb.Message.getField(msg, 8)) == null ? undefined : f,
    knxValue: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    beoDigit: (f = jspb.Message.getField(msg, 9)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.common.Preset}
 */
proto.be.basalte.config.common.Preset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.common.Preset;
  return proto.be.basalte.config.common.Preset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.common.Preset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.common.Preset}
 */
proto.be.basalte.config.common.Preset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaylistUuid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTuneinGid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeezerId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpotifyPreset(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPresetCode(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTidalId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setKnxValue(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBeoDigit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.common.Preset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.common.Preset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.common.Preset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.common.Preset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeUint32(
      9,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.be.basalte.config.common.Preset.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.common.Preset} returns this
 */
proto.be.basalte.config.common.Preset.prototype.setName = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.Preset} returns this
 */
proto.be.basalte.config.common.Preset.prototype.clearName = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.Preset.prototype.hasName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string playlist_uuid = 2;
 * @return {string}
 */
proto.be.basalte.config.common.Preset.prototype.getPlaylistUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.common.Preset} returns this
 */
proto.be.basalte.config.common.Preset.prototype.setPlaylistUuid = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.be.basalte.config.common.Preset.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.Preset} returns this
 */
proto.be.basalte.config.common.Preset.prototype.clearPlaylistUuid = function() {
  return jspb.Message.setOneofField(this, 2, proto.be.basalte.config.common.Preset.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.Preset.prototype.hasPlaylistUuid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string tunein_gid = 3;
 * @return {string}
 */
proto.be.basalte.config.common.Preset.prototype.getTuneinGid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.common.Preset} returns this
 */
proto.be.basalte.config.common.Preset.prototype.setTuneinGid = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.be.basalte.config.common.Preset.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.Preset} returns this
 */
proto.be.basalte.config.common.Preset.prototype.clearTuneinGid = function() {
  return jspb.Message.setOneofField(this, 3, proto.be.basalte.config.common.Preset.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.Preset.prototype.hasTuneinGid = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string deezer_id = 5;
 * @return {string}
 */
proto.be.basalte.config.common.Preset.prototype.getDeezerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.common.Preset} returns this
 */
proto.be.basalte.config.common.Preset.prototype.setDeezerId = function(value) {
  return jspb.Message.setOneofField(this, 5, proto.be.basalte.config.common.Preset.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.Preset} returns this
 */
proto.be.basalte.config.common.Preset.prototype.clearDeezerId = function() {
  return jspb.Message.setOneofField(this, 5, proto.be.basalte.config.common.Preset.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.Preset.prototype.hasDeezerId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string spotify_preset = 6;
 * @return {string}
 */
proto.be.basalte.config.common.Preset.prototype.getSpotifyPreset = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.common.Preset} returns this
 */
proto.be.basalte.config.common.Preset.prototype.setSpotifyPreset = function(value) {
  return jspb.Message.setOneofField(this, 6, proto.be.basalte.config.common.Preset.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.Preset} returns this
 */
proto.be.basalte.config.common.Preset.prototype.clearSpotifyPreset = function() {
  return jspb.Message.setOneofField(this, 6, proto.be.basalte.config.common.Preset.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.Preset.prototype.hasSpotifyPreset = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string preset_code = 7;
 * @return {string}
 */
proto.be.basalte.config.common.Preset.prototype.getPresetCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.common.Preset} returns this
 */
proto.be.basalte.config.common.Preset.prototype.setPresetCode = function(value) {
  return jspb.Message.setOneofField(this, 7, proto.be.basalte.config.common.Preset.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.Preset} returns this
 */
proto.be.basalte.config.common.Preset.prototype.clearPresetCode = function() {
  return jspb.Message.setOneofField(this, 7, proto.be.basalte.config.common.Preset.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.Preset.prototype.hasPresetCode = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string tidal_id = 8;
 * @return {string}
 */
proto.be.basalte.config.common.Preset.prototype.getTidalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.common.Preset} returns this
 */
proto.be.basalte.config.common.Preset.prototype.setTidalId = function(value) {
  return jspb.Message.setOneofField(this, 8, proto.be.basalte.config.common.Preset.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.Preset} returns this
 */
proto.be.basalte.config.common.Preset.prototype.clearTidalId = function() {
  return jspb.Message.setOneofField(this, 8, proto.be.basalte.config.common.Preset.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.Preset.prototype.hasTidalId = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional uint32 knx_value = 4;
 * @return {number}
 */
proto.be.basalte.config.common.Preset.prototype.getKnxValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.common.Preset} returns this
 */
proto.be.basalte.config.common.Preset.prototype.setKnxValue = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.Preset} returns this
 */
proto.be.basalte.config.common.Preset.prototype.clearKnxValue = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.Preset.prototype.hasKnxValue = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional uint32 beo_digit = 9;
 * @return {number}
 */
proto.be.basalte.config.common.Preset.prototype.getBeoDigit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.common.Preset} returns this
 */
proto.be.basalte.config.common.Preset.prototype.setBeoDigit = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.Preset} returns this
 */
proto.be.basalte.config.common.Preset.prototype.clearBeoDigit = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.Preset.prototype.hasBeoDigit = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.common.RepeatedTime.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.common.RepeatedTime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.common.RepeatedTime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.common.RepeatedTime.toObject = function(includeInstance, msg) {
  var f, obj = {
    hour: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    minute: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    weekdays: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.common.RepeatedTime}
 */
proto.be.basalte.config.common.RepeatedTime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.common.RepeatedTime;
  return proto.be.basalte.config.common.RepeatedTime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.common.RepeatedTime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.common.RepeatedTime}
 */
proto.be.basalte.config.common.RepeatedTime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHour(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinute(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWeekdays(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.common.RepeatedTime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.common.RepeatedTime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.common.RepeatedTime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.common.RepeatedTime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint32 hour = 1;
 * @return {number}
 */
proto.be.basalte.config.common.RepeatedTime.prototype.getHour = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.common.RepeatedTime} returns this
 */
proto.be.basalte.config.common.RepeatedTime.prototype.setHour = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.RepeatedTime} returns this
 */
proto.be.basalte.config.common.RepeatedTime.prototype.clearHour = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.RepeatedTime.prototype.hasHour = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 minute = 2;
 * @return {number}
 */
proto.be.basalte.config.common.RepeatedTime.prototype.getMinute = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.common.RepeatedTime} returns this
 */
proto.be.basalte.config.common.RepeatedTime.prototype.setMinute = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.RepeatedTime} returns this
 */
proto.be.basalte.config.common.RepeatedTime.prototype.clearMinute = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.RepeatedTime.prototype.hasMinute = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint32 weekdays = 3;
 * @return {number}
 */
proto.be.basalte.config.common.RepeatedTime.prototype.getWeekdays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.common.RepeatedTime} returns this
 */
proto.be.basalte.config.common.RepeatedTime.prototype.setWeekdays = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.RepeatedTime} returns this
 */
proto.be.basalte.config.common.RepeatedTime.prototype.clearWeekdays = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.RepeatedTime.prototype.hasWeekdays = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.be.basalte.config.common.SunriseSunsetTime.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.be.basalte.config.common.SunriseSunsetTime.OffsetTypeCase = {
  OFFSET_TYPE_NOT_SET: 0,
  SUNRISE_OFFSET: 1,
  SUNSET_OFFSET: 2
};

/**
 * @return {proto.be.basalte.config.common.SunriseSunsetTime.OffsetTypeCase}
 */
proto.be.basalte.config.common.SunriseSunsetTime.prototype.getOffsetTypeCase = function() {
  return /** @type {proto.be.basalte.config.common.SunriseSunsetTime.OffsetTypeCase} */(jspb.Message.computeOneofCase(this, proto.be.basalte.config.common.SunriseSunsetTime.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.common.SunriseSunsetTime.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.common.SunriseSunsetTime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.common.SunriseSunsetTime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.common.SunriseSunsetTime.toObject = function(includeInstance, msg) {
  var f, obj = {
    sunriseOffset: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    sunsetOffset: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    weekdays: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.common.SunriseSunsetTime}
 */
proto.be.basalte.config.common.SunriseSunsetTime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.common.SunriseSunsetTime;
  return proto.be.basalte.config.common.SunriseSunsetTime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.common.SunriseSunsetTime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.common.SunriseSunsetTime}
 */
proto.be.basalte.config.common.SunriseSunsetTime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSunriseOffset(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSunsetOffset(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWeekdays(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.common.SunriseSunsetTime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.common.SunriseSunsetTime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.common.SunriseSunsetTime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.common.SunriseSunsetTime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional int32 sunrise_offset = 1;
 * @return {number}
 */
proto.be.basalte.config.common.SunriseSunsetTime.prototype.getSunriseOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.common.SunriseSunsetTime} returns this
 */
proto.be.basalte.config.common.SunriseSunsetTime.prototype.setSunriseOffset = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.be.basalte.config.common.SunriseSunsetTime.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.SunriseSunsetTime} returns this
 */
proto.be.basalte.config.common.SunriseSunsetTime.prototype.clearSunriseOffset = function() {
  return jspb.Message.setOneofField(this, 1, proto.be.basalte.config.common.SunriseSunsetTime.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.SunriseSunsetTime.prototype.hasSunriseOffset = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 sunset_offset = 2;
 * @return {number}
 */
proto.be.basalte.config.common.SunriseSunsetTime.prototype.getSunsetOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.common.SunriseSunsetTime} returns this
 */
proto.be.basalte.config.common.SunriseSunsetTime.prototype.setSunsetOffset = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.be.basalte.config.common.SunriseSunsetTime.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.SunriseSunsetTime} returns this
 */
proto.be.basalte.config.common.SunriseSunsetTime.prototype.clearSunsetOffset = function() {
  return jspb.Message.setOneofField(this, 2, proto.be.basalte.config.common.SunriseSunsetTime.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.SunriseSunsetTime.prototype.hasSunsetOffset = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint32 weekdays = 3;
 * @return {number}
 */
proto.be.basalte.config.common.SunriseSunsetTime.prototype.getWeekdays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.common.SunriseSunsetTime} returns this
 */
proto.be.basalte.config.common.SunriseSunsetTime.prototype.setWeekdays = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.SunriseSunsetTime} returns this
 */
proto.be.basalte.config.common.SunriseSunsetTime.prototype.clearWeekdays = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.SunriseSunsetTime.prototype.hasWeekdays = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.be.basalte.config.common.AlarmClock.repeatedFields_ = [5];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.be.basalte.config.common.AlarmClock.oneofGroups_ = [[3,4],[7,8]];

/**
 * @enum {number}
 */
proto.be.basalte.config.common.AlarmClock.TimeTypeCase = {
  TIME_TYPE_NOT_SET: 0,
  REPEAT: 3,
  DATETIME: 4
};

/**
 * @return {proto.be.basalte.config.common.AlarmClock.TimeTypeCase}
 */
proto.be.basalte.config.common.AlarmClock.prototype.getTimeTypeCase = function() {
  return /** @type {proto.be.basalte.config.common.AlarmClock.TimeTypeCase} */(jspb.Message.computeOneofCase(this, proto.be.basalte.config.common.AlarmClock.oneofGroups_[0]));
};

/**
 * @enum {number}
 */
proto.be.basalte.config.common.AlarmClock.AlarmTypeCase = {
  ALARM_TYPE_NOT_SET: 0,
  PLAYLIST_UUID: 7,
  TUNEIN_GID: 8
};

/**
 * @return {proto.be.basalte.config.common.AlarmClock.AlarmTypeCase}
 */
proto.be.basalte.config.common.AlarmClock.prototype.getAlarmTypeCase = function() {
  return /** @type {proto.be.basalte.config.common.AlarmClock.AlarmTypeCase} */(jspb.Message.computeOneofCase(this, proto.be.basalte.config.common.AlarmClock.oneofGroups_[1]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.common.AlarmClock.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.common.AlarmClock.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.common.AlarmClock} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.common.AlarmClock.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: (f = jspb.Message.getField(msg, 9)) == null ? undefined : f,
    enabled: (f = jspb.Message.getBooleanField(msg, 1)) == null ? undefined : f,
    volume: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    repeat: (f = msg.getRepeat()) && proto.be.basalte.config.common.RepeatedTime.toObject(includeInstance, f),
    datetime: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    roomUuidsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    playerUuid: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
    playlistUuid: (f = jspb.Message.getField(msg, 7)) == null ? undefined : f,
    tuneinGid: (f = jspb.Message.getField(msg, 8)) == null ? undefined : f,
    preset: (f = msg.getPreset()) && proto.be.basalte.config.common.Preset.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.common.AlarmClock}
 */
proto.be.basalte.config.common.AlarmClock.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.common.AlarmClock;
  return proto.be.basalte.config.common.AlarmClock.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.common.AlarmClock} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.common.AlarmClock}
 */
proto.be.basalte.config.common.AlarmClock.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVolume(value);
      break;
    case 3:
      var value = new proto.be.basalte.config.common.RepeatedTime;
      reader.readMessage(value,proto.be.basalte.config.common.RepeatedTime.deserializeBinaryFromReader);
      msg.setRepeat(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDatetime(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addRoomUuids(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayerUuid(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaylistUuid(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTuneinGid(value);
      break;
    case 10:
      var value = new proto.be.basalte.config.common.Preset;
      reader.readMessage(value,proto.be.basalte.config.common.Preset.deserializeBinaryFromReader);
      msg.setPreset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.common.AlarmClock.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.common.AlarmClock.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.common.AlarmClock} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.common.AlarmClock.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getRepeat();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.be.basalte.config.common.RepeatedTime.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getRoomUuidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPreset();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.be.basalte.config.common.Preset.serializeBinaryToWriter
    );
  }
};


/**
 * optional string uuid = 9;
 * @return {string}
 */
proto.be.basalte.config.common.AlarmClock.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.common.AlarmClock} returns this
 */
proto.be.basalte.config.common.AlarmClock.prototype.setUuid = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.AlarmClock} returns this
 */
proto.be.basalte.config.common.AlarmClock.prototype.clearUuid = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.AlarmClock.prototype.hasUuid = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool enabled = 1;
 * @return {boolean}
 */
proto.be.basalte.config.common.AlarmClock.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.be.basalte.config.common.AlarmClock} returns this
 */
proto.be.basalte.config.common.AlarmClock.prototype.setEnabled = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.AlarmClock} returns this
 */
proto.be.basalte.config.common.AlarmClock.prototype.clearEnabled = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.AlarmClock.prototype.hasEnabled = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 volume = 2;
 * @return {number}
 */
proto.be.basalte.config.common.AlarmClock.prototype.getVolume = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.common.AlarmClock} returns this
 */
proto.be.basalte.config.common.AlarmClock.prototype.setVolume = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.AlarmClock} returns this
 */
proto.be.basalte.config.common.AlarmClock.prototype.clearVolume = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.AlarmClock.prototype.hasVolume = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional RepeatedTime repeat = 3;
 * @return {?proto.be.basalte.config.common.RepeatedTime}
 */
proto.be.basalte.config.common.AlarmClock.prototype.getRepeat = function() {
  return /** @type{?proto.be.basalte.config.common.RepeatedTime} */ (
    jspb.Message.getWrapperField(this, proto.be.basalte.config.common.RepeatedTime, 3));
};


/**
 * @param {?proto.be.basalte.config.common.RepeatedTime|undefined} value
 * @return {!proto.be.basalte.config.common.AlarmClock} returns this
*/
proto.be.basalte.config.common.AlarmClock.prototype.setRepeat = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.be.basalte.config.common.AlarmClock.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.be.basalte.config.common.AlarmClock} returns this
 */
proto.be.basalte.config.common.AlarmClock.prototype.clearRepeat = function() {
  return this.setRepeat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.AlarmClock.prototype.hasRepeat = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 datetime = 4;
 * @return {number}
 */
proto.be.basalte.config.common.AlarmClock.prototype.getDatetime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.common.AlarmClock} returns this
 */
proto.be.basalte.config.common.AlarmClock.prototype.setDatetime = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.be.basalte.config.common.AlarmClock.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.AlarmClock} returns this
 */
proto.be.basalte.config.common.AlarmClock.prototype.clearDatetime = function() {
  return jspb.Message.setOneofField(this, 4, proto.be.basalte.config.common.AlarmClock.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.AlarmClock.prototype.hasDatetime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated string room_uuids = 5;
 * @return {!Array<string>}
 */
proto.be.basalte.config.common.AlarmClock.prototype.getRoomUuidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.be.basalte.config.common.AlarmClock} returns this
 */
proto.be.basalte.config.common.AlarmClock.prototype.setRoomUuidsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.be.basalte.config.common.AlarmClock} returns this
 */
proto.be.basalte.config.common.AlarmClock.prototype.addRoomUuids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.be.basalte.config.common.AlarmClock} returns this
 */
proto.be.basalte.config.common.AlarmClock.prototype.clearRoomUuidsList = function() {
  return this.setRoomUuidsList([]);
};


/**
 * optional string player_uuid = 6;
 * @return {string}
 */
proto.be.basalte.config.common.AlarmClock.prototype.getPlayerUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.common.AlarmClock} returns this
 */
proto.be.basalte.config.common.AlarmClock.prototype.setPlayerUuid = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.AlarmClock} returns this
 */
proto.be.basalte.config.common.AlarmClock.prototype.clearPlayerUuid = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.AlarmClock.prototype.hasPlayerUuid = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string playlist_uuid = 7;
 * @return {string}
 */
proto.be.basalte.config.common.AlarmClock.prototype.getPlaylistUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.common.AlarmClock} returns this
 */
proto.be.basalte.config.common.AlarmClock.prototype.setPlaylistUuid = function(value) {
  return jspb.Message.setOneofField(this, 7, proto.be.basalte.config.common.AlarmClock.oneofGroups_[1], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.AlarmClock} returns this
 */
proto.be.basalte.config.common.AlarmClock.prototype.clearPlaylistUuid = function() {
  return jspb.Message.setOneofField(this, 7, proto.be.basalte.config.common.AlarmClock.oneofGroups_[1], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.AlarmClock.prototype.hasPlaylistUuid = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string tunein_gid = 8;
 * @return {string}
 */
proto.be.basalte.config.common.AlarmClock.prototype.getTuneinGid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.common.AlarmClock} returns this
 */
proto.be.basalte.config.common.AlarmClock.prototype.setTuneinGid = function(value) {
  return jspb.Message.setOneofField(this, 8, proto.be.basalte.config.common.AlarmClock.oneofGroups_[1], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.AlarmClock} returns this
 */
proto.be.basalte.config.common.AlarmClock.prototype.clearTuneinGid = function() {
  return jspb.Message.setOneofField(this, 8, proto.be.basalte.config.common.AlarmClock.oneofGroups_[1], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.AlarmClock.prototype.hasTuneinGid = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Preset preset = 10;
 * @return {?proto.be.basalte.config.common.Preset}
 */
proto.be.basalte.config.common.AlarmClock.prototype.getPreset = function() {
  return /** @type{?proto.be.basalte.config.common.Preset} */ (
    jspb.Message.getWrapperField(this, proto.be.basalte.config.common.Preset, 10));
};


/**
 * @param {?proto.be.basalte.config.common.Preset|undefined} value
 * @return {!proto.be.basalte.config.common.AlarmClock} returns this
*/
proto.be.basalte.config.common.AlarmClock.prototype.setPreset = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.be.basalte.config.common.AlarmClock} returns this
 */
proto.be.basalte.config.common.AlarmClock.prototype.clearPreset = function() {
  return this.setPreset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.AlarmClock.prototype.hasPreset = function() {
  return jspb.Message.getField(this, 10) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.be.basalte.config.common.Playlist.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.common.Playlist.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.common.Playlist.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.common.Playlist} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.common.Playlist.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    fileUuid: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    ownerPlayerUuid: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    shared: (f = jspb.Message.getBooleanField(msg, 4)) == null ? undefined : f,
    itunesLibraryId: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    itunesPlaylistId: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
    modifiedTime: (f = jspb.Message.getField(msg, 8)) == null ? undefined : f,
    tracksList: jspb.Message.toObjectList(msg.getTracksList(),
    proto.be.basalte.config.common.Track.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.common.Playlist}
 */
proto.be.basalte.config.common.Playlist.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.common.Playlist;
  return proto.be.basalte.config.common.Playlist.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.common.Playlist} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.common.Playlist}
 */
proto.be.basalte.config.common.Playlist.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileUuid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerPlayerUuid(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShared(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setItunesLibraryId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setItunesPlaylistId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setModifiedTime(value);
      break;
    case 9:
      var value = new proto.be.basalte.config.common.Track;
      reader.readMessage(value,proto.be.basalte.config.common.Track.deserializeBinaryFromReader);
      msg.addTracks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.common.Playlist.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.common.Playlist.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.common.Playlist} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.common.Playlist.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeUint64(
      8,
      f
    );
  }
  f = message.getTracksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.be.basalte.config.common.Track.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.be.basalte.config.common.Playlist.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.common.Playlist} returns this
 */
proto.be.basalte.config.common.Playlist.prototype.setName = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.Playlist} returns this
 */
proto.be.basalte.config.common.Playlist.prototype.clearName = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.Playlist.prototype.hasName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string file_uuid = 2;
 * @return {string}
 */
proto.be.basalte.config.common.Playlist.prototype.getFileUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.common.Playlist} returns this
 */
proto.be.basalte.config.common.Playlist.prototype.setFileUuid = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.Playlist} returns this
 */
proto.be.basalte.config.common.Playlist.prototype.clearFileUuid = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.Playlist.prototype.hasFileUuid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string owner_player_uuid = 3;
 * @return {string}
 */
proto.be.basalte.config.common.Playlist.prototype.getOwnerPlayerUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.common.Playlist} returns this
 */
proto.be.basalte.config.common.Playlist.prototype.setOwnerPlayerUuid = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.Playlist} returns this
 */
proto.be.basalte.config.common.Playlist.prototype.clearOwnerPlayerUuid = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.Playlist.prototype.hasOwnerPlayerUuid = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool shared = 4;
 * @return {boolean}
 */
proto.be.basalte.config.common.Playlist.prototype.getShared = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.be.basalte.config.common.Playlist} returns this
 */
proto.be.basalte.config.common.Playlist.prototype.setShared = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.Playlist} returns this
 */
proto.be.basalte.config.common.Playlist.prototype.clearShared = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.Playlist.prototype.hasShared = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string itunes_library_id = 5;
 * @return {string}
 */
proto.be.basalte.config.common.Playlist.prototype.getItunesLibraryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.common.Playlist} returns this
 */
proto.be.basalte.config.common.Playlist.prototype.setItunesLibraryId = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.Playlist} returns this
 */
proto.be.basalte.config.common.Playlist.prototype.clearItunesLibraryId = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.Playlist.prototype.hasItunesLibraryId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string itunes_playlist_id = 6;
 * @return {string}
 */
proto.be.basalte.config.common.Playlist.prototype.getItunesPlaylistId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.common.Playlist} returns this
 */
proto.be.basalte.config.common.Playlist.prototype.setItunesPlaylistId = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.Playlist} returns this
 */
proto.be.basalte.config.common.Playlist.prototype.clearItunesPlaylistId = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.Playlist.prototype.hasItunesPlaylistId = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional uint64 modified_time = 8;
 * @return {number}
 */
proto.be.basalte.config.common.Playlist.prototype.getModifiedTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.common.Playlist} returns this
 */
proto.be.basalte.config.common.Playlist.prototype.setModifiedTime = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.Playlist} returns this
 */
proto.be.basalte.config.common.Playlist.prototype.clearModifiedTime = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.Playlist.prototype.hasModifiedTime = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated Track tracks = 9;
 * @return {!Array<!proto.be.basalte.config.common.Track>}
 */
proto.be.basalte.config.common.Playlist.prototype.getTracksList = function() {
  return /** @type{!Array<!proto.be.basalte.config.common.Track>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.be.basalte.config.common.Track, 9));
};


/**
 * @param {!Array<!proto.be.basalte.config.common.Track>} value
 * @return {!proto.be.basalte.config.common.Playlist} returns this
*/
proto.be.basalte.config.common.Playlist.prototype.setTracksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.be.basalte.config.common.Track=} opt_value
 * @param {number=} opt_index
 * @return {!proto.be.basalte.config.common.Track}
 */
proto.be.basalte.config.common.Playlist.prototype.addTracks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.be.basalte.config.common.Track, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.be.basalte.config.common.Playlist} returns this
 */
proto.be.basalte.config.common.Playlist.prototype.clearTracksList = function() {
  return this.setTracksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.common.Track.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.common.Track.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.common.Track} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.common.Track.toObject = function(includeInstance, msg) {
  var f, obj = {
    path: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.common.Track}
 */
proto.be.basalte.config.common.Track.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.common.Track;
  return proto.be.basalte.config.common.Track.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.common.Track} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.common.Track}
 */
proto.be.basalte.config.common.Track.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.common.Track.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.common.Track.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.common.Track} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.common.Track.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string path = 1;
 * @return {string}
 */
proto.be.basalte.config.common.Track.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.common.Track} returns this
 */
proto.be.basalte.config.common.Track.prototype.setPath = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.Track} returns this
 */
proto.be.basalte.config.common.Track.prototype.clearPath = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.Track.prototype.hasPath = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.common.Version.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.common.Version.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.common.Version} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.common.Version.toObject = function(includeInstance, msg) {
  var f, obj = {
    vMajor: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    vMinor: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    vPatch: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    vExtra: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.common.Version}
 */
proto.be.basalte.config.common.Version.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.common.Version;
  return proto.be.basalte.config.common.Version.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.common.Version} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.common.Version}
 */
proto.be.basalte.config.common.Version.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVMajor(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVMinor(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVPatch(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVExtra(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.common.Version.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.common.Version.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.common.Version} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.common.Version.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional uint32 v_major = 1;
 * @return {number}
 */
proto.be.basalte.config.common.Version.prototype.getVMajor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.common.Version} returns this
 */
proto.be.basalte.config.common.Version.prototype.setVMajor = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.Version} returns this
 */
proto.be.basalte.config.common.Version.prototype.clearVMajor = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.Version.prototype.hasVMajor = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 v_minor = 2;
 * @return {number}
 */
proto.be.basalte.config.common.Version.prototype.getVMinor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.common.Version} returns this
 */
proto.be.basalte.config.common.Version.prototype.setVMinor = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.Version} returns this
 */
proto.be.basalte.config.common.Version.prototype.clearVMinor = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.Version.prototype.hasVMinor = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint32 v_patch = 3;
 * @return {number}
 */
proto.be.basalte.config.common.Version.prototype.getVPatch = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.common.Version} returns this
 */
proto.be.basalte.config.common.Version.prototype.setVPatch = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.Version} returns this
 */
proto.be.basalte.config.common.Version.prototype.clearVPatch = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.Version.prototype.hasVPatch = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint32 v_extra = 4;
 * @return {number}
 */
proto.be.basalte.config.common.Version.prototype.getVExtra = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.common.Version} returns this
 */
proto.be.basalte.config.common.Version.prototype.setVExtra = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.Version} returns this
 */
proto.be.basalte.config.common.Version.prototype.clearVExtra = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.Version.prototype.hasVExtra = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.be.basalte.config.common.RoomCluster.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.common.RoomCluster.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.common.RoomCluster.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.common.RoomCluster} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.common.RoomCluster.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    name: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    roomUuidsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    ownerUserUuid: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.common.RoomCluster}
 */
proto.be.basalte.config.common.RoomCluster.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.common.RoomCluster;
  return proto.be.basalte.config.common.RoomCluster.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.common.RoomCluster} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.common.RoomCluster}
 */
proto.be.basalte.config.common.RoomCluster.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addRoomUuids(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerUserUuid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.common.RoomCluster.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.common.RoomCluster.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.common.RoomCluster} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.common.RoomCluster.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRoomUuidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.be.basalte.config.common.RoomCluster.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.common.RoomCluster} returns this
 */
proto.be.basalte.config.common.RoomCluster.prototype.setUuid = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.RoomCluster} returns this
 */
proto.be.basalte.config.common.RoomCluster.prototype.clearUuid = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.RoomCluster.prototype.hasUuid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.be.basalte.config.common.RoomCluster.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.common.RoomCluster} returns this
 */
proto.be.basalte.config.common.RoomCluster.prototype.setName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.RoomCluster} returns this
 */
proto.be.basalte.config.common.RoomCluster.prototype.clearName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.RoomCluster.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated string room_uuids = 3;
 * @return {!Array<string>}
 */
proto.be.basalte.config.common.RoomCluster.prototype.getRoomUuidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.be.basalte.config.common.RoomCluster} returns this
 */
proto.be.basalte.config.common.RoomCluster.prototype.setRoomUuidsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.be.basalte.config.common.RoomCluster} returns this
 */
proto.be.basalte.config.common.RoomCluster.prototype.addRoomUuids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.be.basalte.config.common.RoomCluster} returns this
 */
proto.be.basalte.config.common.RoomCluster.prototype.clearRoomUuidsList = function() {
  return this.setRoomUuidsList([]);
};


/**
 * optional string owner_user_uuid = 4;
 * @return {string}
 */
proto.be.basalte.config.common.RoomCluster.prototype.getOwnerUserUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.common.RoomCluster} returns this
 */
proto.be.basalte.config.common.RoomCluster.prototype.setOwnerUserUuid = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.RoomCluster} returns this
 */
proto.be.basalte.config.common.RoomCluster.prototype.clearOwnerUserUuid = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.RoomCluster.prototype.hasOwnerUserUuid = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.common.IPConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.common.IPConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.common.IPConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.common.IPConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    ipv4: (f = msg.getIpv4()) && proto.be.basalte.config.common.IPConfigv4.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.common.IPConfig}
 */
proto.be.basalte.config.common.IPConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.common.IPConfig;
  return proto.be.basalte.config.common.IPConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.common.IPConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.common.IPConfig}
 */
proto.be.basalte.config.common.IPConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.be.basalte.config.common.IPConfigv4;
      reader.readMessage(value,proto.be.basalte.config.common.IPConfigv4.deserializeBinaryFromReader);
      msg.setIpv4(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.common.IPConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.common.IPConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.common.IPConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.common.IPConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIpv4();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.be.basalte.config.common.IPConfigv4.serializeBinaryToWriter
    );
  }
};


/**
 * optional IPConfigv4 ipv4 = 1;
 * @return {?proto.be.basalte.config.common.IPConfigv4}
 */
proto.be.basalte.config.common.IPConfig.prototype.getIpv4 = function() {
  return /** @type{?proto.be.basalte.config.common.IPConfigv4} */ (
    jspb.Message.getWrapperField(this, proto.be.basalte.config.common.IPConfigv4, 1));
};


/**
 * @param {?proto.be.basalte.config.common.IPConfigv4|undefined} value
 * @return {!proto.be.basalte.config.common.IPConfig} returns this
*/
proto.be.basalte.config.common.IPConfig.prototype.setIpv4 = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.be.basalte.config.common.IPConfig} returns this
 */
proto.be.basalte.config.common.IPConfig.prototype.clearIpv4 = function() {
  return this.setIpv4(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.IPConfig.prototype.hasIpv4 = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.common.IPConfigv4.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.common.IPConfigv4.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.common.IPConfigv4} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.common.IPConfigv4.toObject = function(includeInstance, msg) {
  var f, obj = {
    hostname: (f = jspb.Message.getField(msg, 7)) == null ? undefined : f,
    dhcp: (f = jspb.Message.getBooleanField(msg, 1)) == null ? undefined : f,
    address: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    gateway: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    subnetmask: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    dns1: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    dns2: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.common.IPConfigv4}
 */
proto.be.basalte.config.common.IPConfigv4.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.common.IPConfigv4;
  return proto.be.basalte.config.common.IPConfigv4.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.common.IPConfigv4} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.common.IPConfigv4}
 */
proto.be.basalte.config.common.IPConfigv4.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setHostname(value);
      break;
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDhcp(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAddress(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setGateway(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSubnetmask(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDns1(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDns2(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.common.IPConfigv4.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.common.IPConfigv4.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.common.IPConfigv4} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.common.IPConfigv4.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeUint32(
      6,
      f
    );
  }
};


/**
 * optional string hostname = 7;
 * @return {string}
 */
proto.be.basalte.config.common.IPConfigv4.prototype.getHostname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.common.IPConfigv4} returns this
 */
proto.be.basalte.config.common.IPConfigv4.prototype.setHostname = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.IPConfigv4} returns this
 */
proto.be.basalte.config.common.IPConfigv4.prototype.clearHostname = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.IPConfigv4.prototype.hasHostname = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool dhcp = 1;
 * @return {boolean}
 */
proto.be.basalte.config.common.IPConfigv4.prototype.getDhcp = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.be.basalte.config.common.IPConfigv4} returns this
 */
proto.be.basalte.config.common.IPConfigv4.prototype.setDhcp = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.IPConfigv4} returns this
 */
proto.be.basalte.config.common.IPConfigv4.prototype.clearDhcp = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.IPConfigv4.prototype.hasDhcp = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 address = 2;
 * @return {number}
 */
proto.be.basalte.config.common.IPConfigv4.prototype.getAddress = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.common.IPConfigv4} returns this
 */
proto.be.basalte.config.common.IPConfigv4.prototype.setAddress = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.IPConfigv4} returns this
 */
proto.be.basalte.config.common.IPConfigv4.prototype.clearAddress = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.IPConfigv4.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint32 gateway = 3;
 * @return {number}
 */
proto.be.basalte.config.common.IPConfigv4.prototype.getGateway = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.common.IPConfigv4} returns this
 */
proto.be.basalte.config.common.IPConfigv4.prototype.setGateway = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.IPConfigv4} returns this
 */
proto.be.basalte.config.common.IPConfigv4.prototype.clearGateway = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.IPConfigv4.prototype.hasGateway = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint32 subnetmask = 4;
 * @return {number}
 */
proto.be.basalte.config.common.IPConfigv4.prototype.getSubnetmask = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.common.IPConfigv4} returns this
 */
proto.be.basalte.config.common.IPConfigv4.prototype.setSubnetmask = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.IPConfigv4} returns this
 */
proto.be.basalte.config.common.IPConfigv4.prototype.clearSubnetmask = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.IPConfigv4.prototype.hasSubnetmask = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional uint32 dns1 = 5;
 * @return {number}
 */
proto.be.basalte.config.common.IPConfigv4.prototype.getDns1 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.common.IPConfigv4} returns this
 */
proto.be.basalte.config.common.IPConfigv4.prototype.setDns1 = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.IPConfigv4} returns this
 */
proto.be.basalte.config.common.IPConfigv4.prototype.clearDns1 = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.IPConfigv4.prototype.hasDns1 = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional uint32 dns2 = 6;
 * @return {number}
 */
proto.be.basalte.config.common.IPConfigv4.prototype.getDns2 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.common.IPConfigv4} returns this
 */
proto.be.basalte.config.common.IPConfigv4.prototype.setDns2 = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.IPConfigv4} returns this
 */
proto.be.basalte.config.common.IPConfigv4.prototype.clearDns2 = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.IPConfigv4.prototype.hasDns2 = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.common.SerialConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.common.SerialConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.common.SerialConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.common.SerialConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    baudrate: jspb.Message.getFieldWithDefault(msg, 1, 115200),
    datasize: jspb.Message.getFieldWithDefault(msg, 2, 8),
    flowcontrol: jspb.Message.getFieldWithDefault(msg, 3, 0),
    parity: jspb.Message.getFieldWithDefault(msg, 4, 0),
    stopbits: jspb.Message.getFieldWithDefault(msg, 5, 0),
    crossover: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.common.SerialConfig}
 */
proto.be.basalte.config.common.SerialConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.common.SerialConfig;
  return proto.be.basalte.config.common.SerialConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.common.SerialConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.common.SerialConfig}
 */
proto.be.basalte.config.common.SerialConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.be.basalte.config.common.SerialConfig.Baudrate} */ (reader.readEnum());
      msg.setBaudrate(value);
      break;
    case 2:
      var value = /** @type {!proto.be.basalte.config.common.SerialConfig.DataSize} */ (reader.readEnum());
      msg.setDatasize(value);
      break;
    case 3:
      var value = /** @type {!proto.be.basalte.config.common.SerialConfig.FlowControl} */ (reader.readEnum());
      msg.setFlowcontrol(value);
      break;
    case 4:
      var value = /** @type {!proto.be.basalte.config.common.SerialConfig.Parity} */ (reader.readEnum());
      msg.setParity(value);
      break;
    case 5:
      var value = /** @type {!proto.be.basalte.config.common.SerialConfig.StopBits} */ (reader.readEnum());
      msg.setStopbits(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCrossover(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.common.SerialConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.common.SerialConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.common.SerialConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.common.SerialConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.be.basalte.config.common.SerialConfig.Baudrate} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {!proto.be.basalte.config.common.SerialConfig.DataSize} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {!proto.be.basalte.config.common.SerialConfig.FlowControl} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = /** @type {!proto.be.basalte.config.common.SerialConfig.Parity} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = /** @type {!proto.be.basalte.config.common.SerialConfig.StopBits} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.be.basalte.config.common.SerialConfig.Baudrate = {
  SERIAL_BAUD_300: 300,
  SERIAL_BAUD_600: 600,
  SERIAL_BAUD_1200: 1200,
  SERIAL_BAUD_2400: 2400,
  SERIAL_BAUD_4800: 4800,
  SERIAL_BAUD_9600: 9600,
  SERIAL_BAUD_14400: 14400,
  SERIAL_BAUD_19200: 19200,
  SERIAL_BAUD_38400: 38400,
  SERIAL_BAUD_57600: 57600,
  SERIAL_BAUD_115200: 115200
};

/**
 * @enum {number}
 */
proto.be.basalte.config.common.SerialConfig.DataSize = {
  SERIAL_DATA_5: 5,
  SERIAL_DATA_6: 6,
  SERIAL_DATA_7: 7,
  SERIAL_DATA_8: 8,
  SERIAL_DATA_9: 9
};

/**
 * @enum {number}
 */
proto.be.basalte.config.common.SerialConfig.FlowControl = {
  SERIAL_FLOW_NONE: 0,
  SERIAL_FLOW_SOFTWARE: 1,
  SERIAL_FLOW_HARDWARE: 2
};

/**
 * @enum {number}
 */
proto.be.basalte.config.common.SerialConfig.Parity = {
  SERIAL_PARITY_NONE: 0,
  SERIAL_PARITY_ODD: 1,
  SERIAL_PARITY_EVEN: 2
};

/**
 * @enum {number}
 */
proto.be.basalte.config.common.SerialConfig.StopBits = {
  SERIAL_STOP_ONE: 0,
  SERIAL_STOP_ONE_AND_HALF: 1,
  SERIAL_STOP_TWO: 2
};

/**
 * optional Baudrate baudrate = 1;
 * @return {!proto.be.basalte.config.common.SerialConfig.Baudrate}
 */
proto.be.basalte.config.common.SerialConfig.prototype.getBaudrate = function() {
  return /** @type {!proto.be.basalte.config.common.SerialConfig.Baudrate} */ (jspb.Message.getFieldWithDefault(this, 1, 115200));
};


/**
 * @param {!proto.be.basalte.config.common.SerialConfig.Baudrate} value
 * @return {!proto.be.basalte.config.common.SerialConfig} returns this
 */
proto.be.basalte.config.common.SerialConfig.prototype.setBaudrate = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.SerialConfig} returns this
 */
proto.be.basalte.config.common.SerialConfig.prototype.clearBaudrate = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.SerialConfig.prototype.hasBaudrate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DataSize datasize = 2;
 * @return {!proto.be.basalte.config.common.SerialConfig.DataSize}
 */
proto.be.basalte.config.common.SerialConfig.prototype.getDatasize = function() {
  return /** @type {!proto.be.basalte.config.common.SerialConfig.DataSize} */ (jspb.Message.getFieldWithDefault(this, 2, 8));
};


/**
 * @param {!proto.be.basalte.config.common.SerialConfig.DataSize} value
 * @return {!proto.be.basalte.config.common.SerialConfig} returns this
 */
proto.be.basalte.config.common.SerialConfig.prototype.setDatasize = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.SerialConfig} returns this
 */
proto.be.basalte.config.common.SerialConfig.prototype.clearDatasize = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.SerialConfig.prototype.hasDatasize = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional FlowControl flowcontrol = 3;
 * @return {!proto.be.basalte.config.common.SerialConfig.FlowControl}
 */
proto.be.basalte.config.common.SerialConfig.prototype.getFlowcontrol = function() {
  return /** @type {!proto.be.basalte.config.common.SerialConfig.FlowControl} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.be.basalte.config.common.SerialConfig.FlowControl} value
 * @return {!proto.be.basalte.config.common.SerialConfig} returns this
 */
proto.be.basalte.config.common.SerialConfig.prototype.setFlowcontrol = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.SerialConfig} returns this
 */
proto.be.basalte.config.common.SerialConfig.prototype.clearFlowcontrol = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.SerialConfig.prototype.hasFlowcontrol = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Parity parity = 4;
 * @return {!proto.be.basalte.config.common.SerialConfig.Parity}
 */
proto.be.basalte.config.common.SerialConfig.prototype.getParity = function() {
  return /** @type {!proto.be.basalte.config.common.SerialConfig.Parity} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.be.basalte.config.common.SerialConfig.Parity} value
 * @return {!proto.be.basalte.config.common.SerialConfig} returns this
 */
proto.be.basalte.config.common.SerialConfig.prototype.setParity = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.SerialConfig} returns this
 */
proto.be.basalte.config.common.SerialConfig.prototype.clearParity = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.SerialConfig.prototype.hasParity = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional StopBits stopbits = 5;
 * @return {!proto.be.basalte.config.common.SerialConfig.StopBits}
 */
proto.be.basalte.config.common.SerialConfig.prototype.getStopbits = function() {
  return /** @type {!proto.be.basalte.config.common.SerialConfig.StopBits} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.be.basalte.config.common.SerialConfig.StopBits} value
 * @return {!proto.be.basalte.config.common.SerialConfig} returns this
 */
proto.be.basalte.config.common.SerialConfig.prototype.setStopbits = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.SerialConfig} returns this
 */
proto.be.basalte.config.common.SerialConfig.prototype.clearStopbits = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.SerialConfig.prototype.hasStopbits = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool crossover = 6;
 * @return {boolean}
 */
proto.be.basalte.config.common.SerialConfig.prototype.getCrossover = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.be.basalte.config.common.SerialConfig} returns this
 */
proto.be.basalte.config.common.SerialConfig.prototype.setCrossover = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.SerialConfig} returns this
 */
proto.be.basalte.config.common.SerialConfig.prototype.clearCrossover = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.SerialConfig.prototype.hasCrossover = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.common.Credentials.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.common.Credentials.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.common.Credentials} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.common.Credentials.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    password: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.common.Credentials}
 */
proto.be.basalte.config.common.Credentials.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.common.Credentials;
  return proto.be.basalte.config.common.Credentials.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.common.Credentials} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.common.Credentials}
 */
proto.be.basalte.config.common.Credentials.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.common.Credentials.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.common.Credentials.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.common.Credentials} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.common.Credentials.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.be.basalte.config.common.Credentials.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.common.Credentials} returns this
 */
proto.be.basalte.config.common.Credentials.prototype.setUsername = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.Credentials} returns this
 */
proto.be.basalte.config.common.Credentials.prototype.clearUsername = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.Credentials.prototype.hasUsername = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.be.basalte.config.common.Credentials.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.common.Credentials} returns this
 */
proto.be.basalte.config.common.Credentials.prototype.setPassword = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.Credentials} returns this
 */
proto.be.basalte.config.common.Credentials.prototype.clearPassword = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.Credentials.prototype.hasPassword = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.common.Message.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.common.Message.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.common.Message} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.common.Message.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    origin: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    originId: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    contentMap: (f = msg.getContentMap()) ? f.toObject(includeInstance, proto.be.basalte.config.common.Message.Content.toObject) : [],
    startTime: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
    endTime: (f = jspb.Message.getField(msg, 7)) == null ? undefined : f,
    serverMin: (f = msg.getServerMin()) && proto.be.basalte.config.common.Version.toObject(includeInstance, f),
    serverMax: (f = msg.getServerMax()) && proto.be.basalte.config.common.Version.toObject(includeInstance, f),
    appMin: (f = msg.getAppMin()) && proto.be.basalte.config.common.Version.toObject(includeInstance, f),
    appMax: (f = msg.getAppMax()) && proto.be.basalte.config.common.Version.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.common.Message}
 */
proto.be.basalte.config.common.Message.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.common.Message;
  return proto.be.basalte.config.common.Message.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.common.Message} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.common.Message}
 */
proto.be.basalte.config.common.Message.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {!proto.be.basalte.config.common.Message.MessageType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {!proto.be.basalte.config.common.Message.MessageOrigin} */ (reader.readEnum());
      msg.setOrigin(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginId(value);
      break;
    case 5:
      var value = msg.getContentMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.be.basalte.config.common.Message.Content.deserializeBinaryFromReader, "", new proto.be.basalte.config.common.Message.Content());
         });
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 8:
      var value = new proto.be.basalte.config.common.Version;
      reader.readMessage(value,proto.be.basalte.config.common.Version.deserializeBinaryFromReader);
      msg.setServerMin(value);
      break;
    case 9:
      var value = new proto.be.basalte.config.common.Version;
      reader.readMessage(value,proto.be.basalte.config.common.Version.deserializeBinaryFromReader);
      msg.setServerMax(value);
      break;
    case 10:
      var value = new proto.be.basalte.config.common.Version;
      reader.readMessage(value,proto.be.basalte.config.common.Version.deserializeBinaryFromReader);
      msg.setAppMin(value);
      break;
    case 11:
      var value = new proto.be.basalte.config.common.Version;
      reader.readMessage(value,proto.be.basalte.config.common.Version.deserializeBinaryFromReader);
      msg.setAppMax(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.common.Message.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.common.Message.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.common.Message} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.common.Message.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {!proto.be.basalte.config.common.Message.MessageType} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {!proto.be.basalte.config.common.Message.MessageOrigin} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getContentMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.be.basalte.config.common.Message.Content.serializeBinaryToWriter);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getServerMin();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.be.basalte.config.common.Version.serializeBinaryToWriter
    );
  }
  f = message.getServerMax();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.be.basalte.config.common.Version.serializeBinaryToWriter
    );
  }
  f = message.getAppMin();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.be.basalte.config.common.Version.serializeBinaryToWriter
    );
  }
  f = message.getAppMax();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.be.basalte.config.common.Version.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.be.basalte.config.common.Message.MessageType = {
  MSG_DEFAULT: 0,
  MSG_ALARM: 1,
  MSG_UPDATE: 2
};

/**
 * @enum {number}
 */
proto.be.basalte.config.common.Message.MessageOrigin = {
  ORIGIN_SYSTEM: 0,
  ORIGIN_CLOUD: 1,
  ORIGIN_USER: 2,
  ORIGIN_DEVICE: 3
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.common.Message.Content.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.common.Message.Content.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.common.Message.Content} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.common.Message.Content.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    body: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.common.Message.Content}
 */
proto.be.basalte.config.common.Message.Content.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.common.Message.Content;
  return proto.be.basalte.config.common.Message.Content.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.common.Message.Content} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.common.Message.Content}
 */
proto.be.basalte.config.common.Message.Content.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.common.Message.Content.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.common.Message.Content.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.common.Message.Content} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.common.Message.Content.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.be.basalte.config.common.Message.Content.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.common.Message.Content} returns this
 */
proto.be.basalte.config.common.Message.Content.prototype.setTitle = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.Message.Content} returns this
 */
proto.be.basalte.config.common.Message.Content.prototype.clearTitle = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.Message.Content.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string body = 2;
 * @return {string}
 */
proto.be.basalte.config.common.Message.Content.prototype.getBody = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.common.Message.Content} returns this
 */
proto.be.basalte.config.common.Message.Content.prototype.setBody = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.Message.Content} returns this
 */
proto.be.basalte.config.common.Message.Content.prototype.clearBody = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.Message.Content.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.be.basalte.config.common.Message.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.common.Message} returns this
 */
proto.be.basalte.config.common.Message.prototype.setUuid = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.Message} returns this
 */
proto.be.basalte.config.common.Message.prototype.clearUuid = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.Message.prototype.hasUuid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MessageType type = 2;
 * @return {!proto.be.basalte.config.common.Message.MessageType}
 */
proto.be.basalte.config.common.Message.prototype.getType = function() {
  return /** @type {!proto.be.basalte.config.common.Message.MessageType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.be.basalte.config.common.Message.MessageType} value
 * @return {!proto.be.basalte.config.common.Message} returns this
 */
proto.be.basalte.config.common.Message.prototype.setType = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.Message} returns this
 */
proto.be.basalte.config.common.Message.prototype.clearType = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.Message.prototype.hasType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional MessageOrigin origin = 3;
 * @return {!proto.be.basalte.config.common.Message.MessageOrigin}
 */
proto.be.basalte.config.common.Message.prototype.getOrigin = function() {
  return /** @type {!proto.be.basalte.config.common.Message.MessageOrigin} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.be.basalte.config.common.Message.MessageOrigin} value
 * @return {!proto.be.basalte.config.common.Message} returns this
 */
proto.be.basalte.config.common.Message.prototype.setOrigin = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.Message} returns this
 */
proto.be.basalte.config.common.Message.prototype.clearOrigin = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.Message.prototype.hasOrigin = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string origin_id = 4;
 * @return {string}
 */
proto.be.basalte.config.common.Message.prototype.getOriginId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.common.Message} returns this
 */
proto.be.basalte.config.common.Message.prototype.setOriginId = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.Message} returns this
 */
proto.be.basalte.config.common.Message.prototype.clearOriginId = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.Message.prototype.hasOriginId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * map<string, Content> content = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.be.basalte.config.common.Message.Content>}
 */
proto.be.basalte.config.common.Message.prototype.getContentMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.be.basalte.config.common.Message.Content>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      proto.be.basalte.config.common.Message.Content));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.be.basalte.config.common.Message} returns this
 */
proto.be.basalte.config.common.Message.prototype.clearContentMap = function() {
  this.getContentMap().clear();
  return this;};


/**
 * optional int64 start_time = 6;
 * @return {number}
 */
proto.be.basalte.config.common.Message.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.common.Message} returns this
 */
proto.be.basalte.config.common.Message.prototype.setStartTime = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.Message} returns this
 */
proto.be.basalte.config.common.Message.prototype.clearStartTime = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.Message.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int64 end_time = 7;
 * @return {number}
 */
proto.be.basalte.config.common.Message.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.common.Message} returns this
 */
proto.be.basalte.config.common.Message.prototype.setEndTime = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.Message} returns this
 */
proto.be.basalte.config.common.Message.prototype.clearEndTime = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.Message.prototype.hasEndTime = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Version server_min = 8;
 * @return {?proto.be.basalte.config.common.Version}
 */
proto.be.basalte.config.common.Message.prototype.getServerMin = function() {
  return /** @type{?proto.be.basalte.config.common.Version} */ (
    jspb.Message.getWrapperField(this, proto.be.basalte.config.common.Version, 8));
};


/**
 * @param {?proto.be.basalte.config.common.Version|undefined} value
 * @return {!proto.be.basalte.config.common.Message} returns this
*/
proto.be.basalte.config.common.Message.prototype.setServerMin = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.be.basalte.config.common.Message} returns this
 */
proto.be.basalte.config.common.Message.prototype.clearServerMin = function() {
  return this.setServerMin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.Message.prototype.hasServerMin = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Version server_max = 9;
 * @return {?proto.be.basalte.config.common.Version}
 */
proto.be.basalte.config.common.Message.prototype.getServerMax = function() {
  return /** @type{?proto.be.basalte.config.common.Version} */ (
    jspb.Message.getWrapperField(this, proto.be.basalte.config.common.Version, 9));
};


/**
 * @param {?proto.be.basalte.config.common.Version|undefined} value
 * @return {!proto.be.basalte.config.common.Message} returns this
*/
proto.be.basalte.config.common.Message.prototype.setServerMax = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.be.basalte.config.common.Message} returns this
 */
proto.be.basalte.config.common.Message.prototype.clearServerMax = function() {
  return this.setServerMax(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.Message.prototype.hasServerMax = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional Version app_min = 10;
 * @return {?proto.be.basalte.config.common.Version}
 */
proto.be.basalte.config.common.Message.prototype.getAppMin = function() {
  return /** @type{?proto.be.basalte.config.common.Version} */ (
    jspb.Message.getWrapperField(this, proto.be.basalte.config.common.Version, 10));
};


/**
 * @param {?proto.be.basalte.config.common.Version|undefined} value
 * @return {!proto.be.basalte.config.common.Message} returns this
*/
proto.be.basalte.config.common.Message.prototype.setAppMin = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.be.basalte.config.common.Message} returns this
 */
proto.be.basalte.config.common.Message.prototype.clearAppMin = function() {
  return this.setAppMin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.Message.prototype.hasAppMin = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional Version app_max = 11;
 * @return {?proto.be.basalte.config.common.Version}
 */
proto.be.basalte.config.common.Message.prototype.getAppMax = function() {
  return /** @type{?proto.be.basalte.config.common.Version} */ (
    jspb.Message.getWrapperField(this, proto.be.basalte.config.common.Version, 11));
};


/**
 * @param {?proto.be.basalte.config.common.Version|undefined} value
 * @return {!proto.be.basalte.config.common.Message} returns this
*/
proto.be.basalte.config.common.Message.prototype.setAppMax = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.be.basalte.config.common.Message} returns this
 */
proto.be.basalte.config.common.Message.prototype.clearAppMax = function() {
  return this.setAppMax(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.Message.prototype.hasAppMax = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.common.DPTID.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.common.DPTID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.common.DPTID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.common.DPTID.toObject = function(includeInstance, msg) {
  var f, obj = {
    main: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    sub: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.common.DPTID}
 */
proto.be.basalte.config.common.DPTID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.common.DPTID;
  return proto.be.basalte.config.common.DPTID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.common.DPTID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.common.DPTID}
 */
proto.be.basalte.config.common.DPTID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMain(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSub(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.common.DPTID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.common.DPTID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.common.DPTID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.common.DPTID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 main = 1;
 * @return {number}
 */
proto.be.basalte.config.common.DPTID.prototype.getMain = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.common.DPTID} returns this
 */
proto.be.basalte.config.common.DPTID.prototype.setMain = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.DPTID} returns this
 */
proto.be.basalte.config.common.DPTID.prototype.clearMain = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.DPTID.prototype.hasMain = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 sub = 2;
 * @return {number}
 */
proto.be.basalte.config.common.DPTID.prototype.getSub = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.common.DPTID} returns this
 */
proto.be.basalte.config.common.DPTID.prototype.setSub = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.DPTID} returns this
 */
proto.be.basalte.config.common.DPTID.prototype.clearSub = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.DPTID.prototype.hasSub = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.be.basalte.config.common.DataBlob.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.be.basalte.config.common.DataBlob.DataCase = {
  DATA_NOT_SET: 0,
  RAW: 1,
  PLACEHOLDER: 2
};

/**
 * @return {proto.be.basalte.config.common.DataBlob.DataCase}
 */
proto.be.basalte.config.common.DataBlob.prototype.getDataCase = function() {
  return /** @type {proto.be.basalte.config.common.DataBlob.DataCase} */(jspb.Message.computeOneofCase(this, proto.be.basalte.config.common.DataBlob.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.common.DataBlob.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.common.DataBlob.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.common.DataBlob} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.common.DataBlob.toObject = function(includeInstance, msg) {
  var f, obj = {
    raw: msg.getRaw_asB64(),
    placeholder: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.common.DataBlob}
 */
proto.be.basalte.config.common.DataBlob.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.common.DataBlob;
  return proto.be.basalte.config.common.DataBlob.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.common.DataBlob} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.common.DataBlob}
 */
proto.be.basalte.config.common.DataBlob.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setRaw(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceholder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.common.DataBlob.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.common.DataBlob.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.common.DataBlob} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.common.DataBlob.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bytes raw = 1;
 * @return {!(string|Uint8Array)}
 */
proto.be.basalte.config.common.DataBlob.prototype.getRaw = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes raw = 1;
 * This is a type-conversion wrapper around `getRaw()`
 * @return {string}
 */
proto.be.basalte.config.common.DataBlob.prototype.getRaw_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getRaw()));
};


/**
 * optional bytes raw = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRaw()`
 * @return {!Uint8Array}
 */
proto.be.basalte.config.common.DataBlob.prototype.getRaw_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getRaw()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.be.basalte.config.common.DataBlob} returns this
 */
proto.be.basalte.config.common.DataBlob.prototype.setRaw = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.be.basalte.config.common.DataBlob.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.DataBlob} returns this
 */
proto.be.basalte.config.common.DataBlob.prototype.clearRaw = function() {
  return jspb.Message.setOneofField(this, 1, proto.be.basalte.config.common.DataBlob.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.DataBlob.prototype.hasRaw = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string placeholder = 2;
 * @return {string}
 */
proto.be.basalte.config.common.DataBlob.prototype.getPlaceholder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.common.DataBlob} returns this
 */
proto.be.basalte.config.common.DataBlob.prototype.setPlaceholder = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.be.basalte.config.common.DataBlob.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.common.DataBlob} returns this
 */
proto.be.basalte.config.common.DataBlob.prototype.clearPlaceholder = function() {
  return jspb.Message.setOneofField(this, 2, proto.be.basalte.config.common.DataBlob.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.common.DataBlob.prototype.hasPlaceholder = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * @enum {number}
 */
proto.be.basalte.config.common.Colour = {
  BLACK: 0,
  RED: 16711680,
  GREEN: 65280,
  BLUE: 255,
  YELLOW: 16776960,
  MAGENTA: 16711935,
  CYAN: 65535,
  WHITE: 16777215
};

goog.object.extend(exports, proto.be.basalte.config.common);
