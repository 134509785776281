'use strict'

angular
  .module('basalteApp')
  .config([
    '$stateProvider',
    '$urlServiceProvider',
    'STATES',
    'BAS_HTML',
    'BAS_STATE',
    stateConfig
  ])

/**
 * @param $stateProvider
 * @param $urlServiceProvider
 * @param {STATES} STATES
 * @param {BAS_HTML} BAS_HTML
 * @param {BAS_STATE} BAS_STATE
 */
function stateConfig (
  $stateProvider,
  $urlServiceProvider,
  STATES,
  BAS_HTML,
  BAS_STATE
) {
  $urlServiceProvider.rules.otherwise('/')

  $stateProvider
    .state(STATES.CONNECT, {
      abstract: true,
      template: BAS_HTML.connect
    })
    .state(STATES.CONNECT_UNSUPPORTED, {
      url: '/unsupported',
      template: BAS_HTML.unsupported
    })
    .state(STATES.CONNECT_LIVE, {
      url: '/live',
      template: BAS_HTML.live,
      controller: 'liveCtrl',
      controllerAs: 'live'
    })
    .state(STATES.CONNECT_LIVE_LOGIN, {
      url: '/login',
      template: BAS_HTML.liveLogin,
      controller: 'loginLiveCtrl',
      controllerAs: 'login'
    })
    .state(STATES.CONNECT_LIVE_REGISTER, {
      url: '/register',
      template: BAS_HTML.liveRegister,
      controller: 'registerLiveCtrl',
      controllerAs: 'register'
    })
    .state(STATES.CONNECT_LIVE_VERIFY, {
      url: '/verify',
      template: BAS_HTML.liveVerify,
      controller: 'verifyLiveCtrl',
      controllerAs: 'verify'
    })
    .state(STATES.CONNECT_LIVE_FORGOT, {
      url: '/forgot',
      template: BAS_HTML.liveForgot,
      controller: 'forgotLiveCtrl',
      controllerAs: 'forgot'
    })
    .state(STATES.CONNECT_LIVE_CHANGE, {
      url: '/change',
      template: BAS_HTML.liveChange,
      controller: 'changeLiveCtrl',
      controllerAs: 'change'
    })
    .state(STATES.CONNECT_DISCOVERY, {
      url: '/discovery',
      params: {
        reason: ''
      },
      template: BAS_HTML.discovery,
      controller: 'discoveryCtrl',
      controllerAs: 'discovery'
    })
    .state(STATES.CONNECT_PROFILES, {
      url: '/profiles',
      template: BAS_HTML.profiles,
      controller: 'profilesCtrl',
      controllerAs: 'profiles'
    })
    .state(STATES.DEVICE_SETTINGS_ABSTRACT, {
      abstract: true,
      template: BAS_HTML.deviceSettings,
      controller: 'deviceSettingsCtrl',
      controllerAs: 'devicesettings'
    })
    .state(STATES.DEVICE_SETTINGS, {
      url: '/devicesettings',
      template: BAS_HTML.settings,
      controller: 'settingsCtrl',
      controllerAs: 'settings'
    })
    .state(STATES.DEVICE_SETTINGS_GENERAL, {
      url: '/general',
      views: {
        setting: {
          template: BAS_HTML.generalSettings,
          controller: 'generalSettingsCtrl',
          controllerAs: 'general'
        }
      }
    })
    .state(STATES.DEVICE_SETTINGS_LIVE, {
      url: '/live',
      views: {
        setting: {
          template: BAS_HTML.liveSettings,
          controller: 'liveSettingsCtrl',
          controllerAs: 'live'
        }
      }
    })
    .state(STATES.DEVICE_SETTINGS_NETWORK, {
      url: '/network',
      views: {
        setting: {
          template: BAS_HTML.networkSettings
        }
      }
    })
    .state(STATES.DEVICE_SETTINGS_DOOR_PHONE, {
      url: '/doorphone',
      views: {
        setting: {
          template: BAS_HTML.doorPhoneSettings
        }
      }
    })
    .state(STATES.DEVICE_SETTINGS_ABOUT, {
      url: '/about',
      views: {
        setting: {
          template: BAS_HTML.aboutSettings,
          controller: 'aboutSettingsCtrl',
          controllerAs: 'about'
        }
      }
    })
    .state(STATES.MAIN, {
      template: BAS_HTML.main,
      controller: 'mainCtrl',
      controllerAs: 'main'
    })
    .state(STATES.HOME, {
      url: '/home?view',
      params: {
        view: {
          value: BAS_STATE.S_HOME_VIEW_DASHBOARD,
          dynamic: true,
          squash: true
        }
      },
      views: {
        content: {
          template: BAS_HTML.home,
          controller: 'homeCtrl',
          controllerAs: 'home'
        }
      }
    })
    .state(STATES.WEATHER, {
      url: '/weather',
      views: {
        content: {
          template: BAS_HTML.weather,
          controller: 'weatherCtrl',
          controllerAs: 'weather'
        }
      }
    })
    .state(STATES.CALL_HISTORY, {
      url: '/callhistory',
      views: {
        'content@main': {
          template: BAS_HTML.callHistory,
          controller: 'callHistoryCtrl',
          controllerAs: 'callHistory'
        }
      }
    })
    .state(STATES.CALL_HISTORY_DETAIL, {
      url: '?contactUuid&timeStamp',
      params: {
        contactUuid: {
          value: '',
          dynamic: true
        },
        timeStamp: {
          value: '',
          dynamic: true
        }
      },
      views: {
        content: {
          template: BAS_HTML.callHistoryDetail,
          controller: 'callHistoryDetailCtrl',
          controllerAs: 'callHistoryDetail'
        }
      }
    })
    .state(STATES.TIMERS, {
      url: '/timers',
      views: {
        'content@main': {
          template: BAS_HTML.timers,
          controller: 'timersCtrl',
          controllerAs: 'timers'
        }
      }
    })
    .state(STATES.TIMER_WEEK, {
      url: '/:timer',
      params: {
        timer: {
          value: '',
          dynamic: true
        }
      },
      views: {
        content: {
          template: BAS_HTML.timerWeek,
          controller: 'timerWeekCtrl',
          controllerAs: 'timer'
        }
      }
    })
    .state(STATES.TIMER_DAY, {
      url: '/:day',
      params: {
        day: {
          value: '',
          dynamic: true
        }
      },
      views: {
        'content@main.timers.week': {
          template: BAS_HTML.timerDay,
          controller: 'timerDayCtrl',
          controllerAs: 'day'
        }
      }
    })
    .state(STATES.SCENES, {
      url: '/scenes',
      views: {
        'content@main': {
          template: BAS_HTML.homeScenes,
          controller: 'homeScenesCtrl',
          controllerAs: 'homeScenes'
        }
      }
    })
    .state(STATES.SCENE, {
      url: '/:scene',
      params: {
        scene: {
          value: '',
          dynamic: true
        }
      },
      views: {
        content: {
          template: BAS_HTML.scene,
          controller: 'basScenesSceneCtrl',
          controllerAs: 'scene'
        }
      }
    })
    .state(STATES.SCENE_PRESETS, {
      url: '/presets',
      views: {
        content: {
          template: BAS_HTML.scenePresets
        }
      }
    })
    .state(STATES.SCENE_PICTURE, {
      url: '/picture',
      views: {
        'content@main.scenes.scene': {
          template: BAS_HTML.sceneImage
        }
      }
    })
    .state(STATES.SCENE_ROOMS, {
      url: '/rooms',
      views: {
        content: {
          template: BAS_HTML.roomsDetailScene
        }
      }
    })
    .state(STATES.SCENE_ROOM_FUNCTIONS, {
      url: '/functions',
      views: {
        'content@main.scenes.scene': {
          template: BAS_HTML.functionsDetailScene
        }
      }
    })
    .state(STATES.SCENE_STEP, {
      url: '/:step',
      abstract: true,
      params: {
        step: {
          dynamic: true
        }
      }
    })
    .state(STATES.SCENE_STEP_LIGHTS, {
      url: '/lights',
      views: {
        'content@main.scenes.scene': {
          template: BAS_HTML.lightsDetailScene,
          controller: 'lightsSceneCtrl',
          controllerAs: 'lights'
        }
      }
    })
    .state(STATES.SCENE_STEP_DEVICES, {
      url: '/devices',
      views: {
        'content@main.scenes.scene': {
          template: BAS_HTML.devicesDetailScene,
          controller: 'genericDevicesSceneCtrl',
          controllerAs: 'devices'
        }
      }
    })
    .state(STATES.SCENE_STEP_THERMOSTAT_PICKER, {
      url: '/thermostat',
      views: {
        'content@main.scenes.scene': {
          template: BAS_HTML.thermostatPickerScene,
          controller: 'thermostatPickerSceneCtrl',
          controllerAs: 'room'
        }
      }
    }).state(STATES.SCENE_STEP_THERMOSTAT, {
      url: '/:thermostat',
      views: {
        'content@main.scenes.scene': {
          template: BAS_HTML.thermostatDetailScene,
          controller: 'thermostatSceneCtrl',
          controllerAs: 'basThermostat'
        }
      }
    })
    .state(STATES.SCENE_STEP_SCENES, {
      url: '/scenes',
      views: {
        'content@main.scenes.scene': {
          template: BAS_HTML.scenesDetailScene,
          controller: 'basScenesSceneScenesCtrl',
          controllerAs: 'stepScenes'
        }
      }
    })
    .state(STATES.SCENE_STEP_SHADES, {
      url: '/shades',
      views: {
        'content@main.scenes.scene': {
          template: BAS_HTML.shadesDetailScene,
          controller: 'shadesSceneCtrl',
          controllerAs: 'shades'
        }
      }
    })
    .state(STATES.SCENE_STEP_MUSIC, {
      url: '/music',
      views: {
        'content@main.scenes.scene': {
          template: BAS_HTML.musicDetailScene,
          controller: 'musicSceneCtrl',
          controllerAs: 'music'
        }
      }
    })
    .state(STATES.SCENE_STEP_MUSIC_FAVOURITES, {
      url: '/favourites',
      views: {
        'content@main.scenes.scene': {
          template: BAS_HTML.musicFavouritesDetailScene,
          controller: 'musicFavouritesSceneCtrl',
          controllerAs: 'favourites'
        }
      }
    })
    .state(STATES.SCENE_STEP_VIDEO, {
      url: '/video',
      views: {
        'content@main.scenes.scene': {
          template: BAS_HTML.videoDetailScene,
          controller: 'videoSceneCtrl',
          controllerAs: 'video'
        }
      }
    })
    .state(STATES.SCENE_STEP_VIDEO_FAVOURITES, {
      url: '/favourites',
      views: {
        'content@main.scenes.scene': {
          template: BAS_HTML.videoFavouritesDetailScene,
          controller: 'videoFavouritesSceneCtrl',
          controllerAs: 'favourites'
        }
      }
    })
    .state(STATES.ENERGY, {
      url: '/energy',
      views: {
        'content@main': {
          template: BAS_HTML.energy,
          controller: 'energyCtrl',
          controllerAs: 'energy'
        }
      }
    })
    .state(STATES.ENERGY_DETAIL, {
      url: '/detail/:energydeviceid',
      params: {
        energydeviceid: {
          value: '',
          dynamic: true
        }
      },
      views: {
        'content@main': {
          template: BAS_HTML.energyDetail,
          controller: 'energyDetailCtrl',
          controllerAs: 'energyDetail'
        }
      }
    })
    .state(STATES.SCHEDULES, {
      url: '/schedules',
      views: {
        'content@main': {
          template: BAS_HTML.schedules,
          controller: 'basSceneSchedulesCtrl',
          controllerAs: 'scheduler'
        }
      }
    })
    .state(STATES.SCHEDULE, {
      url: '/:schedule',
      params: {
        schedule: {
          value: '',
          dynamic: true
        }
      },
      views: {
        content: {
          template: BAS_HTML.schedule,
          controller: 'basSceneScheduleCtrl',
          controllerAs: 'scheduleCtrl'
        }
      }
    })
    .state(STATES.SCHEDULE_TIMING, {
      url: '/timing',
      views: {
        content: {
          template: BAS_HTML.scheduleTiming
        }
      }
    })
    .state(STATES.SCHEDULE_SCENES, {
      url: '/scenes',
      views: {
        content: {
          template: BAS_HTML.scheduleScenes
        }
      }
    })
    .state(STATES.DOORS, {
      url: '/doors',
      views: {
        'content@main': {
          template: BAS_HTML.openCloseDevices,
          controller: 'openCloseDevicesCtrl',
          controllerAs: 'openCloseDevices'
        }
      }
    })
    .state(STATES.ROOMS, {
      url: '/rooms?view',
      params: {
        view: {
          value: BAS_STATE.S_ROOMS_VIEW_HOME,
          dynamic: true,
          squash: true
        }
      },
      views: {
        'content@main': {
          template: BAS_HTML.rooms,
          controller: 'roomsCtrl',
          controllerAs: 'rooms'
        }
      }
    })
    .state(STATES.ROOM, {
      url: '/:room',
      views: {
        'content@main': {
          template: BAS_HTML.roomDash,
          controller: 'roomDashboardCtrl',
          controllerAs: 'room'
        }
      }
    })
    .state(STATES.LIGHTS, {
      url: '/lights',
      views: {
        'content@main': {
          template: BAS_HTML.lights,
          controller: 'lightsCtrl',
          controllerAs: 'lights'
        }
      }
    })
    .state(STATES.GENERIC_DEVICES, {
      url: '/other',
      views: {
        'content@main': {
          template: BAS_HTML.genericDevices,
          controller: 'genericDevicesCtrl',
          controllerAs: 'devices'
        }
      }
    })
    .state(STATES.GENERIC_DEVICES_V2, {
      url: '/otherV2?genericDeviceUuid',
      params: {
        genericDeviceUuid: {
          value: '',
          dynamic: true
        }
      },
      views: {
        'content@main': {
          template: BAS_HTML.genericDevices,
          controller: 'genericDevicesCtrl',
          controllerAs: 'devices'
        }
      }
    })
    .state(STATES.SHADES, {
      url: '/shades',
      views: {
        'content@main': {
          template: BAS_HTML.shades,
          controller: 'shadesCtrl',
          controllerAs: 'shades'
        }
      }
    })
    .state(STATES.THERMOSTAT, {
      url: '/thermostat/:thermostat',
      views: {
        'content@main': {
          template: BAS_HTML.thermostat,
          controller: 'thermostatCtrl',
          controllerAs: 'basThermostat'
        }
      }
    })
    .state(STATES.THERMOSTAT_WEEK_SCHEDULER, {
      url: '/weekscheduler',
      views: {
        'content@main': {
          template: BAS_HTML.thermostatWeek,
          controller: 'thermostatWeekCtrl',
          controllerAs: 'week'
        }
      }
    })
    .state(STATES.THERMOSTAT_DAY_SCHEDULER, {
      url: '/dayscheduler?day',
      params: {
        day: {
          value: '0',
          dynamic: true
        }
      },
      views: {
        'content@main': {
          template: BAS_HTML.thermostatDay,
          controller: 'thermostatDayCtrl',
          controllerAs: 'day'
        }
      }
    })
    .state(STATES.ROOM_SCENES, {
      url: '/scenes',
      params: {
        openModal: {
          value: false,
          dynamic: true,
          type: 'bool'
        }
      },
      views: {
        'content@main': {
          template: BAS_HTML.roomScenes,
          controller: 'roomScenesCtrl',
          controllerAs: 'roomScenes'
        }
      }
    })
    .state(STATES.ROOM_SCENE, {
      url: '/:scene',
      params: {
        scene: {
          value: '',
          dynamic: true
        }
      },
      views: {
        content: {
          template: BAS_HTML.scene,
          controller: 'basScenesSceneCtrl',
          controllerAs: 'scene'
        }
      }
    })
    .state(STATES.ROOM_SCENE_PRESETS, {
      url: '/presets',
      views: {
        content: {
          template: BAS_HTML.scenePresets
        }
      }
    })
    .state(STATES.ROOM_SCENE_PICTURE, {
      url: '/picture',
      views: {
        'content@main.rooms.room.scenes.scene': {
          template: BAS_HTML.sceneImage
        }
      }
    })
    .state(STATES.ROOM_SCENE_FUNCTIONS, {
      url: '/functions',
      views: {
        content: {
          template: BAS_HTML.functionsDetailScene
        }
      }
    })
    .state(STATES.ROOM_SCENE_STEP, {
      url: '/:step',
      abstract: true,
      params: {
        step: {
          dynamic: true
        }
      }
    })
    .state(STATES.ROOM_SCENE_STEP_LIGHTS, {
      url: '/lights',
      views: {
        'content@main.rooms.room.scenes.scene': {
          template: BAS_HTML.lightsDetailScene,
          controller: 'lightsSceneCtrl',
          controllerAs: 'lights'
        }
      }
    })
    .state(STATES.ROOM_SCENE_STEP_DEVICES, {
      url: '/devices',
      views: {
        'content@main.rooms.room.scenes.scene': {
          template: BAS_HTML.devicesDetailScene,
          controller: 'genericDevicesSceneCtrl',
          controllerAs: 'devices'
        }
      }
    })
    .state(STATES.ROOM_SCENE_STEP_SHADES, {
      url: '/shades',
      views: {
        'content@main.rooms.room.scenes.scene': {
          template: BAS_HTML.shadesDetailScene,
          controller: 'shadesSceneCtrl',
          controllerAs: 'shades'
        }
      }
    })
    // Disabled because of LGC-5277, scene loop
    // .state(STATES.ROOM_SCENE_STEP_SCENES, {
    //     url: '/scenes',
    //     views: {
    //         'content@main.rooms.room.scenes.scene': {
    //             template: BAS_HTML.scenesDetailScene,
    //             controller: 'basScenesSceneScenesCtrl',
    //             controllerAs: 'stepScenes'
    //         }
    //     }
    // })
    .state(STATES.ROOM_SCENE_STEP_THERMOSTAT_PICKER, {
      url: '/thermostat',
      views: {
        'content@main.rooms.room.scenes.scene': {
          template: BAS_HTML.thermostatPickerScene,
          controller: 'thermostatPickerSceneCtrl',
          controllerAs: 'room'
        }
      }
    }).state(STATES.ROOM_SCENE_STEP_THERMOSTAT, {
      url: '/:thermostat',
      views: {
        'content@main.rooms.room.scenes.scene': {
          template: BAS_HTML.thermostatDetailScene,
          controller: 'thermostatSceneCtrl',
          controllerAs: 'basThermostat'
        }
      }
    })
    .state(STATES.ROOM_SCENE_STEP_MUSIC, {
      url: '/music',
      views: {
        'content@main.rooms.room.scenes.scene': {
          template: BAS_HTML.musicDetailScene,
          controller: 'musicSceneCtrl',
          controllerAs: 'music'
        }
      }
    })
    .state(STATES.ROOM_SCENE_STEP_MUSIC_FAVOURITES, {
      url: '/favourites',
      views: {
        'content@main.rooms.room.scenes.scene': {
          template: BAS_HTML.musicFavouritesDetailScene,
          controller: 'musicFavouritesSceneCtrl',
          controllerAs: 'favourites'
        }
      }
    })
    .state(STATES.ROOM_SCENE_STEP_VIDEO, {
      url: '/music',
      views: {
        'content@main.rooms.room.scenes.scene': {
          template: BAS_HTML.videoDetailScene,
          controller: 'videoSceneCtrl',
          controllerAs: 'video'
        }
      }
    })
    .state(STATES.ROOM_SCENE_STEP_VIDEO_FAVOURITES, {
      url: '/favourites',
      views: {
        'content@main.rooms.room.scenes.scene': {
          template: BAS_HTML.videoFavouritesDetailScene,
          controller: 'videoFavouritesSceneCtrl',
          controllerAs: 'favourites'
        }
      }
    })
    .state(STATES.VIDEO, {
      url: '/video',
      abstract: true,
      views: {
        'content@main': {
          template: BAS_HTML.video,
          controller: 'videoCtrl',
          controllerAs: 'video'
        }
      }
    })
    .state(STATES.VIDEO_PLAYER, {
      url: '/remote',
      views: {
        content: {
          template: BAS_HTML.remote,
          controller: 'remoteCtrl',
          controllerAs: 'remote'
        }
      }
    })
    .state(STATES.VIDEO_SOURCES, {
      url: '/sources',
      views: {
        content: {
          template: BAS_HTML.sources,
          controller: 'sourcesCtrl',
          controllerAs: 'sources'
        }
      }
    })
    .state(STATES.VIDEO_FAVOURITES, {
      url: '/favourites',
      views: {
        content: {
          template: BAS_HTML.favourites,
          controller: 'favouritesCtrl',
          controllerAs: 'favourites'
        }
      }
    })
    .state(STATES.MUSIC, {
      url: '/music',
      abstract: true,
      views: {
        'content@main': {
          template: BAS_HTML.music,
          controller: 'musicCtrl',
          controllerAs: 'music'
        }
      }
    })
    .state(STATES.MUSIC_A, {
      url: '',
      abstract: true
    })
    .state(STATES.VIDEO_A, {
      url: '',
      abstract: true
    })
    .state(STATES.MUSIC_PLAYER, {
      url: '/now-playing',
      views: {
        content: {
          template: BAS_HTML.player,
          controller: 'nowPlayingCtrl',
          controllerAs: 'player'
        }
      }
    })
    .state(STATES.MUSIC_SOURCES, {
      url: '/sources',
      views: {
        content: {
          template: BAS_HTML.sources,
          controller: 'sourcesCtrl',
          controllerAs: 'sources'
        }
      }
    })
    .state(STATES.MUSIC_FAVOURITES, {
      url: '/favourites',
      views: {
        content: {
          template: BAS_HTML.favourites,
          controller: 'favouritesCtrl',
          controllerAs: 'favourites'
        }
      }
    })
    .state(STATES.MUSIC_LIBRARY, {
      url: '/library',
      views: {
        content: {
          template: BAS_HTML.library,
          controller: 'libraryCtrl',
          controllerAs: 'mainLibrary'
        }
      }
    })
    .state(STATES.MUSIC_LIBRARY_LOCAL, {
      url: '/local?type&album&artist&albumUri&artistUri',
      params: {
        type: {
          value: '',
          dynamic: true,
          squash: true
        },
        album: {
          value: '',
          dynamic: true,
          squash: true
        },
        artist: {
          value: '',
          dynamic: true,
          squash: true
        },
        albumUri: {
          value: '',
          dynamic: true,
          squash: true
        },
        artistUri: {
          value: '',
          dynamic: true,
          squash: true
        }
      },
      views: {
        main: {
          template: BAS_HTML.templateLibrary,
          controller: 'localLibraryCtrl',
          controllerAs: 'root'
        }
      }
    })
    .state(STATES.MUSIC_LIBRARY_RADIO, {
      url: '/radio',
      views: {
        main: {
          template: BAS_HTML.templateLibrary,
          controller: 'radioLibraryCtrl',
          controllerAs: 'root'
        }
      }
    })
    .state(STATES.MUSIC_LIBRARY_DEEZER, {
      url: '/deezer?type&id',
      params: {
        type: {
          value: '',
          dynamic: true,
          squash: true
        },
        id: {
          value: '',
          dynamic: true,
          squash: true
        }
      },
      views: {
        main: {
          template: BAS_HTML.templateLibrary,
          controller: 'deezerLibraryCtrl',
          controllerAs: 'root'
        }
      }
    })
    .state(STATES.MUSIC_LIBRARY_SPOTIFY, {
      url: '/spotify?type&id',
      params: {
        type: {
          value: '',
          dynamic: true,
          squash: true
        },
        id: {
          value: '',
          dynamic: true,
          squash: true
        }
      },
      views: {
        main: {
          template: BAS_HTML.templateLibrary,
          controller: 'spotifyLibraryCtrl',
          controllerAs: 'root'
        }
      }
    })
    .state(STATES.MUSIC_LIBRARY_TIDAL, {
      url: '/tidal?type&id',
      params: {
        type: {
          value: '',
          dynamic: true,
          squash: true
        },
        id: {
          value: '',
          dynamic: true,
          squash: true
        }
      },
      views: {
        main: {
          template: BAS_HTML.templateLibrary,
          controller: 'tidalLibraryCtrl',
          controllerAs: 'root'
        }
      }
    })
    .state(STATES.ALARMS, {
      url: '/alarms',
      views: {
        content: {
          template: BAS_HTML.alarms,
          controller: 'alarmsCtrl'
        }
      }
    })
    .state(STATES.ALARMS_ALARM, {
      url: '/alarm',
      views: {
        alarm: {
          template: BAS_HTML.alarm
        }
      }
    })
    .state(STATES.SETTINGS, {
      url: '/settings',
      views: {
        content: {
          template: BAS_HTML.settings,
          controller: 'settingsCtrl',
          controllerAs: 'settings'
        }
      }
    })
    .state(STATES.SETTINGS_GENERAL, {
      url: '/general',
      views: {
        setting: {
          template: BAS_HTML.generalSettings,
          controller: 'generalSettingsCtrl',
          controllerAs: 'general'
        }
      }
    })
    .state(STATES.SETTINGS_GENERAL_LISA_ORDER, {
      url: '/lisa-order',
      views: {
        content: {
          template: BAS_HTML.lisaOrderSettings,
          controller: 'lisaOrderSettingsCtrl',
          controllerAs: 'lisa'
        }
      }
    })
    .state(STATES.SETTINGS_LIVE, {
      url: '/live',
      views: {
        setting: {
          template: BAS_HTML.liveSettings,
          controller: 'liveSettingsCtrl',
          controllerAs: 'live'
        }
      }
    })
    .state(STATES.SETTINGS_MUSIC, {
      url: '/music',
      views: {
        setting: {
          template: BAS_HTML.musicSettings,
          controller: 'musicSettingsCtrl',
          controllerAs: 'music'
        }
      }
    })
    .state(STATES.SETTINGS_MUSIC_STREAMING, {
      url: '/streaming',
      views: {
        'content@main.settings.music': {
          template: BAS_HTML.streamingSettings,
          controller: 'streamingSettingsCtrl',
          controllerAs: 'streaming'
        }
      }
    })
    .state(STATES.SETTINGS_MUSIC_STREAMING_DEEZER, {
      url: '/deezer',
      views: {
        content: {
          template: BAS_HTML.deezerSettings,
          controller: 'deezerSettingsCtrl',
          controllerAs: 'deezer'
        }
      }
    })
    .state(STATES.SETTINGS_MUSIC_STREAMING_TIDAL, {
      url: '/tidal',
      views: {
        content: {
          template: BAS_HTML.tidalSettings,
          controller: 'tidalSettingsCtrl',
          controllerAs: 'tidal'
        }
      }
    })
    .state(STATES.SETTINGS_MUSIC_STREAMING_SPOTIFY_WEB, {
      url: '/spotify',
      views: {
        content: {
          template: BAS_HTML.spotifySettings,
          controller: 'spotifySettingsCtrl',
          controllerAs: 'spotify'
        }
      }
    })
    .state(STATES.SETTINGS_MUSIC_DEFAULT_ROOMS, {
      url: '/defaultrooms',
      views: {
        content: {
          template: BAS_HTML.defaultRoomsSettings,
          controller: 'defaultRoomsSettingCtrl',
          controllerAs: 'defaultRooms'
        }
      }
    })
    .state(STATES.SETTINGS_MUSIC_DEFAULT_ROOMS_ROOMS, {
      url: '/:stream',
      params: {
        stream: {
          value: '',
          dynamic: true
        }
      },
      views: {
        'content@main.settings.music': {
          template: BAS_HTML.defaultRoomsSettingsRooms,
          controller: 'roomsDefaultRoomsSettingCtrl',
          controllerAs: 'defaultRoomsRooms'
        }
      }
    })
    .state(STATES.SETTINGS_MUSIC_KNX_PRESETS, {
      url: '/knx-presets',
      views: {
        content: {
          template: BAS_HTML.knxPresetsSettings,
          controller: 'knxPresetsCtrl',
          controllerAs: 'knxPresets'
        }
      }
    })
    .state(STATES.SETTINGS_MUSIC_KNX_PRESETS_PRESETS, {
      url: '/:stream',
      params: {
        stream: {
          value: '',
          dynamic: true
        }
      },
      views: {
        'content@main.settings.music': {
          template: BAS_HTML.presetsKNXPresets,
          controller: 'presetsKNXPresetsCtrl',
          controllerAs: 'presets'
        }
      }
    })
    .state(STATES.SETTINGS_MUSIC_KNX_PRESETS_PRESET, {
      url: '/:presetId',
      params: {
        presetId: {
          dynamic: true
        }
      },
      views: {
        'content@main.settings.music': {
          template: BAS_HTML.presetKNXPresets,
          controller: 'presetKNXPresetsCtrl',
          controllerAs: 'preset'
        }
      }
    })
    .state(STATES.SETTINGS_PROJECT, {
      url: '/project',
      views: {
        setting: {
          template: BAS_HTML.projectSettings,
          controller: 'projectSettingsCtrl',
          controllerAs: 'project'
        }
      }
    })
    .state(STATES.SETTINGS_PROJECT_PROJECT_IMAGE, {
      url: '/image',
      views: {
        content: {
          template: BAS_HTML.projectImageSettings,
          controller: 'projectImageSettingCtrl',
          controllerAs: 'projectImage'
        }
      }
    })
    .state(STATES.SETTINGS_PROJECT_ROOM_IMAGES, {
      url: '/room-images',
      views: {
        content: {
          template: BAS_HTML.roomImageSettings,
          controller: 'roomImagesSettingCtrl',
          controllerAs: 'roomImages'
        }
      }
    })
    .state(STATES.SETTINGS_PROJECT_ROOM_IMAGES_ROOM, {
      url: '/:roomUuid',
      params: {
        roomUuid: {
          value: '',
          dynamic: true
        }
      },
      views: {
        'content@main.settings.project': {
          template: BAS_HTML.roomImageSettingsRoom,
          controller: 'roomImagesRoomSettingCtrl',
          controllerAs: 'roomImagesRoom'
        }
      }
    })
    .state(STATES.SETTINGS_DOOR_PHONE, {
      url: '/doorphone',
      views: {
        setting: {
          template: BAS_HTML.doorPhoneSettings
        }
      }
    })
    .state(STATES.SETTINGS_CAMERAS, {
      url: '/cameras',
      views: {
        setting: {
          template: BAS_HTML.camerasSettings,
          controller: 'camerasSettingsCtrl',
          controllerAs: 'cameras'
        }
      }
    })
    .state(STATES.SETTINGS_NETWORK, {
      url: '/network',
      views: {
        setting: {
          template: BAS_HTML.networkSettings
        }
      }
    })
    .state(STATES.SETTINGS_ABOUT, {
      url: '/about',
      views: {
        setting: {
          template: BAS_HTML.aboutSettings,
          controller: 'aboutSettingsCtrl',
          controllerAs: 'about'
        }
      }
    })
    .state(STATES.CAMERAS, {
      url: '/cameras',
      views: {
        'content@main': {
          template: BAS_HTML.cameras,
          controller: 'camerasCtrl',
          controllerAs: 'cameras'
        }
      }
    })
    .state(STATES.CAMERAS_DETAIL, {
      url: '/:camera',
      params: {
        camera: {
          value: '',
          dynamic: true
        }
      },
      views: {
        'content@main': {
          template: BAS_HTML.cameraDetail,
          controller: 'camerasDetailCtrl',
          controllerAs: 'cameras'
        }
      }
    })
    .state(STATES.LISA, {
      url: '/lisa?view',
      views: {
        'content@main': {
          template: BAS_HTML.lisa,
          controller: 'lisaCtrl',
          controllerAs: 'lisa'
        }
      },
      params: {
        view: {
          value: BAS_STATE.S_LISA_VIEW_HOME,
          dynamic: true,
          squash: true
        }
      }
    })
    .state(STATES.LISA_TILES, {
      url: '/tiles',
      views: {
        'content@main': {
          template: BAS_HTML.lisaTiles,
          controller: 'lisaTilesCtrl',
          controllerAs: 'lisa'
        }
      },
      params: {
        view: {
          value: BAS_STATE.S_LISA_VIEW_HOME,
          dynamic: true,
          squash: true
        }
      }
    })
    .state(STATES.LISA_SCENES, {
      url: '/scenes',
      views: {
        'content@main': {
          template: BAS_HTML.lisaScenes,
          controller: 'lisaScenesCtrl',
          controllerAs: 'lisa'
        }
      }
    })
    .state(STATES.LISA_THERMOSTAT, {
      url: '/thermostat',
      views: {
        'content@main': {
          template: BAS_HTML.lisaThermostat,
          controller: 'lisaThermostatCtrl',
          controllerAs: 'lisa'
        }
      },
      params: {
        view: {
          dynamic: true
        }
      }
    })
    .state(STATES.LISA_LIGHTS, {
      url: '/lights',
      views: {
        'content@main': {
          template: BAS_HTML.lisaLights,
          controller: 'lisaLightsCtrl',
          controllerAs: 'lisa'
        }
      }
    })
    .state(STATES.LISA_LIGHT_DETAIL, {
      url: '/:light',
      params: {
        light: {
          value: '',
          dynamic: true
        },
        view: {
          value: '',
          dynamic: true
        }
      },
      views: {
        'content@main': {
          template: BAS_HTML.lisaLightDetails,
          controller: 'lisaLightDetailsCtrl',
          controllerAs: 'lisa'
        }
      }
    })
    .state(STATES.LISA_MUSIC, {
      url: '/music',
      views: {
        'content@main': {
          template: BAS_HTML.lisaMusic,
          controller: 'lisaMusicCtrl',
          controllerAs: 'lisa'
        }
      },
      params: {
        view: {
          dynamic: true
        }
      }
    })
    .state(STATES.LISA_SHADES, {
      url: '/shades',
      views: {
        'content@main': {
          template: BAS_HTML.lisaShades,
          controller: 'lisaShadesCtrl',
          controllerAs: 'lisa'
        }
      }
    })
    .state(STATES.LISA_SHADE_DETAIL, {
      url: '/:shade',
      params: {
        shade: {
          value: '',
          dynamic: true
        },
        view: {
          value: '',
          dynamic: true
        }
      },
      views: {
        'content@main': {
          template: BAS_HTML.lisaShadeDetails,
          controller: 'lisaShadeDetailsCtrl',
          controllerAs: 'lisa'
        }
      }
    })
    .state(STATES.LISA_CAMERAS, {
      url: '/cameras',
      views: {
        'content@main': {
          template: BAS_HTML.lisaCameras,
          controller: 'lisaCamerasCtrl',
          controllerAs: 'lisa'
        }
      }
    })
    .state(STATES.LISA_INTERCOM, {
      url: '/intercom',
      views: {
        'content@main': {
          template: BAS_HTML.lisaIntercom,
          controller: 'lisaIntercomCtrl',
          controllerAs: 'lisa'
        }
      }
    })
}
