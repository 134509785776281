{
  "12_hr": "2-mal-12-Stunden-Zählung",
  "24_hr": "24-Stunden-Zählung",
  "24_hr_time": "24-Stunden-Format",
  "about": "Info",
  "accepted": "Akzeptiert",
  "account": "Konto",
  "account_exists": "Konto existiert",
  "account_exists_l1": "Dieses Konto existiert bereits.",
  "account_exists_l2": "Wählen Sie bitte eine andere E-Mail-Adresse oder melden Sie sich mit dieser E-Mail-Adresse an.",
  "account_not_confirmed": "Konto unbestätigt",
  "account_not_confirmed_l1": "Dieses Konto wurde noch nicht bestätigt.",
  "account_not_confirmed_l2": "Prüfen Sie bitte Ihre Emails auf eine Aktivierungsmail.",
  "account_not_confirmed_l3": "Dieses Konto bestätigen?",
  "act_in": "Rein",
  "activate": "Aktivieren",
  "active": "Aktiv",
  "act_out": "Raus",
  "act_push": "DRÜCKEN",
  "adaptive_brightness": "Adaptive Helligkeitseinstellung",
  "add": "Hinzufügen",
  "add_favourite": "Favorit hinzufügen",
  "add_group": "Gruppe hinzufügen",
  "add_home": "Haus hinzufügen",
  "address": "Adresse",
  "add_to_fav": "Zu Favoriten hinzufügen",
  "add_to_playlist": "Zu Playlist hinzufügen",
  "add_to_queue": "Zu Warteschlange hinzufügen",
  "air_conditioning": "Klimaanlage",
  "air_pressure": "Luftdruck",
  "alarm_at": "Wecker auf",
  "alarm_missing_txt": "Ein oder mehrere Pflichtfelder fehlen",
  "alarm_not_selected_l1": "Kein Alarm ausgewählt.",
  "alarm_not_selected_l2": "Wählen Sie bitte einen Alarm.",
  "alarms": "Wecker",
  "album": "Album",
  "albums": "Alben",
  "alert": "Warnung",
  "all": "Alle",
  "all_off": "Alles aus",
  "all_off_txt_l1": "Sie werden alle Räume ausschalten.",
  "all_off_txt_l2": "Sind Sie sicher, dass Sie fortfahren möchten?",
  "all_on": "Alles ein",
  "almost_done": "Sie sind fast fertig.",
  "almost_done_txt_l1": "Sie haben noch keine Lieblingsplayliste oder keinen Radiosender.",
  "almost_done_txt_l2": "Fügen Sie zuerst einige Favoriten hinzu.",
  "already_linked": "Mit Basalte Konto verbunden",
  "already_linked_to_me": "Verknüpft mit Ihrem Basalte Konto",
  "already_verified": "Bereits verifiziert",
  "alt_power": "Power",
  "alt_user": "Benutzer",
  "application": "Applikation",
  "application_version": "Applikationsversion",
  "app_update_required": "App Update erforderlich",
  "app_update_required_android_l2": "Die App im Play Store bitte updaten.",
  "app_update_required_ios_l2": "Die App im App Store bitte updaten.",
  "app_update_required_l2": "Die App im Store bitte updaten.",
  "artist": "Künstler",
  "artists": "Künstler",
  "attention": "Achtung!",
  "audio_unavailable": "Audiosystem ist nicht verfügbar",
  "audio_zones": "Audio-Zonen",
  "author": "Autor",
  "back": "zurück",
  "basalte_account": "Basalte Konto",
  "basalte_info_l1": "Mit einem Basalte Konto können Sie Ihr Gebäude mit der App verlinken und es außerhalb Ihre Heimnetzwerkes steuern.",
  "basalte_link_server_l1": "Dieses Zuhause mit Basalte Konto verbinden?",
  "basalte_link_server_l2": "Sie werden die Möglichkeit haben, Ihr Zuhause außerhalb Ihres Heimnetzwerkes zu steuern.",
  "basalte_unlink_server_l1": "Dieses Zuhause von Ihrem Basalte Konto löschen?",
  "basalte_unlink_server_l2": "Sie haben nicht mehr die Möglichkeit Ihr Zuhause von außerhalb Ihres Heimnetzwerkes zu steuern.",
  "bass": "Bass",
  "BE": "Belgien",
  "beta": "Beta",
  "bluetooth": "Bluetooth",
  "bluetooth_pairing_txt": "Sie können jetzt Ihr Bluetooth-Gerät verbinden.",
  "brightness": "Lichtstärke",
  "browse": "Blättern",
  "browse_deezer": "Deezer Favoriten",
  "browse_library": "Musikbibliothek",
  "browser_app_update_required_l2": "laden Sie bitte die Seite neu oder updaten Sie den Server.",
  "browse_stations": "Radiosender",
  "buffering": "laden",
  "building_building": "Gebäude",
  "building_garage": "Garage",
  "building_main_house": "Haupthaus",
  "building_pool_house": "Poolhaus",
  "building_stables": "Stall",
  "call_accepted": "Anruf angenommen",
  "call_declined": "Anruf abgelehnt",
  "call_history": "Anrufliste",
  "cameras": "Kameras",
  "cancel": "Annullieren",
  "categories": "Kategorien",
  "category": "Kategorie",
  "celsius": "Celsius",
  "change_account": "Konto wechseln",
  "change_home": "Zuhause wechseln",
  "change_image": "Bild ändern",
  "change_password": "Passwort ändern",
  "change_password_l1": "Sie sind kurz davor Ihr Passwort zu ändern.",
  "change_user": "Profil wechseln",
  "channel_number": "Kanal Nummer",
  "charts": "Charts",
  "check_for_updates": "Auf Updates prüfen",
  "checking": "prüfen ...",
  "checking_for_updates": "Auf Updates prüfen...",
  "choose_favourite": "Möchten Sie einen Favorit auswählen?",
  "choose_home": "Wählen Sie ein Zuhause aus",
  "choose_music": "Möchten Sie einen Favorit auswählen?",
  "choose_preset": "Wählen Sie eine Voreinstellung aus",
  "choose_source": "Möchten Sie eine Quelle auswählen?",
  "clear": "Löschen",
  "cleared": "Gelöscht",
  "clear_queue_txt_l1": "Sie sind kurz davor Ihre Warteschlange zu löschen.",
  "clear_queue_txt_l2": "Möchten Sie trotzdem damit fortfahren?",
  "clear_selection": "Auswahl löschen",
  "clear_selection_txt_l1": "Sie sind kurz davor die Auswahl zu löschen.",
  "clear_selection_txt_l2": "Möchten Sie trotzdem damit fortfahren?",
  "close": "Schließen",
  "closed": "Geschlossen",
  "code": "Code",
  "color": "Farbe",
  "color_temperature": "Farbtemperatur",
  "color_theme": "Farbthema",
  "color_white": "Weiß",
  "comfort": "Komfort",
  "configure": "Konfigurieren",
  "confirmation_code_sent": "Bestätigungscode gesendet",
  "confirmation_code_sent_l1": "Ein Bestätigungscode wurde an folgende Adresse gesendet:",
  "confirm_continue": "Soll der Vorgang wirklich fortgesetzt werden?",
  "confirm_overwrite": "Soll der vorhandene Datenbestand wirklich überschrieben werden?",
  "connect": "Verbinden",
  "connected": "Verbunden",
  "connecting": "Verbinden",
  "connecting_no_active_servers": "Server ist offline.",
  "connecting_no_integrator_access": "Fernzugriff für den Integrator wurde für diesen Server nicht freigegeben.",
  "connecting_not_authorized": "Nicht autorisiert, um mit diesem Server zu verbinden.",
  "connecting_small": "verbinden",
  "connecting_stop_and_retry_text": "Verbindung zum Server fehlgeschlagen. In 10 Sekunden wird erneut versucht.",
  "connecting_stop_text": "Verbindung zum Server fehlgeschlagen.",
  "connecting_text": "Verbinden...",
  "connecting_text_small": "Verbinden...",
  "connection_lost": "Keine Verbindung",
  "connection_lost_text": "Wieder mit dem Server verbinden",
  "connection_unable": "Mit dem angegebenen Server kann nicht verbunden werden.",
  "connect_offline_server_l1": "Dieses Gebäude wurde verlinkt, aber scheint offline zu sein.",
  "connect_offline_server_l2": "Trotzdem versuchen, zu konnektieren?",
  "connect_server": "Mit Server verbinden",
  "consumption": "Verbrauch",
  "contact_support": "Kontaktieren Sie unser Support Team",
  "continue_anyway": "Trotzdem fortfahren",
  "continue_without_basalte_account": "Ohne Basalte Konto fortfahren",
  "cover_art_background": "Cover Art Hintergrund",
  "current_password": "Aktuelles Passwort",
  "current_temperature": "Aktuell",
  "custom": "Personalisiert",
  "date": "Datum",
  "day": "Tag",
  "day_friday": "Freitag",
  "day_monday": "Montag",
  "day_saturday": "Samstag",
  "day_short2_friday": "Fr",
  "day_short2_monday": "Mo",
  "day_short2_saturday": "Sa",
  "day_short2_sunday": "So",
  "day_short2_thursday": "Do",
  "day_short2_tuesday": "Di",
  "day_short2_wednesday": "Mi",
  "day_sunday": "Sonntag",
  "day_thursday": "Donnerstag",
  "day_tuesday": "Dienstag",
  "day_wednesday": "Mittwoch",
  "debug": "Debug",
  "declined": "Abgelehnt",
  "deezer": "Deezer",
  "deezer_disconnect_txt": "Sie sind kurz davor das Deezer-Konto für diesen Player abzumelden.",
  "deezer_favourite": "Deezer Favoriten",
  "deezer_flow": "Flow",
  "deezer_flow_description": "Ihr persönlicher Soundtrack",
  "deezer_flow_full": "Deezer Flow",
  "deezer_no_link": "Noch kein Deezer-Konto verknüpft",
  "deezer_oauth_error": "Deezer Authentifizierungsfehler",
  "default_rooms": "Standardräume",
  "default_rooms_hint": "Wählen Sie mindestens einen Raum als Standardraum, damit In-Home-Streaming ordnungsgemäß funktionieren kann.",
  "default_rooms_info_l1": "Standardraum für Audiostreams von Dritten (z.B. Spotify Connect).",
  "default_rooms_info_l2": "Wenn keine Räume an den Stream gekoppelt sind, schalten Sie dann die ausgewählten Räume ein.",
  "default_rooms_spotify_hint": "Wählen Sie mindestens einen Standardraum, damit Spotify Connect ordnungsgemäß funktionieren kann.",
  "default_rooms_spotify_warning_l1": "Wählen Sie mindestens einen Standardraum für jeden Stream",
  "default_rooms_spotify_warning_l2": "zum Vervollständigen der Konfiguration.",
  "default_rooms_spotify_warning_title": "Spotify Connect wird jetzt unterstützt",
  "default_view": "Standardansicht",
  "delay": "Verzögerung",
  "delay_seconds_b": "Verzögerung (Sekunden)",
  "delete": "Löschen",
  "delete_account": "Konto löschen",
  "delete_account_l1": "Sie sind kurz davor Ihr Konto unwiderruflich zu löschen.",
  "demo_enter": "Demo Modus",
  "demo_functionality_warning_txt": "Diese Funktionalität wird im Demo Modus nicht unterstützt.",
  "demo_location": "Gent",
  "demo_server_name": "Demo - Home",
  "demo_title": "Demo",
  "demo_warning_txt": "Sie sind kurz davor den Demo Modus zu starten.",
  "detail": "Detail",
  "device": "Systemgerät",
  "devices": "Systemgeräte",
  "device_settings": "Geräteeinstellungen",
  "device_volume": "Gerätlautstärke",
  "dhcp": "DHCP",
  "direction_east": "Osten",
  "direction_east-northeast": "Osten-Nordosten",
  "direction_east-southeast": "Osten-Südosten",
  "direction_north": "Norden",
  "direction_northeast": "Nordosten",
  "direction_north-northeast": "Nord-Nordosten",
  "direction_north-northwest": "Nord-Nordwesten",
  "direction_northwest": "Nordwesten",
  "direction_short_east": "O",
  "direction_short_east-northeast": "ONO",
  "direction_short_east-southeast": "OSO",
  "direction_short_north": "N",
  "direction_short_northeast": "NO",
  "direction_short_north-northeast": "NNO",
  "direction_short_north-northwest": "NW",
  "direction_short_northwest": "NW",
  "direction_short_south": "S",
  "direction_short_southeast": "SO",
  "direction_short_south-southeast": "SSO",
  "direction_short_south-southwest": "SSW",
  "direction_short_southwest": "SW",
  "direction_short_west": "W",
  "direction_short_west-northwest": "WNW",
  "direction_short_west-southwest": "WSW",
  "direction_south": "Süden",
  "direction_southeast": "Südosten",
  "direction_south-southeast": "Süd-Südosten",
  "direction_south-southwest": "Süd-Südwesten",
  "direction_southwest": "Südwesten",
  "direction_west": "Westen",
  "direction_west-northwest": "West-Nordwesten",
  "direction_west-southwest": "West-Südwesten",
  "disband_group": "Gruppierung aufheben",
  "dns": "DNS",
  "domain": "Domain",
  "done": "Fertig",
  "do_not_disturb": "Nicht stören",
  "do_not_disturb_off_l1": "Alle Soundbenachrichtigungen sind derzeit aktiviert.",
  "do_not_disturb_on_l1": "Alle Soundbenachrichtigungen sind derzeit deaktiviert.",
  "do_not_show_again": "Nicht erneut anzeigen",
  "door_phone": "Türsprechanlage",
  "doors": "Türen",
  "down": "Runter",
  "downloading": "herunterladen",
  "downloading_updates": "Updates herunterladen",
  "do_you_want_to_continue": "Möchten Sie den Vorgang fortsetzen?",
  "drag_and_drop_images": "Wählen Sie ein Bild aus oder legen Sie es hier ab",
  "drag_to_reorder": "Ziehen um neuzuordnen",
  "dsp": "DSP",
  "dsp_configuration": "DSP-Konfiguration",
  "dsp_profile_club": "Club",
  "dsp_profile_custom": "Benutzerdefinierte",
  "dsp_profile_flat": "Flach",
  "dsp_profile_jazz": "Jazz",
  "dsp_profile_live": "Live",
  "dsp_profile_pop": "Pop",
  "dsp_profile_rock": "Rock",
  "dsp_shortcut_l1": "Sie sind kurz davor die DSP Einstellungen zu öffnen.",
  "echo_cancellation": "Echounterdrückung",
  "echo_limit": "Echolimit",
  "edit": "Ändern",
  "edit_dns": "DNS ändern",
  "edit_gateway": "Gateway ändern",
  "edit_ip_address": "IP-Adresse ändern",
  "edit_subnet_mask": "Subnetzmaske ändern",
  "ellie_room": "Ellie-Raum",
  "ellie_system_update": "Ellie-Systemupdate",
  "ellie_system_update_l1": "Ellie sollte neu gestartet werden, um das Systemupdate zu installieren.",
  "ellie_update": "Ellie-Update",
  "email": "E-Mailadresse",
  "empty": "leer",
  "empty_queue": "Warteschlange ist leer",
  "empty_queue_txt_l1": "Ihre Warteschlange scheint leer zu sein.",
  "empty_queue_txt_l2": "Fügen Sie zuerst einige Songs hinzu.",
  "empty_song": "Spieler ist gestoppt",
  "empty_song_txt_l1": "Sie haben das Ende Ihrer Warteschlange erreicht.",
  "empty_song_txt_l2": "Klicken Sie \"Play\" um neuzustarten.",
  "empty_src": "Keine Quelle ausgewählt",
  "empty_src_txt_l1": "Sie haben keine Quelle ausgewählt.",
  "empty_src_txt_l2": "Wählen Sie zuerst eine Quelle.",
  "empty_zone": "Keinen Raum ausgewählt",
  "empty_zone_txt_l1": "Sie haben keinen Raum ausgewählt.",
  "empty_zone_txt_l2": "Wählen Sie zuerst einen Raum.",
  "energy": "Strom",
  "energy_meter": "Energiezähler",
  "eq": "EQ",
  "error": "Fehler",
  "external": "Extern",
  "extra_permissions_required": "Bestimmte Funktionen benötigen möglicherweise zusätzliche Berechtigungen.",
  "ext_src": "Externe Quelle",
  "fahrenheit": "Fahrenheit",
  "failed": "Fehlgeschlagen",
  "fan": "Lüfter",
  "fan_auto": "Auto",
  "fan_high": "Hoch",
  "fan_low": "Niedrig",
  "fan_medium": "Mittel",
  "fan_off": "Aus",
  "favourite": "Favorit",
  "favourites": "Favoriten",
  "favourite_scenes_full": "Lieblingsszenen sind voll",
  "favourite_scenes_full_sub": "Sie können maximal 4 Lieblingsszenen in einem Raum konfigurieren",
  "favourite_songs": "Lieblingssongs",
  "featured_playlists": "Empfohlene Playliste",
  "floor_attic": "Dachgeschoss",
  "floor_basement": "Keller",
  "floor_floor": "Stock",
  "floor_garden": "Garten",
  "floor_heating": "Fußbodenheizung",
  "floor_loft": "Dachboden",
  "floor_lower": "Untergeschoss",
  "floor_lvl_0": "Erdgeschoss",
  "floor_lvl_1": "Erster Stock",
  "floor_lvl_2": "Zweiter Stock",
  "floor_lvl_3": "Dritter Stock",
  "floor_lvl_4": "Vierter Stock",
  "floor_lvl_5": "Fünfter Stock",
  "floor_lvl_6": "Sechster Stock",
  "floor_lvl_7": "Siebter Stock",
  "floor_lvl_8": "Achter Stock",
  "floor_lvl_9": "Neunter Stock",
  "floor_lvl_10": "Zehnter Stock",
  "floor_lvl_11": "Elfter Stock",
  "floor_lvl_12": "Zwölfter Stock",
  "floor_lvl_13": "Dreizehnter Stock",
  "floor_lvl_14": "Vierzehnter Stock",
  "floor_lvl_15": "Fünfzehnter Stock",
  "floor_lvl_16": "Sechzehnter Stock",
  "floor_lvl_17": "Siebzehnter Stock",
  "floor_lvl_18": "Achtzehnter Stock",
  "floor_lvl_19": "Neunzehnter Stock",
  "floor_lvl_20": "Zwanzigster Stock",
  "floor_upper": "Obergeschoss",
  "forgot_password": "Passwort vergessen",
  "frequency": "Frequenz",
  "full_screen": "Vollbildansicht",
  "functions": "Funktionen",
  "gas": "Gas",
  "gateway": "Gateway",
  "general": "Allgemein",
  "generic_devices": "Geräte",
  "generic_devices_fire": "Feuer",
  "generic_devices_garden": "Garten",
  "generic_devices_socket": "Steckdose",
  "genre": "Genre",
  "genre_moods": "Genre und Stimmungen",
  "genres": "Genres",
  "go_to_login_l1": "Melden Sie sich an oder registrieren Sie sich mit Basalte Konto.",
  "go_to_manage_l1": "Verwalten Sie Ihr Basalte Konto.",
  "group": "Gruppe",
  "groups": "Gruppierungen",
  "home": "Home",
  "humidity": "Feuchtigkeit",
  "hz": "Hz",
  "images": "Bild",
  "image_smaller_than_5mb": "Das Bild muss kleiner als 5 MB sein",
  "in": "In",
  "incorrect_album": "Album nicht gefunden",
  "incorrect_artist": "Künstler nicht gefunden",
  "install": "Installieren",
  "installing": "installieren",
  "install_update": "Update installieren",
  "install_updates": "Updates installieren",
  "integrator": "Integrator",
  "integrator_access": "Fernzugriff",
  "integrator_access_allow": "Fernzugriff für den Integrator erlauben",
  "integrator_access_ask": "Bitten Sie den Endkunden den Fernzugriff für den Integrator freizugeben.",
  "integrator_access_disabled": "Fernzugriff wurde deaktiviert.",
  "integrator_access_explanation": "Diese Funktion erlaubt Ihrem Integrator von ferne aus eine (völlig verschlüsselte und sichere) Verbindung mit Ihrem System aufzubauen, um:",
  "integrator_access_o1_program_basalte": "Ihr Basalte System programmieren und bedienen zu können",
  "integrator_access_o2_program_knx": "Ihre KNX Produkte programmieren zu können",
  "integrator_access_o3_access_network": "Zugang zu Ihrem ganzen IP Netzwerk aufbauen zu können",
  "integrator_access_privacy": "Um den Datenschutz gewährleisten zu können, empfehlen wir, diese Funktion nur vorübergehend und wenn nötig einzuschalten.",
  "intercom": "Intercom",
  "internal": "Intern",
  "invalid_address": "Ungültige Addresse",
  "invalid_code": "Ungültiger Code",
  "invalid_code_l1": "Der gegebene Bestätigungscode war nicht korrekt.",
  "invalid_code_l2": "Vergewissern Sie sich, dass der Code korrekt ist, oder fordern Sie einen neuen an.",
  "invalid_input": "Ungültige Eingabe",
  "invalid_user": "Ungültiger Benutzer",
  "invalid_user_l1": "Vergewissern Sie sich, dass die E-Mail-Adresse richtig ist.",
  "ios_plist_string_NSCameraUsageDescription": "Mit der Kamera können Bilder für Räume gemacht werden",
  "ios_plist_string_NSLocalNetworkUsageDescription": "Der lokale Netzwerkzugriff kann verwendet werden, um Home Servers, IP-Kameras, ... zu finden.",
  "ios_plist_string_NSPhotoLibraryAddUsageDescription": "Aus der Fotobibliothek können Fotos für die Räume gespeichert werden",
  "ios_plist_string_NSPhotoLibraryUsageDescription": "Aus der Fotobibliothek können Fotos für Räume ausgewählt werden",
  "ip": "IP",
  "ip_address": "IP addresse",
  "join": "+ Teilnehmen",
  "join_alt": "+ Teilnehmen",
  "keep_awake": "iPad wach halten",
  "keep_awake_device": "Gerät wach halten",
  "kelvin": "Kelvin",
  "Key": "de_DE",
  "knx_presets": "KNX Voreinstellungen",
  "knx_presets_info": "Passen Sie die bestehenden KNX Voreinstellungen an, um etwas anderes abzuspielen.",
  "language": "Sprache",
  "language_system": "Systemsprache",
  "last-7-days": "Letzte 7 Tage",
  "last_hour": "Letzte Stunde",
  "last_room": "Letzter Raum",
  "last_view": "Letzte Ansicht",
  "learn_more": "Mehr Infos",
  "leave_group": "Gruppierung verlassen",
  "left": "Links",
  "lena_system_update": "Lena Systemupdate",
  "lena_system_update_l1": "Lena sollte neu gestartet werden, um das Systemupdate zu installieren.",
  "library": "Bibliothek",
  "library_empty": "Bibliothek ist leer",
  "library_scanning": "Bibliothek scannen",
  "light_accent_light": "Akzentbeleuchtung",
  "light_art_light": "Kunstleuchte",
  "light_cabinet": "Schrankleuchte",
  "light_ceiling_mounted_fixture": "Deckenleuchte",
  "light_chandelier": "Kronleuchter",
  "light_desk": "Schreibtischleuchte",
  "light_down": "Downleuchte",
  "light_floor_lamp": "Stehleuchte",
  "light_lampshade": "Schirmleuchte",
  "light_led": "LED Leuchte",
  "light_led_strip": "LED Stripe",
  "light_mirror": "Spiegelleuchte",
  "light_mood_light": "Stimmungsleuchte",
  "light_pendant": "Pendelleuchte",
  "light_reading_light": "Leseleuchte",
  "lights": "Beleuchtung",
  "lights_off_all": "Alle Leuchten ausschalten",
  "light_spot": "Spotleuchte",
  "light_table_lamp": "Tischleuchte",
  "light_track": "Lichtschiene",
  "light_via": "Via",
  "light_wall_mounted_fixture": "Wandleuchte",
  "link": "Verlinken",
  "link_deezer_info_l1": "Ein Deezer-Konto mit einem Stream verlinken.",
  "link_deezer_info_l2": "Dies erlaubt das Browsen und Abspielen einer Deezer-Bibliothek.",
  "linked_homes": "Verlinkte Gebäude",
  "linked_projects": "Verlinkte Projekte",
  "linked_with": "Verbunden mit",
  "link_home": "Öffnen Sie die Basalte Home App auf einem lokalen Gerät und verlinken Sie in den Einstellungen Ihr Gebäude.",
  "link_server": "Mit Basalte Konto verlinken",
  "link_server_full_sub": "Dieses Projekt mit dem Basalte Konto verlinken?",
  "link_spotify_info_l1": "Ein Spotify-Konto mit einem Stream verlinken.",
  "link_spotify_info_l2": "Dies erlaubt das Browsen und Abspielen einer Spotify-Bibliothek.",
  "link_tidal_info_l1": "Ein TIDAL-Konto mit einem Stream verlinken.",
  "link_tidal_info_l2": "Das erlaubt das Browsen und Abspielen einer TIDAL-Bibliothek.",
  "lisa_order_hint": "Ändern Sie die Reihenfolge und die Sichbarkeit der Seiteun und Kacheln.",
  "lisa_system_update": "Lisa Systemupdate",
  "lisa_system_update_l1": "Lisa sollte neu gestartet werden, um das Systemupdate zu installieren.",
  "listen_in": "Mithören",
  "liters": "Liter",
  "live_adjustments": "Live-Anpassungen",
  "loading": "Laden…",
  "local": "Lokal",
  "location": "Ort",
  "location_back": "Hinten",
  "location_bottom": "Unten",
  "location_center": "Zentral",
  "location_chair": "Stuhl",
  "location_corner": "Ecke",
  "location_desk": "Schreibtisch",
  "location_east": "Osten",
  "location_front": "Vorne",
  "location_high": "Hoch",
  "location_island": "Insel",
  "location_left": "Links",
  "location_low": "Tief",
  "location_mid": "Mitte",
  "location_niche": "Nische",
  "location_north": "Norden",
  "location_right": "Rechts",
  "location_shelves": "Regal",
  "location_south": "Süden",
  "location_stove": "Ofen",
  "location_table": "Tisch",
  "location_top": "Oben",
  "location_west": "Westen",
  "logged_in_as": "Angemeldet als",
  "log_out": "Abmelden",
  "log_out_everywhere": "Überall abmelden",
  "log_out_everywhere_l1": "Sie sind kurz davor aus allen Apps und Geräten auszuloggen.",
  "long-press-save-scene": "Langer Tastendruck, um die Szene zu speichern",
  "louver_30": "30 Grad",
  "louver_45": "45 Grad",
  "louver_60": "60 Grad",
  "louver_horizontal": "Horizontal",
  "louver_mode": "Lamellenmodus",
  "louver_off": "Aus",
  "louver_swing": "Schwenken",
  "louver_vertical": "Vertikal",
  "mac_address": "MAC-Adresse",
  "manage": "Verwalten",
  "manage_account": "Konto verwalten",
  "manual_conn": "Manuelle Verbindung",
  "manual_text": "Geben Sie bitte die Adresse des gewünschten Servers ein.",
  "max": "Max",
  "menu": "Menü",
  "mf_device": "{count, plural, =1 {Systemgerät} other {Systemgeräte}}",
  "mf_feels_like": "Fühlt sich an wie {value}",
  "mf_minute": "{count} {count, plural, =1 {Minute} other {Minuten}}",
  "mf_powered_by": "Unterstützt von {value}",
  "mf_second": "{count} {count, plural, =1 {Sekunde} other {Sekunden}}",
  "mf_server": "{count, plural, =1 {Server} other {Servers}}",
  "mf_song": "{count} {count, plural, =1 {Song} other {Songs}}",
  "mf_source": "Quelle: {value}",
  "microphone_gain": "Mikrofon Gain",
  "min": "Mindest",
  "mix": "Mix",
  "mixed_sources": "Mehrere Quellen",
  "mixed_sources_txt_l1": "Diese Gruppierung enthält Räume mit mehreren Quellen.",
  "mixed_sources_txt_l2": "Wählen Sie eine Quelle aus.",
  "mixes": "Mixes",
  "mixes_small": "mixes",
  "mode": "Modus",
  "mode_auto": "Auto",
  "mode_cooling": "Kühlen",
  "mode_drying": "Trocknen",
  "mode_fan_only": "Nur Lüfter",
  "mode_heating": "Heizen",
  "mode_off": "Aus",
  "month": "Monat",
  "month_april": "April",
  "month_august": "August",
  "month_december": "Dezember",
  "month_february": "Februar",
  "month_january": "Januar",
  "month_july": "Juli",
  "month_june": "Juni",
  "month_march": "März",
  "month_may": "Mai",
  "month_november": "November",
  "month_october": "Oktober",
  "month_september": "September",
  "month_short_april": "Apr",
  "month_short_august": "Aug",
  "month_short_december": "Dez",
  "month_short_february": "Feb",
  "month_short_january": "Jan",
  "month_short_july": "Juli",
  "month_short_june": "Juni",
  "month_short_march": "März",
  "month_short_may": "Mai",
  "month_short_november": "Nov",
  "month_short_october": "Okt",
  "month_short_september": "Sept",
  "mood": "Stimmung",
  "moods": "Stimmungen",
  "music": "Musik",
  "music_choice": "Musikauswahl",
  "music_go_to": "Zu Musik springen",
  "music_is_active": "Musik ist aktiv",
  "music_is_playing": "Musik spielt gerade",
  "music_off": "Musik aus",
  "music_off_all": "Alle Räume ausschalten",
  "music_off_all_rooms": "Musik aus, alle Räume",
  "music_off_current": "Aktuellen Raum ausschalten",
  "music_off_current_room": "Musik aus, dieser Raum",
  "music_off_txt_l1": "Sie sind kurz davor einen Raum oder alle Räume auszuschalten.",
  "music_off_txt_l2": "Möchten Sie trotzdem damit fortfahren?",
  "music_on_current": "Aktuellen Raum einschalten",
  "music_server": "Musikserver",
  "mute_area_on_call": "Musik während eines Anrufes stummschalten",
  "my_favorites": "Meine Favoriten",
  "my_music": "Meine Musik",
  "name": "Name",
  "nds": "Servers suchen",
  "nds_music": "Musikserver suchen",
  "nds_none": "Keine Server gefunden!",
  "nds_none_music": "Keine Musikserver gefunden!",
  "nds_text_l1": "Dies könnte ein paar Sekunden dauern...",
  "nds_text_l2": "Vergewissern Sie sich, dass Ihr Gerät mit dem Heimnetzwerk (WLAN) verbunden ist.",
  "network": "Netzwerk",
  "network_input_invalid": "Alle Eingabefelder müssen korrekt ausgefüllt werden",
  "never": "Niemals",
  "new": "Neu",
  "new_alarm": "Neuer Alarm",
  "new_image": "Neues Bild",
  "new_password": "Neues Passwort",
  "new_password_confirmation": "Bestätigen Sie das neue Passwort",
  "new_playlist": "Neue Playlist...",
  "new_releases": "Neue Releases",
  "new_releases_small": "neue Releases",
  "new_scene": "Neue Szene",
  "new_schedule": "Neuer Zeitplan",
  "new_step": "Neuer Schritt",
  "next": "Nächster",
  "next_song": "nächster Song",
  "no": "Nein",
  "no_active_rooms": "Keine aktiven Räume",
  "no_all_off": "Nein, nicht ausschalten",
  "no_av": "Kein AV-Eingang",
  "no_call_entries": "Keine Anrufeinträge vorhanden",
  "no_configuration": "Keine Konfiguration verfügbar",
  "no_content_available": "Keine Inhalte verfügbar",
  "no_data": "Keine Daten",
  "no_deezer_dis": "Nein, Deezer-Konto nicht trennen",
  "no_devices_capabilities": "Keine anpassbaren Geräte",
  "no_doors_available": "Keine Türen vorhanden",
  "no_favourites": "Keine Favoriten",
  "no_keep_it": "Nein, behalte es",
  "no_lights_capabilities": "Keine dimmbaren Leuchten",
  "no_linked_homes": "Keine verlinkten Gebäuden gefunden.",
  "none": "Keine",
  "no_network": "Keine Netzwerkverbindung",
  "no_preview_available": "Keine Kameravorschau verfügbar",
  "no_projects": "Keine Projekte gefunden.",
  "no_rooms": "Keine Räume",
  "no_rooms_available": "Keine Räume verfügbar",
  "no_room_selected": "Keinen Raum ausgewählt",
  "no_rooms_listening": "Es gibt keine Räume die auf diesen Stream zuhören",
  "no_scene": "Keine Szenen verfügbar",
  "no_scenes_favourites": "Keine Szenen Favoriten",
  "no_scenes_txt": "Ausgewählte Szenen werden hier angezeigt.",
  "no_search_results": "Keine Suchergebnisse",
  "no_servers": "Keine Server gefunden",
  "no_shades_capabilities": "Kein verstellbarer Sonnenschutz",
  "no_source": "- aus -",
  "no_sources_available": "Keine Audioquellen verfügbar",
  "no_steps": "Diese Szene enthält keine Schritte",
  "not_allowed": "Nicht erlaubt",
  "not_allowed_l1": "Sie sind dafür nicht berechtigt",
  "notification": "Benachrichtigung",
  "notification_permission_missing": "Basalte Home ist nicht berechtigt, Benachrichtigungen zu senden. Sie können die Option 'Benachrichtigungen' in den Systemeinstellungen Ihres Geräts aktivieren.",
  "notifications": "Benachrichtigungen",
  "not_linked": "Nicht verlinkt",
  "not_now": "Jetzt nicht",
  "not_set": "Nicht eingestellt",
  "no_users_l1": "Keine Benutzer gefunden.",
  "no_users_l2": "Selektieren Sie einen Server mit Benutzern.",
  "now_playing": "Spielt gerade",
  "number_of_songs": "Song(s)",
  "off": "Aus",
  "offline_server": "Offline Server",
  "offset_minutes": "Offset (Minuten)",
  "ok": "OK",
  "on": "Ein",
  "onix_black": "Schwarz",
  "online": "Online",
  "only_png_jpeg_supported": "Nur PNG- und JPEG-Bilder werden unterstützt",
  "open": "Öffnen",
  "open_close_door": "Tür",
  "open_close_gate": "Tor",
  "open_close_window": "Fenster",
  "open_settings": "Einstellungen öffnen",
  "open_spotify": "Spotify öffnen",
  "open_spotify_l1": "Sie sind kurz davor die Spotify App zu öffnen.",
  "open_state": "Geöffnet",
  "or": "Oder",
  "order_cameras_by_holding_and_draging": "Ziehen Sie die Kameras in die gewünschte Reihenfolge.",
  "other": "Andere",
  "out": "Aus",
  "page_order": "Seitenreihenfolge",
  "pair": "Verbinden",
  "password": "Passwort",
  "password_changed": "Passwort geändert",
  "password_confirmation": "Passwort bestätigt",
  "password_requirements": "Ihr Passwort sollte mindestens 8 Zeichen enthalten und mindestens einen Groß- und Kleinbuchstaben, sowie eine Zahl.",
  "permission_denied": "Berechtigung verweigert",
  "permission_denied_go_to_settings_l1": "Diese Funktion benötigt Berechtigungen, um ordnungsgemäß zu funktionieren.",
  "permission_denied_go_to_settings_l2": "Aktivieren Sie Basalte Home Zugriff in den Einstellungen Ihres mobilen Geräts.",
  "photo_from_camera": "Ein Bild machen",
  "photo_from_library": "Bild aus der Bibliothek auswählen",
  "pick_room_function": "Raum und Funktion auswählen",
  "play": "Play",
  "playback_gain": "Playback Gain",
  "playing_in": "spielt jetzt in",
  "playlist": "Playlist",
  "playlists": "Playlists",
  "playlists_itunes": "iTunes Playlists",
  "playlists_l": "Playlists",
  "playlists_local": "Lokale Playlists",
  "playlists_shared": "Geteilte Playlists",
  "play_next": "Als Nächstes abspielen",
  "play_now": "Jetzt abspielen",
  "please_try_a_different_image": "Versuchen Sie es bitte mit einem anderen Bild",
  "please_try_again": "Versuchen Sie es bitte erneut",
  "popular": "Populär",
  "position": "Position",
  "precipitation": "Niederschlag",
  "previous": "Zurück",
  "problems_continue_integrator": "Wenn Sie weiterhin Probleme erfahren, wenden Sie sich bitte an Ihren Integrator.",
  "project": "Projekt",
  "project_image": "Projektbild",
  "projects_network_error": "Projekte können wegen eines Netzwerkfehlers nicht geladen werden.",
  "proximity_activation": "Annäherungssensor",
  "proximity_far": "Weit",
  "proximity_medium": "Mittel",
  "proximity_near": "Nah",
  "queue": "Warteschlange",
  "radio": "Radio",
  "radio_error": "Fehler beim Abrufen der Radiosender",
  "radio_stations": "Radiosender",
  "radio_stations_other": "Andere Sender",
  "recently_played": "Verlauf",
  "related_artists": "Ähnliche Künstler",
  "reload": "Neu laden",
  "remember_me": "Mich erinnern",
  "remote": "Nicht lokal",
  "remote_ellie_intercom_outdated": "Software von der angerufenen Ellie ist veraltet",
  "remote_ellie_intercom_outdated_l1": "Die angerufene Ellie braucht ein Softwareupdate.",
  "remote_ellie_intercom_outdated_l2": "Versuchen Sie es nach dem Updaten erneut.",
  "remove": "Entfernen",
  "remove_alarm": "Wecker entfernen",
  "remove_basalte_favourite": "Basalte-Favorit entfernen",
  "remove_deezer_favourite": "Deezer-Favorit entfernen",
  "remove_from_fav": "aus Favoriten entfernen",
  "remove_schedule": "Zeitplan entfernen",
  "remove_spotify_favourite": "Die Spotify-Favoriten entfernen",
  "remove_spotify_preset": "Spotify-Preset entfernen",
  "remove_tidal_favourite": "TIDAL-Favorit entfernen",
  "remove_txt_l1": "Sie sind kurz dafür dieses Element zu entfernen.",
  "remove_txt_l2": "Möchten Sie trotzdem damit fortfahren?",
  "rename": "umbenennen",
  "repeat": "Wiederholen",
  "replace_queue": "Warteschlange ersetzen",
  "resend_code": "Code erneut senden",
  "resend_confirmation_code": "Bestätigungscode erneut senden",
  "reset_image": "Bild zurücksetzen",
  "reset_password": "Passwort zurücksetzen",
  "reset_password_l1": "Möchten Sie Ihr Passwort wirklich zurücksetzen?",
  "restart": "Neustart",
  "restart_app": "App neu starten",
  "restart_app_q": "App neu starten?",
  "restarting_server": "Server neu starten...",
  "restart_music_server": "Musikserver neu starten",
  "restart_music_server_txt_l1": "Sie sind kurz davor den Musikserver neuzustarten.",
  "restart_server": "Server neu starten",
  "restart_server_txt_l1": "Sie sind kurz davor den Server neu zu starten.",
  "restart_server_txt_l2": "Die App wird automatisch wieder verbinden.",
  "retrieving": "Abrufen …",
  "retrieving_users_stop_text": "Abrufen von Benutzern fehlgeschlagen, richtiger Server?",
  "retrieving_users_text": "Benutzer abrufen …",
  "right": "Rechts",
  "room": "Raum",
  "room_attic": "Dachgeschoss",
  "room_ball_room": "Ballsaal",
  "room_bar": "Bar",
  "room_basement": "Keller",
  "room_bathroom": "Badezimmer",
  "room_bedroom": "Schlafzimmer",
  "room_corridor": "Flur",
  "room_dining_room": "Esszimmer",
  "room_dressing_room": "Ankleidezimmer",
  "room_driveway": "Einfahrt",
  "room_entry": "Eingang",
  "room_family_room": "Familienzimmer",
  "room_foyer": "Foyer",
  "room_garage": "Garage",
  "room_garden": "Garten",
  "room_guest_room": "Gästezimmer",
  "room_gym": "Fitnessraum",
  "room_hallway": "Halle",
  "room_hobby_room": "Hobbyraum",
  "room_home_cinema": "Heimkino",
  "room_home_office": "Büro",
  "room_images": "Raumbilder",
  "room_intercom_lost": "Verbindung unterbrochen",
  "room_intercom_lost_sub": "Verbindung unterbrochen, versuchen Sie es erneut",
  "room_intercom_unavailable": "Raum nicht verfügbar",
  "room_intercom_unavailable_sub": "Dieser Raum ist derzeit nicht verfügbar",
  "room_intercom_unreachable": "Raum nicht erreichbar",
  "room_intercom_unreachable_sub": "Dieser Raum ist derzeit nicht erreichbar",
  "room_kitchen": "Küche",
  "room_laundry_room": "Waschraum",
  "room_library": "Bibliothek",
  "room_living_room": "Wohnzimmer",
  "room_master_bedroom": "Hauptschlafzimmer",
  "room_media_room": "Medienraum",
  "room_meeting_room": "Konferenzraum",
  "room_nook": "Ecke",
  "room_nursery": "Kinderzimmer",
  "room_pantry": "Vorratskammer",
  "room_parking_place": "Parkplatz",
  "room_patio": "Innenhof",
  "room_pool": "Schwimmbad",
  "room_pool_house": "Poolhaus",
  "room_rack": "Serverschrank",
  "room_room": "Raum",
  "rooms": "Räume",
  "room_scene_no_functions": "Dieser Raum hat keine Funktionen",
  "room_settings": "Raumeinstellungen",
  "room_spa": "Wellness",
  "room_stable": "Stall",
  "room_staircase": "Treppe",
  "room_terrace": "Terrasse",
  "room_toilet": "Toilette",
  "room_utility_room": "Hauswirtschaftsraum",
  "room_wine_cellar": "Weinkeller",
  "room_workshop": "Workshop",
  "rotation": "Winkelverstellung",
  "save": "Speichern",
  "save_as": "speichern unter",
  "save_basalte_favourite": "Basalte-Favorit speichern",
  "save_changes": "Änderungen speichern",
  "save_changes_l1": "Einige Änderungen werden noch nicht gespeichert",
  "save_changes_l2": "Wollen Sie die Änderungen jetzt speichern?",
  "save_deezer_favourite": "Deezer-Favorit speichern",
  "save_spotify_favourite": "Spotify-Favorit speichern",
  "save_spotify_preset": "Spotify-Voreinstellung speichern",
  "save_tidal_favourite": "TIDA-Favorit speichern",
  "scene": "Szene",
  "scene_activated": "Szene aktiviert",
  "scene_away": "Abwesend",
  "scene_cooking": "Kochen",
  "scene_dinner": "Abendessen",
  "scene_error": "Szenenfehler",
  "scene_evening": "Abend",
  "scene_good_morning": "Guten Morgen",
  "scene_good_night": "Gute Nacht",
  "scene_guests": "Gäste",
  "scene_home": "Zuhause",
  "scene_image_error": "Szenenbildfehler",
  "scene_movie": "Film",
  "scene_not_available": "Keine Szenen verfügbar.",
  "scene_not_selected_l1": "Keine Szene ausgewählt.",
  "scene_not_selected_l2": "Wählen Sie bitte eine Szene aus.",
  "scene_party": "Party",
  "scene_relax": "Relax",
  "scenes": "Szenen",
  "scene_select_or_new": "Wählen Sie bitte eine Szene aus oder kreieren Sie eine neue.",
  "scene_vacation": "Urlaub",
  "scene_welcome": "Willkommen",
  "scene_workout": "Training",
  "schedule": "Timer",
  "schedule_at": "Planen um",
  "schedule_not_selected_l1": "Kein Zeitplan ausgewählt.",
  "schedule_not_selected_l2": "Wählen Sie bitte einen Zeitplan aus.",
  "scheduler": "Zeitplan",
  "schedules": "Zeitpläne",
  "schedule_select_or_new": "Wählen Sie bitte einen Zeitplan aus oder kreieren Sie einen neuen.",
  "screen_orientation": "Bildschirmausrichtung",
  "search": "Suchen",
  "search_again": "Aufs Neue suchen",
  "search_ph": "Künstler, Song oder Album suchen...",
  "search_result": "Ergebnisse suchen für",
  "security": "Sicherheit",
  "select_admin": "Wählen Sie ein Administratorprofil aus, um den Aktualisierungsprozess fortzusetzen",
  "select_home": "Wählen Sie Ihr Zuhause",
  "selection": "Auswahl",
  "selection_all": "alle zur Auswahl hinzufügen",
  "select_photo": "Bild auswählen",
  "select_server": "Wählen Sie einen Server",
  "select_user": "Wählen Sie ein Profil aus und loggen Sie ein",
  "serial_number": "Seriennummer",
  "server": "Server",
  "server_address": "Serveradresse",
  "server_basalte_cloud_no_connection": "Server kann keine Verbindung mit der Basalte Cloud herstellen",
  "server_exists_already": "Server besteht schon!",
  "server_mac": "Server-MAC-Adresse",
  "servers": "Servers",
  "server_status": "Serverstatus",
  "server_update_info_l2": "aktualisieren Sie bitte den Basalte Server auf die neuste Version.",
  "server_update_required": "Serverupdate erforderlich",
  "server_version": "Serverversion",
  "set": "Einstellen",
  "set_new_password": "Neues Passwort eingeben",
  "setpoint": "Sollwert",
  "settings": "Einstellungen",
  "shade_blind": "Beschattung",
  "shade_center": "Center",
  "shade_curtain": "Vorhänge",
  "shade_left": "Links",
  "shade_pool_shutter": "Poolabdeckung",
  "shade_right": "Rechts",
  "shade_roman_blind": "Raffrollo",
  "shades": "Beschattung",
  "shade_shade": "Beschattung",
  "shade_shutter": "Rollladen",
  "shade_solar_protection": "Senkrechtmarkisen",
  "shade_venetian_blind": "Raffstore",
  "shade_vertical_blind": "Lamellenvorhang",
  "share": "teilen",
  "shared_playlist": "Geteilte Playlist",
  "show_album": "Album anzeigen",
  "show_artist": "Künstler anzeigen",
  "show_background_art": "Hintergrund anzeigen",
  "show_more": "Mehr zeigen …",
  "show_status_bar": "Statusleiste iPad zeigen",
  "show_status_bar_device": "Statusleiste zeigen",
  "sign_in": "Einloggen",
  "sign_in_info_tidal_l1": "Geben Sie bitte Ihre TIDAL-Zugangsdaten ein",
  "sign_up": "Sich registrieren",
  "single_room": "Raum",
  "sleep": "Bildschirm-Timeout",
  "software_update": "Software-Update",
  "something_went_wrong": "Etwas ist falsch gelaufen",
  "songs": "Songs",
  "sonos": "Sonos",
  "sonos_unreachable": "Kann Sonos nicht finden",
  "sonos_unreachable_txt_l1": "Überprüfen Sie, ob Ihr Gerät am Strom angeschlossen ist und es verbunden ist.",
  "sound_settings": "Toneinstellungen",
  "source": "Quelle",
  "sources": "Quellen",
  "spotify": "Spotify",
  "spotify_beta": "Spotify (Beta)",
  "spotify_connect": "Spotify Connect",
  "spotify_connect_instructions": "Spotify Connect",
  "spotify_connect_instructions_1": "Stellen Sie sicher, dass sich der Core server und das Gerät mit der Spotify App im gleichen Netzwerk befinden.",
  "spotify_connect_instructions_2": "Wählen Sie den Core server-Stream aus der Geräteauswahl in der Spotify-App aus.",
  "spotify_connect_instructions_body": "Sie können auf Ihren Lautsprechern oder Ihrem Fernseher Musik hören. Dabei verwenden Sie Ihr Gerät mit der Spotify-App als Fernbedienung.",
  "spotify_connect_instructions_title": "Bereit zum Musikhören?",
  "spotify_disconnect_txt": "Sie sind kurz davor das Spotify-Konto für diesen Spieler abzumelden.",
  "spotify_free_user_txt": "Ein Spotify-Premium-Konto wird gefordert",
  "spotify_no_link": "Noch kein Spotify-Konto verlinkt",
  "spotify_oauth_error": "Spotify-Authentifizierungsfehler",
  "spotify_preset_error": "Spotify Fehler",
  "spotify_preset_error_l1": "Konnte Spotify-Voreinstellung nicht laden.",
  "spotify_preset_error_l2": "Stellen Sie sicher, dass zuvor ein gültiges Spotify-Konto mit diesem Stream verbunden wurde.",
  "spotify_presets": "Spotify-Voreinstellungen",
  "start": "Start",
  "start_page": "Startseite",
  "start_page_customisation": "Startseite individualisieren",
  "start_time_date": "Datum & Uhrzeit",
  "startup_view": "Startbildschirm",
  "startup_volume": "Anfangslautstärke",
  "start_weather": "Wetterinformationen",
  "state_active": "Aktiv",
  "state_armed": "Scharfgeschaltet",
  "state_closed": "Geschlossen",
  "state_disabled": "Deaktiviert",
  "state_disarmed": "Unscharfgeschaltet",
  "state_down": "Unten",
  "state_enabled": "Aktiviert",
  "state_false": "Falsch",
  "state_free": "Frei",
  "state_high": "Hoch",
  "state_in": "In",
  "state_inactive": "Inaktiv",
  "state_left": "Links",
  "state_low": "Niedrig",
  "state_occupied": "Belegt",
  "state_off": "Aus",
  "state_on": "Ein",
  "state_opened": "Geöffnet",
  "state_out": "Aus",
  "state_right": "Rechts",
  "state_started": "Gestartet",
  "state_stopped": "Gestoppt",
  "state_true": "Wahr",
  "state_up": "Hoch",
  "static": "Statisch",
  "stations": "Sender",
  "status": "Status",
  "stop": "Stop",
  "stream": "Stream",
  "streaming": "Streaming",
  "streaming_services": "Streaming Services",
  "streams": "Streams",
  "streams_beta": "Streams (Beta)",
  "subnet_mask": "Subnetzmaske",
  "sun": "Sonne",
  "sunrise": "Sonnenaufgang",
  "sunset": "Sonnenuntergang",
  "swipe_to_pan": "Swipe, um das Kamerabild zu schwenken",
  "switch_server": "Server ändern",
  "synchronise": "Jetzt scannen",
  "system": "System",
  "system_setting": "Systemeinstellungen",
  "temperature": "Temperatur",
  "temperature_unit": "Temperatureinheit",
  "theme": "Themen",
  "thermostat": "Thermostat",
  "this_cannot_be_undone": "Dieser Vorgang kann nicht rückgängig gemacht werden.",
  "this_week": "Diese Woche",
  "tidal": "TIDAL",
  "tidal_disconnect_txt": "Sie sind kurz davor das TIDAL-Konto für diesen Spieler abzumelden.",
  "tidal_legacy_auth": "TIDAL-Login erforderlich",
  "tidal_legacy_auth_l1": "Um TIDAL weiterhin nutzen zu können, melden Sie sich bitte erneut an.",
  "tidal_no_link": "Noch kein TIDAL-Konto verlinkt",
  "tidal_oauth_error": "TIDAL Authentifizierungsfehler",
  "tidal_rising": "TIDAL Rising",
  "time": "Zeit",
  "time_format": "Zeitformat",
  "timer": "Zeitschaltuhr",
  "timers": "Zeitschaltuhr",
  "title": "Titel",
  "today": "Heute",
  "tomorrow": "Morgen",
  "top_tracks": "Top Songs",
  "treble": "Treble",
  "try_again": "Nochmal versuchen",
  "try_again_later": "Versuchen Sie später erneut",
  "try_again_later_or_contact_support": "Versuchen Sie später erneut oder kontaktieren Sie unser Support Team",
  "turned_off": "Ausgeschaltet",
  "turned_on": "Eingeschaltet",
  "turn_off": "Ausschalten",
  "turn_on": "Einschalten",
  "turn_on_default_rooms": "Die Standardräume dieser Quelle einschalten?",
  "turn_on_music": "Musik einschalten",
  "turn_on_tv": "Fernseher einschalten",
  "tv": "Fernseher",
  "tv_off": "TV aus",
  "tv_off_current_room": "TV in diesem Raum ausschalten",
  "unavailable": "Nicht verfügbar",
  "unavailable_src": "Quelle nicht verfügbar",
  "unavailable_src_txt": "Die aktuelle Quelle ist nicht verfügbar.",
  "ungroup": "Gruppierung aufheben",
  "unknown": "Unbekannt",
  "unknown_account": "Unbekanntes Konto",
  "unknown_account_l1": "Diese E-Mail-Adresse ist keinem Basalte Konto zugeordnet.",
  "unknown_av": "Unbekannte AV-Quelle",
  "unknown_src": "Unbekannte Quelle",
  "unknown_src_txt": "Dieser Server kennt die aktuelle Quelle nicht.",
  "unlink": "Verlinkung aufheben",
  "unlink_server": "Trennen Sie die Verlinkung zu Basalte Konto",
  "unlink_server_full_sub": "Dieses Projekt von Basalte Konto trennen? Es wird dann außerhalb Ihres lokalen Netzwerks nicht mehr zugänglich sein.",
  "unreachable": "Nicht erreichbar",
  "unshare": "Verlinkung aufheben",
  "unsupported_browser": "Browser nicht unterstützt",
  "unsupported_browser_l1": "Manche Funktionen funktionieren möglicherweise nicht wie erwartet.",
  "unsupported_browser_l2": "Installieren Sie Chrome oder Safari für eine bessere Benutzererfahrung.",
  "unsupported_browser_live": "Basalte Konto wird von diesem Browser nicht unterstützt. Versuchen Sie es mit einer neuen Version von Google Chrome oder Safari.",
  "up": "Hoch",
  "update": "Update",
  "update_available": "Update",
  "update_available_full": "Update verfügbar",
  "update_info_l1": "Um die bestmögliche Benutzererfahrung zu gewährleisten,",
  "update_library": "Musikbibliothek erneut scannen",
  "update_music_server": "Musikserver updaten",
  "update_scene": "Aktualisierung der Szene",
  "update_scene_l1": "Den aktuellen Zustand in die Szene speichern.",
  "update_server": "Server Update",
  "updating_server": "Server aktualisieren",
  "updating_server_info": "Server nicht ausschalten",
  "up_to_date": "Auf dem neusten Stand",
  "username": "Benutzername",
  "uv": "UV",
  "value_invalid": "Der gegebene Wert ist ungültig",
  "verify": "Überprüfen",
  "verifying": "überprüfen",
  "version": "Version",
  "video_background": "Video Hintergrund",
  "video_go_to": "Zum TV springen",
  "video_is_active": "TV ist aktiv",
  "video_is_playing": "Video spielt gerade",
  "video_unavailable": "TV oder Audiosystem nicht verfügbar",
  "volume": "Lautstärke",
  "warning": "Achtung",
  "water": "Wasser",
  "weather": "Wetter",
  "weather_daily_data_unavailable": "Derzeit sind keine täglichen Wetterinformationen verfügbar",
  "weather_hourly_data_unavailable": "Derzeit sind keine stündlichen Wetterinformationen verfügbar",
  "weather_information": "Wetterinfo",
  "week": "Woche",
  "weekly": "Wöchentlich",
  "what": "Was",
  "what_music": "Welche Musik",
  "when": "Wann",
  "where": "Wo",
  "who": "Wer",
  "wind": "Wind",
  "wrong_password": "Falsches Passwort",
  "wrong_password_txt": "Das eingegebene Passwort stimmt nicht mit dem Benutzerpasswort überein",
  "wrong_username_and_or_password": "Falscher Benutzername und/oder falsches Passwort",
  "year": "Jahr",
  "yes": "Ja",
  "yes_all_off": "Ja, alles ausschalten",
  "yes_cur_off": "Ja, ausschalten",
  "yes_deezer_dis": "Ja, Deezer-Konto abmelden",
  "yes_delete_it": "Ja, löschen",
  "yesterday": "Gestern",
  "zone": "Raum",
  "zones": "Räume"
}
