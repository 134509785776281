'use strict'

var jspb = require('google-protobuf')
var BasUtil = require('@basalte/bas-util')

var appdataPb = require('../generated/appdata_pb')

var P = require('./parser_constants')
var CONSTANTS = require('./constants')

var Temperature = require('./temperature')
var LightDevice = require('./light_device')
var Capabilities = require('./capabilities')

// Javascript Protocol buffers objects

var SceneConfig = appdataPb.SceneConfig

/**
 * @typedef {Object} TScene
 * @property {string} uuid
 * @property {boolean} favourite
 * @property {string} colour
 * @property {number} template
 * @property {string} name
 * @property {Object} capabilities
 * @property {number} order
 * @property {string} content
 */

/**
 * @typedef {Object} TStep
 * @property {number} [delay]
 * @property {TSceneTarget} [target]
 */

/**
 * @typedef {Object} TSceneTarget
 * @property {string} areaUuid
 * @property {Object<string, TSceneLight>} [lights]
 * @property {Object<string, TSceneShade>} [shades]
 * @property {Object<string, TSceneDeviceControl>} [controls]
 * @property {TSceneThermostat} [thermostat]
 * @property {TSceneSubscene} [subscene]
 * @property {TSceneAV} [audio]
 * @property {TSceneAV} [video]
 */

/**
 * @typedef {Object} TSceneLight
 * @property {boolean} [onOff]
 * @property {number} [brightness]
 * @property {number} [colorTemperature]
 * @property {number} [hue]
 * @property {number} [saturation]
 * @property {number} [white]
 * @property {string} [mode]
 */

/**
 * @typedef {Object} TSceneDeviceControl
 * @property {(boolean|number)} [value]
 */

/**
 * @typedef {Object} TSceneShade
 * @property {boolean} [isClosed]
 * @property {boolean} [autoMode]
 * @property {number} [position]
 * @property {number} [rotation]
 */

/**
 * @typedef {Object} TSceneThermostat
 * @property {string} uuid
 * @property {number} [thermostatMode]
 * @property {number} [fanMode]
 * @property {Temperature} [setPoint]
 * @property {Object} controls
 */

/**
 * @typedef {Object} TSceneSubscene
 * @property {string} uuid
 * @property {string} scene
 */

/**
 * @typedef {Object} TSceneAV
 * @property {number} [volume]
 * @property {string} [sourceUuid]
 * @property {string} [content]
 */

/**
 * @constructor
 * @mixes Capabilities.mixin
 * @param {TScene} scene
 * @param {SceneCtrlDevice} ctrl
 */
function Scene (scene, ctrl) {

  /**
   * @private
   * @type {SceneCtrlDevice}
   */
  this._ctrl = ctrl

  /**
   * @type {string}
   */
  this._uuid = BasUtil.isNEString(scene[P.UUID])
    ? scene[P.UUID]
    : ''

  /**
   * @type {number}
   */
  this._order = 0

  /**
   * @type {Capabilities}
   */
  this[CONSTANTS.CAPABILITIES] = new Capabilities(scene[P.CAPABILITIES])

  /**
   * @type {string}
   */
  this._colour = ''

  /**
   * @type {boolean}
   */
  this._favourite = false

  /**
   * @type {number}
   */
  this._template = Scene.TEMPLATES.CUSTOM

  /**
   * @type {string}
   */
  this._name = ''

  /**
   * @type {boolean}
   */
  this._serverFavourite = false

  /**
   * @type {number}
   */
  this._serverTemplate = Scene.TEMPLATES.CUSTOM

  /**
   * @type {string}
   */
  this._serverName = ''

  /**
   * @type {Object}
   */
  this._serverImages = null

  /**
   * @type {string}
   */
  this._serverContent = ''

  /**
   * @type {TStep[]}
   */
  this._serverSteps = []

  /**
   * @type {TStep[]}
   */
  this._steps = []

  this.parse(
    scene,
    {
      emit: false
    }
  )
}

BasUtil.mergeObjects(Scene.prototype, Capabilities.mixin)

// region Constants

/**
 * @constant {string}
 */
Scene.C_ACTIVATE = P.ACTIVATE

/**
 * @constant {string}
 */
Scene.C_LEARN = P.LEARN

/**
 * @constant {string}
 */
Scene.C_REMOVE = P.REMOVE

/**
 * @constant {string}
 */
Scene.C_COLOUR = P.COLOUR

/**
 * @constant {string}
 */
Scene.C_TEMPLATE = P.TEMPLATE

/**
 * @constant {string}
 */
Scene.C_NAME = P.NAME

/**
 * @constant {string}
 */
Scene.C_CONTENT = P.CONTENT

/**
 * @constant {string}
 */
Scene.C_FAVOURITE = P.FAVOURITE

/**
 * From proto.be.basalte.config.appdata.Scene.ScenePreset
 *
 * @readonly
 * @enum {number}
 */
Scene.TEMPLATES = {
  CUSTOM: 0,
  HOME: 1,
  AWAY: 2,
  RELAX: 3,
  PARTY: 4,
  COOKING: 5,
  DINING: 6,
  WELCOME: 7,
  GUESTS: 8,
  EVENING: 9,
  GOOD_MORNING: 10,
  MOVIE: 11,
  WORKOUT: 12,
  GOOD_NIGHT: 13,
  ALL_ON: 255,
  ALL_OFF: 256
}

/**
 * Reverse enum for Scene.TEMPLATES
 *
 * @readonly
 * @enum {string}
 */
Scene.TEMPLATES_R = BasUtil.switchObjectKeyValue(Scene.TEMPLATES)

// endregion

/**
 * @private
 * @param {Object} pbLights
 * @returns {?Object<string, TSceneLight>}
 */
Scene._pbParseLights = function (pbLights) {

  var result, map, entries
  var i, length, key, value, pbLight, light

  if (BasUtil.isObject(pbLights) && pbLights.getGroupMap) {

    map = pbLights.getGroupMap()
    if (map) entries = map.getEntryList()
  }

  if (Array.isArray(entries)) {

    result = {}

    length = entries.length
    for (i = 0; i < length; i++) {

      key = entries[i][0]
      value = entries[i][1]

      if (BasUtil.isNEString(key) &&
        BasUtil.isObject(value)) {

        pbLight = new SceneConfig.Light(value)
        light = {}

        if (pbLight.hasOnOff()) {

          light.onOff = pbLight.getOnOff()
        }
        if (pbLight.hasBrightness()) {

          // [0-1] (percentage)
          light.brightness = pbLight.getBrightness() * 100
        }
        if (pbLight.hasColorTemperature()) {

          light.colorTemperature = pbLight.getColorTemperature()
          light.mode = LightDevice.MODE_COLOR_TEMPERATURE
        }
        if (pbLight.hasHue()) {

          // [0-1] (degrees)
          light.hue = pbLight.getHue() * 360
          light.mode = LightDevice.MODE_COLOR
        }
        if (pbLight.hasSaturation()) {

          // [0-1] (percentage)
          light.saturation = pbLight.getSaturation() * 100
          light.mode = LightDevice.MODE_COLOR
        }

        if (pbLight.hasWhite()) {

          // [0-1] (percentage)
          light.white = pbLight.getWhite() * 100
        }

        if (pbLight.hasMode()) {

          switch (pbLight.getMode()) {

            case SceneConfig.Light.LightMode.COLOR:

              light.mode = LightDevice.MODE_COLOR
              break

            case SceneConfig.Light.LightMode.COLOR_TEMPERATURE:

              light.mode = LightDevice.MODE_COLOR_TEMPERATURE
              break

            case SceneConfig.Light.LightMode.WHITE:

              light.mode = LightDevice.MODE_WHITE
              break

            case SceneConfig.Light.LightMode.UNKNOWN:
            default:

              light.mode = LightDevice.MODE_UNKNOWN
              break
          }
        }

        result[key] = light
      }
    }

    return result
  }

  return null
}

/**
 * @private
 * @param {Object} pbShades
 * @returns {?Object<string, TSceneShade>}
 */
Scene._pbParseShades = function (pbShades) {

  var result, map, entries
  var i, length, key, value, pbShade, shade

  if (BasUtil.isObject(pbShades) && pbShades.getGroupMap) {

    map = pbShades.getGroupMap()
    if (map) entries = map.getEntryList()
  }

  if (Array.isArray(entries)) {

    result = {}

    length = entries.length
    for (i = 0; i < length; i++) {

      key = entries[i][0]
      value = entries[i][1]

      if (BasUtil.isNEString(key) &&
        BasUtil.isObject(value)) {

        pbShade = new SceneConfig.WindowTreatment(value)
        shade = {}

        if (pbShade.hasOpenClose()) {

          // Protocol buffers OPEN_CLOSE (KNX) ~ isClosed
          shade.isClosed = pbShade.getOpenClose()
        }
        if (pbShade.hasAutoMode()) {

          shade.autoMode = pbShade.getAutoMode()
        }
        if (pbShade.hasPosition()) {

          // [0-1] (percentage)
          shade.position = pbShade.getPosition() * 100
        }
        if (pbShade.hasRotation()) {

          // [0-1] (percentage)
          shade.rotation = pbShade.getRotation() * 100
        }

        result[key] = shade
      }
    }

    return result
  }

  return null
}

/**
 * @private
 * @param {Object} pbControls
 * @returns {?Object<string, TSceneDeviceControl>}
 */
Scene._pbParseControls = function (pbControls) {

  var result, map, entries
  var i, length, key, value, pbDevice, control

  if (BasUtil.isObject(pbControls) && pbControls.getGroupMap) {

    map = pbControls.getGroupMap()
    if (map) entries = map.getEntryList()
  }

  if (Array.isArray(entries)) {

    result = {}

    length = entries.length
    for (i = 0; i < length; i++) {

      key = entries[i][0]
      value = entries[i][1]

      if (BasUtil.isNEString(key) &&
        BasUtil.isObject(value)) {

        pbDevice = new SceneConfig.GenericControl(value)
        control = {}

        if (pbDevice.hasBoolValue()) {

          control.value = pbDevice.getBoolValue()

        } else if (pbDevice.hasNumberValue()) {

          // Value between 0-100
          control.value =
            Math.round(pbDevice.getNumberValue() * 100)
        }

        result[key] = control
      }
    }

    return result
  }

  return null
}

/**
 * @private
 * @param {Object} pbThermostat
 * @returns {?TSceneThermostat}
 */
Scene._pbParseThermostat = function (pbThermostat) {

  var map, controlEntries, length, i, key, value
  var thermostat

  if (BasUtil.isObject(pbThermostat) && pbThermostat.getThermostatMode) {

    thermostat = {}

    if (pbThermostat.hasUuid()) {

      thermostat.uuid = pbThermostat.getUuid()
    }

    if (pbThermostat.hasThermostatMode()) {

      thermostat.thermostatMode = pbThermostat.getThermostatMode()
    }

    if (pbThermostat.hasFanMode()) {

      thermostat.fanMode = pbThermostat.getFanMode()
    }

    if (pbThermostat.hasSetpointK()) {

      thermostat.setPoint = new Temperature()
      thermostat.setPoint.setKelvin(pbThermostat.getSetpointK())
    }

    if (pbThermostat.getControlsMap) {
      map = pbThermostat.getControlsMap()

      if (map) controlEntries = map.getEntryList()
    }

    if (Array.isArray(controlEntries)) {

      thermostat.controls = {}

      length = controlEntries.length
      for (i = 0; i < length; i++) {

        key = controlEntries[i][0]
        value = controlEntries[i][1]

        if (BasUtil.isNEString(key) &&
          BasUtil.isBool(value)) {

          thermostat.controls[key] = value
        }
      }
    }

    return thermostat
  }

  return null
}

/**
 * @private
 * @param {Object} pbSubscene
 * @returns {?TSceneSubscene}
 */
Scene._pbParseSubscene = function (pbSubscene) {

  if (BasUtil.isObject(pbSubscene) && pbSubscene.getScene) {

    return {
      uuid: pbSubscene.getUuid(),
      scene: pbSubscene.getScene()
    }
  }

  return null
}

/**
 * @private
 * @param {Object} pbAV
 * @returns {?TSceneAV}
 */
Scene._pbParseAV = function (pbAV) {

  var result, _isValid

  if (BasUtil.isObject(pbAV) && pbAV.getVolume) {

    result = {}
    _isValid = false

    if (pbAV.hasSourceUuid()) {

      result.sourceUuid = pbAV.getSourceUuid()
      _isValid = true
    }

    if (pbAV.hasVolume()) {

      // [0-1] (percentage)
      result.volume = Math.round(pbAV.getVolume() * 100)
      _isValid = true
    }

    if (pbAV.hasContent()) {

      result.content = pbAV.getContent()
      _isValid = true
    }

    return _isValid ? result : null
  }

  return null
}

/**
 * @private
 * @param {Object<string, TSceneLight>} lights
 * @returns {?Object}
 */
Scene._pbGenerateLights = function (lights) {

  var k, keyLength, group, lightGroup, keys, light, sceneLight

  if (BasUtil.isObject(lights)) {

    lightGroup = new SceneConfig.LightGroup()
    group = lightGroup.getGroupMap()

    keys = Object.keys(lights)
    keyLength = keys.length

    for (k = 0; k < keyLength; k++) {

      sceneLight = lights[keys[k]]

      if (BasUtil.isObject(sceneLight)) {

        light = new SceneConfig.Light()

        if ('onOff' in sceneLight) {

          light.setOnOff(sceneLight.onOff)
        }

        if ('brightness' in sceneLight &&
          BasUtil.isPNumber(sceneLight.brightness, true)) {

          // Value should be between [0-1]
          light.setBrightness(sceneLight.brightness / 100)
        }

        if ('colorTemperature' in sceneLight &&
          BasUtil.isPNumber(sceneLight.colorTemperature, true)) {

          light.setColorTemperature(sceneLight.colorTemperature)
        }

        if ('hue' in sceneLight &&
          BasUtil.isPNumber(sceneLight.hue, true)) {

          // Value should be between [0-1]
          light.setHue(sceneLight.hue / 360)
        }

        if ('saturation' in sceneLight &&
          BasUtil.isPNumber(sceneLight.saturation, true)) {

          // Value should be between [0-1]
          light.setSaturation(sceneLight.saturation / 100)
        }

        if ('white' in sceneLight &&
          BasUtil.isPNumber(sceneLight.white, true)) {

          // Value should be between [0-1]
          light.setWhite(sceneLight.white / 100)
        }

        if ('mode' in sceneLight &&
          BasUtil.isNEString(sceneLight.mode)) {

          switch (sceneLight.mode) {

            case LightDevice.MODE_COLOR:

              light.setMode(SceneConfig.Light.LightMode.COLOR)
              break

            case LightDevice.MODE_COLOR_TEMPERATURE:

              light.setMode(SceneConfig.Light.LightMode.COLOR_TEMPERATURE)
              break

            case LightDevice.MODE_WHITE:

              light.setMode(SceneConfig.Light.LightMode.WHITE)
              break

            case LightDevice.MODE_UNKNOWN:
            default:

              light.setMode(SceneConfig.Light.LightMode.UNKNOWN)
              break
          }
        }

        group.set(keys[k], light)
      }
    }
  }

  return lightGroup
}

/**
 * @private
 * @param {Object<string, TSceneShade>} shades
 * @returns {?Object}
 */
Scene._pbGenerateShades = function (shades) {

  var k, keyLength, group, shadeGroup, keys, shade, sceneShade

  if (BasUtil.isObject(shades)) {

    shadeGroup = new SceneConfig.WindowTreatmentGroup()
    group = shadeGroup.getGroupMap()

    keys = Object.keys(shades)
    keyLength = keys.length

    for (k = 0; k < keyLength; k++) {

      sceneShade = shades[keys[k]]

      if (BasUtil.isObject(sceneShade)) {

        shade = new SceneConfig.WindowTreatment()

        if ('isClosed' in sceneShade) {

          // Protocol buffers OPEN_CLOSE (KNX) ~ isClosed
          shade.setOpenClose(sceneShade.isClosed)
        }

        if ('autoMode' in sceneShade) {

          shade.setAutoMode(sceneShade.autoMode)
        }

        if ('position' in sceneShade &&
          BasUtil.isPNumber(sceneShade.position, true)) {

          // Value should be between [0-1]
          shade.setPosition(sceneShade.position / 100)
        }

        if ('rotation' in sceneShade &&
          BasUtil.isPNumber(sceneShade.rotation, true)) {

          // Value should be between [0-1]
          shade.setRotation(sceneShade.rotation / 100)
        }

        group.set(keys[k], shade)
      }
    }
  }

  return shadeGroup
}

/**
 * @private
 * @param {Object<string, TSceneDeviceControl>} controls
 * @returns {?Object}
 */
Scene._pbGenerateControls = function (controls) {

  var k, keyLength, group, deviceGroup, keys, control, sceneDevice

  if (BasUtil.isObject(controls)) {

    deviceGroup = new SceneConfig.GenericControlGroup()
    group = deviceGroup.getGroupMap()

    keys = Object.keys(controls)
    keyLength = keys.length

    for (k = 0; k < keyLength; k++) {

      sceneDevice = controls[keys[k]]

      if (BasUtil.isObject(sceneDevice)) {

        control = new SceneConfig.GenericControl()

        if ('value' in sceneDevice) {

          if (BasUtil.isBool(sceneDevice.value)) {

            control.setBoolValue(sceneDevice.value)

          } else if (BasUtil.isVNumber(sceneDevice.value)) {

            // Value between 0-1
            control.setNumberValue(sceneDevice.value / 100)
          }
        }

        group.set(keys[k], control)
      }
    }
  }

  return deviceGroup
}

/**
 * @private
 * @param {TSceneThermostat} thermostat
 * @returns {?Object}
 */
Scene._pbGenerateThermostat = function (thermostat) {

  var controls, keys, keyLength, i, uuid
  var pbThermostat, kelvin, pbControls

  if (BasUtil.isObject(thermostat)) {

    pbThermostat = new SceneConfig.Thermostat()
    pbThermostat.setUuid(thermostat.uuid)

    if (BasUtil.isVNumber(thermostat.thermostatMode)) {

      pbThermostat.setThermostatMode(thermostat.thermostatMode)
    }

    if (BasUtil.isVNumber(thermostat.fanMode)) {

      pbThermostat.setFanMode(thermostat.fanMode)
    }

    if (BasUtil.isObject(thermostat.setPoint)) {

      kelvin = thermostat.setPoint.getTemperature(CONSTANTS.TU_KELVIN)

      if (BasUtil.isVNumber(kelvin)) {

        pbThermostat.setSetpointK(kelvin)

      } else {

        pbThermostat.setSetpointK(0)
      }
    }

    controls = thermostat.controls

    if (BasUtil.isObject(controls)) {

      pbControls = pbThermostat.getControlsMap()

      keys = Object.keys(controls)
      keyLength = keys.length
      for (i = 0; i < keyLength; i++) {

        uuid = keys[i]
        pbControls.set(uuid, controls[uuid])
      }
    }
  }

  return pbThermostat
}

/**
 * @private
 * @param {TSceneSubscene} scene
 * @returns {?Object}
 */
Scene._pbGenerateSubscene = function (scene) {

  var pbScene

  if (BasUtil.isObject(scene)) {

    pbScene = new SceneConfig.Subscene()

    pbScene.setUuid(scene.uuid)
    pbScene.setScene(scene.scene)
  }

  return pbScene
}

/**
 * @private
 * @param {TSceneAV} av
 * @returns {?Object}
 */
Scene._pbGenerateAV = function (av) {

  var pbAV

  if (BasUtil.isObject(av)) {

    pbAV = new SceneConfig.AV()

    if (BasUtil.isString(av.sourceUuid)) {

      pbAV.setSourceUuid(av.sourceUuid)
    }

    if (BasUtil.isVNumber(av.volume)) {

      // Value should be between [0-1]
      pbAV.setVolume(av.volume / 100)
      pbAV.setMute(av.volume === 0)
    }

    if (BasUtil.isString(av.content)) {

      pbAV.setContent(av.content)
    }
  }

  return pbAV
}

/**
 * Encodes steps using Protocol Buffers.
 *
 * @private
 * @param {TStep[]} steps
 * @returns {string}
 */
Scene.generateContent = function (steps) {

  var sceneConfig, sceneItems, sceneItem, i, length, step, sceneTarget
  var result, writer

  if (Array.isArray(steps)) {

    sceneConfig = new SceneConfig()
    sceneItems = []

    length = steps.length
    for (i = 0; i < length; i++) {

      step = steps[i]
      sceneItem = new SceneConfig.SceneItem()

      if (step.target) {

        sceneTarget = new SceneConfig.SceneTarget()
        sceneTarget.setAreaUuid(step.target.areaUuid)

        if (BasUtil.isObject(step.target.lights)) {

          sceneTarget.setLights(
            Scene._pbGenerateLights(step.target.lights)
          )

        } else if (BasUtil.isObject(step.target.shades)) {

          sceneTarget.setWindowTreatments(
            Scene._pbGenerateShades(step.target.shades)
          )

        } else if (BasUtil.isObject(step.target.thermostat)) {

          sceneTarget.setThermostat(
            Scene._pbGenerateThermostat(step.target.thermostat)
          )

        } else if (BasUtil.isObject(step.target.subscene)) {

          sceneTarget.setSubscene(
            Scene._pbGenerateSubscene(step.target.subscene)
          )

        } else if (BasUtil.isObject(step.target.audio)) {

          sceneTarget.setAudio(
            Scene._pbGenerateAV(step.target.audio)
          )

        } else if (BasUtil.isObject(step.target.video)) {

          sceneTarget.setVideo(
            Scene._pbGenerateAV(step.target.video)
          )

        } else if (BasUtil.isObject(step.target.controls)) {

          sceneTarget.setGenericControls(
            Scene._pbGenerateControls(step.target.controls)
          )
        }

        sceneItem.setTarget(sceneTarget)

      } else {

        // Delay is in seconds
        sceneItem.setDelayMs(step.delay * 1000)
      }

      sceneItems.push(sceneItem)
    }

    sceneConfig.setItemsList(sceneItems)

    writer = new jspb.BinaryWriter()
    SceneConfig.serializeBinaryToWriter(sceneConfig, writer)
    result = writer.getResultBase64String()

    return result
  }

  return ''
}

/**
 * @name Scene#uuid
 * @type {string}
 * @readonly
 */
Object.defineProperty(Scene.prototype, 'uuid', {
  get: function () {
    return this._uuid
  }
})

/**
 * @name Scene#favourite
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(Scene.prototype, 'favourite', {
  get: function () {
    return this._favourite
  }
})

/**
 * @name Scene#colour
 * @type {string}
 * @readonly
 */
Object.defineProperty(Scene.prototype, 'colour', {
  get: function () {
    return this._colour
  }
})

/**
 * @name Scene#template
 * @type {number}
 * @readonly
 */
Object.defineProperty(Scene.prototype, 'template', {
  get: function () {
    return this._template
  }
})

/**
 * @name Scene#name
 * @type {string}
 * @readonly
 */
Object.defineProperty(Scene.prototype, 'name', {
  get: function () {
    return this._name
  }
})

/**
 * @name Scene#serverImages
 * @type {?Object<string, string>}
 * @readonly
 */
Object.defineProperty(Scene.prototype, 'serverImages', {
  get: function () {
    return this._serverImages
  }
})

/**
 * @name Scene#capabilities
 * @type {Object<string, string>}
 * @readonly
 */
Object.defineProperty(Scene.prototype, 'capabilities', {
  get: function () {
    return this[CONSTANTS.CAPABILITIES]
  }
})

/**
 * @name Scene#steps
 * @type {TStep[]}
 * @readonly
 */
Object.defineProperty(Scene.prototype, 'steps', {
  get: function () {
    return this._steps
  }
})

/**
 * @name Scene#order
 * @type {number}
 * @readonly
 */
Object.defineProperty(Scene.prototype, 'order', {
  get: function () {
    return this._order
  }
})

Scene.prototype.resetToServerValues = function () {

  this._template = this._serverTemplate
  this._favourite = this._serverFavourite
  this._name = this._serverName
  this._steps = this._serverSteps
}

/**
 * @param {TScene} scene
 * @param {TDeviceParseOptions} [options]
 */
Scene.prototype.parse = function (scene, options) {

  var emit
  var changesDetected, imageChangesDetected

  emit = true

  if (BasUtil.isObject(options)) {

    if (BasUtil.isBool(options.emit)) emit = options.emit
  }

  changesDetected = false
  imageChangesDetected = false

  if (BasUtil.isObject(scene)) {

    if (this[CONSTANTS.CAPABILITIES].parse(scene[P.CAPABILITIES])) {

      changesDetected = true
    }

    if (P.FAVOURITE in scene &&
      this._serverFavourite !== scene[P.FAVOURITE]) {

      this._serverFavourite = scene[P.FAVOURITE]
      this._favourite = scene[P.FAVOURITE]
      changesDetected = true
    }

    if (P.COLOUR in scene &&
      this._colour !== scene[P.COLOUR]) {

      this._colour = scene[P.COLOUR]
      changesDetected = true
    }

    if (P.TEMPLATE in scene &&
      this._serverTemplate !== scene[P.TEMPLATE]) {

      this._serverTemplate = scene[P.TEMPLATE]
      this._template = scene[P.TEMPLATE]
      changesDetected = true
    }

    if (P.NAME in scene &&
      this._serverName !== scene[P.NAME]) {

      this._serverName = scene[P.NAME]
      this._name = scene[P.NAME]
      changesDetected = true
    }

    if (P.IMAGES in scene &&
      !BasUtil.compareObjects(this._serverImages, scene[P.IMAGES])) {

      this._serverImages = BasUtil.copyObject(scene[P.IMAGES])
      imageChangesDetected = true
    }

    if (BasUtil.isVNumber(scene[P.ORDER]) &&
      this._order !== scene[P.ORDER]) {

      this._order = scene[P.ORDER]
      changesDetected = true
    }

    if (P.CONTENT in scene &&
      BasUtil.isString(scene[P.CONTENT]) &&
      this._serverContent !== scene[P.CONTENT]) {

      this._serverContent = scene[P.CONTENT]
      changesDetected = true

      this._parseContent()
    }

    if (this._ctrl && emit) {

      if (changesDetected) this._ctrl.emitScene(this)

      if (imageChangesDetected) this._ctrl.emitSceneImagesUpdated(this)
    }
  }
}

/**
 * Parses the current content string into steps.
 */
Scene.prototype._parseContent = function () {

  var i, length, item, target, step, delay
  var sceneConfig, itemList
  var pbTarget, targetItem

  this._serverSteps = this._steps = []

  if (BasUtil.isString(this._serverContent)) {

    sceneConfig = SceneConfig.deserializeBinary(this._serverContent)
    if (sceneConfig) itemList = sceneConfig.getItemsList()
  }

  if (Array.isArray(itemList)) {

    length = itemList.length
    for (i = 0; i < length; i++) {

      item = itemList[i]
      step = {}

      delay = item.getDelayMs()
      pbTarget = item.getTarget()

      if (BasUtil.isVNumber(delay) && delay !== 0) {

        // Delay is in seconds
        step.delay = delay / 1000

        this._steps.push(step)

      } else if (BasUtil.isObject(pbTarget) && pbTarget.getAreaUuid) {

        step.target = target = {}
        target.areaUuid = pbTarget.getAreaUuid()

        switch (pbTarget.getTargetCase()) {
          case SceneConfig.SceneTarget.TargetCase.LIGHTS:

            targetItem = Scene._pbParseLights(pbTarget.getLights())
            if (targetItem) target.lights = targetItem

            break
          case SceneConfig.SceneTarget.TargetCase.WINDOW_TREATMENTS:

            targetItem = Scene._pbParseShades(
              pbTarget.getWindowTreatments()
            )
            if (targetItem) target.shades = targetItem

            break
          case SceneConfig.SceneTarget.TargetCase.THERMOSTAT:

            targetItem = Scene._pbParseThermostat(
              pbTarget.getThermostat()
            )
            if (targetItem) target.thermostat = targetItem

            break
          case SceneConfig.SceneTarget.TargetCase.SUBSCENE:

            targetItem = Scene._pbParseSubscene(
              pbTarget.getSubscene()
            )
            if (targetItem) target.subscene = targetItem

            break
          case SceneConfig.SceneTarget.TargetCase.AUDIO:

            targetItem = Scene._pbParseAV(pbTarget.getAudio())
            if (targetItem) target.audio = targetItem

            break
          case SceneConfig.SceneTarget.TargetCase.VIDEO:

            targetItem = Scene._pbParseAV(pbTarget.getVideo())
            if (targetItem) target.video = targetItem

            break
          case SceneConfig.SceneTarget.TargetCase.GENERIC_CONTROLS:

            targetItem = Scene._pbParseControls(
              pbTarget.getGenericControls()
            )
            if (targetItem) target.controls = targetItem

            break
        }

        this._steps.push(step)
      }
    }
  }
}

/**
 * @param {boolean} bool
 */
Scene.prototype.setFavourite = function (bool) {

  if (BasUtil.isBool(bool)) this._favourite = bool
}

/**
 * @param {number} template
 */
Scene.prototype.setTemplate = function (template) {

  if (BasUtil.isPNumber(template, true)) this._template = template
}

/**
 * @param {string} name
 */
Scene.prototype.setName = function (name) {

  if (BasUtil.isString(name)) this._name = name
}

/**
 * @param {TStep[]} steps
 */
Scene.prototype.setSteps = function (steps) {

  if (Array.isArray(steps)) this._steps = steps
}

/**
 * @param {number} order
 */
Scene.prototype.setOrder = function (order) {

  if (BasUtil.isPNumber(order, true)) this._order = order
}

/**
 * Encodes the current steps using Protocol Buffers.
 *
 * @private
 * @returns {string}
 */
Scene.prototype._generateCurrentContent = function () {

  if (Array.isArray(this._steps)) {

    if (this._serverSteps === this._steps) return this._serverContent

    return Scene.generateContent(this._steps)
  }

  return ''
}

/**
 * @returns {Object}
 */
Scene.prototype.getBasCoreMessage = function () {

  var scene = {}
  var content = this._generateCurrentContent()

  scene[P.UUID] = this.uuid

  if (this.allowsWrite(Scene.C_FAVOURITE) &&
    this._serverFavourite !== this._favourite) {

    scene[P.FAVOURITE] = this._favourite
  }

  if (this.allowsWrite(Scene.C_TEMPLATE) &&
    this._serverTemplate !== this._template) {

    scene[P.TEMPLATE] = this._template
  }

  if (this.allowsWrite(Scene.C_NAME) &&
    this._template === Scene.TEMPLATES.CUSTOM &&
    this._serverName !== this._name) {

    scene[P.NAME] = this._name
  }

  if (this.allowsWrite(Scene.C_CONTENT) &&
      this._serverContent !== content) {

    scene[P.CONTENT] = content
  }

  return scene
}

module.exports = Scene
