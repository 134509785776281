'use strict'

var EventEmitter = require('@gidw/event-emitter-js')
var BasUtil = require('@basalte/bas-util')

var CONSTANTS = require('./constants')
var P = require('./parser_constants')

/**
 * SharedServerStorage class which is used to access the server exposed storage
 *
 * @constructor
 * @extends EventEmitter
 * @param {BasCore} basCore
 * @since 2.6.0
 */
function SharedServerStorage (basCore) {

  EventEmitter.call(this)

  this._basCore = basCore

  /**
   * @private
   * @type {string[]}
   */
  this._camerasOrder = []

  /**
   * @private
   * @type {string[]}
   */
  this._openCloseDevicesOrder = []

  /**
   * @private
   * @type {Object<string, string[]>}
   */
  this._schedulesOrder = {}

  /**
   * @private
   * @type {Object<string, string[]>}
   */
  this._timersOrder = {}

  /**
   * @private
   * @type {Object<string, string[]>}
   */
  this._scenesOrder = {}

  /**
   * @private
   * @type {Object<string, Object>}
   */
  this._lightGroupOrder = {}

  /**
   * @private
   * @type {Object<string, string[]>}
   */
  this._lisaTilesOrder = {}

  /**
   * @private
   * @type {Object<string, string[]>}
   */
  this._scenesFavourites = {}

  /**
   * @private
   * @type {(boolean|undefined)}
   */
  this._tidalLegacyAuthDontAsk = undefined

  this._dirty = true

  /**
   * @private
   * @type {?Promise}
   */
  this._getAllPromise = null

  this._handleStorageUpdate = this._onStorageUpdate.bind(this)
  this._handleGetAll = this._onGetAllResult.bind(this)
  this._handleGetAllError = this._onGetAllError.bind(this)

  this.reset()
}

SharedServerStorage.prototype = Object.create(EventEmitter.prototype)
SharedServerStorage.prototype.constructor = SharedServerStorage

/**
 * @event SharedServerStorage#EVT_CAMERAS_ORDER_UPDATED
 * @param {string[]} camerasOrder
 */

/**
 * @event SharedServerStorage#EVT_OPEN_CLOSE_DEVICES_ORDER_UPDATED
 * @param {string[]} openCloseDevicesOrder
 */

/**
 * @event SharedServerStorage#EVT_SCHEDULES_ORDER_UPDATED
 * @param {Object<string, string[]>} schedulesOrder
 */

/**
 * @event SharedServerStorage#EVT_TIMERS_ORDER_UPDATED
 * @param {Object<string, string[]>} timersOrder
 */

/**
 * @event SharedServerStorage#EVT_SCENES_ORDER_UPDATED
 * @param {Object<string, string[]>} scenesOrder
 */

/**
 * @event SharedServerStorage#EVT_LIGHT_GROUP_ORDER_UPDATED
 * @param {Object<string, Object>} lightGroupOrder
 */

/**
 * @event SharedServerStorage#EVT_SCENES_FAVOURITES_UPDATED
 * @param {Object<string, string[]>} scenesFavourites
 */

/**
 * @event SharedServerStorage#EVT_LISA_TILES_ORDER_UPDATED
 * @param {Object<string, Object[]>} lisaTilesOrder
 * @since 2.11.0
 */

/**
 * @constant {string}
 */
SharedServerStorage.EVT_CAMERAS_ORDER_UPDATED =
  'evtSharedStorageCamerasOrderUpdated'

/**
 * @constant {string}
 */
SharedServerStorage.EVT_OPEN_CLOSE_DEVICES_ORDER_UPDATED =
  'evtSharedStorageOpenCloseDevicesOrderUpdated'

/**
 * @constant {string}
 */
SharedServerStorage.EVT_SCHEDULES_ORDER_UPDATED =
  'evtSharedStorageSchedulesOrderUpdated'

/**
 * @constant {string}
 */
SharedServerStorage.EVT_TIMERS_ORDER_UPDATED =
  'evtSharedStorageTimersOrderUpdated'

/**
 * @constant {string}
 */
SharedServerStorage.EVT_SCENES_ORDER_UPDATED =
  'evtSharedStorageScenesOrderUpdated'

/**
 * @constant {string}
 */
SharedServerStorage.EVT_LIGHT_GROUP_ORDER_UPDATED =
  'evtSharedStorageLightGroupOrderUpdated'

/**
 * @constant {string}
 */
SharedServerStorage.EVT_SCENES_FAVOURITES_UPDATED =
  'evtSharedStorageScenesFavouritesUpdated'

/**
 * @constant {string}
 */
SharedServerStorage.EVT_LISA_TILES_ORDER_UPDATED =
  'evtSharedStorageLisaTilesOrderUpdated'

/**
 * @constant {string}
 */
SharedServerStorage.EVT_TIDAL_LEGACY_AUTH_DONT_ASK_UPDATED =
  'evtSharedStorageTidalLegacyAuthDontAskAgain'

/**
 * @constant {string}
 */
SharedServerStorage.K_CAMERAS_ORDER = 'CAMERA_ORDER'

/**
 * @constant {string}
 */
SharedServerStorage.K_OPEN_CLOSE_DEVICES_ORDER = 'OPEN_CLOSE_DEVICES_ORDER'

/**
 * @constant {string}
 */
SharedServerStorage.K_SCHEDULES_ORDER = 'SCHEDULES_ORDER'

/**
 * @constant {string}
 */
SharedServerStorage.K_TIMERS_ORDER = 'TIMERS_ORDER'

/**
 * @constant {string}
 */
SharedServerStorage.K_SCENES_ORDER = 'SCENES_ORDER'

/**
 * @constant {string}
 */
SharedServerStorage.K_LIGHT_GROUP_ORDER = 'LIGHT_GROUP_ORDER'

/**
 * @constant {string}
 */
SharedServerStorage.K_LISA_TILES_ORDER = 'LISA_TILES_ORDER'

/**
 * @constant {string}
 */
SharedServerStorage.K_SCENES_FAVOURITES = 'SCENES_FAVOURITES'

/**
 * @constant {string}
 */
SharedServerStorage.K_TIDAL_LEGACY_AUTH_DONT_ASK = 'TIDAL_LEGACY_AUTH_DONT_ASK'

/**
 * @name SharedServerStorage#dirty
 * @type {boolean}
 * @readonly
 * @since 2.8.5
 */
Object.defineProperty(SharedServerStorage.prototype, 'dirty', {
  get: function () {
    return this._dirty
  }
})

/**
 * @name SharedServerStorage#camerasOrder
 * @type {string[]}
 * @readonly
 */
Object.defineProperty(SharedServerStorage.prototype, 'camerasOrder', {
  get: function () {
    return this._camerasOrder
  }
})

/**
 * @name SharedServerStorage#openCloseDevicesOrder
 * @type {string[]}
 * @readonly
 * @since 2.7.0
 */
Object.defineProperty(SharedServerStorage.prototype, 'openCloseDevicesOrder', {
  get: function () {
    return this._openCloseDevicesOrder
  }
})

/**
 * @name SharedServerStorage#schedulesOrder
 * @type {Object<string, string[]>}
 * @readonly
 * @since 2.8.5
 */
Object.defineProperty(SharedServerStorage.prototype, 'schedulesOrder', {
  get: function () {
    return this._schedulesOrder
  }
})

/**
 * @name SharedServerStorage#timersOrder
 * @type {Object<string, string[]>}
 * @readonly
 * @since 2.8.5
 */
Object.defineProperty(SharedServerStorage.prototype, 'timersOrder', {
  get: function () {
    return this._timersOrder
  }
})

/**
 * @name SharedServerStorage#scenesOrder
 * @type {Object<string, string[]>}
 * @readonly
 * @since 2.8.5
 */
Object.defineProperty(SharedServerStorage.prototype, 'scenesOrder', {
  get: function () {
    return this._scenesOrder
  }
})

Object.defineProperty(SharedServerStorage.prototype, 'lightGroupOrder', {
  get: function () {
    return this._lightGroupOrder
  }
})

/**
 * @name SharedServerStorage#lisaTilesOrder
 * @type {Object<string, Object[]>}
 * @readonly
 * @since 2.11.0
 */
Object.defineProperty(SharedServerStorage.prototype, 'lisaTilesOrder', {
  get: function () {
    return this._lisaTilesOrder
  }
})

/**
 * @name SharedServerStorage#scenesFavourites
 * @type {Object<string, string[]>}
 * @readonly
 * @since 2.9.0
 */
Object.defineProperty(SharedServerStorage.prototype, 'scenesFavourites', {
  get: function () {
    return this._scenesFavourites
  }
})

/**
 * @name SharedServerStorage#tidalLegacyAuthDontAsk
 * @type {(boolean|undefined)}
 * @readonly
 * @since 3.6.1
 */
Object.defineProperty(SharedServerStorage.prototype, 'tidalLegacyAuthDontAsk', {
  get: function () {
    return this._tidalLegacyAuthDontAsk
  }
})

/**
 * Fetch all known storage keys
 *
 * @returns {Promise}
 */
SharedServerStorage.prototype.getAll = function () {

  if (this._getAllPromise) return this._getAllPromise

  return (this._getAllPromise = this.get([
    SharedServerStorage.K_CAMERAS_ORDER,
    SharedServerStorage.K_OPEN_CLOSE_DEVICES_ORDER,
    SharedServerStorage.K_SCHEDULES_ORDER,
    SharedServerStorage.K_TIMERS_ORDER,
    SharedServerStorage.K_SCENES_ORDER,
    SharedServerStorage.K_LIGHT_GROUP_ORDER,
    SharedServerStorage.K_LISA_TILES_ORDER,
    SharedServerStorage.K_SCENES_FAVOURITES,
    SharedServerStorage.K_TIDAL_LEGACY_AUTH_DONT_ASK
  ]).then(this._handleGetAll, this._handleGetAllError))
}

/**
 * @private
 * @param result
 * @returns {*}
 */
SharedServerStorage.prototype._onGetAllResult = function (result) {

  this._clearGetAllPromise()
  return result
}

/**
 * @private
 * @param error
 * @returns {Promise<*>}
 */
SharedServerStorage.prototype._onGetAllError = function (error) {

  this._clearGetAllPromise()
  return Promise.reject(error)
}

/**
 * @private
 */
SharedServerStorage.prototype._clearGetAllPromise = function () {

  this._getAllPromise = null
}

/**
 * Parse "storage" messages
 * Storage changes caused by other devices
 *
 * @param {Object} obj
 */
SharedServerStorage.prototype.parse = function (obj) {

  var action, data

  if (BasUtil.isObject(obj) &&
    BasUtil.isObject(obj[P.STORAGE]) &&
    BasUtil.isObject(obj[P.STORAGE][P.DATA])) {

    action = obj[P.STORAGE][P.ACTION]
    data = obj[P.STORAGE][P.DATA]

    if (action === P.UPDATE) {

      this._parseData(data)
    }
  }
}

/**
 * @private
 * @param {Object} data
 * @param {TDeviceParseOptions} [options]
 */
SharedServerStorage.prototype._parseData = function (
  data,
  options
) {
  var emit, entry

  emit = true

  if (BasUtil.isObject(options)) {

    if (BasUtil.isBool(options.emit)) emit = options.emit
  }

  if (BasUtil.isObject(data)) {

    // Cameras order

    if (SharedServerStorage.K_CAMERAS_ORDER in data) {

      entry = data[SharedServerStorage.K_CAMERAS_ORDER]

      this._camerasOrder = Array.isArray(entry)
        ? entry
        : []

      if (emit) {

        this.emit(
          SharedServerStorage.EVT_CAMERAS_ORDER_UPDATED,
          this._camerasOrder
        )
      }
    }

    // Open/Close devices order

    if (SharedServerStorage.K_OPEN_CLOSE_DEVICES_ORDER in data) {

      entry = data[SharedServerStorage.K_OPEN_CLOSE_DEVICES_ORDER]

      this._openCloseDevicesOrder = Array.isArray(entry)
        ? entry
        : []

      if (emit) {

        this.emit(
          SharedServerStorage.EVT_OPEN_CLOSE_DEVICES_ORDER_UPDATED,
          this._openCloseDevicesOrder
        )
      }
    }

    // Schedules order

    if (SharedServerStorage.K_SCHEDULES_ORDER in data) {

      entry = data[SharedServerStorage.K_SCHEDULES_ORDER]

      this._schedulesOrder = BasUtil.isObject(entry)
        ? entry
        : {}

      if (emit) {

        this.emit(
          SharedServerStorage.EVT_SCHEDULES_ORDER_UPDATED,
          this._schedulesOrder
        )
      }
    }

    // Timers order

    if (SharedServerStorage.K_TIMERS_ORDER in data) {

      entry = data[SharedServerStorage.K_TIMERS_ORDER]

      this._timersOrder = BasUtil.isObject(entry)
        ? entry
        : {}

      if (emit) {

        this.emit(
          SharedServerStorage.EVT_TIMERS_ORDER_UPDATED,
          this._timersOrder
        )
      }
    }

    // Scenes order

    if (SharedServerStorage.K_SCENES_ORDER in data) {

      entry = data[SharedServerStorage.K_SCENES_ORDER]

      this._scenesOrder = BasUtil.isObject(entry)
        ? entry
        : {}

      if (emit) {

        this.emit(
          SharedServerStorage.EVT_SCENES_ORDER_UPDATED,
          this._scenesOrder
        )
      }
    }

    if (SharedServerStorage.K_LIGHT_GROUP_ORDER in data) {

      entry = data[SharedServerStorage.K_LIGHT_GROUP_ORDER]

      this._lightGroupOrder = BasUtil.isObject(entry)
        ? entry
        : {}

      if (emit) {

        this.emit(
          SharedServerStorage.EVT_LIGHT_GROUP_ORDER_UPDATED,
          this._lightGroupOrder
        )
      }
    }

    // Lisa tiles order

    if (SharedServerStorage.K_LISA_TILES_ORDER in data) {

      entry = data[SharedServerStorage.K_LISA_TILES_ORDER]

      this._lisaTilesOrder = BasUtil.isObject(entry)
        ? entry
        : {}

      if (emit) {

        this.emit(
          SharedServerStorage.EVT_LISA_TILES_ORDER_UPDATED,
          this._lisaTilesOrder
        )
      }
    }

    // Scenes favourites

    if (SharedServerStorage.K_SCENES_FAVOURITES in data) {

      entry = data[SharedServerStorage.K_SCENES_FAVOURITES]

      this._scenesFavourites = BasUtil.isObject(entry)
        ? entry
        : {}

      if (emit) {

        this.emit(
          SharedServerStorage.EVT_SCENES_FAVOURITES_UPDATED,
          this._scenesFavourites
        )
      }
    }

    // Tidal Legacy auth: don't ask again

    if (SharedServerStorage.K_TIDAL_LEGACY_AUTH_DONT_ASK in data) {

      entry = data[SharedServerStorage.K_TIDAL_LEGACY_AUTH_DONT_ASK]

      this._tidalLegacyAuthDontAsk = BasUtil.isBool(entry)
        ? entry
        : undefined

      if (emit) {

        this.emit(
          SharedServerStorage.EVT_TIDAL_LEGACY_AUTH_DONT_ASK_UPDATED,
          this._tidalLegacyAuthDontAsk
        )
      }
    }

    this._dirty = false
  }
}

/**
 * Handle result of storagePromise
 * Storage changes by own request
 *
 * @param {Object} obj
 */
SharedServerStorage.prototype._onStorageUpdate = function (obj) {

  var result, data

  if (BasUtil.isObject(obj) &&
    BasUtil.isObject(obj[P.STORAGE]) &&
    BasUtil.isObject(obj[P.STORAGE][P.DATA])) {

    result = obj[P.STORAGE][P.RESULT]
    data = obj[P.STORAGE][P.DATA]

    if (result === P.OK) {

      this._parseData(data)
    }
  }
}

/**
 * Get objects from storage
 *
 * @param {string[]} keys
 * @returns {Promise}
 */
SharedServerStorage.prototype.get = function (keys) {

  var data

  if (Array.isArray(keys)) {

    data = {}
    data[P.STORAGE] = {}
    data[P.STORAGE][P.ACTION] = P.GET
    data[P.STORAGE][P.KEYS] = keys

    return this._basCore.requestRetry(data, CONSTANTS.RETRY_OPTS_ONE_SHOT)
      .then(this._handleStorageUpdate)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Delete objects from storage
 *
 * @param {string[]} keys
 * @returns {Promise}
 */
SharedServerStorage.prototype.delete = function (keys) {

  var data

  if (Array.isArray(keys)) {

    data = {}
    data[P.STORAGE] = {}
    data[P.STORAGE][P.ACTION] = P.DELETE
    data[P.STORAGE][P.KEYS] = keys

    return this._basCore.requestRetry(data, CONSTANTS.RETRY_OPTS_ONE_SHOT)
      .then(this._handleStorageUpdate)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Update storage
 *
 * @param {Object} obj
 * @returns {Promise}
 */
SharedServerStorage.prototype.update = function (obj) {

  var data

  if (BasUtil.isObject(obj)) {

    data = {}
    data[P.STORAGE] = {}
    data[P.STORAGE][P.ACTION] = P.UPDATE
    data[P.STORAGE][P.DATA] = obj

    return this._basCore.requestRetry(data, CONSTANTS.RETRY_OPTS_ONE_SHOT)
      .then(this._handleStorageUpdate)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Update camera order in storage
 *
 * @param {string[]} uuids
 * @returns {Promise}
 */
SharedServerStorage.prototype.updateCamerasOrder = function (uuids) {

  var data

  if (Array.isArray(uuids)) {

    data = {}
    data[SharedServerStorage.K_CAMERAS_ORDER] = uuids

    return this.update(data)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Update Open/Close devices order in storage
 *
 * @param {string[]} uuids
 * @returns {Promise}
 * @since 2.7.0
 */
SharedServerStorage.prototype.updateOpenCloseDevicesOrder = function (
  uuids
) {
  var data

  if (Array.isArray(uuids)) {

    data = {}
    data[SharedServerStorage.K_OPEN_CLOSE_DEVICES_ORDER] = uuids

    return this.update(data)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Update Schedules order in storage
 *
 * @param {string} roomUuid
 * @param {string[]} uuids
 * @returns {Promise}
 * @since 2.8.5
 */
SharedServerStorage.prototype.updateSchedulesOrder = function (
  roomUuid,
  uuids
) {
  var data

  if (BasUtil.isNEString(roomUuid) && Array.isArray(uuids)) {

    data = {}
    data[SharedServerStorage.K_SCHEDULES_ORDER] = this._timersOrder
    data[SharedServerStorage.K_SCHEDULES_ORDER][roomUuid] = uuids

    return this.update(data)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Update Timers order in storage
 *
 * @param {string} roomUuid
 * @param {string[]} uuids
 * @returns {Promise}
 * @since 2.8.5
 */
SharedServerStorage.prototype.updateTimersOrder = function (
  roomUuid,
  uuids
) {
  var data

  if (BasUtil.isNEString(roomUuid) && Array.isArray(uuids)) {

    data = {}
    data[SharedServerStorage.K_TIMERS_ORDER] = this._timersOrder
    data[SharedServerStorage.K_TIMERS_ORDER][roomUuid] = uuids

    return this.update(data)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Update Scenes order in storage
 *
 * @param {string} roomUuid
 * @param {string[]} uuids
 * @returns {Promise}
 * @since 2.8.5
 */
SharedServerStorage.prototype.updateScenesOrder = function (
  roomUuid,
  uuids
) {
  var data

  if (BasUtil.isNEString(roomUuid) && Array.isArray(uuids)) {

    data = {}
    data[SharedServerStorage.K_SCENES_ORDER] = this._scenesOrder
    data[SharedServerStorage.K_SCENES_ORDER][roomUuid] = uuids

    return this.update(data)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Update light group order in storage
 *
 * @param {string} roomUuid
 * @param {Object} lightGroup
 * @returns {Promise}
 * @since 2.8.5
 */
SharedServerStorage.prototype.updateLightGroupOrder = function (
  roomUuid,
  lightGroup
) {
  var data

  if (BasUtil.isNEString(roomUuid) && BasUtil.isObject(lightGroup)) {

    data = {}
    data[SharedServerStorage.K_LIGHT_GROUP_ORDER] = this._lightGroupOrder
    data[SharedServerStorage.K_LIGHT_GROUP_ORDER][roomUuid] = lightGroup

    return this.update(data)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Update Lisa Tiles order in storage
 *
 * @param {string} roomUuid
 * @param {Object[]} order
 * @returns {Promise}
 * @since 2.11.0
 */
SharedServerStorage.prototype.updateLisaTilesOrder = function (
  roomUuid,
  order
) {
  var data

  if (BasUtil.isNEString(roomUuid) && Array.isArray(order)) {

    data = {}
    data[SharedServerStorage.K_LISA_TILES_ORDER] = this._lisaTilesOrder
    data[SharedServerStorage.K_LISA_TILES_ORDER][roomUuid] = order

    return this.update(data)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Update Scenes favourites in storage
 *
 * @param {string} roomUuid
 * @param {string[]} uuids
 * @returns {Promise}
 * @since 2.9.0
 */
SharedServerStorage.prototype.updateScenesFavourites = function (
  roomUuid,
  uuids
) {
  var data

  if (BasUtil.isNEString(roomUuid) && Array.isArray(uuids)) {

    data = {}
    data[SharedServerStorage.K_SCENES_FAVOURITES] = this._scenesFavourites
    data[SharedServerStorage.K_SCENES_FAVOURITES][roomUuid] = uuids

    return this.update(data)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Update tidal legacy auth dont ask in storage
 *
 * @param {boolean} dontAsk
 * @returns {Promise}
 * @since 3.6.1
 */
SharedServerStorage.prototype.updateTidalLegacyAuthDontAsk = function (
  dontAsk
) {
  var data

  if (BasUtil.isBool(dontAsk)) {

    data = {}
    data[SharedServerStorage.K_TIDAL_LEGACY_AUTH_DONT_ASK] = dontAsk

    return this.update(data)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Resets properties to their default values
 */
SharedServerStorage.prototype.reset = function () {

  this._camerasOrder = []
  this._openCloseDevicesOrder = []
  this._schedulesOrder = {}
  this._lisaTilesOrder = {}
  this._timersOrder = {}
  this._scenesOrder = {}
  this._lightGroupOrder = {}
  this._scenesFavourites = {}
  this._tidalLegacyAuthDontAsk = undefined

  this._dirty = true
}

SharedServerStorage.prototype.destroy = function () {

  this.removeAllListeners()
}

module.exports = SharedServerStorage
