'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('lisaLightDetailsCtrl', [
    '$uiRouterGlobals',
    'STATES',
    'BAS_STATE',
    'BAS_LIGHT',
    'BasState',
    'CurrentRoom',
    lisaLightDetailsCtrl
  ])

/**
 * @param $uiRouterGlobals
 * @param {STATES} STATES
 * @param {BAS_STATE} BAS_STATE
 * @param {BAS_LIGHT} BAS_LIGHT
 * @param {BasState} BasState
 * @param {CurrentRoom} CurrentRoom
 */
function lisaLightDetailsCtrl (
  $uiRouterGlobals,
  STATES,
  BAS_STATE,
  BAS_LIGHT,
  BasState,
  CurrentRoom
) {
  var lisa = this

  var CSS_SHOW_COLOR_PICKER_OVERLAY = 'lisa-light-show-color-picker'
  var CSS_SHOW_SETTINGS = 'lisa-light-show-settings'

  lisa.BAS_LIGHT = BAS_LIGHT

  lisa.setBrightnessOrWhite = setBrightnessOrWhite
  lisa.setColorTemperature = setColorTemperature
  lisa.setWhite = setWhite
  lisa.toggleColorPicker = toggleColorPicker
  lisa.toggleSettings = toggleSettings

  lisa.css = {}
  lisa.css[CSS_SHOW_SETTINGS] =
    $uiRouterGlobals.params.view ===
    BAS_STATE.S_LISA_LIGHT_DETAILS_VIEW_SETTINGS

  this.uiOnParamsChanged = uiOnParamsChanged

  init()

  function init () {

    _getLight()
  }

  function setBrightnessOrWhite (value) {

    if (lisa.light) {

      lisa.light.updateBrightness(value)

      if (
        lisa.light._supportsWhiteViaBrightness &&
        lisa.light.mode === BAS_LIGHT.MODE_WHITE
      ) {

        lisa.light.white = lisa.light.brightnessSlider
      }

      lisa.light.changeWhiteOrBrightness()
    }
  }

  function setColorTemperature (value) {
    if (lisa.light) {

      lisa.light.relTemperature = value
      lisa.light.colorTemperatureChange()
    }
  }

  function setWhite (value) {
    if (lisa.light) {

      lisa.light.white = value
      lisa.light.whiteChange()
    }
  }

  function toggleColorPicker (force) {

    var view

    view = (
      BasUtil.isBool(force) ||
      $uiRouterGlobals.params.view !==
        BAS_STATE.S_LISA_LIGHT_DETAILS_VIEW_COLOR_PICKER
    )
      ? BAS_STATE.S_LISA_LIGHT_DETAILS_VIEW_COLOR_PICKER
      : ''

    BasState.go(
      STATES.LISA_LIGHT_DETAIL,
      {
        view: view
      }
    )
  }

  function toggleSettings (force) {

    var view = BasUtil.isBool(force)
      ? force
        ? BAS_STATE.S_LISA_LIGHT_DETAILS_VIEW_SETTINGS
        : ''
      : $uiRouterGlobals.params.view ===
      BAS_STATE.S_LISA_LIGHT_DETAILS_VIEW_SETTINGS
        ? ''
        : BAS_STATE.S_LISA_LIGHT_DETAILS_VIEW_SETTINGS

    BasState.go(
      STATES.LISA_LIGHT_DETAIL,
      {
        view: view
      }
    )

    lisa.css[CSS_SHOW_SETTINGS] =
      view === BAS_STATE.S_LISA_LIGHT_DETAILS_VIEW_SETTINGS
  }

  function uiOnParamsChanged (params) {

    lisa.css[CSS_SHOW_COLOR_PICKER_OVERLAY] =
      params.view === BAS_STATE.S_LISA_LIGHT_DETAILS_VIEW_COLOR_PICKER
    lisa.css[CSS_SHOW_SETTINGS] =
      params.view === BAS_STATE.S_LISA_LIGHT_DETAILS_VIEW_SETTINGS
  }

  function _getLight () {

    var room

    room = CurrentRoom.getRoom()

    lisa.light = (room && room.lights && room.lights.getLight)
      ? room.lights.getLight($uiRouterGlobals.params.light)
      : null
  }
}
