'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasThermostatScheduler', [
    'BAS_API',
    'BAS_INTL',
    'BAS_THERMOSTAT',
    'CurrentBasCore',
    'BasThermostatSchedulerDay',
    'BasSchedulerPointType',
    basThermostatSchedulerFactory
  ])

/**
 * @param BAS_API
 * @param {BAS_INTL} BAS_INTL
 * @param {BAS_THERMOSTAT} BAS_THERMOSTAT
 * @param {CurrentBasCore} CurrentBasCore
 * @param BasThermostatSchedulerDay
 * @param BasSchedulerPointType
 * @returns BasThermostatScheduler
 */
function basThermostatSchedulerFactory (
  BAS_API,
  BAS_INTL,
  BAS_THERMOSTAT,
  CurrentBasCore,
  BasThermostatSchedulerDay,
  BasSchedulerPointType
) {
  var CSS_IS_ENABLED = 'bas-scheduler--is-enabled'
  var CSS_HAS_FILTERS = 'bas-scheduler--has-filters'

  var CSS_POINT_TYPE_HEATING = 'bas-point-type--heating'
  var CSS_POINT_TYPE_COOLING = 'bas-point-type--cooling'
  var CSS_POINT_TYPE_AUTO = 'bas-point-type--auto'

  /**
   * @constructor
   * @param {BasRoom} room
   * @param {BasThermostat} basThermostat
   * @param {string} thermostatUuid
   */
  function BasThermostatScheduler (
    room,
    basThermostat,
    thermostatUuid
  ) {
    /**
     * @type {boolean}
     */
    this.enabled = false

    /**
     * @type {boolean}
     */
    this.canChangeEnabledState = false

    /**
     * @type {Object<string, BasThermostatSchedulerDay>}
     */
    this.entries = {}

    /**
     * @type {string[]}
     */
    this.uiEntries = []

    /**
     * @type {BasSchedulerPointType[]}
     */
    this.pointTypes = []

    /**
     * @type {string[]}
     */
    this.uiFilters = []

    /**
     * @type {Object<string, BasSchedulerFilter>}
     */
    this.filters = {}

    /**
     * @type {string}
     */
    this.activeFilterId = BAS_THERMOSTAT.ID_MODE_FILTER_ALL

    /**
     * @type {boolean}
     */
    this.canReadScheduler = false

    /**
     * @type {boolean}
     */
    this.canWriteScheduler = false

    /**
     * @type {boolean}
     */
    this.canReadSchedulerV2 = false

    /**
     * @type {boolean}
     */
    this.canWriteSchedulerV2 = false

    /**
     * @type {boolean}
     */
    this.supportsFilters = false

    /**
     * @type {boolean}
     */
    this.hasFilters = false

    /**
     * @type {boolean}
     */
    this.needsMigration = false

    /**
     * @private
     * @type {BasRoom}
     */
    this._room = room

    /**
     * @private
     * @type {BasThermostat}
     */
    this._basThermostat = basThermostat

    /**
     * @private
     * @type {string}
     */
    this._thermostatUuid = thermostatUuid

    this.css = {}
    this._resetCss()

    /**
     * @private
     * @type {?ThermostatDevice}
     */
    this._device = null

    this.init(room, basThermostat, thermostatUuid)
  }

  /**
   * @param {BasRoom} room
   * @param {BasThermostat} basThermostat
   * @param {string} thermostatUuid
   */
  BasThermostatScheduler.prototype.init = function (
    room,
    basThermostat,
    thermostatUuid
  ) {
    this._resetCss()

    this._room = room
    this._basThermostat = basThermostat
    this._thermostatUuid = thermostatUuid

    this._device = this.getThermostatDevice()

    if (BasUtil.isObject(this._device)) {

      this.parseDeviceProperties(this._device)
      this.parse(this._device)
    }
  }

  /**
   * @param {ThermostatDevice} device
   */
  BasThermostatScheduler.prototype.parse = function (device) {

    var scheduler, length, i, day, key

    if (device) {

      this._device = device

      if (BasUtil.isBool(device.enabled)) {

        this.toggleEnabled(device.enabled)
      }

      this.canChangeEnabledState =
        this.canWriteScheduler || this.canWriteSchedulerV2

      scheduler = device.scheduler

      if (scheduler) {

        length = BAS_INTL.DAYS_WEEK_INTL.length

        this.uiEntries = []
        this.entries = {}

        for (i = 0; i < length; i++) {

          key = BAS_INTL.DAYS_WEEK_INTL[i]

          if (BasUtil.isObject(scheduler[key])) {

            day = new BasThermostatSchedulerDay(
              key,
              this._basThermostat.precision
            )
            day.parse(scheduler[key])

            this.entries[day.id] = day
            this.uiEntries.push(key)
          }
        }

        this._processActiveFilter()
      }

      this.needsMigration = this.canReadSchedulerV2
        ? this._hasPointsWithMode(BAS_API.SetPoint.M_ANY)
        : false
    }
  }

  /**
   * @param {ThermostatDevice} device
   */
  BasThermostatScheduler.prototype.parseDeviceProperties =
    function (device) {

      if (device) {

        this.canReadScheduler = device.allowsRead(
          BAS_API.ThermostatDevice.C_SCHEDULER
        )
        this.canWriteScheduler = device.allowsWrite(
          BAS_API.ThermostatDevice.C_SCHEDULER
        )
        this.canReadSchedulerV2 = device.allowsRead(
          BAS_API.ThermostatDevice.C_SCHEDULER_V2
        )
        this.canWriteSchedulerV2 = device.allowsWrite(
          BAS_API.ThermostatDevice.C_SCHEDULER_V2
        )

        this.supportsFilters = this.canReadSchedulerV2

        if (this.supportsFilters && this._basThermostat) {

          this.filters = {}
          this.uiFilters = []
          this.pointTypes = []

          // All filter

          const modesLength = this._basThermostat.modeOptions.length

          // Don't show "any" points if we have modes (in this case "any" points
          //  should have been filtered out anyway)
          const allFilter = modesLength
            ? BAS_THERMOSTAT.ID_MODE_FILTER_ALL_NON_ANY
            : BAS_THERMOSTAT.ID_MODE_FILTER_ALL

          this.filters[allFilter] =
            BAS_THERMOSTAT.MODE_FILTERS[allFilter]
          this.uiFilters.push(allFilter)

          // Set current active filter to all
          this.activeFilterId = allFilter

          for (const mode of this._basThermostat.modeOptions) {

            let filterId = ''

            switch (mode) {
              case BAS_THERMOSTAT.MODE_HEATING: {
                filterId = BAS_THERMOSTAT.ID_MODE_FILTER_HEATING
                const pointType = new BasSchedulerPointType(
                  BAS_THERMOSTAT.MODE_HEATING,
                  BAS_THERMOSTAT.MODE_HEATING
                )
                pointType.css[CSS_POINT_TYPE_HEATING] = true
                this.pointTypes.push(pointType)
                break
              }
              case BAS_THERMOSTAT.MODE_COOLING: {
                filterId = BAS_THERMOSTAT.ID_MODE_FILTER_COOLING
                const pointType = new BasSchedulerPointType(
                  BAS_THERMOSTAT.MODE_COOLING,
                  BAS_THERMOSTAT.MODE_COOLING
                )
                pointType.css[CSS_POINT_TYPE_COOLING] = true
                this.pointTypes.push(pointType)
                break
              }
              case BAS_THERMOSTAT.MODE_AUTO: {
                filterId = BAS_THERMOSTAT.ID_MODE_FILTER_AUTO
                const pointType = new BasSchedulerPointType(
                  BAS_THERMOSTAT.MODE_AUTO,
                  BAS_THERMOSTAT.MODE_AUTO
                )
                pointType.css[CSS_POINT_TYPE_AUTO] = true
                this.pointTypes.push(pointType)
                break
              }
            }

            if (filterId) {

              this.filters[filterId] =
                BAS_THERMOSTAT.MODE_FILTERS[filterId]
              this.uiFilters.push(filterId)
            }
          }

          this.hasFilters = this.css[CSS_HAS_FILTERS] =
            this.uiFilters.length > 2
        }
      }
    }

  BasThermostatScheduler.prototype.determineInitialFilter = function () {

    var mode

    if (this.supportsFilters) {

      this.activeFilterId =
        BAS_THERMOSTAT.ID_MODE_FILTER_ALL_NON_ANY

      if (this.hasFilters && this._basThermostat) {

        mode = this._basThermostat.mode

        if (mode !== BAS_THERMOSTAT.MODE_OFF) {

          if (mode === BAS_THERMOSTAT.MODE_HEATING) {

            this.activeFilterId =
              BAS_THERMOSTAT.ID_MODE_FILTER_HEATING

          } else if (mode === BAS_THERMOSTAT.MODE_COOLING) {

            this.activeFilterId =
              BAS_THERMOSTAT.ID_MODE_FILTER_COOLING

          } else if (mode === BAS_THERMOSTAT.MODE_AUTO) {

            this.activeFilterId =
              BAS_THERMOSTAT.ID_MODE_FILTER_AUTO
          }
        }
      }

    } else {

      this.activeFilterId = BAS_THERMOSTAT.ID_MODE_FILTER_ALL
    }

    this._processActiveFilter()
  }

  BasThermostatScheduler.prototype.toggleScheduler = function () {

    if (this.canChangeEnabledState) {

      this.toggleEnabled()
      this.commit()
      this.updateSchedule()
    }
  }

  /**
   * @param {string} dayId
   * @param {string[]} dayIds
   */
  BasThermostatScheduler.prototype.copyDayToDays = function (
    dayId,
    dayIds
  ) {
    var length, i, sourceEntry, destinationEntry

    if (this.canWriteScheduler || this.canWriteSchedulerV2) {

      sourceEntry = this.entries[dayId]

      if (sourceEntry && Array.isArray(dayIds)) {

        length = dayIds.length
        for (i = 0; i < length; i++) {

          destinationEntry = this.entries[dayIds[i]]

          if (destinationEntry) {

            destinationEntry.setPointsFromEntry(sourceEntry)
          }
        }

        this.commit()
        this.updateSchedule()
      }
    }
  }

  /**
   * @param {string} dayId
   * @param {string[]} dayIds
   */
  BasThermostatScheduler.prototype.copyFilteredDayToDays = function (
    dayId,
    dayIds
  ) {
    var length, i, sourceEntry, destinationEntry

    if (this.canWriteScheduler || this.canWriteSchedulerV2) {

      sourceEntry = this.entries[dayId]

      if (sourceEntry && Array.isArray(dayIds)) {

        length = dayIds.length
        for (i = 0; i < length; i++) {

          destinationEntry = this.entries[dayIds[i]]

          if (destinationEntry) {

            destinationEntry.setPointsFromEntry(
              sourceEntry.clone(),
              this.filters[this.activeFilterId]
            )
          }
        }

        this.commit()
        this.updateSchedule()
      }
    }
  }

  BasThermostatScheduler.prototype.toggleEnabled = function (force) {

    this.enabled = BasUtil.isBool(force) ? force : !this.enabled
    this.css[CSS_IS_ENABLED] = this.enabled
  }

  /**
   * @param {string} filterId
   */
  BasThermostatScheduler.prototype.selectFilter = function (filterId) {

    this.activeFilterId = filterId
    this._processActiveFilter()
  }

  /**
   * @param {string} entryId
   * @param {BasSchedulerPointType} [pointType]
   */
  BasThermostatScheduler.prototype.addNewPoint = function (
    entryId,
    pointType
  ) {
    var entry

    if (this.canWriteScheduler || this.canWriteSchedulerV2) {

      entry = this.entries[entryId]

      if (entry) {

        entry.addNewPoint(pointType)

        if (pointType && this.hasFilters) {

          switch (pointType.id) {
            case BAS_THERMOSTAT.MODE_HEATING:
              this.selectFilter(
                BAS_THERMOSTAT.ID_MODE_FILTER_HEATING
              )
              break
            case BAS_THERMOSTAT.MODE_COOLING:
              this.selectFilter(
                BAS_THERMOSTAT.ID_MODE_FILTER_COOLING
              )
              break
            case BAS_THERMOSTAT.MODE_AUTO:
              this.selectFilter(
                BAS_THERMOSTAT.ID_MODE_FILTER_AUTO
              )
              break
            default:
              entry.generateUiPoints(
                this.filters[this.activeFilterId]
              )
          }

        } else {

          entry.generateUiPoints(this.filters[this.activeFilterId])
        }
      }
    }
  }

  /**
   * @param {string} _entryId
   * @param {BasThermostatSchedulerPoint} _point
   */
  BasThermostatScheduler.prototype.changePoint = function (
    _entryId,
    _point
  ) {
    // Empty
  }

  /**
   * @param {string} entryId
   * @param {BasThermostatSchedulerPoint} point
   */
  BasThermostatScheduler.prototype.deletePoint = function (
    entryId,
    point
  ) {
    var entry

    if (this.canWriteScheduler || this.canWriteSchedulerV2) {

      entry = this.entries[entryId]

      if (entry) {

        entry.deletePoint(point)

        entry.generateUiPoints(this.filters[this.activeFilterId])
      }
    }
  }

  /**
   * @private
   */
  BasThermostatScheduler.prototype._processActiveFilter = function () {

    var filter, length, i, entryId, entry

    filter = this.filters[this.activeFilterId]

    length = this.uiEntries.length
    for (i = 0; i < length; i++) {

      entryId = this.uiEntries[i]
      entry = this.entries[entryId]

      if (entry) entry.generateUiPoints(filter)
    }
  }

  /**
   * @private
   * @param {string} mode
   * @returns {boolean}
   */
  BasThermostatScheduler.prototype._hasPointsWithMode = function (
    mode
  ) {
    var length, i, entryId, entry

    length = this.uiEntries.length
    for (i = 0; i < length; i++) {

      entryId = this.uiEntries[i]
      entry = this.entries[entryId]

      if (entry) {

        if (entry.hasPointsWithMode(mode)) return true
      }
    }

    return false
  }

  BasThermostatScheduler.prototype.migrate = function () {

    var length, i, modes, pointType, entryId, entry

    if (this.needsMigration) {

      modes = []

      length = this.pointTypes.length
      for (i = 0; i < length; i++) {

        pointType = this.pointTypes[i]

        switch (pointType.id) {
          case BAS_THERMOSTAT.MODE_HEATING:
            modes.push(BAS_API.SetPoint.M_HEATING)
            break
          case BAS_THERMOSTAT.MODE_COOLING:
            modes.push(BAS_API.SetPoint.M_COOLING)
            break
          case BAS_THERMOSTAT.MODE_AUTO:
            modes.push(BAS_API.SetPoint.M_AUTO)
            break
        }
      }

      length = this.uiEntries.length
      for (i = 0; i < length; i++) {

        entryId = this.uiEntries[i]
        entry = this.entries[entryId]

        if (entry) {

          entry.migrate(BAS_API.SetPoint.M_ANY, modes)
        }
      }

      this.commit()
      this.updateSchedule()

      this.needsMigration = false
    }
  }

  BasThermostatScheduler.prototype.updateTemperatureUnit = function () {

    var length, i, entryId, entry

    length = this.uiEntries.length
    for (i = 0; i < length; i++) {

      entryId = this.uiEntries[i]
      entry = this.entries[entryId]

      if (entry) entry.updateTemperatureUnit()
    }
  }

  BasThermostatScheduler.prototype.updateTranslation = function () {

    var length, i, entryId, entry

    length = this.uiEntries.length
    for (i = 0; i < length; i++) {

      entryId = this.uiEntries[i]
      entry = this.entries[entryId]

      if (entry) entry.updateTranslation()
    }
  }

  /**
   * Write current scheduler to Thermostat device
   */
  BasThermostatScheduler.prototype.commit = function () {

    if (BasUtil.isObject(this._device)) {

      this._device.setEnabled(this.enabled)
      this._device.setSetpoints(this._makeDays())
    }
  }

  /**
   * @returns {Promise}
   */
  BasThermostatScheduler.prototype.updateSchedule = function () {

    if (BasUtil.isObject(this._device)) {

      return this._device.updateDevice()
    }

    return Promise.reject('No Device')
  }

  /**
   * @returns {Object<string,SetPoint[]>}
   */
  BasThermostatScheduler.prototype._makeDays = function () {

    var result, length, i, entryId, entry

    result = {}

    length = this.uiEntries.length
    for (i = 0; i < length; i++) {

      entryId = this.uiEntries[i]
      entry = this.entries[entryId]

      if (entry) result[entryId] = entry.generateApiDay()
    }

    return result
  }

  /**
   * @returns {?ThermostatDevice}
   */
  BasThermostatScheduler.prototype.getThermostatDevice = function () {

    return CurrentBasCore.getDevice(this._thermostatUuid)
  }

  /**
   * @private
   */
  BasThermostatScheduler.prototype._resetCss = function () {

    this.css[CSS_IS_ENABLED] = false
    this.css[CSS_HAS_FILTERS] = false
  }

  return BasThermostatScheduler
}
