[
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 0,
      "name": "",
      "reachable": true,
      "state": {
        "brightness": 0.30,
        "on": true
      },
      "subType": 9,
      "type": "light",
      "uuid": "HallPendantLight"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 0,
      "name": "",
      "reachable": true,
      "state": {
        "brightness": 0.40,
        "on": true
      },
      "subType": 12,
      "type": "light",
      "uuid": "HallDownLightsFront"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 0,
      "name": "",
      "reachable": true,
      "state": {
        "brightness": 0.70,
        "on": true
      },
      "subType": 19,
      "type": "light",
      "uuid": "HallDownLightArt"
    }
  },
  {
    "device": {
      "capabilities": {
        "on": "rw"
      },
      "location": 0,
      "name": "Staircase",
      "reachable": true,
      "state": {
        "on": true
      },
      "subType": 0,
      "type": "light",
      "uuid": "HallStaircase"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 0,
      "name": "Wall",
      "reachable": true,
      "state": {
        "brightness": 0.30,
        "on": true
      },
      "subType": 12,
      "type": "light",
      "uuid": "LivingRoomDownLightsWall"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 0,
      "name": "Center",
      "reachable": true,
      "state": {
        "brightness": 0.50,
        "on": true
      },
      "subType": 12,
      "type": "light",
      "uuid": "LivingRoomDownLightsCenter"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 0,
      "name": "Cabinet",
      "reachable": true,
      "state": {
        "brightness": 0.20,
        "on": true
      },
      "subType": 12,
      "type": "light",
      "uuid": "LivingRoomDownLightsCabinet"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 0,
      "name": "Window",
      "reachable": true,
      "state": {
        "brightness": 0.70,
        "on": true
      },
      "subType": 12,
      "type": "light",
      "uuid": "LivingRoomDownLightsWindow"
    }
  },
  {
    "device": {
      "uuid": "LivingRoomHueLamp",
      "name": "Hue Lamp",
      "type": "light",
      "subType": 0,
      "location": 0,
      "reachable": true,
      "capabilities": {
        "brightness": "rw",
        "color": "rw",
        "colorTemperature": "rw",
        "on": "rw",
        "mode": "rw"
      },
      "attributes": {
        "colorTemperature": {
          "max": 6500,
          "min": 2000
        }
      },
      "state": {
        "brightness": 0.60,
        "colorTemperature": 3260,
        "hue": 0.23,
        "mode": "colorTemperature",
        "on": true,
        "saturation": 1
      }
    }
  },
  {
    "device": {
      "capabilities": {
        "on": "rw"
      },
      "location": 0,
      "name": "",
      "reachable": true,
      "state": {
        "on": true
      },
      "subType": 1,
      "type": "light",
      "uuid": "LivingRoomFloorLamp"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 0,
      "name": "",
      "reachable": true,
      "state": {
        "brightness": 0.90,
        "on": true
      },
      "subType": 5,
      "type": "light",
      "uuid": "DiningRoomChandelier"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 5,
      "name": "",
      "reachable": true,
      "state": {
        "brightness": 0.60,
        "on": true
      },
      "subType": 12,
      "type": "light",
      "uuid": "DiningRoomDownLightsLeft"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 6,
      "name": "",
      "reachable": true,
      "state": {
        "brightness": 0.60,
        "on": true
      },
      "subType": 12,
      "type": "light",
      "uuid": "DiningRoomDownLightsRight"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 0,
      "name": "",
      "reachable": true,
      "state": {
        "brightness": 1.00,
        "on": true
      },
      "subType": 4,
      "type": "light",
      "uuid": "DiningRoomWallLight"
    }
  },
  {
    "device": {
      "capabilities": {
        "on": "rw"
      },
      "location": 0,
      "name": "Sockets",
      "reachable": true,
      "state": {
        "on": false
      },
      "subType": 0,
      "type": "light",
      "uuid": "DiningRoomSockets"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 0,
      "name": "Pendant Light",
      "reachable": true,
      "state": {
        "brightness": 0.80,
        "on": true
      },
      "subType": 0,
      "type": "light",
      "uuid": "KitchenPendantLight"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 0,
      "name": "Table",
      "reachable": true,
      "state": {
        "brightness": 0.50,
        "on": true
      },
      "subType": 5,
      "type": "light",
      "uuid": "KitchenTable"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 0,
      "name": "Cabinet",
      "reachable": true,
      "state": {
        "brightness": 0,
        "on": false
      },
      "subType": 0,
      "type": "light",
      "uuid": "KitchenCabinet"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 0,
      "name": "Track Light",
      "reachable": true,
      "state": {
        "brightness": 0.70,
        "on": true
      },
      "subType": 0,
      "type": "light",
      "uuid": "KitchenTrackLight"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 0,
      "name": "Pendant Light",
      "reachable": true,
      "state": {
        "brightness": 0.30,
        "on": true
      },
      "subType": 0,
      "type": "light",
      "uuid": "FamilyRoomPendantLight"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 0,
      "name": "Window",
      "reachable": true,
      "state": {
        "brightness": 0.20,
        "on": true
      },
      "subType": 12,
      "type": "light",
      "uuid": "FamilyRoomDownLightsWindow"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 0,
      "name": "Wall",
      "reachable": true,
      "state": {
        "brightness": 0.40,
        "on": true
      },
      "subType": 12,
      "type": "light",
      "uuid": "FamilyRoomDownLightsWall"
    }
  },
  {
    "device": {
      "uuid": "FamilyRoomHueLamp",
      "name": "Hue Lamp",
      "type": "light",
      "subType": 0,
      "location": 0,
      "reachable": true,
      "capabilities": {
        "brightness": "rw",
        "color": "rw",
        "colorTemperature": "rw",
        "on": "rw",
        "mode": "rw"
      },
      "attributes": {
        "colorTemperature": {
          "max": 6500,
          "min": 2000
        }
      },
      "state": {
        "brightness": 0,
        "colorTemperature": 3260,
        "hue": 0.23,
        "mode": "colorTemperature",
        "on": false,
        "saturation": 1
      }
    }
  },
  {
    "device": {
      "capabilities": {
        "on": "rw"
      },
      "location": 0,
      "name": "",
      "reachable": true,
      "state": {
        "on": false
      },
      "subType": 1,
      "type": "light",
      "uuid": "FamilyRoomFloorLamp"
    }
  },
  {
    "device": {
      "capabilities": {
        "on": "rw"
      },
      "location": 0,
      "name": "Sockets",
      "reachable": true,
      "state": {
        "on": true
      },
      "subType": 0,
      "type": "light",
      "uuid": "FamilyRoomSockets"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 9,
      "name": "",
      "reachable": true,
      "state": {
        "brightness": 0.70,
        "on": true
      },
      "subType": 12,
      "type": "light",
      "uuid": "BarDownLightsCenter"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 0,
      "name": "Desk",
      "reachable": true,
      "state": {
        "brightness": 0,
        "on": false
      },
      "subType": 0,
      "type": "light",
      "uuid": "HomeOfficeDesk"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 5,
      "name": "",
      "reachable": true,
      "state": {
        "brightness": 0.60,
        "on": true
      },
      "subType": 12,
      "type": "light",
      "uuid": "HomeOfficeDownLightsLeft"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 6,
      "name": "",
      "reachable": true,
      "state": {
        "brightness": 0.60,
        "on": true
      },
      "subType": 12,
      "type": "light",
      "uuid": "HomeOfficeDownLightsRight"
    }
  },
  {
    "device": {
      "capabilities": {
        "on": "rw"
      },
      "location": 0,
      "name": "",
      "reachable": true,
      "state": {
        "on": true
      },
      "subType": 1,
      "type": "light",
      "uuid": "HomeOfficeFloorLamp"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 0,
      "name": "",
      "reachable": true,
      "state": {
        "brightness": 0.10,
        "on": true
      },
      "subType": 12,
      "type": "light",
      "uuid": "MasterBedroomDownLights"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 5,
      "name": "Bedside",
      "reachable": true,
      "state": {
        "brightness": 0.20,
        "on": true
      },
      "subType": 0,
      "type": "light",
      "uuid": "MasterBedroomBedsideLeft"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 6,
      "name": "Bedside",
      "reachable": true,
      "state": {
        "brightness": 0.20,
        "on": true
      },
      "subType": 0,
      "type": "light",
      "uuid": "MasterBedroomBedsideRight"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 0,
      "name": "",
      "reachable": true,
      "state": {
        "brightness": 0,
        "on": false
      },
      "subType": 1,
      "type": "light",
      "uuid": "MasterBedroomFloorLamp"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 0,
      "name": "",
      "reachable": true,
      "state": {
        "brightness": 0.50,
        "on": true
      },
      "subType": 12,
      "type": "light",
      "uuid": "MasterBathroomDownLights"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 0,
      "name": "Shower",
      "reachable": true,
      "state": {
        "brightness": 0,
        "on": false
      },
      "subType": 0,
      "type": "light",
      "uuid": "MasterBathroomShower"
    }
  },
  {
    "device": {
      "uuid": "MasterBathroomBath",
      "type": "light",
      "subType": 0,
      "name": "Bath",
      "location": 0,
      "reachable": true,
      "capabilities": {
        "brightness": "rw",
        "color": "rw",
        "colorTemperature": "rw",
        "on": "rw"
      },
      "attributes": {
        "colorTemperature": {
          "max": 6500,
          "min": 2000
        }
      },
      "state": {
        "brightness": 0.70,
        "colorTemperature": 3260,
        "hue": 0.23,
        "mode": "colorTemperature",
        "on": true,
        "saturation": 1
      }
    }
  },
  {
    "device": {
      "capabilities": {
        "on": "rw"
      },
      "location": 0,
      "name": "Sink",
      "reachable": true,
      "state": {
        "on": true
      },
      "subType": 0,
      "type": "light",
      "uuid": "MasterBathroomSink"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 0,
      "name": "",
      "reachable": true,
      "state": {
        "brightness": 0.70,
        "on": true
      },
      "subType": 12,
      "type": "light",
      "uuid": "BedroomJudyDownLights"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 0,
      "name": "",
      "reachable": true,
      "state": {
        "brightness": 0,
        "on": false
      },
      "subType": 5,
      "type": "light",
      "uuid": "BedroomJudyBedside"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 0,
      "name": "Desk",
      "reachable": true,
      "state": {
        "brightness": 0.80,
        "on": true
      },
      "subType": 2,
      "type": "light",
      "uuid": "BedroomJudyDesk"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "color": "rw",
        "on": "rw"
      },
      "location": 0,
      "name": "",
      "reachable": true,
      "state": {
        "brightness": 0.30,
        "hue": 0.23,
        "mode": "color",
        "on": true,
        "saturation": 1
      },
      "subType": 12,
      "type": "light",
      "uuid": "BedroomElroyDownLights"
    }
  },
  {
    "device": {
      "uuid": "BedroomElroyWhiteLights",
      "name": "White Lights",
      "type": "light",
      "subType": 0,
      "location": 0,
      "reachable": true,
      "capabilities": {
        "brightness": "rw",
        "color": "rw",
        "colorTemperature": "rw",
        "on": "rw",
        "white": "rw"
      },
      "attributes": {
        "colorTemperature": {
          "max": 6500,
          "min": 2000
        }
      },
      "state": {
        "brightness": 0.30,
        "colorTemperature": 3260,
        "hue": 0.23,
        "mode": "color",
        "on": true,
        "saturation": 1,
        "white": 0.7
      }
    }
  },
  {
    "device": {
      "uuid": "BedroomElroyWallLight",
      "name": "",
      "type": "light",
      "subType": 4,
      "location": 3,
      "reachable": true,
      "capabilities": {
        "brightness": "rw",
        "colorTemperature": "rw",
        "on": "rw"
      },
      "attributes": {
        "colorTemperature": {
          "max": 6500,
          "min": 2000
        }
      },
      "state": {
        "brightness": 0.30,
        "colorTemperature": 3260,
        "mode": "colorTemperature",
        "on": true
      }
    }
  },
  {
    "device": {
      "capabilities": {
        "on": "rw"
      },
      "location": 2,
      "name": "Bedside",
      "reachable": true,
      "state": {
        "on": true
      },
      "subType": 1,
      "type": "light",
      "uuid": "BedroomElroyBedside"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 0,
      "name": "Desk",
      "reachable": true,
      "state": {
        "brightness": 0,
        "on": false
      },
      "subType": 2,
      "type": "light",
      "uuid": "BedroomElroyDesk"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 0,
      "name": "",
      "reachable": true,
      "state": {
        "brightness": 0.60,
        "on": true
      },
      "subType": 12,
      "type": "light",
      "uuid": "BathroomChildrenDownLights"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 0,
      "name": "Shower",
      "reachable": true,
      "state": {
        "brightness": 0.80,
        "on": true
      },
      "subType": 0,
      "type": "light",
      "uuid": "BathroomChildrenShower"
    }
  },
  {
    "device": {
      "capabilities": {
        "on": "rw"
      },
      "location": 0,
      "name": "Sink",
      "reachable": true,
      "state": {
        "on": true
      },
      "subType": 0,
      "type": "light",
      "uuid": "BathroomChildrenSink"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 0,
      "name": "",
      "reachable": true,
      "state": {
        "brightness": 0,
        "on": false
      },
      "subType": 12,
      "type": "light",
      "uuid": "GuestRoomDownLights"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 5,
      "name": "Bedside",
      "reachable": true,
      "state": {
        "brightness": 0,
        "on": false
      },
      "subType": 0,
      "type": "light",
      "uuid": "GuestRoomBedsideLeft"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 6,
      "name": "Bedside",
      "reachable": true,
      "state": {
        "brightness": 0,
        "on": false
      },
      "subType": 0,
      "type": "light",
      "uuid": "GuestRoomBedsideRight"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 0,
      "name": "",
      "reachable": true,
      "state": {
        "brightness": 0,
        "on": false
      },
      "subType": 1,
      "type": "light",
      "uuid": "GuestRoomFloorLamp"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 13,
      "name": "",
      "reachable": true,
      "state": {
        "brightness": 0.10,
        "on": true
      },
      "subType": 12,
      "type": "light",
      "uuid": "HomeCinemaDownLightsFront"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 9,
      "name": "",
      "reachable": true,
      "state": {
        "brightness": 0.10,
        "on": true
      },
      "subType": 12,
      "type": "light",
      "uuid": "HomeCinemaDownLightCenter"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 14,
      "name": "",
      "reachable": true,
      "state": {
        "brightness": 0.10,
        "on": true
      },
      "subType": 12,
      "type": "light",
      "uuid": "HomeCinemaDownLightsRear"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 13,
      "name": "",
      "reachable": true,
      "state": {
        "brightness": 0.80,
        "on": true
      },
      "subType": 12,
      "type": "light",
      "uuid": "FitnessDownLightsFront"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 9,
      "name": "",
      "reachable": true,
      "state": {
        "brightness": 0.80,
        "on": true
      },
      "subType": 12,
      "type": "light",
      "uuid": "FitnessDownLightCenter"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 14,
      "name": "",
      "reachable": true,
      "state": {
        "brightness": 0.80,
        "on": true
      },
      "subType": 12,
      "type": "light",
      "uuid": "FitnessDownLightsRear"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 0,
      "name": "",
      "reachable": true,
      "state": {
        "brightness": 0.60,
        "on": true
      },
      "subType": 12,
      "type": "light",
      "uuid": "TerraceDownLights"
    }
  },
  {
    "device": {
      "capabilities": {
        "on": "rw"
      },
      "location": 0,
      "name": "Garden",
      "reachable": true,
      "state": {
        "on": false
      },
      "subType": 0,
      "type": "light",
      "uuid": "TerraceGarden"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 0,
      "name": "Pool Lights",
      "reachable": true,
      "state": {
        "brightness": 0.50,
        "on": true
      },
      "subType": 0,
      "type": "light",
      "uuid": "PoolPoolLights"
    }
  },
  {
    "device": {
      "capabilities": {
        "brightness": "rw",
        "on": "rw"
      },
      "location": 0,
      "name": "Up Lights",
      "reachable": true,
      "state": {
        "brightness": 1.00,
        "on": true
      },
      "subType": 0,
      "type": "light",
      "uuid": "PoolUpLights"
    }
  },
  {
    "device": {
      "capabilities": {
        "on": "rw"
      },
      "location": 0,
      "name": "Garden",
      "reachable": true,
      "state": {
        "on": true
      },
      "subType": 0,
      "type": "light",
      "uuid": "PoolGarden"
    }
  },
  {
    "device": {
      "name": "Front Door",
      "mjpeg": "img/demo/cameras/front_door.m4v",
      "type": "camera",
      "uuid": "HomeFrontDoorCamera"
    }
  },
  {
    "device": {
      "name": "Street",
      "mjpeg": "img/demo/cameras/street.m4v",
      "type": "camera",
      "uuid": "HomeStreetCamera"
    }
  },
  {
    "device": {
      "name": "Yard",
      "mjpeg": "img/demo/cameras/yard.m4v",
      "type": "camera",
      "uuid": "HomeYardCamera"
    }
  },
  {
    "device": {
      "uuid": "HallSceneController",
      "type": "sceneController",
      "name": "Room scene controller",
      "reachable": true,
      "capabilities": {
        "scene": {
          "add": "x"
        }
      },
      "favourites": [
        "GroundFloorHallWelcome",
        "GroundFloorHallGuests",
        "GroundFloorHallEvening",
        "GroundFloorHallRelax"
      ],
      "scenes": [
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#00ff00",
          "favourite": false,
          "name": "Welcome",
          "template": 7,
          "uuid": "GroundFloorHallWelcome"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#ffff00",
          "favourite": false,
          "name": "Guests",
          "template": 8,
          "uuid": "GroundFloorHallGuests"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#ff3232",
          "favourite": false,
          "name": "Evening",
          "template": 9,
          "uuid": "GroundFloorHallEvening"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#007dff",
          "favourite": false,
          "name": "Relax",
          "template": 3,
          "uuid": "GroundFloorHallRelax"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "r",
            "name": "r",
            "template": "r",
            "learn": "x"
          },
          "colour": "#0000ff",
          "favourite": false,
          "name": "On",
          "template": 255,
          "uuid": "GroundFloorHallOn"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "r",
            "name": "r",
            "template": "r"
          },
          "colour": "#0000ff",
          "favourite": false,
          "name": "Off",
          "template": 256,
          "uuid": "GroundFloorHallOff"
        }
      ]
    }
  },
  {
    "device": {
      "uuid": "LivingRoomSceneController",
      "type": "sceneController",
      "name": "Room scene controller",
      "reachable": true,
      "capabilities": {
        "scene": {
          "add": "x"
        }
      },
      "favourites": [
        "GroundFloorLivingWelcome",
        "GroundFloorLivingRoomGoodMorning",
        "GroundFloorLivingRoomEvening",
        "GroundFloorLivingMovie"
      ],
      "scenes": [
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#00ff00",
          "favourite": false,
          "name": "Welcome",
          "template": 7,
          "uuid": "GroundFloorLivingWelcome"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#ffff00",
          "favourite": false,
          "name": "Good Morning",
          "template": 10,
          "uuid": "GroundFloorLivingRoomGoodMorning"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#ff3232",
          "favourite": false,
          "name": "Evening",
          "template": 9,
          "uuid": "GroundFloorLivingRoomEvening"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "favourite": false,
          "name": "Relax",
          "template": 3,
          "uuid": "GroundFloorLivingRoomRelax"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "r",
            "name": "r",
            "template": "r",
            "learn": "x"
          },
          "colour": "#0000ff",
          "favourite": false,
          "name": "On",
          "template": 255,
          "uuid": "GroundFloorLivingRoomOn"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "r",
            "name": "r",
            "template": "r"
          },
          "colour": "#0000ff",
          "favourite": false,
          "name": "Off",
          "template": 256,
          "uuid": "GroundFloorLivingRoomOff"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "content": "rw",
            "remove": "x",
            "learn": "x"
          },
          "colour": "#007dff",
          "favourite": false,
          "name": "Movie",
          "template": 11,
          "uuid": "GroundFloorLivingMovie",
          "content": [
            {
              "target": {
                "areaUuid": "GroundFloorLivingRoom",
                "shades": {
                  "LivingRoomCenter": {
                    "autoMode": false,
                    "isClosed": true
                  },
                  "LivingRoomLeft": {
                    "autoMode": false,
                    "isClosed": true
                  },
                  "LivingRoomRight": {
                    "autoMode": false,
                    "isClosed": true
                  }
                }
              }
            },
            {
              "delay": 5
            },
            {
              "target": {
                "areaUuid": "GroundFloorLivingRoom",
                "lights": {
                  "LivingRoomDownLightsCabinet": {
                    "onOff": true,
                    "brightness": 50
                  },
                  "LivingRoomDownLightsCenter": {
                    "onOff": true,
                    "brightness": 50
                  },
                  "LivingRoomDownLightsWall": {
                    "onOff": true,
                    "brightness": 24
                  },
                  "LivingRoomDownLightsWindow": {
                    "onOff": true,
                    "brightness": 50
                  }
                }
              }
            },
            {
              "delay": 5
            },
            {
              "target": {
                "areaUuid": "GroundFloorLivingRoom",
                "lights": {
                  "LivingRoomHueLamp": {
                    "onOff": true,
                    "brightness": 74
                  }
                }
              }
            },
            {
              "delay": 10
            },
            {
              "target": {
                "areaUuid": "GroundFloorLivingRoom",
                "audio": {
                  "sourceUuid": ""
                }
              }
            },
            {
              "target": {
                "areaUuid": "GroundFloorLivingRoom",
                "thermostat": {
                  "uuid": "LivingRoomThermostat1"
                }
              }
            }
          ]
        }
      ]
    }
  },
  {
    "device": {
      "uuid": "DiningRoomSceneController",
      "type": "sceneController",
      "name": "Room scene controller",
      "reachable": true,
      "capabilities": {
        "scene": {
          "add": "x"
        }
      },
      "favourites": [
        "GroundFloorDiningRoomWelcome",
        "GroundFloorDiningRoomGoodMorning",
        "GroundFloorDiningRoomDinner",
        "GroundFloorLivingGuests"
      ],
      "scenes": [
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#00ff00",
          "favourite": false,
          "name": "Welcome",
          "template": 7,
          "uuid": "GroundFloorDiningRoomWelcome"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#ffff00",
          "favourite": false,
          "name": "Good Morning",
          "template": 10,
          "uuid": "GroundFloorDiningRoomGoodMorning"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#ff3232",
          "favourite": false,
          "name": "Dinner",
          "template": 6,
          "uuid": "GroundFloorDiningRoomDinner"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "favourite": false,
          "name": "Relax",
          "template": 3,
          "uuid": "GroundFloorDiningRoomRelax"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "content": "rw",
            "remove": "x",
            "learn": "x"
          },
          "colour": "#007dff",
          "favourite": false,
          "name": "Guests",
          "template": 8,
          "uuid": "GroundFloorLivingGuests",
          "content": [
            {
              "target": {
                "areaUuid": "GroundFloorDiningRoom",
                "lights": {
                  "DiningRoomChandelier": {
                    "onOff": true,
                    "brightness": 99
                  },
                  "DiningRoomDownLightsLeft": {
                    "onOff": true,
                    "brightness": 43
                  },
                  "DiningRoomDownLightsRight": {
                    "onOff": true,
                    "brightness": 41
                  },
                  "DiningRoomWallLight": {
                    "onOff": true,
                    "brightness": 69
                  }
                }
              }
            },
            {
              "target": {
                "areaUuid": "GroundFloorDiningRoom",
                "audio": {
                  "sourceUuid": "sourceTurntable",
                  "volume": 25
                }
              }
            }
          ]
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "r",
            "name": "r",
            "template": "r",
            "learn": "x"
          },
          "colour": "#0000ff",
          "favourite": false,
          "name": "On",
          "template": 255,
          "uuid": "GroundFloorDiningRoomOn"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "r",
            "name": "r",
            "template": "r"
          },
          "colour": "#0000ff",
          "favourite": false,
          "name": "Off",
          "template": 256,
          "uuid": "GroundFloorDiningRoomOff"
        }
      ]
    }
  },
  {
    "device": {
      "uuid": "KitchenSceneController",
      "type": "sceneController",
      "name": "Room scene controller",
      "reachable": true,
      "capabilities": {
        "scene": {
          "add": "x"
        }
      },
      "favourites": [
        "GroundFloorKitchenWelcome",
        "GroundFloorKitchenGoodMorning",
        "GroundFloorKitchenDinner",
        "GroundFloorKitchenCooking"
      ],
      "scenes": [
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#00ff00",
          "favourite": false,
          "name": "Welcome",
          "template": 7,
          "uuid": "GroundFloorKitchenWelcome"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#ffff00",
          "favourite": false,
          "name": "Good Morning",
          "template": 10,
          "uuid": "GroundFloorKitchenGoodMorning"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#ff3232",
          "favourite": false,
          "name": "Dinner",
          "template": 6,
          "uuid": "GroundFloorKitchenDinner"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "favourite": false,
          "name": "Relax",
          "template": 3,
          "uuid": "GroundFloorKitchenRelax"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "r",
            "name": "r",
            "template": "r",
            "content": "rw",
            "remove": "x",
            "learn": "x"
          },
          "colour": "#007dff",
          "favourite": true,
          "name": "Off",
          "template": 5,
          "uuid": "GroundFloorKitchenCooking",
          "content": [
            {
              "target": {
                "areaUuid": "GroundFloorKitchen",
                "audio": {
                  "volume": 25
                }
              }
            },
            {
              "target": {
                "areaUuid": "GroundFloorKitchen",
                "shades": {
                  "KitchenCurtain": {
                    "autoMode": false,
                    "isClosed": false
                  }
                }
              }
            },
            {
              "target": {
                "areaUuid": "GroundFloorKitchen",
                "lights": {
                  "KitchenCabinet": {
                    "onOff": true,
                    "brightness": 99
                  },
                  "KitchenTable": {
                    "onOff": true,
                    "brightness": 43
                  },
                  "KitchenTrackLight": {
                    "onOff": true,
                    "brightness": 100
                  }
                }
              }
            }
          ]
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "r",
            "name": "r",
            "template": "r",
            "learn": "x"
          },
          "colour": "#0000ff",
          "favourite": false,
          "name": "On",
          "template": 255,
          "uuid": "GroundFloorKitchenOn"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "r",
            "name": "r",
            "template": "r"
          },
          "colour": "#0000ff",
          "favourite": false,
          "name": "Off",
          "template": 256,
          "uuid": "GroundFloorKitchenOff"
        }
      ]
    }
  },
  {
    "device": {
      "uuid": "FamilyRoomSceneController",
      "type": "sceneController",
      "name": "Room scene controller",
      "reachable": true,
      "capabilities": {
        "scene": {
          "add": "x"
        }
      },
      "favourites": [
        "GroundFloorFamilyRoomWelcome",
        "GroundFloorFamilyRoomGoodMorning",
        "GroundFloorFamilyRoomEvening",
        "GroundFloorFamilyRoomRelax"
      ],
      "scenes": [
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#00ff00",
          "favourite": false,
          "name": "Welcome",
          "template": 7,
          "uuid": "GroundFloorFamilyRoomWelcome"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#ffff00",
          "favourite": false,
          "name": "Good Morning",
          "template": 10,
          "uuid": "GroundFloorFamilyRoomGoodMorning"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#ff3232",
          "favourite": false,
          "name": "Evening",
          "template": 9,
          "uuid": "GroundFloorFamilyRoomEvening"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#007dff",
          "favourite": false,
          "name": "Relax",
          "template": 3,
          "uuid": "GroundFloorFamilyRoomRelax"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "r",
            "name": "r",
            "template": "r",
            "learn": "x"
          },
          "colour": "#0000ff",
          "favourite": false,
          "name": "On",
          "template": 255,
          "uuid": "GroundFloorFamilyRoomOn"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "r",
            "name": "r",
            "template": "r"
          },
          "colour": "#0000ff",
          "favourite": false,
          "name": "Off",
          "template": 256,
          "uuid": "GroundFloorFamilyRoomOff"
        }
      ]
    }
  },
  {
    "device": {
      "uuid": "BarSceneController",
      "type": "sceneController",
      "name": "Room scene controller",
      "reachable": true,
      "capabilities": {
        "scene": {
          "add": "x"
        }
      },
      "favourites": [
        "GroundFloorBarWelcome",
        "GroundFloorBarRelax",
        "GroundFloorBarParty",
        ""
      ],
      "scenes": [
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#00ff00",
          "favourite": false,
          "name": "Welcome",
          "template": 7,
          "uuid": "GroundFloorBarWelcome"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#ffff00",
          "favourite": false,
          "name": "Relax",
          "template": 3,
          "uuid": "GroundFloorBarRelax"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#ff3232",
          "favourite": false,
          "name": "Party",
          "template": 4,
          "uuid": "GroundFloorBarParty"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "r",
            "name": "r",
            "template": "r",
            "learn": "x"
          },
          "colour": "#0000ff",
          "favourite": false,
          "name": "On",
          "template": 255,
          "uuid": "GroundFloorBarOn"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "r",
            "name": "r",
            "template": "r"
          },
          "colour": "#0000ff",
          "favourite": false,
          "name": "Off",
          "template": 256,
          "uuid": "GroundFloorBarOff"
        }
      ]
    }
  },
  {
    "device": {
      "uuid": "HomeOfficeSceneController",
      "type": "sceneController",
      "name": "Room scene controller",
      "reachable": true,
      "capabilities": {
        "scene": {
          "add": "x"
        }
      },
      "favourites": [
        "FirstFloorHomeOfficeWelcome",
        "FirstFloorHomeOfficeGoodMorning",
        "FirstFloorHomeOfficeEvening",
        "FirstFloorHomeOfficeRelax"
      ],
      "scenes": [
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#00ff00",
          "favourite": false,
          "name": "Welcome",
          "template": 7,
          "uuid": "FirstFloorHomeOfficeWelcome"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#ffff00",
          "favourite": false,
          "name": "Good Morning",
          "template": "0",
          "uuid": "FirstFloorHomeOfficeGoodMorning"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#ff3232",
          "favourite": false,
          "name": "Evening",
          "template": 9,
          "uuid": "FirstFloorHomeOfficeEvening"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#007dff",
          "favourite": false,
          "name": "Relax",
          "template": 3,
          "uuid": "FirstFloorHomeOfficeRelax"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "r",
            "name": "r",
            "template": "r",
            "learn": "x"
          },
          "colour": "#0000ff",
          "favourite": false,
          "name": "On",
          "template": 255,
          "uuid": "FirstFloorHomeOfficeOn"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "r",
            "name": "r",
            "template": "r"
          },
          "colour": "#0000ff",
          "favourite": false,
          "name": "Off",
          "template": 256,
          "uuid": "FirstFloorHomeOfficeOff"
        }
      ]
    }
  },
  {
    "device": {
      "uuid": "MasterBedroomSceneController",
      "type": "sceneController",
      "name": "Room scene controller",
      "reachable": true,
      "capabilities": {
        "scene": {
          "add": "x"
        }
      },
      "favourites": [
        "FirstFloorMasterBedroomWelcome",
        "FirstFloorMasterBedroomGoodMorning",
        "FirstFloorMasterBedroomEvening",
        "FirstFloorMasterBedroomRelax"
      ],
      "scenes": [
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#00ff00",
          "favourite": false,
          "name": "Welcome",
          "template": 7,
          "uuid": "FirstFloorMasterBedroomWelcome"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#ffff00",
          "favourite": false,
          "name": "Good Morning",
          "template": 10,
          "uuid": "FirstFloorMasterBedroomGoodMorning"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#ff3232",
          "favourite": false,
          "name": "Evening",
          "template": 9,
          "uuid": "FirstFloorMasterBedroomEvening"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#007dff",
          "favourite": false,
          "name": "Relax",
          "template": 3,
          "uuid": "FirstFloorMasterBedroomRelax"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "r",
            "name": "r",
            "template": "r",
            "learn": "x"
          },
          "colour": "#0000ff",
          "favourite": false,
          "name": "On",
          "template": 255,
          "uuid": "FirstFloorMasterBedroomOn"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "r",
            "name": "r",
            "template": "r"
          },
          "colour": "#0000ff",
          "favourite": false,
          "name": "Off",
          "template": 256,
          "uuid": "FirstFloorMasterBedroomOff"
        }
      ]
    }
  },
  {
    "device": {
      "uuid": "MasterBathroomSceneController",
      "type": "sceneController",
      "name": "Room scene controller",
      "reachable": true,
      "capabilities": {
        "scene": {
          "add": "x"
        }
      },
      "favourites": [
        "FirstFloorMasterBathroomWelcome",
        "FirstFloorMasterBathroomGoodMorning",
        "FirstFloorMasterBathroomEvening",
        "FirstFloorMasterBathroomRelax"
      ],
      "scenes": [
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#00ff00",
          "favourite": false,
          "name": "Welcome",
          "template": 7,
          "uuid": "FirstFloorMasterBathroomWelcome"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#ffff00",
          "favourite": false,
          "name": "Good Morning",
          "template": 10,
          "uuid": "FirstFloorMasterBathroomGoodMorning"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#ff3232",
          "favourite": false,
          "name": "Evening",
          "template": 9,
          "uuid": "FirstFloorMasterBathroomEvening"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#007dff",
          "favourite": false,
          "name": "Relax",
          "template": 3,
          "uuid": "FirstFloorMasterBathroomRelax"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "r",
            "name": "r",
            "template": "r",
            "learn": "x"
          },
          "colour": "#0000ff",
          "favourite": false,
          "name": "On",
          "template": 255,
          "uuid": "FirstFloorMasterBathroomOn"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "r",
            "name": "r",
            "template": "r"
          },
          "colour": "#0000ff",
          "favourite": false,
          "name": "Off",
          "template": 256,
          "uuid": "FirstFloorMasterBathroomOff"
        }
      ]
    }
  },
  {
    "device": {
      "uuid": "BedroomJudySceneController",
      "type": "sceneController",
      "name": "Room scene controller",
      "reachable": true,
      "capabilities": {
        "scene": {
          "add": "x"
        }
      },
      "favourites": [
        "FirstFloorBedroomJudyWelcome",
        "FirstFloorBedroomJudyGoodMorning",
        "FirstFloorBedroomJudyEvening",
        "FirstFloorBedroomJudyRelax"
      ],
      "scenes": [
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#00ff00",
          "favourite": false,
          "name": "Welcome",
          "template": 7,
          "uuid": "FirstFloorBedroomJudyWelcome"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#ffff00",
          "favourite": false,
          "name": "Good Morning",
          "template": 10,
          "uuid": "FirstFloorBedroomJudyGoodMorning"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#ff3232",
          "favourite": false,
          "name": "Evening",
          "template": 9,
          "uuid": "FirstFloorBedroomJudyEvening"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#007dff",
          "favourite": false,
          "name": "Relax",
          "template": 3,
          "uuid": "FirstFloorBedroomJudyRelax"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "r",
            "name": "r",
            "template": "r",
            "learn": "x"
          },
          "colour": "#0000ff",
          "favourite": false,
          "name": "On",
          "template": 255,
          "uuid": "FirstFloorBedroomJudyOn"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "r",
            "name": "r",
            "template": "r"
          },
          "colour": "#0000ff",
          "favourite": false,
          "name": "Off",
          "template": 256,
          "uuid": "FirstFloorBedroomJudyOff"
        }
      ]
    }
  },
  {
    "device": {
      "uuid": "BedroomElroySceneController",
      "type": "sceneController",
      "name": "Room scene controller",
      "reachable": true,
      "capabilities": {
        "scene": {
          "add": "x"
        }
      },
      "favourites": [
        "FirstFloorBedroomElroyWelcome",
        "FirstFloorBedroomElroyGoodMorning",
        "FirstFloorBedroomElroyEvening",
        "FirstFloorBedroomElroyRelax"
      ],
      "scenes": [
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#00ff00",
          "favourite": false,
          "name": "Welcome",
          "template": 7,
          "uuid": "FirstFloorBedroomElroyWelcome"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#ffff00",
          "favourite": false,
          "name": "Good Morning",
          "template": 10,
          "uuid": "FirstFloorBedroomElroyGoodMorning"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#ff3232",
          "favourite": false,
          "name": "Evening",
          "template": 9,
          "uuid": "FirstFloorBedroomElroyEvening"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#007dff",
          "favourite": false,
          "name": "Relax",
          "template": 3,
          "uuid": "FirstFloorBedroomElroyRelax"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "r",
            "name": "r",
            "template": "r",
            "learn": "x"
          },
          "colour": "#0000ff",
          "favourite": false,
          "name": "On",
          "template": 255,
          "uuid": "FirstFloorBedroomElroyOn"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "r",
            "name": "r",
            "template": "r"
          },
          "colour": "#0000ff",
          "favourite": false,
          "name": "Off",
          "template": 256,
          "uuid": "FirstFloorBedroomElroyOff"
        }
      ]
    }
  },
  {
    "device": {
      "uuid": "BathroomChildrenSceneController",
      "type": "sceneController",
      "name": "Room scene controller",
      "reachable": true,
      "capabilities": {
        "scene": {
          "add": "x"
        }
      },
      "favourites": [
        "FirstFloorBathroomChildrenWelcome",
        "FirstFloorBathroomChildrenGoodMorning",
        "FirstFloorBathroomChildrenEvening",
        "FirstFloorBathroomChildrenRelax"
      ],
      "scenes": [
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#00ff00",
          "favourite": false,
          "name": "Welcome",
          "template": 7,
          "uuid": "FirstFloorBathroomChildrenWelcome"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#ffff00",
          "favourite": false,
          "name": "Good Morning",
          "template": 10,
          "uuid": "FirstFloorBathroomChildrenGoodMorning"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#ff3232",
          "favourite": false,
          "name": "Evening",
          "template": 9,
          "uuid": "FirstFloorBathroomChildrenEvening"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#007dff",
          "favourite": false,
          "name": "Relax",
          "template": 3,
          "uuid": "FirstFloorBathroomChildrenRelax"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "r",
            "name": "r",
            "template": "r",
            "learn": "x"
          },
          "colour": "#0000ff",
          "favourite": false,
          "name": "On",
          "template": 255,
          "uuid": "FirstFloorBathroomChildrenOn"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "r",
            "name": "r",
            "template": "r"
          },
          "colour": "#0000ff",
          "favourite": false,
          "name": "Off",
          "template": 256,
          "uuid": "FirstFloorBathroomChildrenOff"
        }
      ]
    }
  },
  {
    "device": {
      "uuid": "GuestRoomSceneController",
      "type": "sceneController",
      "name": "Room scene controller",
      "reachable": true,
      "capabilities": {
        "scene": {
          "add": "x"
        }
      },
      "favourites": [
        "FirstFloorGuestRoomWelcome",
        "FirstFloorGuestRoomGoodMorning",
        "FirstFloorGuestRoomEvening",
        "FirstFloorGuestRoomRelax"
      ],
      "scenes": [
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#00ff00",
          "favourite": false,
          "name": "Welcome",
          "template": 7,
          "uuid": "FirstFloorGuestRoomWelcome"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#ffff00",
          "favourite": false,
          "name": "Good Morning",
          "template": 10,
          "uuid": "FirstFloorGuestRoomGoodMorning"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#ff3232",
          "favourite": false,
          "name": "Evening",
          "template": 9,
          "uuid": "FirstFloorGuestRoomEvening"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#007dff",
          "favourite": false,
          "name": "Relax",
          "template": 3,
          "uuid": "FirstFloorGuestRoomRelax"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "r",
            "name": "r",
            "template": "r",
            "learn": "x"
          },
          "colour": "#0000ff",
          "favourite": false,
          "name": "On",
          "template": 255,
          "uuid": "FirstFloorGuestRoomOn"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "r",
            "name": "r",
            "template": "r"
          },
          "colour": "#0000ff",
          "favourite": false,
          "name": "Off",
          "template": 256,
          "uuid": "FirstFloorGuestRoomOff"
        }
      ]
    }
  },
  {
    "device": {
      "uuid": "HomeCinemaSceneController",
      "type": "sceneController",
      "name": "Room scene controller",
      "reachable": true,
      "capabilities": {
        "scene": {
          "add": "x"
        }
      },
      "favourites": [
        "BasementHomeCinemaWelcome",
        "BasementHomeCinemaMovie",
        "",
        ""
      ],
      "scenes": [
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#00ff00",
          "favourite": false,
          "name": "Welcome",
          "template": 7,
          "uuid": "BasementHomeCinemaWelcome"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#ff3232",
          "favourite": false,
          "name": "Movie",
          "template": 11,
          "uuid": "BasementHomeCinemaMovie"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "r",
            "name": "r",
            "template": "r",
            "learn": "x"
          },
          "colour": "#0000ff",
          "favourite": false,
          "name": "On",
          "template": 255,
          "uuid": "BasementHomeCinemaOn"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "r",
            "name": "r",
            "template": "r"
          },
          "colour": "#0000ff",
          "favourite": false,
          "name": "Off",
          "template": 256,
          "uuid": "BasementHomeCinemaOff"
        }
      ]
    }
  },
  {
    "device": {
      "uuid": "FitnessSceneController",
      "type": "sceneController",
      "name": "Room scene controller",
      "reachable": true,
      "capabilities": {
        "scene": {
          "add": "x"
        }
      },
      "favourites": [
        "BasementFitnessWelcome",
        "BasementFitnessWorkout",
        "BasementFitnessRelax",
        ""
      ],
      "scenes": [
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#00ff00",
          "favourite": false,
          "name": "Welcome",
          "template": 7,
          "uuid": "BasementFitnessWelcome"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#ff3232",
          "favourite": false,
          "name": "Workout",
          "template": 12,
          "uuid": "BasementFitnessWorkout"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#007dff",
          "favourite": false,
          "name": "Relax",
          "template": 3,
          "uuid": "BasementFitnessRelax"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "r",
            "name": "r",
            "template": "r",
            "learn": "x"
          },
          "colour": "#0000ff",
          "favourite": false,
          "name": "On",
          "template": 255,
          "uuid": "BasementFitnessOn"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "r",
            "name": "r",
            "template": "r"
          },
          "colour": "#0000ff",
          "favourite": false,
          "name": "Off",
          "template": 256,
          "uuid": "BasementFitnessOff"
        }
      ]
    }
  },
  {
    "device": {
      "uuid": "TerraceSceneController",
      "type": "sceneController",
      "name": "Room scene controller",
      "reachable": true,
      "capabilities": {
        "scene": {
          "add": "x"
        }
      },
      "favourites": [
        "GardenTerraceWelcome",
        "GardenTerraceParty",
        "",
        ""
      ],
      "scenes": [
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#00ff00",
          "favourite": false,
          "name": "Welcome",
          "template": 7,
          "uuid": "GardenTerraceWelcome"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#ff3232",
          "favourite": false,
          "name": "Party",
          "template": 4,
          "uuid": "GardenTerraceParty"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "r",
            "name": "r",
            "template": "r",
            "learn": "x"
          },
          "colour": "#0000ff",
          "favourite": false,
          "name": "On",
          "template": 255,
          "uuid": "GardenTerraceOn"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "r",
            "name": "r",
            "template": "r"
          },
          "colour": "#0000ff",
          "favourite": false,
          "name": "Off",
          "template": 256,
          "uuid": "GardenTerraceOff"
        }
      ]
    }
  },
  {
    "device": {
      "uuid": "PoolSceneController",
      "type": "sceneController",
      "name": "Room scene controller",
      "reachable": true,
      "capabilities": {
        "scene": {
          "add": "x"
        }
      },
      "favourites": [
        "GardenPoolWelcome",
        "GardenPoolParty",
        "",
        ""
      ],
      "scenes": [
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#00ff00",
          "favourite": false,
          "name": "Welcome",
          "template": 7,
          "uuid": "GardenPoolWelcome"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "learn": "x"
          },
          "colour": "#ff3232",
          "favourite": false,
          "name": "Party",
          "template": 4,
          "uuid": "GardenPoolParty"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "r",
            "name": "r",
            "template": "r",
            "learn": "x"
          },
          "colour": "#0000ff",
          "favourite": false,
          "name": "On",
          "template": 255,
          "uuid": "GardenPoolOn"
        },
        {
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "favourite": "r",
            "name": "r",
            "template": "r"
          },
          "colour": "#0000ff",
          "favourite": false,
          "name": "Off",
          "template": 256,
          "uuid": "GardenPoolOff"
        }
      ]
    }
  },
  {
    "device": {
      "uuid": "HomeSceneController",
      "type": "sceneController",
      "name": "Home Scene controller",
      "reachable": true,
      "capabilities": {
        "job": {
          "add": "x"
        },
        "scene": {
          "add": "x"
        }
      },
      "favourites": [
        "InternalGoodNight",
        "InternalHome",
        "InternalWorkout"
      ],
      "scenes": [
        {
          "uuid": "InternalGoodNight",
          "name": "Good night",
          "template": 13,
          "order": 0,
          "favourite": false,
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "content": "rw",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "remove": "x"
          },
          "content": [
            {
              "target": {
                "areaUuid": "GroundFloorLivingRoom",
                "lights": {
                  "LivingRoomDownLightsCabinet": {
                    "onOff": false,
                    "brightness": 0
                  },
                  "LivingRoomDownLightsCenter": {
                    "onOff": false,
                    "brightness": 0
                  },
                  "LivingRoomDownLightsWall": {
                    "onOff": false,
                    "brightness": 0
                  },
                  "LivingRoomDownLightsWindow": {
                    "onOff": false,
                    "brightness": 0
                  }
                }
              }
            },
            {
              "target": {
                "areaUuid": "GroundFloorBar",
                "lights": {
                  "BarDownLightsCenter": {
                    "onOff": true,
                    "brightness": 0
                  }
                }
              }
            },
            {
              "delay": 10
            },
            {
              "target": {
                "areaUuid": "GroundFloorLivingRoom",
                "shades": {
                  "LivingRoomCenter": {
                    "autoMode": false
                  },
                  "LivingRoomLeft": {
                    "autoMode": false,
                    "isClosed": false
                  },
                  "LivingRoomRight": {
                    "autoMode": false,
                    "isClosed": false
                  }
                }
              }
            },
            {
              "target": {
                "areaUuid": "GroundFloorDiningRoom",
                "audio": {
                  "sourceUuid": "",
                  "volume": 0
                }
              }
            },
            {
              "target": {
                "areaUuid": "GroundFloorDiningRoom",
                "thermostat": {
                  "uuid": "DiningRoomThermostat",
                  "thermostatMode": 8,
                  "fanMode": 4,
                  "setPoint": {
                    "_celsius": 21,
                    "_fahrenheit": 69.80000000000001,
                    "_kelvin": 294.15
                  }
                }
              }
            }
          ]
        },
        {
          "uuid": "InternalGoodMorning",
          "name": "Good morning",
          "template": 10,
          "order": 1,
          "favourite": false,
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "content": "rw",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "remove": "x"
          },
          "content": [
            {
              "target": {
                "areaUuid": "GroundFloorHall",
                "thermostat": {
                  "uuid": "HallThermostat",
                  "thermostatMode": 8,
                  "fanMode": 4,
                  "setPoint": {
                    "_celsius": 21.5,
                    "_fahrenheit": 70.69999999999999,
                    "_kelvin": 294.65
                  }
                }
              }
            },
            {
              "delay": 3
            },
            {
              "target": {
                "areaUuid": "GroundFloorBar",
                "lights": {
                  "BarDownLightsCenter": {
                    "onOff": true,
                    "brightness": 0
                  }
                }
              }
            },
            {
              "target": {
                "areaUuid": "FirstFloorHomeOffice",
                "shades": {
                  "HomeOfficeCurtain": {
                    "autoMode": false,
                    "isClosed": true
                  }
                }
              }
            },
            {
              "delay": 10
            },
            {
              "target": {
                "areaUuid": "GroundFloorBar",
                "thermostat": {
                  "uuid": "BarThermostat",
                  "thermostatMode": 8,
                  "fanMode": 4,
                  "setPoint": {
                    "_celsius": 21,
                    "_fahrenheit": 69.80000000000001,
                    "_kelvin": 294.15
                  }
                }
              }
            }
          ]
        },
        {
          "uuid": "InternalHome",
          "name": "Home",
          "template": 1,
          "order": 2,
          "favourite": false,
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "content": "rw",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "remove": "x"
          },
          "content": [
            {
              "target": {
                "areaUuid": "GroundFloorHall",
                "subscene": {
                  "uuid": "HallScenes",
                  "scene": "GroundFloorHallWelcome"
                }
              }
            },
            {
              "target": {
                "areaUuid": "GroundFloorFamilyRoom",
                "subscene": {
                  "uuid": "FamilyRoomScenes",
                  "scene": "GroundFloorFamilyRoomWelcome"
                }
              }
            },
            {
              "target": {
                "areaUuid": "FirstFloorMasterBedroom",
                "subscene": {
                  "uuid": "MasterBedroomScenes",
                  "scene": "FirstFloorMasterBedroomWelcome"
                }
              }
            },
            {
              "target": {
                "areaUuid": "BasementHomeCinema",
                "subscene": {
                  "uuid": "HomeCinemaScenes",
                  "scene": "BasementHomeCinemaWelcome"
                }
              }
            },
            {
              "target": {
                "areaUuid": "BasementFitness",
                "subscene": {
                  "uuid": "FitnessScenes",
                  "scene": "BasementFitnessWelcome"
                }
              }
            },
            {
              "target": {
                "areaUuid": "GroundFloorKitchen",
                "subscene": {
                  "uuid": "KitchenScenes",
                  "scene": "GroundFloorKitchenWelcome"
                }
              }
            }
          ]
        },
        {
          "uuid": "InternalRelax",
          "name": "Relax",
          "template": 3,
          "order": 3,
          "favourite": false,
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "content": "rw",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "remove": "x"
          },
          "content": [
            {
              "target": {
                "areaUuid": "GroundFloorFamilyRoom",
                "subscene": {
                  "uuid": "FamilyRoomScenes",
                  "scene": "GroundFloorFamilyRoomRelax"
                }
              }
            },
            {
              "target": {
                "areaUuid": "GroundFloorDiningRoom",
                "audio": {
                  "sourceUuid": "sourceJane",
                  "volume": 25
                }
              }
            },
            {
              "delay": 7
            },
            {
              "target": {
                "areaUuid": "GroundFloorBar",
                "lights": {
                  "BarDownLightsCenter": {
                    "onOff": true,
                    "brightness": 34
                  }
                }
              }
            },
            {
              "target": {
                "areaUuid": "GroundFloorLivingRoom",
                "shades": {
                  "LivingRoomCenter": {
                    "autoMode": false,
                    "isClosed": false
                  },
                  "LivingRoomLeft": {
                    "autoMode": false,
                    "isClosed": false
                  },
                  "LivingRoomRight": {
                    "autoMode": false,
                    "isClosed": false
                  }
                }
              }
            }
          ]
        },
        {
          "uuid": "InternalDinner",
          "name": "Dinner",
          "template": 6,
          "order": 4,
          "favourite": false,
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "content": "rw",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "remove": "x"
          },
          "content": [
            {
              "target": {
                "areaUuid": "GroundFloorKitchen",
                "subscene": {
                  "uuid": "KitchenScenes",
                  "scene": "GroundFloorKitchenDinner"
                }
              }
            },
            {
              "target": {
                "areaUuid": "GroundFloorDiningRoom",
                "lights": {
                  "DiningRoomChandelier": {
                    "onOff": true,
                    "brightness": 31
                  },
                  "DiningRoomDownLightsLeft": {
                    "onOff": true,
                    "brightness": 31
                  },
                  "DiningRoomDownLightsRight": {
                    "onOff": true,
                    "brightness": 31
                  },
                  "DiningRoomWallLight": {
                    "onOff": true,
                    "brightness": 31
                  }
                }
              }
            },
            {
              "delay": 10
            },
            {
              "target": {
                "areaUuid": "GroundFloorFamilyRoom",
                "audio": {
                  "sourceUuid": "SourceTVFamily",
                  "volume": 25
                }
              }
            },
            {
              "target": {
                "areaUuid": "GroundFloorBar",
                "subscene": {
                  "uuid": "BarScenes",
                  "scene": "GroundFloorBarRelax"
                }
              }
            }
          ]
        },
        {
          "uuid": "InternalWorkout",
          "name": "Workout",
          "template": 12,
          "order": 5,
          "favourite": false,
          "capabilities": {
            "activate": "x",
            "colour": "r",
            "content": "rw",
            "favourite": "rw",
            "name": "rw",
            "template": "rw",
            "remove": "x"
          },
          "content": [
            {
              "target": {
                "areaUuid": "BasementFitness",
                "subscene": {
                  "uuid": "FitnessScenes",
                  "scene": "BasementFitnessWorkout"
                }
              }
            },
            {
              "target": {
                "areaUuid": "GroundFloorHall",
                "lights": {
                  "HallDownLightArt": {
                    "onOff": false,
                    "brightness": 0
                  },
                  "HallDownLightsFront": {
                    "onOff": false,
                    "brightness": 0
                  },
                  "HallPendantLight": {
                    "onOff": false,
                    "brightness": 0
                  },
                  "HallStaircase": {
                    "onOff": true,
                    "brightness": 0
                  }
                }
              }
            },
            {
              "target": {
                "areaUuid": "GroundFloorHall",
                "audio": {
                  "sourceUuid": "",
                  "volume": 25
                }
              }
            },
            {
              "target": {
                "areaUuid": "BasementFitness",
                "thermostat": {
                  "uuid": "FitnessThermostat",
                  "fanMode": 4
                }
              }
            }
          ]
        }
      ],
      "jobs": [
        {
          "uuid": "job1",
          "capabilities": {
            "enable": "rw",
            "name": "rw",
            "remove": "x",
            "scene": "rw",
            "time": "rw"
          },
          "enabled": true,
          "scene": "InternalGoodNight",
          "type": "repeated",
          "hour": 23,
          "minutes": 0,
          "sunday": true,
          "monday": true,
          "tuesday": true,
          "wednesday": true,
          "thursday": true,
          "friday": false,
          "saturday": false
        },
        {
          "uuid": "job2",
          "capabilities": {
            "enable": "rw",
            "name": "rw",
            "remove": "x",
            "scene": "rw",
            "time": "rw"
          },
          "enabled": false,
          "scene": "InternalGoodMorning",
          "type": "datetime",
          "datetime": 1546326000
        }
      ]
    }
  },
  {
    "device": {
      "capabilities": {
        "open": "x",
        "close": "x",
        "stop": "x",
        "openClose": "r"
      },
      "location": 5,
      "name": "",
      "reachable": true,
      "state": {
        "openClose": "open",
        "position": 0.60
      },
      "subType": 1,
      "type": "windowTreatment",
      "uuid": "LivingRoomLeft"
    }
  },
  {
    "device": {
      "capabilities": {
        "open": "x",
        "close": "x",
        "stop": "x",
        "openClose": "r",
        "position": "rw",
        "rotation": "rw"
      },
      "location": 9,
      "name": "",
      "reachable": true,
      "state": {
        "openClose": "open",
        "position": 0.10
      },
      "subType": 1,
      "type": "windowTreatment",
      "uuid": "LivingRoomCenter"
    }
  },
  {
    "device": {
      "capabilities": {
        "open": "x",
        "close": "x",
        "openClose": "r",
        "rotation": "rw"
      },
      "location": 6,
      "name": "",
      "reachable": true,
      "state": {
        "openClose": "open",
        "position": 0.10
      },
      "subType": 1,
      "type": "windowTreatment",
      "uuid": "LivingRoomRight"
    }
  },
  {
    "device": {
      "capabilities": {
        "open": "x",
        "close": "x",
        "stop": "x",
        "openClose": "r",
        "position": "rw"
      },
      "location": 5,
      "name": "",
      "reachable": true,
      "state": {
        "openClose": "open",
        "position": 0.40
      },
      "subType": 1,
      "type": "windowTreatment",
      "uuid": "DiningRoomLeft"
    }
  },
  {
    "device": {
      "capabilities": {
        "open": "x",
        "close": "x",
        "stop": "x",
        "openClose": "r",
        "position": "rw"
      },
      "location": 6,
      "name": "",
      "reachable": true,
      "state": {
        "openClose": "open",
        "position": 0.40
      },
      "subType": 1,
      "type": "windowTreatment",
      "uuid": "DiningRoomRight"
    }
  },
  {
    "device": {
      "capabilities": {},
      "controls": [
        {
          "capabilities": {
            "value": "r"
          },
          "attributes": {
            "labels": {
              "units": "ppm"
            }
          },
          "name": "Level",
          "state": {
            "value": 892
          },
          "type": "number",
          "uuid": "CO2Level"
        },
        {
          "capabilities": {
            "value": "rw"
          },
          "attributes": {
            "min": 700,
            "max": 1200,
            "precision": 5,
            "labels": {
              "units": "ppm"
            },
            "inputTypes": [
              "keypad",
              "slider"
            ]
          },
          "name": "Threshold",
          "state": {
            "value": 800
          },
          "type": "numericInput",
          "uuid": "CO2Threshold"
        },
        {
          "capabilities": {
            "value": "rw"
          },
          "name": "Alarm",
          "state": {
            "value": true
          },
          "type": "toggle",
          "uuid": "CO2Alarm"
        }
      ],
      "name": "CO2 Meter",
      "reachable": true,
      "type": "generic",
      "uuid": "diningRoomC02Threshold"
    }
  },
  {
    "device": {
      "capabilities": {
        "open": "x",
        "close": "x",
        "stop": "x",
        "openClose": "r",
        "position": "rw"
      },
      "location": 0,
      "name": "",
      "reachable": true,
      "state": {
        "openClose": "open",
        "position": 0.60
      },
      "subType": 6,
      "type": "windowTreatment",
      "uuid": "KitchenCurtain"
    }
  },
  {
    "device": {
      "capabilities": {
        "open": "x",
        "close": "x",
        "stop": "x",
        "openClose": "r"
      },
      "location": 5,
      "name": "",
      "reachable": true,
      "state": {
        "openClose": "close"
      },
      "subType": 1,
      "type": "windowTreatment",
      "uuid": "FamilyRoomLeft"
    }
  },
  {
    "device": {
      "capabilities": {
        "open": "x",
        "close": "x",
        "stop": "x",
        "openClose": "r"
      },
      "location": 6,
      "name": "",
      "reachable": true,
      "state": {
        "openClose": "close"
      },
      "subType": 1,
      "type": "windowTreatment",
      "uuid": "FamilyRoomRight"
    }
  },
  {
    "device": {
      "capabilities": {
        "open": "x",
        "close": "x",
        "stop": "x",
        "openClose": "r",
        "position": "rw",
        "rotation": "rw"
      },
      "location": 0,
      "name": "",
      "reachable": true,
      "state": {
        "openClose": "open",
        "position": 0.40,
        "rotation": 0.20
      },
      "subType": 6,
      "type": "windowTreatment",
      "uuid": "HomeOfficeCurtain"
    }
  },
  {
    "device": {
      "capabilities": {
        "open": "x",
        "close": "x",
        "position": "rw"
      },
      "location": 0,
      "name": "",
      "reachable": true,
      "state": {
        "position": 0.50
      },
      "subType": 6,
      "type": "windowTreatment",
      "uuid": "MasterBedroomCurtain"
    }
  },
  {
    "device": {
      "capabilities": {
        "open": "x",
        "close": "x",
        "position": "rw"
      },
      "location": 0,
      "name": "Shutter",
      "reachable": true,
      "state": {
        "position": 0.70
      },
      "subType": 0,
      "type": "windowTreatment",
      "uuid": "MasterBedroomShutter"
    }
  },
  {
    "device": {
      "capabilities": {
        "open": "x",
        "close": "x",
        "position": "rw",
        "rotation": "rw"
      },
      "location": 5,
      "name": "",
      "reachable": true,
      "state": {
        "position": 0.60,
        "rotation": 0.30
      },
      "subType": 1,
      "type": "windowTreatment",
      "uuid": "MasterBathroomLeft"
    }
  },
  {
    "device": {
      "capabilities": {
        "open": "x",
        "close": "x",
        "position": "r",
        "rotation": "r"
      },
      "location": 6,
      "name": "",
      "reachable": true,
      "state": {
        "position": 0.60,
        "rotation": 0.30
      },
      "subType": 1,
      "type": "windowTreatment",
      "uuid": "MasterBathroomRight"
    }
  },
  {
    "device": {
      "capabilities": {
        "open": "x",
        "close": "x",
        "position": "rw"
      },
      "location": 0,
      "name": "",
      "reachable": true,
      "state": {
        "position": 0.80
      },
      "subType": 6,
      "type": "windowTreatment",
      "uuid": "BedroomJudyCurtain"
    }
  },
  {
    "device": {
      "capabilities": {
        "open": "x",
        "close": "x",
        "position": "rw"
      },
      "location": 0,
      "name": "Shutter",
      "reachable": true,
      "state": {
        "position": 0.40
      },
      "subType": 0,
      "type": "windowTreatment",
      "uuid": "BedroomJudyShutter"
    }
  },
  {
    "device": {
      "capabilities": {
        "open": "x",
        "close": "x",
        "openClose": "r"
      },
      "location": 1,
      "name": "",
      "reachable": true,
      "state": {
        "openClose": "close"
      },
      "subType": 6,
      "type": "windowTreatment",
      "uuid": "BedroomElroyCurtain"
    }
  },
  {
    "device": {
      "capabilities": {
        "open": "x",
        "close": "x",
        "stop": "x",
        "position": "rw",
        "rotation": "rw"
      },
      "location": 2,
      "name": "",
      "reachable": true,
      "state": {
        "position": 0.60,
        "rotation": 0.30
      },
      "subType": 1,
      "type": "windowTreatment",
      "uuid": "BedroomElroyShade"
    }
  },
  {
    "device": {
      "capabilities": {
        "open": "x",
        "close": "x",
        "stop": "x",
        "openClose": "r",
        "position": "rw",
        "rotation": "rw"
      },
      "location": 0,
      "name": "Shutter",
      "reachable": true,
      "state": {
        "openClose": "open",
        "position": 0.50,
        "rotation": 0.10
      },
      "subType": 0,
      "type": "windowTreatment",
      "uuid": "BedroomElroyShutter"
    }
  },
  {
    "device": {
      "capabilities": {
        "open": "x",
        "close": "x",
        "stop": "x",
        "openClose": "r",
        "position": "rw",
        "rotation": "rw"
      },
      "location": 0,
      "name": "",
      "reachable": true,
      "state": {
        "openClose": "open",
        "position": 0.30,
        "rotation": 0.60
      },
      "subType": 1,
      "type": "windowTreatment",
      "uuid": "BathroomChildrenShade"
    }
  },
  {
    "device": {
      "capabilities": {
        "open": "x",
        "close": "x",
        "position": "rw"
      },
      "location": 0,
      "name": "",
      "reachable": true,
      "state": {
        "position": 0.70
      },
      "subType": 6,
      "type": "windowTreatment",
      "uuid": "GuestRoomCurtain"
    }
  },
  {
    "device": {
      "capabilities": {
        "open": "x",
        "close": "x",
        "position": "rw"
      },
      "location": 0,
      "name": "Shutter",
      "reachable": true,
      "state": {
        "position": 0.50
      },
      "subType": 0,
      "type": "windowTreatment",
      "uuid": "GuestRoomShutter"
    }
  },
  {
    "device": {
      "capabilities": {
        "open": "x",
        "close": "x",
        "stop": "x",
        "openClose": "r",
        "position": "rw",
        "rotation": "rw"
      },
      "location": 0,
      "name": "Pool Shutter",
      "reachable": true,
      "state": {
        "openClose": "open",
        "position": 0.40,
        "rotation": 0.60
      },
      "subType": 0,
      "type": "windowTreatment",
      "uuid": "PoolPoolShutter"
    }
  },
  {
    "device": {
      "attributes": {
        "fanMode": [
          "off",
          "low",
          "mid",
          "high",
          "auto"
        ],
        "thermostatMode": [
          "auto",
          "off"
        ]
      },
      "capabilities": {
        "coolingActive": "r",
        "fanMode": "rw",
        "heatCoolMode": "r",
        "heatingActive": "r",
        "humidity": "r",
        "setpoint": "rw",
        "temperature": "r",
        "thermostatMode": "rw",
        "scheduler": "r",
        "schedulerV2": "rw"
      },
      "name": "Thermostat",
      "reachable": true,
      "scheduler": {
        "enabled": true,
        "monday": [
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 18000,
            "mode": "auto"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30600,
            "mode": "auto"
          },
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 59400,
            "mode": "auto"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 79200,
            "mode": "auto"
          }
        ],
        "tuesday": [
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 18000,
            "mode": "auto"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30600,
            "mode": "auto"
          },
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 59400,
            "mode": "auto"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 79200,
            "mode": "auto"
          }
        ],
        "wednesday": [
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 18000,
            "mode": "auto"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30600,
            "mode": "auto"
          },
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 59400,
            "mode": "auto"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 79200,
            "mode": "auto"
          }
        ],
        "thursday": [
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 18000,
            "mode": "auto"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30600,
            "mode": "auto"
          },
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 59400,
            "mode": "auto"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 79200,
            "mode": "auto"
          }
        ],
        "friday": [
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 18000,
            "mode": "auto"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30600,
            "mode": "auto"
          },
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 59400,
            "mode": "auto"
          },
          {
            "celsius": 19,
            "fahrenheit": 66,
            "time": 84600,
            "mode": "auto"
          }
        ],
        "saturday": [
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 27000,
            "mode": "auto"
          },
          {
            "celsius": 19,
            "fahrenheit": 66,
            "time": 84600,
            "mode": "auto"
          }
        ],
        "sunday": [
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 27000,
            "mode": "auto"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 79200,
            "mode": "auto"
          }
        ]
      },
      "state": {
        "coolingActive": false,
        "fanMode": "low",
        "heatCoolMode": "heating",
        "heatingActive": true,
        "humidity": 0.38,
        "setpoint": {
          "celsius": 20,
          "fahrenheit": 68
        },
        "temperature": {
          "celsius": 18,
          "fahrenheit": 64.4
        },
        "thermostatMode": "auto"
      },
      "type": "thermostat",
      "uuid": "HallThermostat"
    }
  },
  {
    "device": {
      "attributes": {
        "fanMode": [],
        "thermostatMode": [
          "cooling",
          "auto"
        ]
      },
      "capabilities": {
        "coolingActive": "r",
        "fanMode": "rw",
        "heatCoolMode": "r",
        "heatingActive": "r",
        "humidity": "r",
        "setpoint": "rw",
        "temperature": "r",
        "thermostatMode": "rw",
        "scheduler": "r",
        "schedulerV2": "rw"
      },
      "name": "Ceiling ventilation",
      "reachable": true,
      "scheduler": {
        "enabled": true,
        "monday": [
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 18000,
            "mode": "heating"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30600,
            "mode": "heating"
          },
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 59400,
            "mode": "heating"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 79200,
            "mode": "heating"
          }
        ],
        "tuesday": [
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 18000,
            "mode": "heating"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30600,
            "mode": "heating"
          },
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 59400,
            "mode": "heating"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 79200,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 21000,
            "mode": "cooling"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 33600,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 53400,
            "mode": "cooling"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 73200,
            "mode": "cooling"
          }
        ],
        "wednesday": [
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 18000,
            "mode": "heating"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30600,
            "mode": "heating"
          },
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 59400,
            "mode": "heating"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 79200,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 21000,
            "mode": "cooling"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 33600,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 53400,
            "mode": "cooling"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 73200,
            "mode": "cooling"
          }
        ],
        "thursday": [
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 18000,
            "mode": "heating"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30600,
            "mode": "heating"
          },
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 59400,
            "mode": "heating"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 79200,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 21000,
            "mode": "cooling"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 33600,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 53400,
            "mode": "cooling"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 73200,
            "mode": "cooling"
          }
        ],
        "friday": [
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 18000,
            "mode": "heating"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30600,
            "mode": "heating"
          },
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 59400,
            "mode": "heating"
          },
          {
            "celsius": 19,
            "fahrenheit": 66,
            "time": 84600,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 21000,
            "mode": "cooling"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 33600,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 53400,
            "mode": "cooling"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 73200,
            "mode": "cooling"
          }
        ],
        "saturday": [
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 27000,
            "mode": "heating"
          },
          {
            "celsius": 19,
            "fahrenheit": 66,
            "time": 84600,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 21000,
            "mode": "cooling"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 33600,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 53400,
            "mode": "cooling"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 73200,
            "mode": "cooling"
          }
        ],
        "sunday": [
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 27000,
            "mode": "heating"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 79200,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 21000,
            "mode": "cooling"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 33600,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 53400,
            "mode": "cooling"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 73200,
            "mode": "cooling"
          }
        ]
      },
      "state": {
        "coolingActive": false,
        "fanMode": "auto",
        "heatCoolMode": "cooling",
        "heatingActive": false,
        "humidity": 0.41,
        "setpoint": {
          "celsius": 17,
          "fahrenheit": 60.8
        },
        "temperature": {
          "celsius": 20,
          "fahrenheit": 68
        },
        "thermostatMode": "cooling"
      },
      "controls": [],
      "type": "thermostat",
      "uuid": "LivingRoomThermostat1"
    }
  },
  {
    "device": {
      "attributes": {
        "fanMode": [
          "off",
          "auto"
        ],
        "thermostatMode": [
          "heating",
          "cooling",
          "auto"
        ]
      },
      "capabilities": {
        "coolingActive": "r",
        "fanMode": "rw",
        "heatCoolMode": "r",
        "heatingActive": "r",
        "humidity": "r",
        "setpoint": "rw",
        "temperature": "r",
        "thermostatMode": "rw",
        "scheduler": "r",
        "schedulerV2": "rw"
      },
      "name": "Floor heating",
      "reachable": true,
      "scheduler": {
        "enabled": true,
        "monday": [
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 18000,
            "mode": "heating"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30600,
            "mode": "heating"
          },
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 59400,
            "mode": "heating"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 79200,
            "mode": "heating"
          }
        ],
        "tuesday": [
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 18000,
            "mode": "heating"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30600,
            "mode": "heating"
          },
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 59400,
            "mode": "heating"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 79200,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 21000,
            "mode": "cooling"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 33600,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 53400,
            "mode": "cooling"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 73200,
            "mode": "cooling"
          }
        ],
        "wednesday": [
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 18000,
            "mode": "heating"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30600,
            "mode": "heating"
          },
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 59400,
            "mode": "heating"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 79200,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 21000,
            "mode": "cooling"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 33600,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 53400,
            "mode": "cooling"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 73200,
            "mode": "cooling"
          }
        ],
        "thursday": [
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 18000,
            "mode": "heating"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30600,
            "mode": "heating"
          },
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 59400,
            "mode": "heating"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 79200,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 21000,
            "mode": "cooling"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 33600,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 53400,
            "mode": "cooling"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 73200,
            "mode": "cooling"
          }
        ],
        "friday": [
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 18000,
            "mode": "heating"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30600,
            "mode": "heating"
          },
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 59400,
            "mode": "heating"
          },
          {
            "celsius": 19,
            "fahrenheit": 66,
            "time": 84600,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 21000,
            "mode": "cooling"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 33600,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 53400,
            "mode": "cooling"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 73200,
            "mode": "cooling"
          }
        ],
        "saturday": [
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 27000,
            "mode": "heating"
          },
          {
            "celsius": 19,
            "fahrenheit": 66,
            "time": 84600,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 21000,
            "mode": "cooling"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 33600,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 53400,
            "mode": "cooling"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 73200,
            "mode": "cooling"
          }
        ],
        "sunday": [
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 27000,
            "mode": "heating"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 79200,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 21000,
            "mode": "cooling"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 33600,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 53400,
            "mode": "cooling"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 73200,
            "mode": "cooling"
          }
        ]
      },
      "state": {
        "coolingActive": false,
        "fanMode": "auto",
        "heatCoolMode": "heating",
        "heatingActive": false,
        "humidity": 0.41,
        "setpoint": {
          "celsius": 21,
          "fahrenheit": 69.8
        },
        "temperature": {
          "celsius": 20,
          "fahrenheit": 68
        },
        "thermostatMode": "auto"
      },
      "controls": [
        {
          "capabilities": {
            "active": "rw"
          },
          "name": "",
          "state": {
            "active": true
          },
          "subType": 1,
          "type": "toggle",
          "uuid": "LivingRoomThermostatControl1"
        }
      ],
      "type": "thermostat",
      "uuid": "LivingRoomThermostat2"
    }
  },
  {
    "device": {
      "attributes": {
        "fanMode": [
          "off",
          "auto"
        ],
        "thermostatMode": [
          "heating",
          "cooling",
          "auto"
        ]
      },
      "capabilities": {
        "coolingActive": "r",
        "fanMode": "rw",
        "heatCoolMode": "r",
        "heatingActive": "r",
        "humidity": "r",
        "setpoint": "rw",
        "temperature": "r",
        "thermostatMode": "rw",
        "scheduler": "rw",
        "schedulerV2": "rw"
      },
      "name": "Main thermostat",
      "reachable": true,
      "scheduler": {
        "enabled": true,
        "monday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          }
        ],
        "tuesday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 33000,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "wednesday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 33000,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "thursday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 33000,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "friday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 33000,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "saturday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 35100,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },
          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 35400,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30000,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "sunday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 35100,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },
          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 35400,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30000,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ]
      },
      "state": {
        "coolingActive": true,
        "fanMode": "auto",
        "heatCoolMode": "cooling",
        "heatingActive": false,
        "humidity": 0.36,
        "setpoint": {
          "celsius": 21,
          "fahrenheit": 69.8
        },
        "temperature": {
          "celsius": 21.5,
          "fahrenheit": 70.7
        },
        "thermostatMode": "cooling"
      },
      "type": "thermostat",
      "uuid": "DiningRoomThermostat"
    }
  },
  {
    "device": {
      "attributes": {
        "fanMode": [
          "off",
          "low",
          "mid",
          "high",
          "auto"
        ],
        "thermostatMode": [
          "heating",
          "cooling",
          "auto"
        ]
      },
      "capabilities": {
        "coolingActive": "r",
        "fanMode": "rw",
        "heatCoolMode": "r",
        "heatingActive": "r",
        "humidity": "r",
        "setpoint": "rw",
        "temperature": "r",
        "thermostatMode": "rw",
        "scheduler": "rw",
        "schedulerV2": "rw"
      },
      "name": "Thermostat",
      "reachable": true,
      "scheduler": {
        "enabled": true,
        "monday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          }
        ],
        "tuesday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 33000,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "wednesday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 33000,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "thursday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 33000,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "friday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 33000,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "saturday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 35100,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },
          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 35400,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30000,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "sunday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 35100,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },
          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 35400,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30000,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ]
      },
      "state": {
        "coolingActive": true,
        "fanMode": "high",
        "heatCoolMode": "cooling",
        "heatingActive": false,
        "humidity": 0.32,
        "setpoint": {
          "celsius": 21,
          "fahrenheit": 69.8
        },
        "temperature": {
          "celsius": 23,
          "fahrenheit": 73.4
        },
        "thermostatMode": "cooling"
      },
      "type": "thermostat",
      "uuid": "KitchenThermostat"
    }
  },
  {
    "device": {
      "attributes": {
        "fanMode": [
          "off",
          "auto"
        ],
        "thermostatMode": [
          "heating",
          "cooling",
          "auto"
        ]
      },
      "capabilities": {
        "coolingActive": "r",
        "fanMode": "rw",
        "heatCoolMode": "r",
        "heatingActive": "r",
        "humidity": "r",
        "setpoint": "rw",
        "temperature": "r",
        "thermostatMode": "rw",
        "scheduler": "rw",
        "schedulerV2": "rw"
      },
      "name": "Ceiling ventilation",
      "reachable": true,
      "scheduler": {
        "enabled": true,
        "monday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          }
        ],
        "tuesday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 33000,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "wednesday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 33000,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "thursday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 33000,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "friday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 33000,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "saturday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 35100,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },
          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 35400,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30000,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "sunday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 35100,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },
          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 35400,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30000,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ]
      },
      "state": {
        "coolingActive": true,
        "fanMode": "auto",
        "heatCoolMode": "cooling",
        "heatingActive": true,
        "humidity": 0.50,
        "setpoint": {
          "celsius": 20,
          "fahrenheit": 69.8
        },
        "temperature": {
          "celsius": 20,
          "fahrenheit": 68
        },
        "thermostatMode": "cooling"
      },
      "type": "thermostat",
      "uuid": "FamilyRoomThermostat1"
    }
  },
  {
    "device": {
      "attributes": {
        "fanMode": [
          "off",
          "auto"
        ],
        "thermostatMode": [
          "heating",
          "cooling",
          "auto"
        ]
      },
      "capabilities": {
        "coolingActive": "r",
        "fanMode": "rw",
        "heatCoolMode": "r",
        "heatingActive": "r",
        "humidity": "r",
        "setpoint": "rw",
        "temperature": "r",
        "thermostatMode": "rw",
        "scheduler": "rw",
        "schedulerV2": "rw"
      },
      "name": "Floor heating",
      "reachable": true,
      "scheduler": {
        "enabled": true,
        "monday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          }
        ],
        "tuesday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 33000,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "wednesday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 33000,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "thursday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 33000,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "friday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 33000,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "saturday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 35100,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },
          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 35400,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30000,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "sunday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 35100,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },
          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 35400,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30000,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ]
      },
      "state": {
        "coolingActive": false,
        "fanMode": "auto",
        "heatCoolMode": "heating",
        "heatingActive": true,
        "humidity": 0.50,
        "setpoint": {
          "celsius": 22,
          "fahrenheit": 69.8
        },
        "temperature": {
          "celsius": 20,
          "fahrenheit": 68
        },
        "thermostatMode": "cooling"
      },
      "type": "thermostat",
      "uuid": "FamilyRoomThermostat2"
    }
  },
  {
    "device": {
      "attributes": {
        "fanMode": [
          "off",
          "low",
          "mid",
          "high",
          "auto"
        ],
        "thermostatMode": [
          "auto",
          "off"
        ]
      },
      "capabilities": {
        "coolingActive": "r",
        "fanMode": "rw",
        "heatCoolMode": "r",
        "heatingActive": "r",
        "humidity": "r",
        "setpoint": "rw",
        "temperature": "r",
        "thermostatMode": "rw",
        "scheduler": "rw",
        "schedulerV2": "rw"
      },
      "name": "Thermostat",
      "reachable": true,
      "scheduler": {
        "enabled": true,
        "monday": [
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 18000,
            "mode": "auto"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30600,
            "mode": "auto"
          },
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 59400,
            "mode": "auto"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 79200,
            "mode": "auto"
          }
        ],
        "tuesday": [
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 18000,
            "mode": "auto"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30600,
            "mode": "auto"
          },
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 59400,
            "mode": "auto"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 79200,
            "mode": "auto"
          }
        ],
        "wednesday": [
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 18000,
            "mode": "auto"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30600,
            "mode": "auto"
          },
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 59400,
            "mode": "auto"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 79200,
            "mode": "auto"
          }
        ],
        "thursday": [
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 18000,
            "mode": "auto"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30600,
            "mode": "auto"
          },
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 59400,
            "mode": "auto"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 79200,
            "mode": "auto"
          }
        ],
        "friday": [
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 18000,
            "mode": "auto"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30600,
            "mode": "auto"
          },
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 59400,
            "mode": "auto"
          },
          {
            "celsius": 19,
            "fahrenheit": 66,
            "time": 84600,
            "mode": "auto"
          }
        ],
        "saturday": [
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 27000,
            "mode": "auto"
          },
          {
            "celsius": 19,
            "fahrenheit": 66,
            "time": 84600,
            "mode": "auto"
          }
        ],
        "sunday": [
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 27000,
            "mode": "auto"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 79200,
            "mode": "auto"
          }
        ]
      },
      "state": {
        "coolingActive": false,
        "fanMode": "mid",
        "heatCoolMode": "",
        "heatingActive": false,
        "humidity": 0.43,
        "setpoint": {
          "celsius": 21,
          "fahrenheit": 69.8
        },
        "temperature": {
          "celsius": 21,
          "fahrenheit": 69.8
        },
        "thermostatMode": "auto"
      },
      "type": "thermostat",
      "uuid": "BarThermostat"
    }
  },
  {
    "device": {
      "attributes": {
        "fanMode": [
          "off",
          "auto"
        ],
        "thermostatMode": [
          "auto",
          "off",
          "fanOnly"
        ],
        "louverMode" : [
          "30degrees",
          "45degrees",
          "auto"
        ]
      },
      "capabilities": {
        "coolingActive": "r",
        "fanMode": "rw",
        "heatCoolMode": "r",
        "heatingActive": "r",
        "humidity": "r",
        "setpoint": "rw",
        "temperature": "r",
        "thermostatMode": "rw",
        "scheduler": "rw",
        "schedulerV2": "rw",
        "louverMode": "rw"
      },
      "name": "Thermostat",
      "reachable": true,
      "scheduler": {
        "enabled": true,
        "monday": [
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 18000,
            "mode": "auto"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30600,
            "mode": "auto"
          },
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 59400,
            "mode": "auto"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 79200,
            "mode": "auto"
          }
        ],
        "tuesday": [
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 18000,
            "mode": "auto"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30600,
            "mode": "auto"
          },
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 59400,
            "mode": "auto"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 79200,
            "mode": "auto"
          }
        ],
        "wednesday": [
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 18000,
            "mode": "auto"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30600,
            "mode": "auto"
          },
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 59400,
            "mode": "auto"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 79200,
            "mode": "auto"
          }
        ],
        "thursday": [
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 18000,
            "mode": "auto"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30600,
            "mode": "auto"
          },
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 59400,
            "mode": "auto"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 79200,
            "mode": "auto"
          }
        ],
        "friday": [
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 18000,
            "mode": "auto"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30600,
            "mode": "auto"
          },
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 59400,
            "mode": "auto"
          },
          {
            "celsius": 19,
            "fahrenheit": 66,
            "time": 84600,
            "mode": "auto"
          }
        ],
        "saturday": [
          {
            "celsius": 22,
            "fahrenheit": 70,
            "time": 27000,
            "mode": "auto"
          },
          {
            "celsius": 19,
            "fahrenheit": 66,
            "time": 79200,
            "mode": "auto"
          }
        ],
        "sunday": [
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 27000,
            "mode": "auto"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 79200,
            "mode": "auto"
          }
        ]
      },
      "state": {
        "coolingActive": false,
        "fanMode": "auto",
        "heatCoolMode": "heating",
        "heatingActive": true,
        "humidity": 0.33,
        "setpoint": {
          "celsius": 21,
          "fahrenheit": 69.8
        },
        "temperature": {
          "celsius": 20,
          "fahrenheit": 68
        },
        "thermostatMode": "auto"
      },
      "type": "thermostat",
      "uuid": "HomeOfficeThermostat"
    }
  },
  {
    "device": {
      "attributes": {
        "fanMode": [
          "off",
          "low",
          "mid",
          "high",
          "auto"
        ],
        "thermostatMode": [
          "heating",
          "cooling",
          "auto",
          "off",
          "drying",
          "fanOnly"
        ],
        "louverMode": [
          "30degrees",
          "45degrees",
          "60degrees",
          "horizontal",
          "vertical",
          "auto",
          "off"
        ]
      },
      "capabilities": {
        "coolingActive": "r",
        "fanMode": "rw",
        "heatCoolMode": "r",
        "heatingActive": "r",
        "humidity": "r",
        "setpoint": "rw",
        "temperature": "r",
        "thermostatMode": "rw",
        "scheduler": "rw",
        "schedulerV2": "rw",
        "louverMode": "rw"
      },
      "name": "Thermostat",
      "reachable": true,
      "scheduler": {
        "enabled": true,
        "monday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          }
        ],
        "tuesday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 29100,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "wednesday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 29100,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "thursday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 29100,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "friday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 29100,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "saturday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 35100,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },
          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 35400,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30000,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "sunday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 35100,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },
          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 35400,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30000,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ]
      },
      "state": {
        "coolingActive": false,
        "fanMode": "off",
        "heatCoolMode": "",
        "heatingActive": false,
        "humidity": 0.40,
        "setpoint": {
          "celsius": 19,
          "fahrenheit": 66.2
        },
        "temperature": {
          "celsius": 19.5,
          "fahrenheit": 67.1
        },
        "thermostatMode": "auto"
      },
      "type": "thermostat",
      "uuid": "MasterBedroomThermostat"
    }
  },
  {
    "device": {
      "attributes": {
        "fanMode": [
          "off",
          "low",
          "mid",
          "high",
          "auto"
        ],
        "thermostatMode": [
          "heating",
          "cooling",
          "auto"
        ]
      },
      "capabilities": {
        "coolingActive": "r",
        "fanMode": "rw",
        "heatCoolMode": "r",
        "heatingActive": "r",
        "humidity": "r",
        "setpoint": "rw",
        "temperature": "r",
        "thermostatMode": "rw",
        "scheduler": "rw",
        "schedulerV2": "rw"
      },
      "name": "Thermostat",
      "reachable": true,
      "scheduler": {
        "enabled": true,
        "monday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          }
        ],
        "tuesday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 33000,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "wednesday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 33000,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "thursday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 33000,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "friday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 33000,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "saturday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 35100,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },
          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 35400,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30000,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "sunday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 35100,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },
          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 35400,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30000,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ]
      },
      "state": {
        "coolingActive": false,
        "fanMode": "high",
        "heatCoolMode": "heating",
        "heatingActive": false,
        "humidity": 0.39,
        "setpoint": {
          "celsius": 23,
          "fahrenheit": 73.4
        },
        "temperature": {
          "celsius": 23,
          "fahrenheit": 73.4
        },
        "thermostatMode": "heating"
      },
      "type": "thermostat",
      "uuid": "MasterBathroomThermostat"
    }
  },
  {
    "device": {
      "attributes": {
        "fanMode": [
          "off",
          "low",
          "mid",
          "high",
          "auto"
        ],
        "thermostatMode": [
          "heating",
          "cooling",
          "auto",
          "off"
        ]
      },
      "capabilities": {
        "coolingActive": "r",
        "fanMode": "",
        "heatCoolMode": "r",
        "heatingActive": "r",
        "humidity": "",
        "setpoint": "rw",
        "temperature": "r",
        "thermostatMode": "rw",
        "scheduler": "rw",
        "schedulerV2": "rw"
      },
      "name": "Thermostat",
      "reachable": true,
      "scheduler": {
        "enabled": true,
        "monday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          }
        ],
        "tuesday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 33000,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "wednesday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 33000,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "thursday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 33000,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "friday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 33000,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "saturday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 35100,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },
          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 35400,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30000,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "sunday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 35100,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },
          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 35400,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30000,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ]
      },
      "state": {
        "coolingActive": false,
        "fanMode": "low",
        "heatCoolMode": "cooling",
        "heatingActive": false,
        "humidity": 0.33,
        "setpoint": {
          "celsius": 20,
          "fahrenheit": 68
        },
        "temperature": {
          "celsius": 20,
          "fahrenheit": 68
        },
        "thermostatMode": "auto"
      },
      "type": "thermostat",
      "uuid": "BedroomJudyThermostat"
    }
  },
  {
    "device": {
      "attributes": {
        "fanMode": [
          "off",
          "low",
          "mid",
          "high",
          "auto"
        ],
        "thermostatMode": [
          "heating",
          "cooling",
          "auto",
          "off"
        ]
      },
      "capabilities": {
        "coolingActive": "r",
        "fanMode": "rw",
        "heatCoolMode": "r",
        "heatingActive": "r",
        "humidity": "r",
        "setpoint": "rw",
        "temperature": "r",
        "thermostatMode": "rw",
        "scheduler": "rw",
        "schedulerV2": "rw"
      },
      "name": "Thermostat",
      "reachable": true,
      "scheduler": {
        "enabled": true,
        "monday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 29100,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "tuesday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 29100,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "wednesday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 29100,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "thursday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 29100,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "friday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 29100,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "saturday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 35100
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600
          }
        ],
        "sunday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 35100
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600
          }
        ]
      },
      "state": {
        "coolingActive": true,
        "fanMode": "low",
        "heatCoolMode": "cooling",
        "heatingActive": false,
        "humidity": 0.45,
        "setpoint": {
          "celsius": 20,
          "fahrenheit": 68
        },
        "temperature": {
          "celsius": 21,
          "fahrenheit": 69.8
        },
        "thermostatMode": "auto"
      },
      "type": "thermostat",
      "uuid": "BedroomElroyThermostat"
    }
  },
  {
    "device": {
      "attributes": {
        "fanMode": [
          "off",
          "low",
          "mid",
          "high",
          "auto"
        ],
        "thermostatMode": [
          "heating",
          "cooling",
          "auto"
        ]
      },
      "capabilities": {
        "coolingActive": "r",
        "fanMode": "rw",
        "heatCoolMode": "r",
        "heatingActive": "r",
        "humidity": "r",
        "setpoint": "rw",
        "temperature": "r",
        "thermostatMode": "rw",
        "scheduler": "rw",
        "schedulerV2": "rw"
      },
      "name": "Thermostat",
      "reachable": true,
      "scheduler": {
        "enabled": true,
        "monday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          }
        ],
        "tuesday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 33000,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "wednesday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 33000,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "thursday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 33000,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "friday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 33000,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "saturday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 35100,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },
          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 35400,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30000,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "sunday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 35100,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },
          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 35400,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30000,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ]
      },
      "state": {
        "coolingActive": false,
        "fanMode": "high",
        "heatCoolMode": "heating",
        "heatingActive": false,
        "humidity": 0.35,
        "setpoint": {
          "celsius": 23,
          "fahrenheit": 73.4
        },
        "temperature": {
          "celsius": 24,
          "fahrenheit": 75.2
        },
        "thermostatMode": "heating"
      },
      "type": "thermostat",
      "uuid": "BathroomChildrenThermostat"
    }
  },
  {
    "device": {
      "attributes": {
        "fanMode": [
          "off",
          "low",
          "mid",
          "high",
          "auto"
        ],
        "thermostatMode": [
          "heating",
          "cooling",
          "auto",
          "off"
        ]
      },
      "capabilities": {
        "coolingActive": "",
        "fanMode": "rw",
        "heatCoolMode": "",
        "heatingActive": "",
        "humidity": "",
        "setpoint": "",
        "temperature": "",
        "thermostatMode": "",
        "scheduler": ""
      },
      "name": "Thermostat",
      "reachable": true,
      "scheduler": {
        "enabled": true,
        "monday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600
          }
        ],
        "tuesday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600
          }
        ],
        "wednesday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600
          }
        ],
        "thursday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600
          }
        ],
        "friday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600
          }
        ],
        "saturday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 35100
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600
          }
        ],
        "sunday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 35100
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600
          }
        ]
      },
      "state": {
        "coolingActive": true,
        "fanMode": "off",
        "heatCoolMode": "",
        "heatingActive": false,
        "humidity": 0.43,
        "setpoint": {
          "celsius": 20,
          "fahrenheit": 68
        },
        "temperature": {
          "celsius": 21,
          "fahrenheit": 69.8
        },
        "thermostatMode": "auto"
      },
      "type": "thermostat",
      "uuid": "GuestRoomThermostat"
    }
  },
  {
    "device": {
      "attributes": {
        "fanMode": [
          "off",
          "low",
          "mid",
          "high",
          "auto"
        ],
        "thermostatMode": [
          "auto",
          "off"
        ]
      },
      "capabilities": {
        "coolingActive": "r",
        "fanMode": "rw",
        "heatCoolMode": "r",
        "heatingActive": "r",
        "humidity": "r",
        "setpoint": "rw",
        "temperature": "r",
        "thermostatMode": "rw",
        "scheduler": "rw",
        "schedulerV2": "rw"
      },
      "name": "Thermostat",
      "reachable": true,
      "scheduler": {
        "enabled": true,
        "monday": [
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 18000,
            "mode": "auto"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30600,
            "mode": "auto"
          },
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 59400,
            "mode": "auto"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 79200,
            "mode": "auto"
          }
        ],
        "tuesday": [
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 18000,
            "mode": "auto"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30600,
            "mode": "auto"
          },
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 59400,
            "mode": "auto"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 79200,
            "mode": "auto"
          }
        ],
        "wednesday": [
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 18000,
            "mode": "auto"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30600,
            "mode": "auto"
          },
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 59400,
            "mode": "auto"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 79200,
            "mode": "auto"
          }
        ],
        "thursday": [
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 18000,
            "mode": "auto"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30600,
            "mode": "auto"
          },
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 59400,
            "mode": "auto"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 79200,
            "mode": "auto"
          }
        ],
        "friday": [
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 18000,
            "mode": "auto"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30600,
            "mode": "auto"
          },
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 59400,
            "mode": "auto"
          },
          {
            "celsius": 19,
            "fahrenheit": 66,
            "time": 84600,
            "mode": "auto"
          }
        ],
        "saturday": [
          {
            "celsius": 22,
            "fahrenheit": 70,
            "time": 27000,
            "mode": "auto"
          },
          {
            "celsius": 19,
            "fahrenheit": 66,
            "time": 79200,
            "mode": "auto"
          }
        ],
        "sunday": [
          {
            "celsius": 21,
            "fahrenheit": 70,
            "time": 27000,
            "mode": "auto"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 79200,
            "mode": "auto"
          }
        ]
      },
      "state": {
        "coolingActive": false,
        "fanMode": "low",
        "heatCoolMode": "heating",
        "heatingActive": true,
        "humidity": 0.47,
        "setpoint": {
          "celsius": 22,
          "fahrenheit": 68
        },
        "temperature": {
          "celsius": 21,
          "fahrenheit": 69.8
        },
        "thermostatMode": "auto"
      },
      "type": "thermostat",
      "uuid": "HomeCinemaThermostat"
    }
  },
  {
    "device": {
      "attributes": {
        "fanMode": [
          "off",
          "low",
          "mid",
          "high",
          "auto"
        ],
        "thermostatMode": [
          "heating",
          "cooling",
          "auto"
        ]
      },
      "capabilities": {
        "coolingActive": "",
        "fanMode": "rw",
        "heatCoolMode": "",
        "heatingActive": "",
        "humidity": "r",
        "setpoint": "",
        "temperature": "r",
        "thermostatMode": "",
        "scheduler": "",
        "schedulerV2": "rw"
      },
      "name": "Thermostat",
      "reachable": true,
      "scheduler": {
        "enabled": true,
        "monday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          }
        ],
        "tuesday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 33000,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "wednesday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 33000,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "thursday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 33000,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "friday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 33000,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 28800,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "saturday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 40100,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60600,
            "mode": "heating"
          },
          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 35400,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30000,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 75600,
            "mode": "auto"
          }
        ],
        "sunday": [
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 40100,
            "mode": "heating"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 60000,
            "mode": "heating"
          },
          {
            "celsius": 21,
            "fahrenheit": 64,
            "time": 38400,
            "mode": "cooling"
          },
          {
            "celsius": 21,
            "fahrenheit": 68,
            "time": 72000,
            "mode": "cooling"
          },
          {
            "celsius": 18,
            "fahrenheit": 64,
            "time": 30000,
            "mode": "auto"
          },
          {
            "celsius": 20,
            "fahrenheit": 68,
            "time": 83000,
            "mode": "auto"
          }
        ]
      },
      "state": {
        "fanMode": "mid",
        "humidity": 0.52,
        "temperature": {
          "celsius": 22,
          "fahrenheit": 71.6
        }
      },
      "type": "thermostat",
      "uuid": "FitnessThermostat"
    }
  },
  {
    "device": {
      "uuid": "HomeEnergy",
      "name": "Main building",
      "type": "energyMeter",
      "subType": "energy",
      "capabilities": {
        "value": "r",
        "timestamp": "r",
        "oldestTimestamp": "r"
      },
      "resourceType": "consumer",
      "realTimeUnit": "W",
      "totalUnit": "Wh",
      "state": {
        "value": 2432,
        "timestamp": "2021-11-23T16:12:34+0100",
        "oldestTimestamp": "2021-05-23T16:12:34+0100"
      }
    }
  },
  {
    "device": {
      "uuid": "HomeEnergySolar",
      "name": "Solar",
      "type": "energyMeter",
      "subType": "energy",
      "capabilities": {
        "value": "r",
        "timestamp": "r",
        "oldestTimestamp": "r"
      },
      "resourceType": "generator",
      "realTimeUnit": "W",
      "totalUnit": "Wh",
      "state": {
        "value": -534,
        "timestamp": "2021-11-23T16:12:34+0100",
        "oldestTimestamp": "2021-05-23T16:12:34+0100"
      }
    }
  },
  {
    "device": {
      "uuid": "PoolEnergy",
      "name": "Pool house",
      "type": "energyMeter",
      "subType": "energy",
      "capabilities": {
        "value": "r",
        "timestamp": "r",
        "oldestTimestamp": "r"
      },
      "resourceType": "consumer",
      "realTimeUnit": "W",
      "totalUnit": "Wh",
      "state": {
        "value": 376,
        "timestamp": "2021-11-23T16:12:34+0100",
        "oldestTimestamp": "2021-05-23T16:12:34+0100"
      }
    }
  },
  {
    "device": {
      "uuid": "HomeEnergyGas",
      "name": "Main building",
      "type": "energyMeter",
      "subType": "gas",
      "capabilities": {
        "value": "r",
        "timestamp": "r",
        "oldestTimestamp": "r"
      },
      "resourceType": "consumer",
      "realTimeUnit": "m³/h",
      "totalUnit": "m³",
      "state": {
        "value": 4,
        "timestamp": "2021-11-23T16:12:34+0100",
        "oldestTimestamp": "2021-05-23T16:12:34+0100"
      }
    }
  },
  {
    "device": {
      "uuid": "HomeEnergyWater",
      "name": "Main building",
      "type": "energyMeter",
      "subType": "water",
      "capabilities": {
        "value": "r",
        "timestamp": "r",
        "oldestTimestamp": "r"
      },
      "resourceType": "consumer",
      "realTimeUnit": "l/h",
      "totalUnit": "l",
      "state": {
        "value": 14,
        "timestamp": "2021-11-23T16:12:34+0100",
        "oldestTimestamp": "2021-05-23T16:12:34+0100"
      }
    }
  },
  {
    "device": {
      "capabilities": {},
      "timers": [
        {
          "name": "Pool pump",
          "uuid": "HomePoolPump",
          "enabled": true,
          "type": "onOff",
          "monday": [
            {
              "time": 32400,
              "armed": true
            },
            {
              "time": 68400,
              "armed": false
            }
          ],
          "tuesday": [
            {
              "time": 32400,
              "armed": true
            },
            {
              "time": 68400,
              "armed": false
            }
          ],
          "wednesday": [
            {
              "time": 32400,
              "armed": true
            },
            {
              "time": 68400,
              "armed": false
            }
          ],
          "thursday": [
            {
              "time": 32400,
              "armed": true
            },
            {
              "time": 68400,
              "armed": false
            }
          ],
          "friday": [
            {
              "time": 32400,
              "armed": true
            },
            {
              "time": 73800,
              "armed": false
            }
          ],
          "saturday": [
            {
              "time": 18000,
              "armed": true
            },
            {
              "time": 73800,
              "armed": false
            }
          ],
          "sunday": [
            {
              "time": 18000,
              "armed": true
            },
            {
              "time": 68400,
              "armed": false
            }
          ]
        },
        {
          "name": "Fountain",
          "uuid": "HomeFountain",
          "enabled": true,
          "type": "onOff",
          "monday": [
            {
              "time": 63000,
              "armed": true
            },
            {
              "time": 75600,
              "armed": false
            }
          ],
          "tuesday": [
            {
              "time": 63000,
              "armed": true
            },
            {
              "time": 75600,
              "armed": false
            }
          ],
          "wednesday": [
            {
              "time": 63000,
              "armed": true
            },
            {
              "time": 75600,
              "armed": false
            }
          ],
          "thursday": [
            {
              "time": 63000,
              "armed": true
            },
            {
              "time": 75600,
              "armed": false
            }
          ],
          "friday": [
            {
              "time": 63000,
              "armed": true
            },
            {
              "time": 75600,
              "armed": false
            }
          ],
          "saturday": [
            {
              "time": 36000,
              "armed": true
            },
            {
              "time": 79200,
              "armed": false
            }
          ],
          "sunday": [
            {
              "time": 36000,
              "armed": true
            },
            {
              "time": 79200,
              "armed": false
            }
          ]
        },
        {
          "name": "Sprinklers",
          "uuid": "HomeSprinklers",
          "enabled": true,
          "type": "percentage",
          "monday": [
            {
              "time": 32400,
              "percentage": 0.5
            },
            {
              "time": 61200,
              "percentage": 0
            }
          ],
          "tuesday": [
            {
              "time": 32400,
              "percentage": 0.6
            },
            {
              "time": 61200,
              "percentage": 0
            }
          ],
          "wednesday": [
            {
              "time": 32400,
              "percentage": 0.5
            },
            {
              "time": 61200,
              "percentage": 0
            }
          ],
          "thursday": [
            {
              "time": 32400,
              "percentage": 0.6
            },
            {
              "time": 61200,
              "percentage": 0
            }
          ],
          "friday": [
            {
              "time": 32400,
              "percentage": 0.7
            },
            {
              "time": 61200,
              "percentage": 0
            }
          ],
          "saturday": [
            {
              "time": 21600,
              "percentage": 0.2
            },
            {
              "time": 39600,
              "percentage": 0
            }
          ],
          "sunday": [
            {
              "time": 21600,
              "percentage": 0.2
            },
            {
              "time": 39600,
              "percentage": 0
            }
          ]
        }
      ],
      "type": "timer",
      "uuid": "HomeTimers"
    }
  },
  {
    "device": {
      "capabilities": {},
      "controls": [
        {
          "capabilities": {
            "value": "rw"
          },
          "name": "On/Off",
          "state": {
            "value": true
          },
          "type": "toggle",
          "uuid": "FireplaceToggle"
        }
      ],
      "name": "Fireplace",
      "reachable": true,
      "type": "generic",
      "uuid": "LivingRoomFireplace"
    }
  },
  {
    "device": {
      "capabilities": {},
      "class": 128,
      "deviceType": 39,
      "type": "generic_v2",
      "nameType": "NAME_TYPE_SOCKET",
      "icon": "NONE",
      "uuid": "genericDeviceV2Sockets",
      "categories": [
        {
          "name": "Wall Sockets",
          "controls": [
            {
              "capabilities": {
                "value": "rw"
              },
              "name": "Lounge area",
              "state": {
                "value": true
              },
              "type": "toggle",
              "uuid": "LoungeAreaSockets"
            },
            {
              "capabilities": {
                "value": "rw"
              },
              "name": "Bar",
              "state": {
                "value": false
              },
              "type": "toggle",
              "uuid": "BarAreaSockets"
            }
          ]
        }
      ]
    }
  },
  {
    "device": {
      "capabilities": {},
      "class": 128,
      "deviceType": 39,
      "type": "generic_v2",
      "name": "Pool",
      "nameType": "NAME_TYPE_GARDEN",
      "icon": "NONE",
      "uuid": "genericDeviceV2Pool",
      "categories": [
        {
          "name": "Pool lighting",
          "controls": [
            {
              "capabilities": {
                "value": "rw"
              },
              "name": "Lighting",
              "state": {
                "value": 0.64
              },
              "type": "slider",
              "uuid": "PoolLighting"
            }
          ]
        },
        {
          "name": "Pool controls",
          "controls": [
            {
              "capabilities": {
                "value": "rw"
              },
              "name": "Heating",
              "state": {
                "value": true
              },
              "type": "toggle",
              "uuid": "PoolHeating"
            },
            {
              "capabilities": {
                "value": "rw"
              },
              "name": "Bubbles",
              "state": {
                "value": false
              },
              "type": "toggle",
              "uuid": "PoolBubbles"
            }
          ]
        }
      ]
    }
  },
  {
    "device": {
      "capabilities": {},
      "controls": [
        {
          "capabilities": {
            "value": "rw"
          },
          "name": "Front yard",
          "state": {
            "value": false
          },
          "type": "toggle",
          "uuid": "SprinklersFront"
        },
        {
          "capabilities": {
            "value": "rw"
          },
          "name": "Back yard",
          "state": {
            "value": false
          },
          "type": "toggle",
          "uuid": "SprinklersBack"
        }
      ],
      "name": "Sprinklers",
      "reachable": true,
      "type": "generic",
      "uuid": "TerraceSprinklers"
    }
  },
  {
    "device": {
      "capabilities": {},
      "controls": [
        {
          "capabilities": {
            "value": "rw"
          },
          "name": "Intensity",
          "state": {
            "value": 0.64
          },
          "type": "slider",
          "uuid": "FountainIntensity"
        },
        {
          "capabilities": {
            "value": "rw"
          },
          "name": "Smoke",
          "state": {
            "value": true
          },
          "type": "toggle",
          "uuid": "FountainSmoke"
        },
        {
          "capabilities": {
            "value": "rw"
          },
          "name": "Extra",
          "state": {
            "value": false
          },
          "type": "trigger",
          "uuid": "FountainTrigger"
        }
      ],
      "name": "Fountain",
      "reachable": true,
      "type": "generic",
      "uuid": "TerraceFountain"
    }
  },
  {
    "device": {
      "capabilities": {},
      "controls": [
        {
          "capabilities": {
            "value": "rw"
          },
          "name": "",
          "state": {
            "value": true
          },
          "type": "toggle",
          "uuid": "PoolCoverToggle"
        }
      ],
      "name": "Cover",
      "reachable": true,
      "type": "generic",
      "uuid": "PoolCover"
    }
  },
  {
    "device": {
      "capabilities": {
        "open": "x",
        "close": "x",
        "openClose": "r"
      },
      "name": "Front gate",
      "reachable": true,
      "state": {
        "openClose": "open"
      },
      "subType": 3,
      "type": "openClose",
      "uuid": "homeFrontGate"
    }
  },
  {
    "device": {
      "capabilities": {
        "open": "x",
        "close": "x",
        "openClose": "r"
      },
      "name": "Garden gate",
      "reachable": true,
      "state": {
        "openClose": "open"
      },
      "subType": 3,
      "type": "openClose",
      "uuid": "homeGardenGate"
    }
  },
  {
    "device": {
      "capabilities": {
        "open": "x",
        "close": "x",
        "stop": "x",
        "openClose": "r"
      },
      "name": "Garage",
      "reachable": true,
      "state": {
        "openClose": "close"
      },
      "subType": 1,
      "type": "openClose",
      "uuid": "homeGarage"
    }
  },
  {
    "device": {
      "capabilities": {
        "toggle": "x"
      },
      "name": "Front door",
      "reachable": true,
      "subType": 1,
      "type": "openClose",
      "uuid": "homeFrontDoor"
    }
  },
  {
    "device": {
      "capabilities": {
        "toggle": "x",
        "openClose": "r"
      },
      "name": "Back window",
      "reachable": true,
      "state": {
        "openClose": "close"
      },
      "subType": 2,
      "type": "openClose",
      "uuid": "homeBackWindow"
    }
  },
  {
    "device": {
      "capabilities": {
        "openClose": "r"
      },
      "name": "Panoramic window",
      "reachable": true,
      "state": {
        "openClose": "close"
      },
      "subType": 2,
      "type": "openClose",
      "uuid": "masterBedroomPanoramicWindow"
    }
  },
  {
    "device": {
      "capabilities": {
        "openClose": "r"
      },
      "name": "Side window",
      "reachable": true,
      "state": {
        "openClose": "open"
      },
      "subType": 2,
      "type": "openClose",
      "uuid": "masterBedroomWindowSide"
    }
  },
  {
    "device": {
      "capabilities": {
        "openClose": "r",
        "toggle": "x"
      },
      "name": "Skylight",
      "reachable": true,
      "state": {
        "openClose": "close"
      },
      "subType": 2,
      "type": "openClose",
      "uuid": "masterBedroomSkylight"
    }
  },
  {
    "device": {
      "capabilities": {
        "openClose": "r"
      },
      "name": "Window left",
      "reachable": true,
      "state": {
        "openClose": "close"
      },
      "subType": 2,
      "type": "openClose",
      "uuid": "kitchenWindowLeft"
    }
  },
  {
    "device": {
      "capabilities": {
        "openClose": "r"
      },
      "name": "Window right",
      "reachable": true,
      "state": {
        "openClose": "close"
      },
      "subType": 2,
      "type": "openClose",
      "uuid": "kitchenWindowRight"
    }
  },
  {
    "device":{
      "capabilities":{
        "airPressure":"r",
        "brightness":"r",
        "humidity":"r",
        "temperature":"r",
        "windSpeed": "r"
      },
      "name":"KNX Weather Station",
      "reachable":true,
      "state":{
        "airPressure": 103020.0,
        "brightness": 1153,
        "humidity": 0.52,
        "temperature": {
          "celsius": 21,
          "fahrenheit": 68
        },
        "windSpeed": 1
      },
      "type":"weatherStation",
      "uuid":"TerraceWeatherStation"
    }
  }
]
