{
  "12_hr": "12 horas",
  "24_hr": "24 horas",
  "24_hr_time": "Formato 24 horas",
  "about": "Acerca de",
  "account": "Cuenta",
  "account_exists": "Cuenta existente",
  "account_exists_l1": "La cuenta ya existe.",
  "account_exists_l2": "Por favor, elija una dirección de correo electrónico diferente o inicie sesión con esta dirección de correo electrónico.",
  "account_not_confirmed": "Cuenta no confirmada",
  "account_not_confirmed_l1": "Esta cuenta aún no está confirmada.",
  "account_not_confirmed_l2": "Por favor, compruebe su bandeja de entrada para obtener un correo de confirmación.",
  "account_not_confirmed_l3": "¿Verificar esta cuenta?",
  "act_in": "Dentro",
  "activate": "Activar",
  "active": "Activo",
  "act_out": "Fuera",
  "act_push": "PULSE",
  "adaptive_brightness": "Brillo adaptativo",
  "add": "Agregar",
  "add_favourite": "Añadir favorita",
  "add_group": "Añadir grupo",
  "add_home": "Añadir casa",
  "address": "Dirección",
  "add_to_fav": "Añadir a favoritos",
  "add_to_playlist": "Añadir a la lista de reproducción",
  "add_to_queue": "Añadir a la cola de espera",
  "air_conditioning": "Aire acondicionado",
  "air_pressure": "Presión del aire",
  "alarm_at": "Alarma a las",
  "alarm_missing_txt": "Falta uno o más campos obligatorios",
  "alarm_not_selected_l1": "No hay alarma seleccionada.",
  "alarm_not_selected_l2": "Por favor seleccione una alarma.",
  "alarms": "Alarmas",
  "album": "Álbum",
  "albums": "Álbumes",
  "alert": "Advertencia",
  "all": "Todo",
  "all_off": "Todo apagado",
  "all_off_txt_l1": "Está a punto de apagar todas las habitaciones.",
  "all_off_txt_l2": "¿Está seguro de que quiere continuar?",
  "all_on": "Todo encendido",
  "almost_done": "Casi listo",
  "almost_done_txt_l1": "No tiene aún listas de reproducción favoritas o emisoras de radio.",
  "almost_done_txt_l2": "Añada primero algo a sus favoritos.",
  "already_linked": "Conectado a la cuenta de Basalte",
  "already_linked_to_me": "Vinculado a su cuenta Basalte",
  "already_verified": "Verificado",
  "alt_power": "Apagado/Encendido",
  "alt_user": "Usuario",
  "application": "Aplicación",
  "application_version": "Versión de la aplicación",
  "app_update_required": "Se requiere actualizar la app",
  "app_update_required_android_l2": "Por favor, actualice la app en Play store.",
  "app_update_required_ios_l2": "Por favor, actualice la app en App Store.",
  "app_update_required_l2": "Por favor, actualice la app en la tienda",
  "artist": "Artista",
  "artists": "Artistas",
  "attention": "¡Atención!",
  "audio_zones": "Zonas de audio",
  "author": "Autor",
  "back": "volver",
  "basalte_account": "Cuenta Basalte",
  "basalte_info_l1": "Con una cuenta Basalte podrá vincular su casa y controlarla desde fuera de su red local.",
  "basalte_link_server_l1": "¿Vincular esta casa a su cuenta Basalte?",
  "basalte_link_server_l2": "Ya puede controlar esta casa desde fuera de su red local.",
  "basalte_unlink_server_l1": "¿Desvincular esta casa de su cuenta Basalte?",
  "basalte_unlink_server_l2": "Ya no podrá acceder a esta casa desde fuera de su red local.",
  "bass": "Graves",
  "BE": "Bélgica",
  "beta": "beta",
  "bluetooth": "Bluetooth",
  "bluetooth_pairing_txt": "No se le permite conectar su dispositivo vía bluetooth.",
  "brightness": "Brillo",
  "browse": "Navegar",
  "browse_deezer": "Favoritos Deezer",
  "browse_library": "Biblioteca de música",
  "browser_app_update_required_l2": "por favor, cargue la página de nuevo o actualice el servidor.",
  "browse_stations": "Emisoras de radio",
  "buffering": "almacenando en búfer",
  "building_building": "Edificio",
  "building_garage": "Garaje",
  "building_main_house": "Vivienda Principal",
  "building_pool_house": "Casa de la Piscina",
  "building_stables": "Establos",
  "cameras": "Cámaras",
  "cancel": "Cancelar",
  "categories": "Categorías",
  "category": "Categoría",
  "celsius": "Centígrados",
  "change_account": "Cambiar cuenta",
  "change_home": "Cambiar casa",
  "change_image": "Cambiar imagen",
  "change_password": "Cambiar contraseña",
  "change_password_l1": "Está a punto de cambiar la contraseña.",
  "change_user": "Cambiar perfil",
  "channel_number": "Numero de canal",
  "charts": "Lista de éxitos",
  "check_for_updates": "Buscar actualizaciones",
  "checking": "comprobando",
  "checking_for_updates": "Buscando actualizaciones...",
  "choose_favourite": "¿Le gustaría elegir un favorito?",
  "choose_home": "Seleccione casa",
  "choose_music": "¿Le gustaría elegir un favorito?",
  "choose_preset": "Escoja una preselección",
  "choose_source": "¿Quiere escoger una fuente de origen?",
  "clear": "Borrar",
  "cleared": "Borrado",
  "clear_queue_txt_l1": "Está a punto de borrar la cola de espera.",
  "clear_queue_txt_l2": "¿Está seguro de que quiere continuar?",
  "clear_schedules": "¿Estás seguro de que quieres borrar tu horarios?",
  "clear_selection": "Borrar la selección",
  "clear_selection_txt_l1": "Está a punto de borrar la selección.",
  "clear_selection_txt_l2": "¿Seguro que quiere continuar?",
  "close": "Cerrar",
  "closed": "Cerrado",
  "code": "Clave",
  "color": "Color",
  "color_temperature": "Temperatura de color",
  "color_theme": "Color del tema",
  "color_white": "Blanco",
  "comfort": "Confort",
  "configure": "Configurar",
  "confirmation_code_sent": "Confirmación de clave enviada",
  "confirmation_code_sent_l1": "Se ha enviado una confirmación de clave a la siguiente dirección:",
  "confirm_continue": "¿Está seguro de que desea continuar?",
  "confirm_overwrite": "¿Estás seguro de que quieres sobrescribir los datos existentes?",
  "connect": "Conectarse",
  "connected": "Conectado",
  "connecting": "Conectando",
  "connecting_small": "conectando",
  "connecting_stop_and_retry_text": "Error al conectar con el servidor. Reintento en 10 segundos.",
  "connecting_stop_text": "Error al conectar con el servidor.",
  "connecting_text": "Conectando…",
  "connecting_text_small": "conectando…",
  "connection_lost": "No hay conexión",
  "connection_lost_text": "Conectando de nuevo con el servidor",
  "connection_unable": "Ha sido imposible conectarse con el servidor indicado.",
  "connect_offline_server_l1": "Esta casa está vinculada, pero parece desconectada.",
  "connect_offline_server_l2": "¿Reintentar conectarse de todas formas?",
  "connect_server": "Conectarse al servidor",
  "consumption": "Consumo",
  "continue_anyway": "Continuar de todos modos",
  "continue_without_basalte_account": "Continuar sin cuenta de Basalte",
  "cover_art_background": "Imagen de fondo de portada",
  "current_password": "Contraseña actual",
  "current_temperature": "Actual",
  "custom": "Personalizado",
  "date": "Fecha",
  "day": "Día",
  "day_friday": "Viernes",
  "day_monday": "Lunes",
  "day_saturday": "Sábado",
  "day_short2_friday": "Vi",
  "day_short2_monday": "Lu",
  "day_short2_saturday": "Sa",
  "day_short2_sunday": "Do",
  "day_short2_thursday": "Ju",
  "day_short2_tuesday": "Ma",
  "day_short2_wednesday": "Mi",
  "day_sunday": "Domingo",
  "day_thursday": "Jueves",
  "day_tuesday": "Martes",
  "day_wednesday": "Miércoles",
  "debug": "Depurar",
  "deezer": "Deezer",
  "deezer_disconnect_txt": "Está a punto de desconectar la cuenta de Deezer desde este reproductor.",
  "deezer_favourite": "Favorito Deezer",
  "deezer_flow": "Retransmisión de audio",
  "deezer_flow_description": "Su banda sonora personal.",
  "deezer_flow_full": "Retransmisión de audio Deezer",
  "deezer_no_link": "No hay cuenta de Deezer conectada",
  "deezer_oauth_error": "Error de autentificación de Deezer",
  "default_rooms": "Habitaciones por defecto",
  "default_rooms_hint": "Para que la retransmisión de audio funcione correctamente, asegúrese de que al menos una habitación se ha seleccionado como habitación por defecto.",
  "default_rooms_info_l1": "Habitaciones predeterminadas para transmisiones digitales de audio de terceros (streams como Spotify Connect).",
  "default_rooms_info_l2": "Cuando no hay habitaciones conectadas a la transmisión digital (stream), activar las habitaciones especificadas.",
  "default_rooms_spotify_hint": "Para que Spotify Connect funcione correctamente, asegúrese de que al menos una habitación se ha seleccionado como habitación por defecto.",
  "default_rooms_spotify_warning_l1": "Defina al menos una habitación por defecto para cada retransmisión de audio",
  "default_rooms_spotify_warning_l2": "con el fin de completar la configuración.",
  "default_rooms_spotify_warning_title": "Spotify Connect ya está disponible",
  "default_view": "Vista por defecto",
  "delay": "Retardo",
  "delay_seconds_b": "Retardo (segundos)",
  "delete": "Borrar",
  "delete_account": "Eliminar cuenta",
  "demo_enter": "Modo demo",
  "demo_functionality_warning_txt": "Esta funcionalidad no es compatible con el modo demo.",
  "demo_location": "Ghent",
  "demo_server_name": "Casa de prueba",
  "demo_title": "Demo",
  "demo_warning_txt": "Está a punto de entrar en el modo demo.",
  "detail": "detalle",
  "device": "Dispositivo",
  "devices": "Dispositivos",
  "device_settings": "Configuración de dispositivo",
  "device_volume": "Volumen del dispositivo",
  "dhcp": "DHCP",
  "direction_east": "Este",
  "direction_east-northeast": "Este-nordeste",
  "direction_east-southeast": "Este-sudeste",
  "direction_north": "Norte",
  "direction_northeast": "Nordeste",
  "direction_north-northeast": "Norte-noreste",
  "direction_north-northwest": "Norte-noroeste",
  "direction_northwest": "Noroeste",
  "direction_short_east": "E",
  "direction_short_east-northeast": "ENE",
  "direction_short_east-southeast": "ESE",
  "direction_short_north": "N",
  "direction_short_northeast": "NE",
  "direction_short_north-northeast": "NNE",
  "direction_short_north-northwest": "NO",
  "direction_short_northwest": "NO",
  "direction_short_south": "S",
  "direction_short_southeast": "SE",
  "direction_short_south-southeast": "SSE",
  "direction_short_south-southwest": "SSO",
  "direction_short_southwest": "SO",
  "direction_short_west": "O",
  "direction_short_west-northwest": "ONO",
  "direction_short_west-southwest": "OSO",
  "direction_south": "Sur",
  "direction_southeast": "Sureste",
  "direction_south-southeast": "Sur-sureste",
  "direction_south-southwest": "Sur-suroeste",
  "direction_southwest": "Suroeste",
  "direction_west": "Oeste",
  "direction_west-northwest": "Oeste-noroeste",
  "direction_west-southwest": "Oeste-suroeste",
  "disband_group": "Disolver grupo",
  "dns": "DNS",
  "domain": "Dominio",
  "done": "Hecho",
  "do_not_disturb": "No molestar",
  "do_not_disturb_off_l1": "Todas las notificaciones de sonido están actualmente activadas.",
  "do_not_disturb_on_l1": "Todas las notificaciones de sonido están actualmente desactivadas.",
  "do_not_show_again": "No mostrar más",
  "door_phone": "Teléfono de puerta",
  "doors": "Puertas",
  "down": "Abajo",
  "downloading": "descargando",
  "downloading_updates": "Descargando actualizaciones",
  "do_you_want_to_continue": "¿Deseas continuar?",
  "drag_and_drop_images": "Seleccione una imagen o suéltela aquí",
  "drag_and_drop_lights": "Arrastre y suelte las luces aquí",
  "drag_to_reorder": "Arrastre para reordenar",
  "dsp": "DSP",
  "dsp_configuration": "Configuración DSP",
  "dsp_profile_club": "Club",
  "dsp_profile_custom": "Personalizado",
  "dsp_profile_flat": "Plano",
  "dsp_profile_jazz": "Jazz",
  "dsp_profile_live": "Live",
  "dsp_profile_pop": "Pop",
  "dsp_profile_rock": "Rock",
  "dsp_shortcut_l1": "Está a punto de modificar los ajustes DSP.",
  "echo_cancellation": "Cancelación del eco",
  "echo_limit": "Límite de eco",
  "edit": "Modificar",
  "edit_dns": "Editar DNS",
  "edit_gateway": "Editar puerta de enlace",
  "edit_ip_address": "Editar dirección IP",
  "edit_subnet_mask": "Editar máscara de subred",
  "ellie_room": "Habitación Ellie",
  "ellie_system_update": "Ellie - actualización sistema",
  "ellie_system_update_l1": "Ellie necesita arrancar de nuevo para instalar la actualización de sistema.",
  "ellie_update": "Actualización Ellie",
  "email": "Dirección de correo electrónico",
  "empty": "Vacío",
  "empty_queue": "La cola de espera está vacía",
  "empty_queue_txt_l1": "No hay canciones en la cola de espera.",
  "empty_queue_txt_l2": "Añada primero algo.",
  "empty_song": "Se ha detenido la reproducción",
  "empty_song_txt_l1": "Ha llegado al final de la cola de espera.",
  "empty_song_txt_l2": "Hacer clic en \"play\" empezar de nuevo",
  "empty_src": "No hay fuente seleccionada",
  "empty_src_txt_l1": "Parece que no ha seleccionado fuente.",
  "empty_src_txt_l2": "Escoja una fuente.",
  "empty_zone": "No hay habitación seleccionada",
  "empty_zone_txt_l1": "Parece que no ha seleccionado habitación.",
  "empty_zone_txt_l2": "Escoja una habitación.",
  "energy": "Energía",
  "eq": "EQ",
  "error": "Error",
  "external": "Externo",
  "extra_permissions_required": "Algunas funciones pueden requerir permisos adicionales del dispositivo.",
  "ext_src": "Fuente externa",
  "fahrenheit": "Fahrenheit",
  "failed": "Sin éxito",
  "fan": "Ventilador",
  "fan_auto": "Auto",
  "fan_high": "Alto",
  "fan_low": "Bajo",
  "fan_medium": "Medio",
  "fan_off": "Apagado",
  "favourite": "Favorito",
  "favourites": "Favoritos",
  "favourite_scenes_full": "Las escenas favoritas están llenas",
  "favourite_scenes_full_sub": "Puedes configurar un máximo de 4 escenas favoritas en una habitación",
  "favourite_songs": "Canciones favoritas",
  "featured_playlists": "Listas de reproducción destacadas",
  "floor_attic": "Ático",
  "floor_basement": "Sótano",
  "floor_floor": "Planta",
  "floor_garden": "Jardín",
  "floor_heating": "Suelo radiante",
  "floor_loft": "Lofts",
  "floor_lower": "Planta baja",
  "floor_lvl_0": "Planta baja",
  "floor_lvl_1": "Primera planta",
  "floor_lvl_2": "Segunda planta",
  "floor_lvl_3": "Tercera planta",
  "floor_lvl_4": "Cuarta planta",
  "floor_lvl_5": "Quinta planta",
  "floor_lvl_6": "Sexta planta",
  "floor_lvl_7": "Séptima planta",
  "floor_lvl_8": "Octava planta",
  "floor_lvl_9": "Novena planta",
  "floor_lvl_10": "Décima planta",
  "floor_lvl_11": "Undécima planta",
  "floor_lvl_12": "Duodécima planta",
  "floor_lvl_13": "Decimotercera planta",
  "floor_lvl_14": "Decimocuarta planta",
  "floor_lvl_15": "Decimoquinta planta",
  "floor_lvl_16": "Decimosexta planta",
  "floor_lvl_17": "Decimoséptima planta",
  "floor_lvl_18": "Decimoctava planta",
  "floor_lvl_19": "Decimonovena planta",
  "floor_lvl_20": "Vigésima planta",
  "floor_upper": "Piso superior",
  "forgot_password": "¿Olvidaste tu contraseña?",
  "frequency": "Frecuencia",
  "full_screen": "Pantalla completa",
  "functions": "Funciones",
  "gateway": "Puerta de enlace",
  "general": "General",
  "generic_devices": "Dispositivos",
  "generic_devices_fire": "Fuego",
  "generic_devices_garden": "Jardín",
  "generic_devices_socket": "Enchufe",
  "genre": "Género",
  "genre_moods": "Géneros y Estados de Ánimo",
  "genres": "Géneros",
  "go_to_login_l1": "Inicia sesión o regístrate en su cuenta de Basalte.",
  "go_to_manage_l1": "Administre su cuenta de Basalte.",
  "group": "Grupo",
  "groups": "Grupos",
  "home": "Inicio",
  "humidity": "Humedad",
  "hz": "Hz",
  "images": "Imágenes",
  "image_smaller_than_5mb": "La imagen debe ser inferior a 5 MB",
  "in": "Dentro",
  "incorrect_album": "No se ha encontrado el álbum",
  "incorrect_artist": "No se ha encontrado el artista",
  "install": "Instalar",
  "installing": "Instalando",
  "install_update": "Instalar actualización",
  "install_updates": "Instalar actualizaciones",
  "integrator": "Integrador",
  "integrator_access_allow": "Permitir configuración remota",
  "intercom": "Intercomunicador",
  "internal": "Interno",
  "invalid_address": "Dirección inválida",
  "invalid_code": "Código no válido",
  "invalid_code_l1": "El código introduciso no es correcto.",
  "invalid_code_l2": "Asegúrese de que el código sea correcto o solicite uno nuevo.",
  "invalid_input": "Entrada inválida",
  "invalid_user": "Usuario inválido",
  "invalid_user_l1": "Asegúrese de que la dirección de correo electrónico sea válida.",
  "ios_plist_string_NSCameraUsageDescription": "La cámara se puede usar para tomar fotos de habitaciones",
  "ios_plist_string_NSLocalNetworkUsageDescription": "El acceso a la red local se puede utilizar para buscar servidores, cámaras IP, ...",
  "ios_plist_string_NSPhotoLibraryAddUsageDescription": "La galería de imágenes se puede utilizar para guardar fotos para habitaciones",
  "ios_plist_string_NSPhotoLibraryUsageDescription": "La galería de imágenes se puede utilizar para seleccionar fotos para habitaciones",
  "ip": "IP",
  "ip_address": "Dirección IP",
  "join": "+ Añadir",
  "join_alt": "+ Añadir",
  "keep_awake": "Mantener el iPad encendido",
  "keep_awake_device": "Mantener el dispositivo encendido",
  "kelvin": "Kelvin",
  "Key": "es_ES",
  "knx_presets": "Ajustes KNX",
  "knx_presets_info": "Modifique los ajustes KNX para reproducir algo más.",
  "language": "Idioma",
  "language_system": "Idioma del sistema",
  "last-7-days": "Últimos 7 días",
  "last_hour": "Última hora",
  "last_room": "Última habitación seleccionada",
  "last_view": "Última vista",
  "learn_more": "Más información",
  "leave_group": "Dejar el grupo",
  "left": "Izquierda",
  "lena_system_update": "Lena - actualización sistema",
  "lena_system_update_l1": "Lena necesita arrancar de nuevo para instalar la actualización de sistema.",
  "library": "Biblioteca",
  "library_empty": "La biblioteca está vacía",
  "library_scanning": "Explorando librería",
  "light_accent_light": "Luz de acento",
  "light_art_light": "Luz de arte",
  "light_cabinet": "Armario",
  "light_ceiling_mounted_fixture": "Lámpara de techo",
  "light_chandelier": "Lámpara",
  "light_desk": "Escritorio",
  "light_down": "Foco empotrado en techo",
  "light_floor_lamp": "Lámpara de Pie",
  "light_lampshade": "Lámpara de pantalla",
  "light_led": "Luz LED",
  "light_led_strip": "Tira LED",
  "light_mirror": "Espejo",
  "light_mood_light": "Lámpara de ambiente",
  "light_pendant": "Lámpara colgante",
  "light_reading_light": "Luz de lectura",
  "lights": "Luces",
  "lights_off_all": "Apagar todas las luces",
  "light_spot": "Foco",
  "light_table_lamp": "Lámpara de mesa",
  "light_track": "Regleta con focos",
  "light_via": "Luz Vía",
  "light_wall_mounted_fixture": "Lámpara de pared",
  "link": "Enlace",
  "link_deezer_info_l1": "Vincule una cuenta de Deezer con una transmisión digital (stream).",
  "link_deezer_info_l2": "Esto permite navegar y reproducir música de Deezer en la transmisión digital.",
  "linked_homes": "Casas vinculadas",
  "linked_projects": "Proyectos vinculados",
  "linked_with": "Conectado a",
  "link_home": "Abra la app Basalte en un dispositivo local y vincule su casa en los ajustes.",
  "link_server": "Enlace a la cuenta Basalte",
  "link_server_full_sub": "¿Vincular este proyecto a la siguiente cuenta de Basalte?",
  "link_spotify_info_l1": "Vincule una cuenta de Spotify con una transmisión digital (stream).",
  "link_spotify_info_l2": "Esto permite navegar y reproducir música de Spotify en la transmisión digital.",
  "link_tidal_info_l1": "Vincule una cuenta de TIDAL con una transmisión digital (stream).",
  "link_tidal_info_l2": "Esto permite navegar y reproducir música de TIDAL en la transmisión digital.",
  "lisa_system_update": "Lisa - actualización sistema",
  "lisa_system_update_l1": "Lisa necesita arrancar de nuevo para instalar la actualización de sistema.",
  "listen_in": "Escuchar",
  "liters": "Litros",
  "live_adjustments": "Ajustes de directo",
  "loading": "Cargando…",
  "local": "local",
  "location": "Ubicación",
  "location_back": "Detrás",
  "location_bottom": "Fondo",
  "location_center": "Centro",
  "location_chair": "Silla",
  "location_corner": "Esquina",
  "location_desk": "Escritorio",
  "location_east": "Este",
  "location_front": "Frente",
  "location_high": "Alto",
  "location_left": "Izquierda",
  "location_low": "Bajo",
  "location_mid": "Medio",
  "location_north": "Norte",
  "location_right": "Derecha",
  "location_south": "Sur",
  "location_stove": "Encimera",
  "location_table": "Mesa",
  "location_top": "Arriba",
  "location_west": "Oeste",
  "logged_in_as": "Registrado como",
  "log_out": "Cerrar sesión",
  "log_out_everywhere": "Cerrar la sesión en todas partes",
  "long-press-save-scene": "Mantener pulsada una escena para actualizarla",
  "louver_30": "30 grados",
  "louver_45": "45 grados",
  "louver_60": "60 grados",
  "louver_horizontal": "Horizontal",
  "louver_mode": "Modo rejilla",
  "louver_off": "Apagado",
  "louver_swing": "Balancearse",
  "louver_vertical": "Vertical",
  "mac_address": "dirección MAC",
  "manage": "administrar",
  "manage_account": "Administrar cuenta",
  "manual_conn": "Conexión manual",
  "manual_text": "Por favor, añada la dirección del servidor deseado.",
  "max": "Máximo",
  "menu": "Menú",
  "mf_device": "{count, plural, =0 {Dispositivos} =1 {Dispositivo} other {Dispositivos}}",
  "mf_feels_like": "Se siente como {value}",
  "mf_minute": "{count} {count, plural, =1 {minuto} other {minutos}}",
  "mf_powered_by": "Alimentado por {value}",
  "mf_second": "{count} {count, plural, =1 {segundo} other {segundos}}",
  "mf_server": "{count, plural, =0 {Servidores} =1 {Servidor} other {Servidores}}",
  "mf_song": "{count} {count, plural, =1 {canción} other {canciones}}",
  "mf_source": "Fuente: {value}",
  "microphone_gain": "Ganancia del micrófono",
  "min": "Mínimo",
  "mix": "Mezcla",
  "mixed_sources": "Varias fuentes",
  "mixed_sources_txt_l1": "Este grupo contiene habitaciones con varias fuentes.",
  "mixed_sources_txt_l2": "Seleccione una fuente.",
  "mixes": "Mezclas",
  "mixes_small": "mezclas",
  "mode": "Modo",
  "mode_auto": "Auto",
  "mode_cooling": "Frío",
  "mode_heating": "Calor",
  "mode_off": "Apagado",
  "month": "Mes",
  "month_april": "Abril",
  "month_august": "Agosto",
  "month_december": "Diciembre",
  "month_february": "Febrero",
  "month_january": "Enero",
  "month_july": "Julio",
  "month_june": "Junio",
  "month_march": "Marzo",
  "month_may": "Mayo",
  "month_november": "Noviembre",
  "month_october": "Octubre",
  "month_september": "Septiembre",
  "month_short_april": "Abr",
  "month_short_august": "Agosto",
  "month_short_december": "Dic",
  "month_short_february": "Feb",
  "month_short_january": "Enero",
  "month_short_july": "Jul",
  "month_short_june": "Jun",
  "month_short_march": "Marzo",
  "month_short_may": "Mayo",
  "month_short_november": "Nov",
  "month_short_october": "Oct",
  "month_short_september": "Set",
  "mood": "Estado de ánimo",
  "moods": "Estados de ánimo",
  "music": "Música",
  "music_choice": "Seleccionar música",
  "music_off": "apagar música",
  "music_off_all": "Apagar todas las habitaciones",
  "music_off_all_rooms": "Música apagada, todas las habitaciones",
  "music_off_current": "Apagar la habitación actual",
  "music_off_current_room": "Música apagada, esta habitación",
  "music_off_txt_l1": "Está a punto de apagar una o todas las habitaciones.",
  "music_off_txt_l2": "¿Seguro que quiere continuar?",
  "music_on_current": "Encender la habitación actual",
  "music_server": "Servidor de música",
  "mute_area_on_call": "Silenciar música durante la llamada",
  "my_favorites": "Mis favoritos",
  "my_music": "Mi música",
  "name": "Nombre",
  "nds": "Buscando servidores",
  "nds_music": "Buscando servidores de música",
  "nds_none": "¡No se han encontrado servidores!",
  "nds_none_music": "¡No se han encontrado servidores de música!",
  "nds_text_l1": "Esto podría llevar algunos segundos…",
  "nds_text_l2": "Asegúrese de que su dispositivo esté conectado a su red Wifi.",
  "network": "Red",
  "network_input_invalid": "Todos los campos de entrada deben ingresarse correctamente",
  "never": "Nunca",
  "new": "Nuevo",
  "new_alarm": "Nueva alarma",
  "new_image": "Nueva imagen",
  "new_password": "Nueva contraseña",
  "new_password_confirmation": "Confirmación de nueva contraseña",
  "new_playlist": "Nueva lista de reproducción",
  "new_releases": "Nuevos lanzamientos",
  "new_releases_small": "nuevos lanzamientos",
  "new_scene": "Nueva escena",
  "new_schedule": "Nuevo horario",
  "new_step": "Nuevo paso",
  "next": "Siguiente",
  "next_song": "canción siguiente",
  "no": "No",
  "no_active_rooms": "No hay habitaciones activas",
  "no_all_off": "No, no apagar",
  "no_av": "No hay entrada AV",
  "no_configuration": "No hay configuración disopnible",
  "no_content_available": "No hay contenido disponible",
  "no_data": "Sin datos",
  "no_deezer_dis": "No, no desconectar el perfil Deezer",
  "no_devices_capabilities": "No hay dispositivos ajustables",
  "no_doors_available": "No hay puertas disponibles",
  "no_favourites": "No hay favoritos",
  "no_keep_it": "No, déjalo así.",
  "no_lights_capabilities": "Luces no regulables",
  "no_linked_homes": "No se han encontrado casas vinculadas.",
  "none": "No",
  "no_network": "No hay conexión de red",
  "no_preview_available": "No hay vista previa disponible",
  "no_rooms_available": "No hay habitaciones disponibles",
  "no_room_selected": "No se ha seleccionado habitación",
  "no_scene": "No hay escenas",
  "no_scenes_favourites": "No hay escenas favoritas",
  "no_scenes_txt": "Seleccionar escenas para mostrar aquí.",
  "no_search_results": "No se ha encontrado ningún resultado",
  "no_servers": "No se ha encontrado ningún servidor",
  "no_shades_capabilities": "Toldos no regulables",
  "no_source": "- apagado -",
  "no_sources_available": "No hay fuentes disponibles",
  "no_steps": "Esta escena no contiene pasos",
  "not_allowed": "No está permitido",
  "not_allowed_l1": "No tiene permisos para hacer esto",
  "notification": "Notificación",
  "notifications": "Notificaciones",
  "not_linked": "No está conectado",
  "not_now": "No por ahora",
  "not_set": "No establecido",
  "no_users_l1": "No se han encontrado usuarios.",
  "no_users_l2": "Seleccione un servidor con usuarios.",
  "number_of_songs": "canción(es)",
  "off": "Apagado",
  "offline_server": "Servidor sin conexión",
  "offset_minutes": "Desplazamiento (minutos)",
  "ok": "OK",
  "on": "encendido",
  "onix_black": "Negro",
  "online": "en línea",
  "only_png_jpeg_supported": "Solo se admiten imágenes PNG y JPEG",
  "open": "Abierto",
  "open_close_door": "Puerta",
  "open_close_gate": "Puerta",
  "open_close_window": "Ventana",
  "open_settings": "Abrir configuración",
  "open_spotify": "Abrir Spotify",
  "open_spotify_l1": "Está a punto de abrir la aplicación de Spotify.",
  "open_state": "Abierto",
  "or": "O",
  "order_cameras_by_holding_and_draging": "Arrastre las cámaras en el orden deseado.",
  "other": "Otro",
  "out": "Fuera",
  "pair": "Emparejar",
  "password": "Contraseña",
  "password_changed": "Contraseña cambiada",
  "password_confirmation": "Confirmación de contraseña",
  "password_requirements": "Su contraseña debe contener al menos 8 caracteres e incluir al menos una letra mayúscula y minúscula y un número.",
  "permission_denied": "Permiso denegado",
  "permission_denied_go_to_settings_l1": "Esta función necesita permiso (s) para funcionar correctamente.",
  "permission_denied_go_to_settings_l2": "Permitir permisos para esta aplicación en la configuración de su dispositivo para habilitar todas las funciones.",
  "photo_from_camera": "Hacer una foto",
  "photo_from_library": "Seleccionar imagen de la galería",
  "pick_room_function": "Escoja una habitación y una función",
  "play": "Reproducir",
  "playback_gain": "Ganancia en reproducción",
  "playing_in": "se está reproduciendo en",
  "playlist": "Lista de reproducción",
  "playlists": "Listas de reproducción",
  "playlists_itunes": "Lista de reproducción iTunes",
  "playlists_l": "listas de reproducción",
  "playlists_local": "Listas de reproducción locales",
  "playlists_shared": "Listas de reproducción compartidas",
  "play_next": "Reproducir siguiente",
  "play_now": "Reproducir ahora",
  "please_try_a_different_image": "Por favor, inténtelo con otra imagen",
  "please_try_again": "Por favor, inténtelo de nuevo",
  "popular": "Popular",
  "position": "Posición",
  "precipitation": "Precipitación",
  "previous": "Anterior",
  "project": "Proyecto",
  "project_image": "Imagen de proyecto",
  "proximity_activation": "Activacion de proximidad",
  "proximity_far": "Lejos",
  "proximity_medium": "Medio",
  "proximity_near": "Cerca",
  "queue": "cola de espera",
  "radio": "Radio",
  "radio_error": "Se encontró un error en la recuperación de las emisoras de radio",
  "radio_stations": "Emisoras de radio",
  "radio_stations_other": "Otras emisoras",
  "recently_played": "Recientemente reproducido",
  "related_artists": "Artistas relacionados",
  "reload": "Recargar",
  "remember_me": "Recuérdame",
  "remote": "Remoto",
  "remote_ellie_intercom_outdated": "Ellie remota desactualizada",
  "remote_ellie_intercom_outdated_l1": "La Ellie a la que intenta llamar necesita una actualización de software.",
  "remote_ellie_intercom_outdated_l2": "Inténtelo de nuevo tras la actualización.",
  "remove": "Eliminar",
  "remove_alarm": "Eliminar alarma",
  "remove_basalte_favourite": "Eliminar favorito Basalte",
  "remove_deezer_favourite": "Eliminar favorito Deezer",
  "remove_from_fav": "Quitar de los favoritos",
  "remove_schedule": "Eliminar horario",
  "remove_spotify_favourite": "Eliminar Spotify favorito",
  "remove_spotify_preset": "Borrar parámetro Spotify",
  "remove_tidal_favourite": "Eliminar favorito TIDAL",
  "remove_txt_l1": "Está a punto de borrar este elemento.",
  "remove_txt_l2": "¿Seguro que quiere continuar?",
  "rename": "renombrar",
  "repeat": "Repetir",
  "replace_queue": "Sustituir cola de espera",
  "resend_code": "Reenviar código",
  "resend_confirmation_code": "Reenviar código de confirmación",
  "reset_image": "Restablecer imagen",
  "reset_password": "Restablecer contraseña",
  "reset_password_l1": "¿Estás seguro de que quieres restablecer tu contraseña?",
  "restart": "Reiniciar",
  "restart_app": "Reiniciar aplicación",
  "restart_app_q": "Reiniciar aplicación?",
  "restarting_server": "Reiniciando el servidor",
  "restart_music_server": "Reiniciar el servidor de música",
  "restart_music_server_txt_l1": "Está a punto de reiniciar el servidor de música.",
  "restart_server": "Reiniciar el servidor",
  "restart_server_txt_l1": "Está a punto de reiniciar el servidor.",
  "restart_server_txt_l2": "La aplicación restablecerá la conexión automáticamente.",
  "retrieving": "Recuperación en curso…",
  "retrieving_users_stop_text": "Error en la búsqueda de usuarios, ¿el servidor es correcto?",
  "retrieving_users_text": "Buscando usuarios…",
  "right": "Derecho",
  "room": "Habitación",
  "room_attic": "Ático",
  "room_ball_room": "Salón de Baile",
  "room_bar": "Bar",
  "room_basement": "Sótano",
  "room_bathroom": "Baño",
  "room_bedroom": "Dormitorio",
  "room_corridor": "Corredor",
  "room_dining_room": "Comedor",
  "room_dressing_room": "Vestidor",
  "room_driveway": "Entrada",
  "room_entry": "Acceso",
  "room_family_room": "Sala Auxiliar",
  "room_foyer": "Vestíbulo",
  "room_garage": "Garaje",
  "room_garden": "Jardín",
  "room_guest_room": "Habitación Invitados",
  "room_gym": "Gimnasio",
  "room_hallway": "Pasillo",
  "room_hobby_room": "Sala de Juegos",
  "room_home_cinema": "Home Cinema",
  "room_home_office": "Despacho",
  "room_images": "Imágenes de habitación",
  "room_intercom_lost": "Conexión perdida",
  "room_intercom_lost_sub": "Se perdió la conexión de llamada, intente de nuevo",
  "room_intercom_unavailable": "Habitación no disponible",
  "room_intercom_unavailable_sub": "Esta habitación no está disponible actualmente",
  "room_intercom_unreachable": "Habitación no accesible",
  "room_intercom_unreachable_sub": "Actualmente no se puede acceder a esta sala",
  "room_kitchen": "Cocina",
  "room_laundry_room": "Habitación de Lavado",
  "room_library": "Biblioteca",
  "room_living_room": "Salón",
  "room_master_bedroom": "Dormitorio Principal",
  "room_media_room": "Sala de cine",
  "room_meeting_room": "Sala de Reuniones",
  "room_nook": "Rincón",
  "room_nursery": "Guardería",
  "room_pantry": "Despensa",
  "room_parking_place": "Lugar de Estacionamiento",
  "room_patio": "Patio",
  "room_pool": "Piscina",
  "room_pool_house": "Casa de la Piscina",
  "room_rack": "Rack",
  "room_room": "Habitación",
  "rooms": "Habitaciones",
  "room_scene_no_functions": "Esta habitación no tiene funciones",
  "room_settings": "Ajustes de la habitación",
  "room_spa": "Spa",
  "room_stable": "Estable",
  "room_staircase": "Escalera",
  "room_terrace": "Terraza",
  "room_toilet": "Aseo",
  "room_utility_room": "Lavadero",
  "room_wine_cellar": "Bodega",
  "room_workshop": "Taller",
  "rotation": "Rotación",
  "save": "Guardar",
  "save_as": "guardar como",
  "save_basalte_favourite": "Guardar favorito Basalte",
  "save_changes": "Guardar cambios",
  "save_changes_l1": "Algunos cambios no se han guardado aún",
  "save_changes_l2": "¿Quiere guardarlos ahora?",
  "save_deezer_favourite": "Guardar favorito Deezer",
  "save_spotify_favourite": "Guardar Spotify favorito",
  "save_spotify_preset": "Guardar ajustes Spotify",
  "save_tidal_favourite": "Guardar favorito TIDAL",
  "scene": "Escena",
  "scene_activated": "Escena activada",
  "scene_away": "Ausencia",
  "scene_cooking": "Cocinar",
  "scene_dinner": "Cenar",
  "scene_error": "Error de escena",
  "scene_evening": "Noche",
  "scene_good_morning": "Buenos Días",
  "scene_good_night": "Buenas Noches",
  "scene_guests": "Invitados",
  "scene_home": "En Casa",
  "scene_image_error": "Error de imagen de escena",
  "scene_movie": "Película",
  "scene_not_available": "No hay escenas presentes.",
  "scene_not_selected_l1": "No hay escena seleccionada.",
  "scene_not_selected_l2": "Por favor, seleccione una escena.",
  "scene_party": "Fiesta",
  "scene_relax": "Relax",
  "scenes": "Escenas",
  "scene_select_or_new": "Por favor, seleccione una escena o cree una nueva.",
  "scene_vacation": "Vacaciones",
  "scene_welcome": "Bienvenida",
  "scene_workout": "Entrenamiento",
  "schedule": "Temporizador",
  "schedule_at": "Horario de",
  "schedule_not_selected_l1": "No hay horario seleccionado.",
  "schedule_not_selected_l2": "Por favor, seleccione un horario.",
  "scheduler": "Planificador",
  "schedules": "Horarios",
  "schedule_select_or_new": "Por favor, seleccione un horario o cree uno nuevo.",
  "screen_orientation": "Orientación de la pantalla",
  "search": "Buscar",
  "search_again": "Buscar de nuevo",
  "search_ph": "buscar artista, canción o álbum",
  "search_result": "Buscar los resultados por",
  "security": "Seguridad",
  "select_admin": "Seleccione un perfil de administrador para continuar con el proceso de actualización",
  "select_home": "Elige tu casa",
  "selection": "Selección",
  "selection_all": "seleccionar todo",
  "select_photo": "Seleccionar imagen",
  "select_server": "Seleccione un servidor",
  "select_user": "Seleccione un perfil e inicie sesión",
  "serial_number": "Número de serie",
  "server": "Servidor",
  "server_address": "Dirección del servidor",
  "server_basalte_cloud_no_connection": "El servidor no puede conectarse a Basalte Cloud",
  "server_exists_already": "¡El servidor ya existe!",
  "server_mac": "Dirección MAC del servidor",
  "servers": "Servidores",
  "server_status": "Estado del servidor",
  "server_update_info_l2": "por favor, actualice el servidor Basalte a la última versión",
  "server_update_required": "Se requiere actualizar el servidor",
  "server_version": "Versión del servidor",
  "set": "Ajustar",
  "set_new_password": "Establecer nueva contraseña",
  "setpoint": "Valor de consigna",
  "settings": "Ajustes",
  "shade_blind": "Cortina",
  "shade_center": "Centro",
  "shade_curtain": "Cortina",
  "shade_left": "Izquierda",
  "shade_pool_shutter": "Cubierta Piscina",
  "shade_right": "Derecha",
  "shade_roman_blind": "Estor",
  "shades": "Cortinas",
  "shade_shade": "Cortina",
  "shade_shutter": "Persiana",
  "shade_solar_protection": "Foscurit",
  "shade_venetian_blind": "Persiana veneciana",
  "shade_vertical_blind": "Persiana vertical",
  "share": "compartir",
  "shared_playlist": "Lista de reproducción compartida",
  "show_album": "Mostrar álbum",
  "show_artist": "Mostrar artista",
  "show_background_art": "Mostras las imágenes de segundo plano",
  "show_more": "Mostrar más…",
  "show_status_bar": "Mostrar barra de estado del iPad",
  "show_status_bar_device": "Mostrar barra de estado",
  "sign_in": "Entrar",
  "sign_in_info_tidal_l1": "Introduzca su clave de acceso a TIDAL",
  "sign_up": "Registrarse",
  "single_room": "Habitación",
  "sleep": "Pasar la pantalla a stand-by",
  "software_update": "Actualización de software",
  "something_went_wrong": "Algo salió mal",
  "songs": "Canciones",
  "sonos": "Sonos",
  "sound_settings": "Sono agordoj",
  "source": "Fuente",
  "sources": "Fuentes",
  "spotify": "Spotify",
  "spotify_beta": "Spotify (beta)",
  "spotify_connect": "Spotify Connect",
  "spotify_connect_instructions": "Spotify Connect",
  "spotify_connect_instructions_1": "Asegúrese de que el Core servidor y el dispositivo con la aplicación de Spotify estén en la misma red.",
  "spotify_connect_instructions_2": "Seleccione la retransmisión de audio Core servidor en la lista de aparatos de la aplicación Spotify.",
  "spotify_connect_instructions_body": "Escucha desde tu televisor o tus altavoces usando la aplicación de Spotify como control remoto.",
  "spotify_connect_instructions_title": "¿Estás listo para escuchar música?",
  "spotify_disconnect_txt": "Está a punto de desconectar la cuenta de Spotify de este reproductor.",
  "spotify_free_user_txt": "Se requiere una suscripción Spotify Premium",
  "spotify_no_link": "Aún no hay cuenta de Spotify vinculada",
  "spotify_oauth_error": "Error de autentificación de Spotify",
  "spotify_preset_error": "Error Spotify",
  "spotify_preset_error_l1": "No se pueden cargar los ajustes de Spotify.",
  "spotify_preset_error_l2": "Asegúrese de que una cuenta Spotify válida esté ya conectada a esta retransmisión de audio.",
  "spotify_presets": "Ajustes Spotify",
  "start": "Principal",
  "startup_view": "Inicio",
  "startup_volume": "Volumen inicial",
  "state_active": "Activo",
  "state_armed": "Armada",
  "state_closed": "Cerrado",
  "state_disabled": "Desactivada",
  "state_disarmed": "Desarmada",
  "state_down": "Abajo",
  "state_enabled": "Habilitada",
  "state_false": "Falso",
  "state_free": "Libre",
  "state_high": "Alto",
  "state_in": "Dentro",
  "state_inactive": "Inactivo",
  "state_left": "Izquierda",
  "state_low": "Baja",
  "state_occupied": "Ocupada",
  "state_off": "Apagado",
  "state_on": "Encendido",
  "state_opened": "Abierto",
  "state_out": "Fuera",
  "state_right": "Derecho",
  "state_started": "Empezada",
  "state_stopped": "Detenida",
  "state_true": "Verdadero",
  "state_up": "Arriba",
  "static": "Estático",
  "stations": "Emisoras de radio",
  "status": "Estado",
  "stop": "Parar",
  "stream": "Retransmisión de audio",
  "streaming": "Streaming",
  "streaming_services": "Servicios de streaming",
  "streams": "Retransmisiones de audio",
  "streams_beta": "Streams (beta)",
  "subnet_mask": "Máscara de subred",
  "sun": "Dom",
  "sunrise": "Amanecer",
  "sunset": "Puesta de sol",
  "swipe_to_pan": "Desliza para desplazarte",
  "switch_server": "Cambiar servidor",
  "synchronise": "Explorar ahora",
  "system": "Sistema",
  "system_setting": "Ajuste de sistema",
  "temperature": "Temperatura",
  "temperature_unit": "Unidad de temperatura",
  "theme": "Tema",
  "thermostat": "Termostato",
  "this_cannot_be_undone": "No se puede deshacer.",
  "this_week": "Esta semana",
  "tidal": "TIDAL",
  "tidal_disconnect_txt": "Está a punto de desconectar la cuenta TIDAL de este reproductor.",
  "tidal_legacy_auth": "Se requiere inicio de sesión de TIDAL",
  "tidal_legacy_auth_l1": "Para continuar usando TIDAL, inicie sesión nuevamente.",
  "tidal_no_link": "Aún no hay cuenta de TIDAL vinculada",
  "tidal_oauth_error": "Error de autentificación de TIDAL",
  "tidal_rising": "TIDAL Rising",
  "time": "Hora",
  "time_format": "Formato de tiempo",
  "timer": "Temporizador",
  "timers": "Temporizadores",
  "title": "Título",
  "today": "Hoy",
  "tomorrow": "Mañana",
  "top_tracks": "Canciones favoritas",
  "treble": "Agudos",
  "try_again": "Intentar de nuevo",
  "turned_off": "Apagado",
  "turned_on": "Encendido",
  "turn_off": "Apagar",
  "turn_on": "Encender",
  "turn_on_music": "Enciende la musica",
  "turn_on_tv": "Enciende la televisión",
  "tv": "TV",
  "unavailable": "No disponible",
  "unavailable_src": "Fuente no disponible",
  "unavailable_src_txt": "La fuente actual no está disponible.",
  "ungroup": "Desagrupar",
  "unknown": "Desconocido",
  "unknown_account": "Cuenta desconocida",
  "unknown_account_l1": "Esta dirección de correo electrónico no es una cuenta Basalte.",
  "unknown_av": "Entrada AV desconocida",
  "unknown_src": "Fuente desconocida",
  "unknown_src_txt": "Fuente desconocida para este servidor.",
  "unlink": "Desconectar",
  "unlink_server": "Desconectar de la cuenta de Basalte",
  "unlink_server_full_sub": "¿Desconectar este proyecto de la cuenta de Basalte? Ya no será accesible fuera de su red local.",
  "unreachable": "Inaccesible",
  "unshare": "no compartir",
  "unsupported_browser": "Navegador no soportado",
  "unsupported_browser_l1": "Es posible que algunas funcionalidades no trabajen como se esperaba.",
  "unsupported_browser_l2": "Para una mejor experiencia de usuario use Chrome o Safari.",
  "unsupported_browser_live": "Este navegador no es compatible con la cuenta de Basalte. Intente utilizar una nueva vesión de Chrome o Safari.",
  "up": "Arriba",
  "update": "Actualizar",
  "update_available": "Actualizar",
  "update_available_full": "Actualización disponible",
  "update_info_l1": "Para asegurar la mejor experiencia posible,",
  "update_library": "Volver a explorar la biblioteca de música",
  "update_music_server": "Actualizar el servidor de música",
  "update_scene": "Actualizar la escena",
  "update_scene_l1": "Guardar el estado actual en esta escena.",
  "update_server": "Actualizar el servidor",
  "updating_server": "Actualizando servidor",
  "updating_server_info": "No apagar el servidor",
  "up_to_date": "A hoy",
  "username": "Nombre de usuario",
  "uv": "UV",
  "value_invalid": "El valor especificado no es válido",
  "verify": "Verificar",
  "verifying": "verificando",
  "version": "Versión",
  "video_background": "Video de fondo",
  "volume": "Volumen",
  "warning": "Advertencia",
  "water": "Agua",
  "weather": "Clima",
  "weather_daily_data_unavailable": "Actualmente no hay información meteorológica diaria disponible",
  "weather_hourly_data_unavailable": "Actualmente no hay información meteorológica por hora disponible",
  "weather_information": "Información del tiempo",
  "week": "Semana",
  "weekly": "Semanal",
  "what": "Qué",
  "what_music": "Qué música",
  "when": "Cuándo",
  "where": "Dónde",
  "who": "Quién",
  "wind": "viento",
  "wrong_password": "Contraseña incorrecta",
  "wrong_password_txt": "La contraseña que ha introducido no coincide con la contraseña de usuario",
  "wrong_username_and_or_password": "Nombre de usuario o contraseña incorrectos",
  "year": "Año",
  "yes": "Sí",
  "yes_all_off": "Sí, apagar todo",
  "yes_cur_off": "Sí, apagar",
  "yes_deezer_dis": "Sí, desconectar la cuenta Deezer",
  "yes_delete_it": "Sí, borrar",
  "yesterday": "Ayer",
  "zone": "Habitación",
  "zones": "Habitaciones"
}
