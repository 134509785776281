'use strict'

const BasUtil = require('@basalte/bas-util')

angular
  .module('basalteApp')
  .controller('roomDashboardCtrl', [
    '$rootScope',
    '$scope',
    'STATES',
    'BAS_ROOM',
    'BAS_ROOMS',
    'CurrentRoom',
    'BasRoomsHelper',
    'BasTile',
    'BasUtilities',
    'Swiper',
    roomDashboardCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param {STATES} STATES
 * @param {BAS_ROOM} BAS_ROOM
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {CurrentRoom} CurrentRoom
 * @param {BasRoomsHelper} BasRoomsHelper
 * @param BasTile
 * @param {BasUtilities} BasUtilities
 * @param Swiper
 */
function roomDashboardCtrl (
  $rootScope,
  $scope,
  STATES,
  BAS_ROOM,
  BAS_ROOMS,
  CurrentRoom,
  BasRoomsHelper,
  BasTile,
  BasUtilities,
  Swiper
) {

  const room = this

  let _listeners = []
  let _swiper

  /**
   * @type {BasRooms}
   */
  room.rooms = BAS_ROOMS.ROOMS

  /**
   * @type {TCurrentRoomState}
   */
  room.currentRoom = CurrentRoom.get()

  room.BasTile = BasTile

  room.selectTile = selectTile
  room.executeNoScenes = executeNoScenes
  room.selectMusicPlayerHandler = selectMusicPlayerHandler

  init()

  function init () {

    $scope.$on('$destroy', _onDestroy)

    _listeners.push($rootScope.$on(
      BAS_ROOM.EVT_SCENES_INITIALIZED,
      onRoomEvtScenesInitialized
    ))

    BasUtilities.waitForFrames(2, _initSwiper)
  }

  function _initSwiper () {
    var basRoom = CurrentRoom.getRoom()

    _swiper = new Swiper(
      '.bas-thermostats',
      {
        pagination: {
          el: '.swiper-pagination',
          renderBullet: _renderBullet
        },
        touchStartPreventDefault: true,
        touchMoveStopPropagation: true,
        touchStartForcePreventDefault: true,
        enabled: basRoom && basRoom.room.thermostats.length > 1,
        spaceBetween: 12
      }
    )

    function _renderBullet (_index, className) {
      return '<span class="' + className + ' bas-page-bullet">' +
        '<div class="bas-page-bullet-color"></div>' +
        '</span>'
    }
  }

  function selectTile (item) {
    var basRoom

    basRoom = CurrentRoom.getRoom()

    if (item && basRoom) {
      if (basRoom.thermostats?.thermostats?.includes(item)) {

        CurrentRoom.go(STATES.THERMOSTAT, {
          room: basRoom.id,
          thermostat: item.uuid
        })

      } else {

        switch (item.id) {
          case BAS_ROOMS.FUNCTIONS.LIGHTS:

            CurrentRoom.go(STATES.LIGHTS)

            break
          case BAS_ROOMS.FUNCTIONS.GENERIC_DEVICES:

            if (BasUtil.isNEString(item.uuid)) {

              CurrentRoom.go(
                STATES.GENERIC_DEVICES_V2,
                { genericDeviceUuid: item.uuid }
              )

            } else {

              CurrentRoom.go(
                STATES.GENERIC_DEVICES
              )
            }

            break
          case BAS_ROOMS.FUNCTIONS.SHADES:

            CurrentRoom.go(STATES.SHADES)

            break
          case BAS_ROOMS.FUNCTIONS.MUSIC:

            BasRoomsHelper.goToMusic(room.currentRoom.roomId)

            break
          case BAS_ROOMS.FUNCTIONS.VIDEO:

            BasRoomsHelper.goToVideo(room.currentRoom.roomId)

            break
          case BAS_ROOMS.FUNCTIONS.SCENES:

            CurrentRoom.go(STATES.ROOM_SCENES)

            break
        }
      }
    }
  }

  function executeNoScenes () {

    CurrentRoom.go(STATES.ROOM_SCENES, { openModal: true })
  }

  function selectMusicPlayerHandler () {

    var basRoom, musicBasSource, videoBasSource

    basRoom = CurrentRoom.getRoom()

    if (basRoom) {

      musicBasSource = basRoom.getMusicBasSource()
      videoBasSource = basRoom.getVideoBasSource()
    }

    CurrentRoom.go(
      (
        musicBasSource &&
        musicBasSource.isVideoSource &&
        basRoom.video &&
        basRoom.video.on &&
        videoBasSource &&
        videoBasSource.isVideoSource
      )
        ? STATES.VIDEO_PLAYER
        : STATES.MUSIC_PLAYER
    )
  }

  function onRoomEvtScenesInitialized () {

    $scope.$applyAsync()
  }

  function _onDestroy () {

    BasUtil.executeArray(_listeners)
    _listeners = []

    if (_swiper) _swiper.destroy(true, false)
    _swiper = null
  }
}
