{
  "12_hr": "12-hour",
  "24_hr": "24-hour",
  "24_hr_time": "24-Hour Time",
  "about": "About",
  "accepted": "Accepted",
  "account": "Account",
  "account_exists": "Account exists",
  "account_exists_l1": "This account exists already.",
  "account_exists_l2": "Please choose a different email address or login with this email address.",
  "account_not_confirmed": "Account not confirmed",
  "account_not_confirmed_l1": "This account is not confirmed yet.",
  "account_not_confirmed_l2": "Please check your email for a confirmation email.",
  "account_not_confirmed_l3": "Verify this account?",
  "act_in": "In",
  "activate": "Activate",
  "active": "Active",
  "act_out": "Out",
  "act_push": "PUSH",
  "adaptive_brightness": "Adaptive brightness",
  "add": "Add",
  "add_favourite": "Add favourite",
  "add_group": "Add group",
  "add_home": "Add home",
  "address": "Address",
  "add_to_fav": "Add to favourites",
  "add_to_playlist": "Add to playlist",
  "add_to_queue": "Add to queue",
  "air_conditioning": "Air conditioning",
  "air_pressure": "Air pressure",
  "alarm_at": "Alarm at",
  "alarm_missing_txt": "One or more required fields are missing",
  "alarm_not_selected_l1": "No alarm is selected.",
  "alarm_not_selected_l2": "Please select an alarm.",
  "alarms": "Alarms",
  "album": "Album",
  "albums": "Albums",
  "alert": "Alert",
  "all": "All",
  "all_off": "All Off",
  "all_off_txt_l1": "You're about to turn off all rooms.",
  "all_off_txt_l2": "Are you sure you want to continue?",
  "all_on": "All On",
  "almost_done": "You're almost done.",
  "almost_done_txt_l1": "You have no favourite playlists or radio stations yet.",
  "almost_done_txt_l2": "Go ahead and add some to your favourites first.",
  "already_linked": "Linked to Basalte account",
  "already_linked_to_me": "Linked to your Basalte account",
  "already_verified": "Already verified",
  "alt_power": "Power",
  "alt_user": "User",
  "application": "Application",
  "application_version": "Application version",
  "app_update_required": "App update required",
  "app_update_required_android_l2": "please update the app in the Play Store.",
  "app_update_required_ios_l2": "please update the app in the App Store.",
  "app_update_required_l2": "please update the app in the store.",
  "artist": "Artist",
  "artists": "Artists",
  "attention": "Attention!",
  "audio_unavailable": "Audio system is not available",
  "audio_zones": "Audio zones",
  "author": "Author",
  "back": "back",
  "basalte_account": "Basalte account",
  "basalte_info_l1": "With a Basalte account you are able to link your home and control it from outside your home network.",
  "basalte_link_server_l1": "Link this home to your Basalte account?",
  "basalte_link_server_l2": "You will be able to control this home from outside your local network.",
  "basalte_unlink_server_l1": "Unlink this home from your Basalte account?",
  "basalte_unlink_server_l2": "You will no longer be able to access this home from outside of your local network.",
  "bass": "Bass",
  "BE": "Belgium",
  "beta": "beta",
  "bluetooth": "Bluetooth",
  "bluetooth_pairing_txt": "You're now able to connect your device via bluetooth.",
  "brightness": "Brightness",
  "browse": "Browse",
  "browse_deezer": "Deezer favourites",
  "browse_library": "Music library",
  "browser_app_update_required_l2": "please reload the page or update the server.",
  "browse_stations": "Radio stations",
  "buffering": "buffering",
  "building_building": "Building",
  "building_garage": "Garage",
  "building_main_house": "Main House",
  "building_pool_house": "Pool House",
  "building_stables": "Stables",
  "call_accepted": "Call accepted",
  "call_declined": "Call declined",
  "call_history": "Call history",
  "camera_loading_error": "Unable to load camera",
  "cameras": "Cameras",
  "cancel": "Cancel",
  "categories": "Categories",
  "category": "Category",
  "celsius": "Celsius",
  "change_account": "Change account",
  "change_home": "Change home",
  "change_image": "Change image",
  "change_password": "Change password",
  "change_password_l1": "You're about to change your password.",
  "change_user": "Change profile",
  "channel_number": "Channel number",
  "charts": "Charts",
  "check_for_updates": "Check for updates",
  "check_inbox": "Please check your inbox.",
  "checking": "checking",
  "checking_for_updates": "Checking for updates...",
  "choose_favourite": "Would you like to pick a favourite?",
  "choose_home": "Choose home",
  "choose_music": "Would you like to select some music?",
  "choose_preset": "Choose a preset",
  "choose_source": "Would you like to pick a source?",
  "clear": "Clear",
  "cleared": "Cleared",
  "clear_queue_txt_l1": "You're about to delete your queue.",
  "clear_queue_txt_l2": "Are you sure you want to continue?",
  "clear_schedules": "Are you sure you want to clear your schedules?",
  "clear_selection": "Clear Selection",
  "clear_selection_txt_l1": "You're about to delete your selection.",
  "clear_selection_txt_l2": "Are you sure you want to continue?",
  "close": "Close",
  "closed": "Closed",
  "cloud_environment": "Cloud environment",
  "code": "Code",
  "collapse": "Collapse",
  "color": "Color",
  "color_temperature": "Color temperature",
  "color_theme": "Color theme",
  "color_white": "White",
  "comfort": "Comfort",
  "configure": "Configure",
  "confirmation_code_sent": "Confirmation code sent",
  "confirmation_code_sent_l1": "A confirmation code has been sent to the following address:",
  "confirmation_mail_sent": "If a basalte account with this email exists, a new verification mail has ben sent out.",
  "confirmation_mail_sent_title": "Confirmation mail sent",
  "confirm_continue": "Are you sure you want to continue?",
  "confirm_overwrite": "Are you sure you want to overwrite the existing data?",
  "connect": "Connect",
  "connected": "Connected",
  "connecting": "Connecting",
  "connecting_no_active_servers": "Server is not online.",
  "connecting_no_integrator_access": "Remote configuration is not enabled on this server.",
  "connecting_not_authorized": "Not authorized to connect with this server.",
  "connecting_small": "connecting",
  "connecting_stop_and_retry_text": "Failed to connect to server. Retrying in 10 seconds.",
  "connecting_stop_text": "Failed to connect to server.",
  "connecting_text": "Connecting...",
  "connecting_text_small": "connecting...",
  "connection_lost": "No connection",
  "connection_lost_text": "Reconnecting with the server",
  "connection_unable": "Unable to connect with the specified server.",
  "connect_offline_server_l1": "This home is linked, but seems to be offline.",
  "connect_offline_server_l2": "Try to connect anyway?",
  "connect_server": "Connect to server",
  "consumption": "Consumption",
  "contact_support": "Contact support",
  "continue_anyway": "Continue anyway",
  "continue_without_basalte_account": "Continue without Basalte account",
  "cover_art_background": "Cover art background",
  "current_password": "Current password",
  "current_temperature": "Current",
  "custom": "Custom",
  "date": "Date",
  "day": "Day",
  "day_friday": "Friday",
  "day_monday": "Monday",
  "day_saturday": "Saturday",
  "day_short2_friday": "Fr",
  "day_short2_monday": "Mo",
  "day_short2_saturday": "Sa",
  "day_short2_sunday": "Su",
  "day_short2_thursday": "Th",
  "day_short2_tuesday": "Tu",
  "day_short2_wednesday": "We",
  "day_sunday": "Sunday",
  "day_thursday": "Thursday",
  "day_tuesday": "Tuesday",
  "day_wednesday": "Wednesday",
  "debug": "Debug",
  "debug_code": "Code",
  "debug_remote": "Remote debugging",
  "debug_remote_enable": "Enable remote debugging",
  "debug_remote_exit_info": "To disable remote debugging, completely restart the app.",
  "declined": "Declined",
  "deezer": "Deezer",
  "deezer_disconnect_txt": "You're about to disconnect the Deezer account from this player.",
  "deezer_favourite": "Deezer favourite",
  "deezer_flow": "Flow",
  "deezer_flow_description": "Your personal soundtrack",
  "deezer_flow_full": "Deezer Flow",
  "deezer_no_link": "No Deezer account linked yet",
  "deezer_oauth_error": "Deezer authentication error",
  "default_rooms": "Default rooms",
  "default_rooms_hint": "In order for in-home streaming to function properly, make sure at least 1 room is selected as default room.",
  "default_rooms_info_l1": "Default rooms for third party audio streams (such as Spotify Connect).",
  "default_rooms_info_l2": "When no rooms are connected to the stream, activate the specified rooms.",
  "default_rooms_spotify_hint": "In order for Spotify Connect to function properly, make sure at least 1 room is selected as default room.",
  "default_rooms_spotify_warning_l1": "Set at least one default room for each stream",
  "default_rooms_spotify_warning_l2": "to complete the configuration.",
  "default_rooms_spotify_warning_title": "Spotify Connect is now supported",
  "default_view": "Default view",
  "delay": "Delay",
  "delay_seconds_b": "Delay (seconds)",
  "delete": "Delete",
  "delete_account": "Delete account",
  "delete_account_l1": "You are about to permanently delete your account.",
  "demo_enter": "Demo mode",
  "demo_functionality_warning_txt": "This functionality is not supported in demo mode.",
  "demo_location": "Ghent",
  "demo_server_name": "Demo Home",
  "demo_title": "Demo",
  "demo_warning_txt": "You're about to enter demo mode.",
  "detail": "detail",
  "device": "Device",
  "devices": "Devices",
  "device_settings": "Device settings",
  "device_volume": "Device volume",
  "dhcp": "DHCP",
  "direction_east": "East",
  "direction_east-northeast": "East-northeast",
  "direction_east-southeast": "East-southeast",
  "direction_north": "North",
  "direction_northeast": "Northeast",
  "direction_north-northeast": "North-northeast",
  "direction_north-northwest": "North-northwest",
  "direction_northwest": "Northwest",
  "direction_short_east": "E",
  "direction_short_east-northeast": "ENE",
  "direction_short_east-southeast": "ESE",
  "direction_short_north": "N",
  "direction_short_northeast": "NE",
  "direction_short_north-northeast": "NNE",
  "direction_short_north-northwest": "NW",
  "direction_short_northwest": "NW",
  "direction_short_south": "S",
  "direction_short_southeast": "SE",
  "direction_short_south-southeast": "SSE",
  "direction_short_south-southwest": "SSW",
  "direction_short_southwest": "SW",
  "direction_short_west": "W",
  "direction_short_west-northwest": "WNW",
  "direction_short_west-southwest": "WSW",
  "direction_south": "South",
  "direction_southeast": "Southeast",
  "direction_south-southeast": "South-southeast",
  "direction_south-southwest": "South-southwest",
  "direction_southwest": "Southwest",
  "direction_west": "West",
  "direction_west-northwest": "West-northwest",
  "direction_west-southwest": "West-southwest",
  "disband_group": "Disband group",
  "dns": "DNS",
  "domain": "Domain",
  "done": "Done",
  "do_not_disturb": "Do not disturb",
  "do_not_disturb_off_l1": "All sound notifications are currently turned on.",
  "do_not_disturb_on_l1": "All sound notifications are currently turned off.",
  "do_not_show_again": "Do not show again",
  "door_phone": "Door phone",
  "doors": "Doors",
  "down": "Down",
  "downloading": "downloading",
  "downloading_updates": "Downloading updates",
  "do_you_want_to_continue": "Do you want to continue?",
  "drag_and_drop_images": "Select image or drop it here",
  "drag_and_drop_lights": "Drag and drop lights here",
  "drag_to_reorder": "Drag to reorder",
  "dsp": "DSP",
  "dsp_configuration": "DSP configuration",
  "dsp_profile_club": "Club",
  "dsp_profile_custom": "Custom",
  "dsp_profile_flat": "Flat",
  "dsp_profile_jazz": "Jazz",
  "dsp_profile_live": "Live",
  "dsp_profile_pop": "Pop",
  "dsp_profile_rock": "Rock",
  "dsp_shortcut_l1": "You're about to open the DSP settings.",
  "echo_cancellation": "Echo cancellation",
  "echo_limit": "Echo limit",
  "edit": "Edit",
  "edit_dns": "Edit DNS",
  "edit_gateway": "Edit gateway",
  "edit_ip_address": "Edit IP address",
  "edit_subnet_mask": "Edit subnet mask",
  "ellie_room": "Ellie room",
  "ellie_system_update": "Ellie system update",
  "ellie_system_update_l1": "Ellie needs to restart to install the system update.",
  "ellie_update": "Ellie update",
  "email": "Email Address",
  "empty": "empty",
  "empty_queue": "Queue is empty",
  "empty_queue_txt_l1": "It seems like you have no songs in your queue.",
  "empty_queue_txt_l2": "Go ahead and add some.",
  "empty_song": "Player has stopped",
  "empty_song_txt_l1": "You have reached the end of your queue.",
  "empty_song_txt_l2": "Click play to restart.",
  "empty_src": "No source selected",
  "empty_src_txt_l1": "It seems like you haven't selected a source.",
  "empty_src_txt_l2": "Go ahead and choose a source first.",
  "empty_zone": "No room selected",
  "empty_zone_txt_l1": "It seems like you haven't selected a room.",
  "empty_zone_txt_l2": "Go ahead and choose a room first.",
  "energy": "Energy",
  "energy_meter": "Energy meter",
  "eq": "EQ",
  "error": "Error",
  "external": "External",
  "extra_permissions_required": "Some features may require additional device permissions.",
  "ext_src": "External source",
  "fahrenheit": "Fahrenheit",
  "failed": "Failed",
  "fan": "Fan",
  "fan_auto": "Auto",
  "fan_high": "High",
  "fan_low": "Low",
  "fan_medium": "Medium",
  "fan_off": "Off",
  "favourite": "Favourite",
  "favourites": "Favourites",
  "favourite_scenes_full": "Favourite scenes are full",
  "favourite_scenes_full_sub": "You can set a maximum of 4 favourite scenes in a room",
  "favourite_songs": "Favourite songs",
  "featured_playlists": "Featured playlists",
  "floor_attic": "Attic",
  "floor_basement": "Basement",
  "floor_floor": "Floor",
  "floor_garden": "Garden",
  "floor_heating": "Underfloor heating",
  "floor_loft": "Loft",
  "floor_lower": "Lower floor",
  "floor_lvl_0": "Ground floor",
  "floor_lvl_1": "First floor",
  "floor_lvl_2": "Second floor",
  "floor_lvl_3": "Third floor",
  "floor_lvl_4": "Fourth floor",
  "floor_lvl_5": "Fifth floor",
  "floor_lvl_6": "Sixth floor",
  "floor_lvl_7": "Seventh floor",
  "floor_lvl_8": "Eighth floor",
  "floor_lvl_9": "Ninth floor",
  "floor_lvl_10": "Tenth floor",
  "floor_lvl_11": "Eleventh floor",
  "floor_lvl_12": "Twelfth floor",
  "floor_lvl_13": "Thirteenth floor",
  "floor_lvl_14": "Fourteenth floor",
  "floor_lvl_15": "Fifteenth floor",
  "floor_lvl_16": "Sixteenth floor",
  "floor_lvl_17": "Seventeenth floor",
  "floor_lvl_18": "Eighteenth floor",
  "floor_lvl_19": "Nineteenth floor",
  "floor_lvl_20": "Twentieth floor",
  "floor_upper": "Upper floor",
  "forgot_password": "Forgot password",
  "freeze_time": "Freeze clock",
  "frequency": "Frequency",
  "full_screen": "Full screen",
  "functions": "Functions",
  "gas": "Gas",
  "gateway": "Gateway",
  "general": "General",
  "generic_devices": "Devices",
  "generic_devices_fire": "Fire",
  "generic_devices_garden": "Garden",
  "generic_devices_socket": "Socket",
  "genre": "Genre",
  "genre_moods": "Genre and Moods",
  "genres": "Genres",
  "go_to_login_l1": "Sign in or sign up for Basalte account.",
  "go_to_manage_l1": "Manage your Basalte account.",
  "group": "Group",
  "groups": "Groups",
  "home": "Home",
  "humidity": "Humidity",
  "hz": "Hz",
  "images": "Images",
  "image_smaller_than_5mb": "Image must be smaller than 5MB",
  "in": "In",
  "incorrect_album": "The album was not found",
  "incorrect_artist": "The artist was not found",
  "install": "Install",
  "installing": "installing",
  "install_update": "Install update",
  "install_updates": "Install updates",
  "integrator": "Integrator",
  "integrator_access": "Remote configuration",
  "integrator_access_allow": "Allow remote configuration",
  "integrator_access_ask": "Ask the owner to enable remote configuration.",
  "integrator_access_disabled": "Remote configuration is disabled",
  "integrator_access_explanation": "This enables (fully encrypted and secure) remote access to your system, so your home automation integrator can:",
  "integrator_access_o1_program_basalte": "program and control your Basalte system",
  "integrator_access_o2_program_knx": "program your KNX devices",
  "integrator_access_o3_access_network": "access your full IP network",
  "integrator_access_privacy": "For your privacy, we suggest only enabling this temporarily when needed.",
  "intercom": "Intercom",
  "internal": "Internal",
  "invalid_address": "Invalid address",
  "invalid_code": "Invalid code",
  "invalid_code_l1": "The given code was not correct.",
  "invalid_code_l2": "Make sure the code is correct or request a new one.",
  "invalid_input": "Invalid input",
  "invalid_user": "Invalid user",
  "invalid_user_l1": "Make sure that the email address is valid.",
  "ios_plist_string_NSCameraUsageDescription": "Camera can be used to take photos for rooms",
  "ios_plist_string_NSLocalNetworkUsageDescription": "Local network access can be used to find home servers, IP cameras, ...",
  "ios_plist_string_NSPhotoLibraryAddUsageDescription": "Photo Library can be used to save photos for rooms",
  "ios_plist_string_NSPhotoLibraryUsageDescription": "Photo Library can be used to select photos for rooms",
  "ip": "IP",
  "ip_address": "IP address",
  "join": "+ Join",
  "join_alt": "+ Join",
  "keep_awake": "Keep iPad awake",
  "keep_awake_device": "Keep device awake",
  "kelvin": "Kelvin",
  "Key": "en_GB",
  "knx_presets": "KNX presets",
  "knx_presets_info": "Modify existing KNX presets to play something else.",
  "landscape": "Landscape",
  "landscape_reverse": "Landscape reverse",
  "language": "Language",
  "language_system": "System language",
  "last-7-days": "Last 7 days",
  "last_hour": "Last hour",
  "last_room": "Last room",
  "last_view": "Last view",
  "learn_more": "Learn More",
  "leave_group": "Leave group",
  "left": "Left",
  "lena_system_update": "Lena system update",
  "lena_system_update_l1": "Lena needs to restart to install the system update.",
  "library": "Library",
  "library_empty": "Library is empty",
  "library_scanning": "Scanning library",
  "light_accent_light": "Accent light",
  "light_art_light": "Art light",
  "light_cabinet": "Cabinet",
  "light_ceiling_mounted_fixture": "Ceiling light",
  "light_chandelier": "Chandelier",
  "light_desk": "Desk",
  "light_down": "Downlight",
  "light_floor_lamp": "Floor light",
  "light_lampshade": "Lampshade",
  "light_led": "LED light",
  "light_led_strip": "LED strip",
  "light_mirror": "Mirror",
  "light_mood_light": "Mood light",
  "light_pendant": "Pendant",
  "light_reading_light": "Reading light",
  "lights": "Lights",
  "lights_off_all": "Turn off all lights",
  "light_spot": "Spot",
  "light_table_lamp": "Table light",
  "light_track": "Track",
  "light_via": "Via",
  "light_wall_mounted_fixture": "Wall light",
  "link": "Link",
  "link_deezer_info_l1": "Link a Deezer account with a stream.",
  "link_deezer_info_l2": "This enables browsing and playing Deezer media on the stream.",
  "linked_homes": "Linked homes",
  "linked_projects": "Linked projects",
  "linked_with": "Linked to",
  "link_home": "Open the Basalte app on a local device and link your home in settings.",
  "link_server": "Link to Basalte account",
  "link_server_full_sub": "Link this project to the following Basalte account?",
  "link_spotify_info_l1": "Link a Spotify account with a stream.",
  "link_spotify_info_l2": "This enables browsing and playing Spotify media on the stream.",
  "link_tidal_info_l1": "Link a TIDAL account with a stream.",
  "link_tidal_info_l2": "This enables browsing and playing TIDAL media on the stream.",
  "lisa_order_hint": "Change the order and visibility of pages and tiles.",
  "lisa_system_update": "Lisa system update",
  "lisa_system_update_l1": "Lisa needs to restart to install the system update.",
  "listen_in": "Listen in",
  "liters": "Liters",
  "live_adjustments": "Live adjustments",
  "loading": "Loading…",
  "local": "local",
  "location": "Location",
  "location_back": "Back",
  "location_bottom": "Bottom",
  "location_center": "Center",
  "location_chair": "Chair",
  "location_corner": "Corner",
  "location_desk": "Desk",
  "location_east": "East",
  "location_front": "Front",
  "location_high": "High",
  "location_island": "Island",
  "location_left": "Left",
  "location_low": "Low",
  "location_mid": "Mid",
  "location_niche": "Niche",
  "location_north": "North",
  "location_right": "Right",
  "location_shelves": "Shelves",
  "location_south": "South",
  "location_stove": "Stove",
  "location_table": "Table",
  "location_top": "Top",
  "location_west": "West",
  "logged_in_as": "Logged in as",
  "logged_out": "Logged out",
  "logged_out_description": "You were logged out of your basalte account",
  "log_out": "Log out",
  "log_out_everywhere": "Log out everywhere",
  "log_out_everywhere_l1": "You're about to log out from all apps and devices.",
  "long-press-save-scene": "Long press a scene to update it",
  "louver_30": "30 Degrees",
  "louver_45": "45 Degrees",
  "louver_60": "60 Degrees",
  "louver_horizontal": "Horizontal",
  "louver_mode": "Louver mode",
  "louver_off": "Off",
  "louver_swing": "Swing",
  "louver_vertical": "Vertical",
  "mac_address": "MAC address",
  "manage": "Manage",
  "manage_account": "Manage account",
  "manual_conn": "Manual connection",
  "manual_text": "Please enter the address of the desired server.",
  "max": "Max",
  "menu": "Menu",
  "mf_device": "{count, plural, =1 {Device} other {Devices}}",
  "mf_feels_like": "Feels like {value}",
  "mf_link_streaming_service": "Link your {streamingService} account to this stream by clicking the 'Link' button below.",
  "mf_minute": "{count} {count, plural, =1 {minute} other {minutes}}",
  "mf_powered_by": "Powered by {value}",
  "mf_second": "{count} {count, plural, =1 {second} other {seconds}}",
  "mf_server": "{count, plural, =1 {Server} other {Servers}}",
  "mf_song": "{count} {count, plural, =1 {song} other {songs}}",
  "mf_source": "Source: {value}",
  "microphone_gain": "Microphone gain",
  "min": "Min",
  "mix": "Mix",
  "mixed_sources": "Multiple sources",
  "mixed_sources_txt_l1": "This group contains rooms with multiple sources.",
  "mixed_sources_txt_l2": "Go ahead and select a source.",
  "mixes": "Mixes",
  "mixes_small": "mixes",
  "mode": "Mode",
  "mode_auto": "Auto",
  "mode_cooling": "Cooling",
  "mode_drying": "Drying",
  "mode_fan_only": "Fan only",
  "mode_heating": "Heating",
  "mode_off": "Off",
  "month": "Month",
  "month_april": "April",
  "month_august": "August",
  "month_december": "December",
  "month_february": "February",
  "month_january": "January",
  "month_july": "July",
  "month_june": "June",
  "month_march": "March",
  "month_may": "May",
  "month_november": "November",
  "month_october": "October",
  "month_september": "September",
  "month_short_april": "Apr",
  "month_short_august": "Aug",
  "month_short_december": "Dec",
  "month_short_february": "Feb",
  "month_short_january": "Jan",
  "month_short_july": "Jul",
  "month_short_june": "Jun",
  "month_short_march": "Mar",
  "month_short_may": "May",
  "month_short_november": "Nov",
  "month_short_october": "Oct",
  "month_short_september": "Sep",
  "mood": "Mood",
  "moods": "Moods",
  "music": "Music",
  "music_choice": "Music choice",
  "music_go_to": "Go to music",
  "music_is_active": "Music is active",
  "music_is_playing": "Music is playing",
  "music_off": "music off",
  "music_off_all": "Turn off all rooms",
  "music_off_all_rooms": "Music off, all rooms",
  "music_off_current": "Turn off the current room",
  "music_off_current_room": "Music off, this room",
  "music_off_txt_l1": "You're about to turn off one or all rooms.",
  "music_off_txt_l2": "Are you sure you want to continue?",
  "music_on_current": "Turn on the current room",
  "music_server": "Music server",
  "mute_area_on_call": "Mute music during call",
  "my_favorites": "My favourites",
  "my_music": "My music",
  "name": "Name",
  "nds": "Searching for servers",
  "nds_music": "Searching for music servers",
  "nds_none": "No servers were found!",
  "nds_none_music": "No music servers were found!",
  "nds_text_l1": "This could take a few seconds...",
  "nds_text_l2": "Make sure that your device is connected to your home Wi-Fi network.",
  "network": "Network",
  "network_input_invalid": "All input fields must be correctly filled in",
  "never": "Never",
  "new": "New",
  "new_alarm": "New alarm",
  "new_image": "New image",
  "new_password": "New password",
  "new_password_confirmation": "New password confirmation",
  "new_playlist": "new playlist...",
  "new_releases": "New releases",
  "new_releases_small": "new releases",
  "new_scene": "New scene",
  "new_schedule": "New schedule",
  "new_step": "New step",
  "next": "Next",
  "next_song": "next song",
  "no": "No",
  "no_active_rooms": "No active rooms",
  "no_all_off": "No, don't turn off",
  "no_av": "No AV input",
  "no_call_entries": "No call entries available",
  "no_configuration": "No configuration available",
  "no_content_available": "No content available",
  "no_data": "No data",
  "no_deezer_dis": "No, don't disconnect Deezer",
  "no_devices_capabilities": "No adjustable devices",
  "no_doors_available": "No doors available",
  "no_favourites": "No favourites",
  "no_home_permission": "You do not have permission to access this home",
  "no_keep_it": "No, keep it",
  "no_lights_capabilities": "No adjustable lights",
  "no_linked_homes": "No linked homes were found.",
  "none": "None",
  "no_network": "No network connection",
  "no_preview_available": "No preview available",
  "no_projects": "No projects were found.",
  "no_rooms": "No rooms",
  "no_rooms_available": "No rooms available",
  "no_room_selected": "no room selected",
  "no_rooms_listening": "There are no rooms listening to this source",
  "no_scene": "No scene",
  "no_scenes_favourites": "No favourite scenes",
  "no_scenes_txt": "Select scenes to show here.",
  "no_search_results": "No search results",
  "no_servers": "No servers were found",
  "no_shades_capabilities": "No adjustable shades",
  "no_source": "- off -",
  "no_sources_available": "No sources available",
  "no_steps": "This scene contains no steps",
  "not_allowed": "Not allowed",
  "not_allowed_l1": "You don't have permission to do this",
  "notification": "Notification",
  "notification_permission_missing": "Basalte Home does not have permission to send notifications. You can enable notifications in the system settings of your device.",
  "notifications": "Notifications",
  "not_linked": "Not linked",
  "not_now": "Not now",
  "not_set": "Not set",
  "no_users_l1": "There are no users discovered.",
  "no_users_l2": "Select a server with users.",
  "now_playing": "Now playing",
  "number_of_songs": "song(s)",
  "off": "Off",
  "offline_server": "Offline server",
  "offset_minutes": "Offset (minutes)",
  "ok": "OK",
  "on": "on",
  "onix_black": "Black",
  "online": "online",
  "only_png_jpeg_supported": "Only PNG and JPEG images are supported",
  "open": "Open",
  "open_close_door": "Door",
  "open_close_gate": "Gate",
  "open_close_window": "Window",
  "open_settings": "Open settings",
  "open_spotify": "Open Spotify",
  "open_spotify_l1": "You're about to open the Spotify App.",
  "open_state": "Open",
  "or": "Or",
  "order_cameras_by_holding_and_draging": "Drag the cameras in the desired order.",
  "other": "Other",
  "out": "Out",
  "page_order": "Page/tile order",
  "pair": "Pair",
  "password": "Password",
  "password_changed": "Password changed",
  "password_confirmation": "Password confirmation",
  "password_requirements": "Your password should contain at least 8 characters and include at least one upper and lowercase letter and one number.",
  "permission_denied": "Permission denied",
  "permission_denied_go_to_settings_l1": "This feature needs permission(s) to function properly.",
  "permission_denied_go_to_settings_l2": "Allow permission(s) for this app in the settings of your device to enable all features.",
  "photo_from_camera": "Take a picture",
  "photo_from_library": "Select image from library",
  "pick_room_function": "Pick a room and a function",
  "play": "Play",
  "playback_gain": "Playback gain",
  "playing_in": "is playing in",
  "playlist": "Playlist",
  "playlists": "Playlists",
  "playlists_itunes": "iTunes playlists",
  "playlists_l": "playlists",
  "playlists_local": "Local playlists",
  "playlists_shared": "Shared playlists",
  "play_next": "Play next",
  "play_now": "Play now",
  "please_login_again": "Please log in again",
  "please_try_a_different_image": "Please try a different image",
  "please_try_again": "Please try again",
  "popular": "Popular",
  "portrait": "Portrait",
  "portrait_reverse": "Portrait reverse",
  "position": "Position",
  "precipitation": "Precipitation",
  "previous": "Previous",
  "problems_continue_integrator": "If you keep experiencing issues, please contact your integrator.",
  "project": "Project",
  "project_image": "Project image",
  "projects_network_error": "Unable to load projects because of a network error.",
  "proximity_activation": "Proximity activation",
  "proximity_far": "Far",
  "proximity_medium": "Medium",
  "proximity_near": "Near",
  "queue": "Queue",
  "radio": "Radio",
  "radio_error": "There was an error retrieving radio stations",
  "radio_stations": "Radio stations",
  "radio_stations_other": "Other Stations",
  "recently_played": "Recently played",
  "related_artists": "Related artists",
  "reload": "Reload",
  "remember_me": "Remember me",
  "remote": "Remote",
  "remote_ellie_intercom_outdated": "Remote Ellie outdated",
  "remote_ellie_intercom_outdated_l1": "The Ellie you tried to call needs a software update.",
  "remote_ellie_intercom_outdated_l2": "Try again after updating.",
  "remove": "Remove",
  "remove_alarm": "Remove alarm",
  "remove_basalte_favourite": "Remove Basalte favourite",
  "remove_deezer_favourite": "Remove Deezer favourite",
  "remove_from_fav": "remove from favourites",
  "remove_schedule": "Remove schedule",
  "remove_spotify_favourite": "Remove Spotify favourite",
  "remove_spotify_preset": "Remove Spotify preset",
  "remove_tidal_favourite": "Remove TIDAL favourite",
  "remove_txt_l1": "You're about to remove this item.",
  "remove_txt_l2": "Are you sure you want to continue?",
  "rename": "rename",
  "repeat": "Repeat",
  "replace_queue": "Replace Queue",
  "resend_code": "Resend code",
  "resend_confirmation_code": "Resend confirmation code",
  "reset_image": "Reset image",
  "reset_password": "Reset password",
  "reset_password_l1": "Are you sure you want to reset your password?",
  "restart": "Restart",
  "restart_app": "Restart app",
  "restart_app_q": "Restart app?",
  "restarting_server": "Restarting server",
  "restart_music_server": "Restart the music server",
  "restart_music_server_txt_l1": "You're about to restart the music server.",
  "restart_server": "Restart the server",
  "restart_server_txt_l1": "You're about to restart the server.",
  "restart_server_txt_l2": "The application will reconnect automatically.",
  "retrieving": "Retrieving…",
  "retrieving_users_stop_text": "Failed to retrieve users, correct server?",
  "retrieving_users_text": "Retrieving users...",
  "right": "Right",
  "room": "Room",
  "room_attic": "Attic",
  "room_ball_room": "Ball Room",
  "room_bar": "Bar",
  "room_basement": "Basement",
  "room_bathroom": "Bathroom",
  "room_bedroom": "Bedroom",
  "room_corridor": "Corridor",
  "room_dining_room": "Dining Room",
  "room_dressing_room": "Dressing Room",
  "room_driveway": "Driveway",
  "room_entry": "Entry",
  "room_family_room": "Family Room",
  "room_foyer": "Foyer",
  "room_garage": "Garage",
  "room_garden": "Garden",
  "room_guest_room": "Guest Room",
  "room_gym": "Gym",
  "room_hallway": "Hallway",
  "room_hobby_room": "Hobby Room",
  "room_home_cinema": "Home Cinema",
  "room_home_office": "Home Office",
  "room_images": "Room Images",
  "room_intercom_lost": "Connection lost",
  "room_intercom_lost_sub": "Lost call connection, please try again",
  "room_intercom_unavailable": "Room unavailable",
  "room_intercom_unavailable_sub": "This room is currently unavailable",
  "room_intercom_unreachable": "Room unreachable",
  "room_intercom_unreachable_sub": "This room is currently unreachable",
  "room_kitchen": "Kitchen",
  "room_laundry_room": "Laundry Room",
  "room_library": "Library",
  "room_living_room": "Living Room",
  "room_master_bedroom": "Master Bedroom",
  "room_media_room": "Media Room",
  "room_meeting_room": "Meeting Room",
  "room_nook": "Nook",
  "room_nursery": "Nursery",
  "room_pantry": "Pantry",
  "room_parking_place": "Parking Lot",
  "room_patio": "Patio",
  "room_pool": "Pool",
  "room_pool_house": "Pool House",
  "room_rack": "Rack",
  "room_room": "Room",
  "rooms": "Rooms",
  "room_scene_no_functions": "This room has no functions",
  "room_settings": "Room settings",
  "room_spa": "Spa",
  "room_stable": "Stable",
  "room_staircase": "Staircase",
  "room_terrace": "Terrace",
  "room_toilet": "Toilet",
  "room_utility_room": "Utility room",
  "room_wine_cellar": "Wine Cellar",
  "room_workshop": "Workshop",
  "rotation": "Rotation",
  "save": "Save",
  "save_as": "save as",
  "save_basalte_favourite": "Save Basalte favourite",
  "save_changes": "Save changes",
  "save_changes_l1": "Some changes haven't been saved yet",
  "save_changes_l2": "Do you want to save them now?",
  "save_deezer_favourite": "Save Deezer favourite",
  "save_spotify_favourite": "Save Spotify favourite",
  "save_spotify_preset": "Save Spotify preset",
  "save_tidal_favourite": "Save TIDAL favourite",
  "scene": "Scene",
  "scene_activated": "Scene activated",
  "scene_away": "Away",
  "scene_cooking": "Cooking",
  "scene_dinner": "Dinner",
  "scene_error": "Scene error",
  "scene_evening": "Evening",
  "scene_good_morning": "Good Morning",
  "scene_good_night": "Good Night",
  "scene_guests": "Guests",
  "scene_home": "Home",
  "scene_image_error": "Scene image error",
  "scene_movie": "Movie",
  "scene_not_available": "No scenes present.",
  "scene_not_selected_l1": "No scene is selected.",
  "scene_not_selected_l2": "Please select a scene.",
  "scene_party": "Party",
  "scene_relax": "Relax",
  "scenes": "Scenes",
  "scene_select_or_new": "Please select a scene or create a new one.",
  "scene_vacation": "Vacation",
  "scene_welcome": "Welcome",
  "scene_workout": "Workout",
  "schedule": "Timer",
  "schedule_at": "Schedule at",
  "schedule_not_selected_l1": "No schedule is selected.",
  "schedule_not_selected_l2": "Please select a schedule.",
  "scheduler": "Scheduler",
  "schedules": "Schedules",
  "schedule_select_or_new": "Please select a schedule or create a new one.",
  "screen_orientation": "Screen orientation",
  "search": "Search",
  "search_again": "Search again",
  "search_ph": "search artist, song or album...",
  "search_result": "Search results for",
  "security": "Security",
  "select_admin": "Select an admin profile to continue the update process",
  "select_home": "Select your home",
  "selection": "Selection",
  "selection_all": "add all to selection",
  "select_photo": "Select image",
  "select_server": "Select a server",
  "select_user": "Select a profile to log in",
  "serial_number": "Serial number",
  "server": "Server",
  "server_address": "Server address",
  "server_basalte_cloud_no_connection": "Server cannot connect to the Basalte Cloud",
  "server_exists_already": "Server already exists!",
  "server_mac": "Server MAC address",
  "servers": "Servers",
  "server_status": "Server status",
  "server_update_info_l2": "please update the Basalte server to the latest version.",
  "server_update_required": "Server update required",
  "server_version": "Server version",
  "set": "Set",
  "set_new_password": "Set new password",
  "setpoint": "Setpoint",
  "settings": "Settings",
  "shade_blind": "Blind",
  "shade_center": "Center",
  "shade_curtain": "Curtain",
  "shade_left": "Left",
  "shade_pool_shutter": "Pool Shutter",
  "shade_right": "Right",
  "shade_roman_blind": "Roman blind",
  "shades": "Shades",
  "shade_shade": "Shade",
  "shade_shutter": "Shutter",
  "shade_solar_protection": "Solar protection",
  "shade_venetian_blind": "Venetian blind",
  "shade_vertical_blind": "Vertical blind",
  "share": "share",
  "shared_playlist": "Shared playlist",
  "show_album": "Show album",
  "show_artist": "Show artist",
  "show_background_art": "Show background art",
  "show_more": "Show more…",
  "show_status_bar": "Show iPad status bar",
  "show_status_bar_device": "Show status bar",
  "sign_in": "Sign in",
  "sign_in_info_tidal_l1": "Enter your TIDAL login credentials",
  "sign_up": "Sign up",
  "single_room": "Room",
  "sleep": "Sleep",
  "software_update": "Software update",
  "something_went_wrong": "Something went wrong",
  "songs": "Songs",
  "sonos": "Sonos",
  "sonos_unreachable": "Can't find Sonos",
  "sonos_unreachable_txt_l1": "Verify that your device is plugged in and connected.",
  "sound_settings": "Sound settings",
  "source": "Source",
  "sources": "Sources",
  "spotify": "Spotify",
  "spotify_beta": "Spotify (beta)",
  "spotify_connect": "Spotify Connect",
  "spotify_connect_instructions": "Spotify Connect",
  "spotify_connect_instructions_1": "Make sure the Core server and the device with the Spotify app are on the same network.",
  "spotify_connect_instructions_2": "Select the Core server stream from the device picker in the Spotify app.",
  "spotify_connect_instructions_body": "Listen on your speakers or TV, using the Spotify app as a remote.",
  "spotify_connect_instructions_title": "Ready to play some music?",
  "spotify_disconnect_txt": "You're about to disconnect the Spotify account from this player.",
  "spotify_free_user_txt": "A Spotify Premium subscription is required",
  "spotify_no_link": "No Spotify account linked yet",
  "spotify_oauth_error": "Spotify authentication error",
  "spotify_preset_error": "Spotify error",
  "spotify_preset_error_l1": "Could not load Spotify preset.",
  "spotify_preset_error_l2": "Make sure a valid Spotify account has connected with this stream before.",
  "spotify_presets": "Spotify Presets",
  "start": "Start",
  "start_page": "Start page",
  "start_page_customisation": "Start page customisation",
  "start_time_date": "Time and date",
  "startup_view": "Startup view",
  "startup_volume": "Startup volume",
  "start_weather": "Weather information",
  "state_active": "Active",
  "state_armed": "Armed",
  "state_closed": "Closed",
  "state_disabled": "Disabled",
  "state_disarmed": "Disarmed",
  "state_down": "Down",
  "state_enabled": "Enabled",
  "state_false": "False",
  "state_free": "Free",
  "state_high": "High",
  "state_in": "In",
  "state_inactive": "Inactive",
  "state_left": "Left",
  "state_low": "Low",
  "state_occupied": "Occupied",
  "state_off": "Off",
  "state_on": "On",
  "state_opened": "Open",
  "state_out": "Out",
  "state_right": "Right",
  "state_started": "Started",
  "state_stopped": "Stopped",
  "state_true": "True",
  "state_up": "Up",
  "static": "Static",
  "stations": "Stations",
  "status": "Status",
  "stereo_widening": "Stereo widening",
  "stop": "Stop",
  "stream": "Stream",
  "streaming": "Streaming",
  "streaming_services": "Streaming services",
  "streams": "Streams",
  "streams_beta": "Streams (beta)",
  "subnet_mask": "Subnet mask",
  "sun": "Sun",
  "sunrise": "Sunrise",
  "sunset": "Sunset",
  "swipe_to_pan": "Swipe to pan",
  "switch_server": "Switch server",
  "synchronise": "Scan now",
  "system": "System",
  "system_setting": "System setting",
  "temperature": "Temperature",
  "temperature_unit": "Temperature unit",
  "theme": "Theme",
  "thermostat": "Thermostat",
  "this_cannot_be_undone": "This cannot be undone!",
  "this_week": "This week",
  "tidal": "TIDAL",
  "tidal_disconnect_txt": "You're about to disconnect the TIDAL account from this player.",
  "tidal_legacy_auth": "TIDAL login required",
  "tidal_legacy_auth_l1": "To continue using TIDAL, please login again.",
  "tidal_no_link": "No TIDAL account linked yet",
  "tidal_oauth_error": "TIDAL authentication error",
  "tidal_rising": "TIDAL Rising",
  "time": "Time",
  "time_format": "Time format",
  "timer": "Timer",
  "timers": "Timers",
  "title": "Title",
  "today": "Today",
  "tomorrow": "Tomorrow",
  "top_tracks": "Top tracks",
  "treble": "Treble",
  "try_again": "Try again",
  "try_again_later": "Try again later",
  "try_again_later_or_contact_support": "Try again later or contact support",
  "turned_off": "Turned off",
  "turned_on": "Turned on",
  "turn_off": "Turn off",
  "turn_on": "Turn on",
  "turn_on_default_rooms": "Turn on this source's default rooms?",
  "turn_on_music": "Turn on music",
  "turn_on_tv": "Turn on TV",
  "tv": "TV",
  "tv_off": "TV off",
  "tv_off_current_room": "TV off, this room",
  "unavailable": "Unavailable",
  "unavailable_src": "Source not available",
  "unavailable_src_txt": "The current source is not available.",
  "uncollapse": "Uncollapse",
  "ungroup": "Ungroup",
  "unknown": "Unknown",
  "unknown_account": "Unknown account",
  "unknown_account_l1": "This email address is not a Basalte account.",
  "unknown_av": "Unknown AV input",
  "unknown_src": "Unknown source",
  "unknown_src_txt": "The current source is unknown to this server.",
  "unlink": "Unlink",
  "unlink_server": "Unlink from Basalte account",
  "unlink_server_full_sub": "Unlink this project from Basalte account? It will no longer be accessible outside of your local network.",
  "unreachable": "Unreachable",
  "unshare": "unshare",
  "unsupported_browser": "Unsupported browser",
  "unsupported_browser_l1": "Some functionality may not work as expected.",
  "unsupported_browser_l2": "For a better user experience use Chrome or Safari.",
  "unsupported_browser_live": "This browser does not support Basalte account. Try using a new version of Chrome or Safari.",
  "up": "Up",
  "update": "Update",
  "update_available": "Update",
  "update_available_full": "Update available",
  "update_info_l1": "To ensure the best possible experience,",
  "update_in_progress": "Update in progress",
  "update_library": "Rescan music library",
  "update_music_server": "Update the music server",
  "update_scene": "Update the scene",
  "update_scene_l1": "Save the current state to this scene.",
  "update_server": "Update the server",
  "updating_library": "Scanning music library...",
  "updating_server": "Updating server",
  "updating_server_info": "Do not power off the server",
  "up_to_date": "Up to date",
  "username": "Username",
  "uv": "UV",
  "value_invalid": "The given value is invalid",
  "verify": "Verify",
  "verifying": "verifying",
  "version": "Version",
  "video_background": "Video background",
  "video_go_to": "Go to TV",
  "video_is_active": "TV is active",
  "video_is_playing": "Video is playing",
  "video_unavailable": "TV or audio system is not available",
  "volume": "Volume",
  "warning": "Warning",
  "water": "Water",
  "weather": "Weather",
  "weather_daily_data_unavailable": "There is currently no daily weather data available",
  "weather_hourly_data_unavailable": "There is currently no hourly weather data available",
  "weather_information": "Weather information",
  "week": "Week",
  "weekly": "Weekly",
  "what": "What",
  "what_music": "What music",
  "when": "When",
  "where": "Where",
  "who": "Who",
  "widget_favourite_scenes_description": "Quickly access your favourite scenes.",
  "widget_favourite_scenes_title": "Home scenes",
  "wind": "Wind",
  "wrong_password": "Incorrect password",
  "wrong_password_txt": "The password you entered did not match the user password",
  "wrong_username_and_or_password": "Wrong username and/or password",
  "year": "Year",
  "yes": "Yes",
  "yes_all_off": "Yes, turn all off",
  "yes_cur_off": "Yes, turn current off",
  "yes_deezer_dis": "Yes, disconnect Deezer account",
  "yes_delete_it": "Yes, delete it",
  "yesterday": "Yesterday",
  "zone": "Room",
  "zones": "Rooms"
}
