'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('colorPickerModalCtrl', [
    '$scope',
    '$rootScope',
    'BAS_SPLASH',
    'BAS_LIGHT',
    'BasLight',
    'close',
    'light',
    colorPickerModalCtrl
  ])

function colorPickerModalCtrl (
  $scope,
  $rootScope,
  BAS_SPLASH,
  BAS_LIGHT,
  BasLight,
  close,
  light
) {
  var modal = this

  var _listeners = []

  modal.BAS_LIGHT = BAS_LIGHT

  modal.close = close
  modal.BasLight = BasLight

  /**
   * @type {BasLight}
   */
  modal.light = light

  modal.onColorTemperatureChange = onColorTemperatureChange
  modal.onWhiteChange = onWhiteChange
  modal.onColorChange = onColorChange
  modal.onBrightnessChange = onBrightnessChange

  init()

  function init () {

    _listeners.push($rootScope.$on(
      BAS_SPLASH.EVT_SPLASH_VISIBILITY_CHANGED,
      _onSplashVisibility
    ))

    $scope.$on('$destroy', onDestroy)
  }

  function onBrightnessChange () {

    if (isValidLight()) {

      modal.light.onState = modal.light.brightness > 0
      modal.light.brightnessChange()
    }
  }

  function onWhiteChange () {

    if (isValidLight()) modal.light.whiteChange()
  }

  function onColorTemperatureChange () {

    if (isValidLight()) modal.light.colorTemperatureChange()
  }

  function onColorChange () {

    if (isValidLight()) modal.light.colorHSBChange()
  }

  function isValidLight () {
    return modal.light instanceof BasLight
  }

  function _onSplashVisibility () {

    close()
  }

  function onDestroy () {

    BasUtil.executeArray(_listeners)
    _listeners = []
  }
}
