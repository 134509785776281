'use strict'

angular
  .module('basalteApp')
  .constant('BAS_STATE', {
    EVT_STATE_SUCCESS: 'stateSuccess',

    S_ROOMS_VIEW_HOME: 'home',
    S_ROOMS_VIEW_MUSIC: 'music',
    S_ROOMS_VIEW_THERMOSTATS: 'thermostats',
    S_ROOMS_VIEW_INTERCOM: 'intercom',

    S_HOME_VIEW_DASHBOARD: 'dashboard',
    S_HOME_VIEW_OPTIONS: 'options',

    S_LISA_VIEW_HOME: 'home',
    S_LISA_VIEW_SCENES: 'scenes',
    S_LISA_VIEW_THERMOSTAT: 'thermostat',
    S_LISA_VIEW_LIGHTS: 'lights',
    S_LISA_VIEW_SHADES: 'shades',
    S_LISA_VIEW_MUSIC: 'music',
    S_LISA_VIEW_CAMERAS: 'cameras',
    S_LISA_VIEW_INTERCOM: 'intercom',

    S_LISA_LIGHT_DETAILS_VIEW_COLOR_PICKER: 'color_picker',
    S_LISA_LIGHT_DETAILS_VIEW_SETTINGS: 'settings',

    S_LISA_SHADE_DETAILS_VIEW_EXTRA: 'extra',

    S_LISA_THERMOSTAT_VIEW_SLIDER: 'slider',
    S_LISA_THERMOSTAT_VIEW_SETTINGS: 'settings',

    S_LISA_MUSIC_VIEW_FAVOURITES: 'favourites'
  })
