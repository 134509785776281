'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasThermostat', [
    '$rootScope',
    'BAS_API',
    'BAS_THERMOSTAT',
    'CurrentBasCore',
    'BasAppTemperature',
    'RoomsHelper',
    'BasThermostatControl',
    'BasThermostatScheduler',
    'BasUtilities',
    basThermostatFactory
  ])

/**
 * @param $rootScope
 * @param BAS_API
 * @param {BAS_THERMOSTAT} BAS_THERMOSTAT
 * @param {CurrentBasCore} CurrentBasCore
 * @param {BasAppTemperature} BasAppTemperature
 * @param {RoomsHelper} RoomsHelper
 * @param BasThermostatControl
 * @param BasThermostatScheduler
 * @param {BasUtilities} BasUtilities
 * @returns BasThermostat
 */
function basThermostatFactory (
  $rootScope,
  BAS_API,
  BAS_THERMOSTAT,
  CurrentBasCore,
  BasAppTemperature,
  RoomsHelper,
  BasThermostatControl,
  BasThermostatScheduler,
  BasUtilities
) {
  var EVENT_DEBOUNCE = 600

  var CSS_CAN_SCHEDULER = 'btw-can-scheduler'
  var CSS_CAN_FAN = 'btw-can-fan'
  var CSS_CAN_MODE = 'btw-can-mode'
  var CSS_CAN_LOUVER_MODE = 'btw-can-louver'
  var CSS_HAS_CONTOLS = 'btw-has-controls'
  var CSS_CAN_SET_POINT = 'btw-can-set-point'
  var CSS_MODE_OFF = 'btw-mode-off'
  var CSS_MODE_HEATING = 'btw-mode-heating'
  var CSS_MODE_COOLING = 'btw-mode-cooling'
  var CSS_MODE_DRYING = 'btw-mode-drying'
  var CSS_MODE_FAN_ONLY = 'btw-mode-fan-only'
  var CSS_ACT_HEATING = 'btw-activity-heating'
  var CSS_ACT_COOLING = 'btw-activity-cooling'
  var CSS_HAS_FAN_OPTIONS = 'btw-has-fan-options'
  var CSS_HAS_MODE_OPTIONS = 'btw-has-mode-options'
  var CSS_HAS_LOUVER_OPTIONS = 'btw-has-louver-options'
  var CSS_HAS_TEMPERATURE = 'btw-has-temperature'
  var CSS_HAS_SET_POINT = 'btw-has-set-point'
  var CSS_HAS_HUMIDITY = 'btw-has-humidity'

  /**
   * @constant {number}
   */
  BasThermostat.DEF_SETPOINT_MIN = 5

  /**
   * @constant {number}
   */
  BasThermostat.DEF_SETPOINT_MAX = 30

  /**
   * @constant {number}
   */
  BasThermostat.DEF_PRECISION = 0.5

  /**
   * @constructor
   * @param {BasRoom} [basRoom]
   * @param {string} [uuid]
   */
  function BasThermostat (basRoom, uuid) {
    /**
     * @type {string}
     */
    this.uuid = uuid

    /**
     * @type {number}
     */
    this.setpointMin = BasThermostat.DEF_SETPOINT_MIN

    /**
     * @type {number}
     */
    this.setpointMax = BasThermostat.DEF_SETPOINT_MAX

    /**
     * @type {number}
     */
    this.precision = BasThermostat.DEF_PRECISION

    /**
     * @type {Temperature}
     */
    this.desired = new BAS_API.Temperature()

    /**
     * @type {Temperature}
     */
    this.current = new BAS_API.Temperature()

    /**
     * @type {number}
     */
    this.humidity = 0

    /**
     * @type {string}
     */
    this.fanSpeed = BAS_THERMOSTAT.FAN_SPEED_OFF

    /**
     * @type {string}
     */
    this.mode = BAS_THERMOSTAT.MODE_AUTO

    /**
     * @type {string}
     */
    this.louverMode = ''

    /**
     * @type {string}
     */
    this.activeMode = BAS_THERMOSTAT.ACT_MODE_OFF

    /**
     * @type {string}
     */
    this.activity = BAS_THERMOSTAT.ACT_IDLE

    /**
     * @type {string}
     */
    this.uiTitle = ''

    /**
     * @type {string}
     */
    this.uiDegree = BAS_THERMOSTAT.DEGREE

    /**
     * @type {string}
     */
    this.uiDesired = ''

    /**
     * @type {string}
     */
    this.uiDesiredFraction = ''

    /**
     * @type {string}
     */
    this.uiCurrent = ''

    /**
     * @type {number}
     */
    this.roundedCurrent = 0

    /**
     * @type {string}
     */
    this.uiFanSpeed = ''

    /**
     * @type {string}
     */
    this.uiHumidity = ''

    /**
     * @type {?BasThermostatScheduler}
     */
    this.thermostatScheduler = null

    /**
     * @type {string[]}
     */
    this.modeOptions = []

    /**
     * @type {string[]}
     */
    this.louverOptions = []

    /**
     * @type {BasThermostatControl[]}
     */
    this.controls = []

    /**
     * @type {string[]}
     */
    this.fanSpeedAutoOptions = []

    /**
     * @type {string[]}
     */
    this.fanSpeedSpecificOptions = []

    /**
     * @type {boolean}
     */
    this.canSetPoint = false

    /**
     * @type {Object}
     */
    this.includeInScene = {}
    this.includeInScene.desired = false
    this.includeInScene.mode = false
    this.includeInScene.fan = false

    /**
     * @type {Object}
     */
    this.css = {}
    this._resetCss()
    this._resetSchedulerCss()

    /**
     * @private
     * @type {?BasRoom}
     */
    this._basRoom = basRoom

    /**
     * @type {?ThermostatDevice}
     */
    this.device = null

    this._deviceListeners = []

    this._eventTimeoutId = 0

    this._handleDeviceStateChange = this._onDeviceState.bind(this)
    this._handleDeviceSchedulerChange =
      this._syncDeviceScheduler.bind(this)
    this._handleDeviceCapabilitiesChange =
      this._syncDeviceCapabilities.bind(this)
    this._doSyncDeviceState = this._syncDeviceState.bind(this)

    this.init(basRoom)
  }

  /**
   * @param {number} pbMode
   * @returns {string}
   */
  BasThermostat.getMode = function (pbMode) {

    switch (pbMode) {
      case BAS_API.ThermostatDevice.PB_TM_HEATING:
        return BAS_THERMOSTAT.MODE_HEATING
      case BAS_API.ThermostatDevice.PB_TM_COOLING:
        return BAS_THERMOSTAT.MODE_COOLING
      case BAS_API.ThermostatDevice.PB_TM_AUTO:
        return BAS_THERMOSTAT.MODE_AUTO
      case BAS_API.ThermostatDevice.PB_TM_OFF:
      default:
        return BAS_THERMOSTAT.MODE_OFF
    }
  }

  /**
   * @param {string} mode
   * @returns {number}
   */
  BasThermostat.getPBMode = function (mode) {

    switch (mode) {
      case BAS_THERMOSTAT.MODE_HEATING:
        return BAS_API.ThermostatDevice.PB_TM_HEATING
      case BAS_THERMOSTAT.MODE_COOLING:
        return BAS_API.ThermostatDevice.PB_TM_COOLING
      case BAS_THERMOSTAT.MODE_AUTO:
        return BAS_API.ThermostatDevice.PB_TM_AUTO
      case BAS_THERMOSTAT.MODE_OFF:
      default:
        return BAS_API.ThermostatDevice.PB_TM_OFF
    }
  }

  /**
   * @param {number} pbMode
   * @returns {string}
   */
  BasThermostat.getFanMode = function (pbMode) {

    switch (pbMode) {
      case BAS_API.ThermostatDevice.PB_FM_LOW:
        return BAS_THERMOSTAT.FAN_SPEED_LOW
      case BAS_API.ThermostatDevice.PB_FM_MID:
        return BAS_THERMOSTAT.FAN_SPEED_MEDIUM
      case BAS_API.ThermostatDevice.PB_FM_HIGH:
        return BAS_THERMOSTAT.FAN_SPEED_HIGH
      case BAS_API.ThermostatDevice.PB_FM_AUTO:
        return BAS_THERMOSTAT.FAN_SPEED_AUTO
      case BAS_API.ThermostatDevice.PB_FM_OFF:
      default:
        return BAS_THERMOSTAT.FAN_SPEED_OFF
    }
  }

  /**
   * @param {string} mode
   * @returns {number}
   */
  BasThermostat.getPBFanMode = function (mode) {

    switch (mode) {
      case BAS_THERMOSTAT.FAN_SPEED_LOW:
        return BAS_API.ThermostatDevice.PB_FM_LOW
      case BAS_THERMOSTAT.FAN_SPEED_MEDIUM:
        return BAS_API.ThermostatDevice.PB_FM_MID
      case BAS_THERMOSTAT.FAN_SPEED_HIGH:
        return BAS_API.ThermostatDevice.PB_FM_HIGH
      case BAS_THERMOSTAT.FAN_SPEED_AUTO:
        return BAS_API.ThermostatDevice.PB_FM_AUTO
      case BAS_THERMOSTAT.FAN_SPEED_OFF:
      default:
        return BAS_API.ThermostatDevice.PB_FM_OFF
    }
  }

  /**
   * @returns {BasThermostat}
   */
  BasThermostat.prototype.sceneClone = function () {

    var i, length, _control, control
    var thermostat = new BasThermostat()

    thermostat.desired = this.desired.clone()
    thermostat.fanSpeed = this.fanSpeed
    thermostat.mode = this.mode
    thermostat.uiDegree = this.uiDegree
    thermostat.uiDesired = this.uiDesired
    thermostat.uiDesiredFraction = this.uiDesiredFraction
    thermostat.uiFanSpeed = this.uiFanSpeed
    thermostat.modeOptions = this.modeOptions
    thermostat.fanSpeedAutoOptions = this.fanSpeedAutoOptions
    thermostat.fanSpeedSpecificOptions = this.fanSpeedSpecificOptions
    thermostat.louverOptions = this.louverOptions
    thermostat.uuid = this.uuid
    thermostat.canSetPoint = this.canSetPoint
    thermostat.setpointMin = this.setpointMin
    thermostat.setpointMax = this.setpointMax
    thermostat.precision = this.precision

    length = this.controls.length
    for (i = 0; i < length; i++) {

      control = this.controls[i]

      _control = new BasThermostatControl()
      _control.name = control.name
      _control.uuid = control.uuid
      _control.subType = control.subType
      _control.subTypeStr = control.subTypeStr
      _control.canEdit = control.canEdit
      _control.updateTranslation()

      thermostat.controls.push(_control)
    }

    thermostat.css = BasUtil.copyObject(this.css)
    thermostat._resetSchedulerCss()

    return thermostat
  }

  /**
   * @param {BasRoom} basRoom
   */
  BasThermostat.prototype.init = function (basRoom) {

    this._resetCss()
    this._resetSchedulerCss()

    this._clearControls()
    this._clearDeviceListeners()
    this._clearEventTimeout()

    this._basRoom = basRoom

    this.device = this._getDevice()

    this._basRoom?.cssSetHasThermostat(
      RoomsHelper.roomHasFunctionThermostat(this._basRoom)
    )

    this.parseDevice()
  }

  BasThermostat.prototype.parseDevice = function () {

    if (this.device) {

      this.setDeviceListeners()
      this.syncDevice()
      this.syncScheduler()
    }
  }

  /**
   * @param {string} uuid
   * @param {*} value
   */
  BasThermostat.prototype.commit = function (uuid, value) {

    if (this.device) {

      this.device.setControlActive(uuid, value)
    }
  }

  BasThermostat.prototype.onDesiredChanges = function (desired) {

    this.setUiDesired(desired)
    this.setDesired(desired)
  }

  /**
   * @param {number} desired
   */
  BasThermostat.prototype.setDesired = function (desired) {

    if (this.device) {

      // Format and convert to number again to correctly round according to
      //  precision
      const roundedDesired = BasUtil.formatTemperature(
        desired,
        { precision: this.precision }
      )

      this.device.setNewSetpoint(
        Number(roundedDesired),
        BasAppTemperature.getTemperatureUnit()
      )
    }

    this.setUiDesired(desired)
  }

  BasThermostat.prototype.setMode = function (mode) {

    this.mode = mode
    this.toggleIncludeMode(true)

    if (this.device) {

      switch (mode) {
        case BAS_THERMOSTAT.MODE_AUTO:
          this.device.setMode(BAS_API.ThermostatDevice.A_TM_AUTO)
          break
        case BAS_THERMOSTAT.MODE_HEATING:
          this.device.setMode(BAS_API.ThermostatDevice.A_TM_HEATING)
          break
        case BAS_THERMOSTAT.MODE_COOLING:
          this.device.setMode(BAS_API.ThermostatDevice.A_TM_COOLING)
          break
        case BAS_THERMOSTAT.MODE_OFF:
          this.device.setMode(BAS_API.ThermostatDevice.A_TM_OFF)
          break
        case BAS_THERMOSTAT.MODE_DRYING:
          this.device.setMode(BAS_API.ThermostatDevice.A_TM_DRYING)
          break
        case BAS_THERMOSTAT.MODE_FAN_ONLY:
          this.device.setMode(BAS_API.ThermostatDevice.A_TM_FAN_ONLY)
          break
      }

    } else {

      switch (mode) {
        case BAS_THERMOSTAT.MODE_HEATING:
          this.setUiActiveMode(BAS_THERMOSTAT.ACT_MODE_HEATING)
          break
        case BAS_THERMOSTAT.MODE_COOLING:
          this.setUiActiveMode(BAS_THERMOSTAT.ACT_MODE_COOLING)
          break
        case BAS_THERMOSTAT.MODE_OFF:
          this.setUiActiveMode(BAS_THERMOSTAT.ACT_MODE_OFF)
          break
      }
    }
  }

  /**
   * @returns {number}
   */
  BasThermostat.prototype.getPBMode = function () {

    return BasThermostat.getPBMode(this.mode)
  }

  /**
   * @returns {number}
   */
  BasThermostat.prototype.getPBFanMode = function () {

    return BasThermostat.getPBFanMode(this.fanSpeed)
  }

  /**
   * Returns a translation key which represents the mode
   *
   * @returns {string}
   */
  BasThermostat.prototype.getModeKey = function () {

    switch (this.mode) {
      case BAS_THERMOSTAT.MODE_AUTO:
        return 'mode_auto'
      case BAS_THERMOSTAT.MODE_HEATING:
        return 'mode_heating'
      case BAS_THERMOSTAT.MODE_COOLING:
        return 'mode_cooling'
      case BAS_THERMOSTAT.MODE_OFF:
        return 'mode_off'
    }
  }

  /**
   * Returns a translation key which represents the fan with a capital
   *
   * @returns {string}
   */
  BasThermostat.prototype.getCaptialFanKey = function () {

    switch (this.fanSpeed) {
      case BAS_THERMOSTAT.FAN_SPEED_AUTO:
        return 'fan_auto'
      case BAS_THERMOSTAT.FAN_SPEED_LOW:
        return 'fan_low'
      case BAS_THERMOSTAT.FAN_SPEED_MEDIUM:
        return 'fan_medium'
      case BAS_THERMOSTAT.FAN_SPEED_HIGH:
        return 'fan_high'
      case BAS_THERMOSTAT.FAN_SPEED_OFF:
        return 'fan_off'
    }
  }

  BasThermostat.prototype.setFanMode = function (mode) {

    this.toggleIncludeFan(true)

    if (this.device) {

      switch (mode) {
        case BAS_THERMOSTAT.FAN_SPEED_AUTO:
          this.device.setFanMode(BAS_API.ThermostatDevice.A_FM_AUTO)
          break
        case BAS_THERMOSTAT.FAN_SPEED_OFF:
          this.device.setFanMode(BAS_API.ThermostatDevice.A_FM_OFF)
          break
        case BAS_THERMOSTAT.FAN_SPEED_LOW:
          this.device.setFanMode(BAS_API.ThermostatDevice.A_FM_LOW)
          break
        case BAS_THERMOSTAT.FAN_SPEED_MEDIUM:
          this.device.setFanMode(BAS_API.ThermostatDevice.A_FM_MID)
          break
        case BAS_THERMOSTAT.FAN_SPEED_HIGH:
          this.device.setFanMode(BAS_API.ThermostatDevice.A_FM_HIGH)
          break
      }
    }

    this.setUiFanMode(mode)
  }

  BasThermostat.prototype.setLouverMode = function (mode) {

    if (this.device) {

      switch (mode) {
        case BAS_THERMOSTAT.LOUVER_30_DEG:
          this.device.setLouverMode(BAS_API.ThermostatDevice.A_LM_30_DEG)
          break
        case BAS_THERMOSTAT.LOUVER_45_DEG:
          this.device.setLouverMode(BAS_API.ThermostatDevice.A_LM_45_DEG)
          break
        case BAS_THERMOSTAT.LOUVER_60_DEG:
          this.device.setLouverMode(BAS_API.ThermostatDevice.A_LM_60_DEG)
          break
        case BAS_THERMOSTAT.LOUVER_SWING:
          this.device.setLouverMode(BAS_API.ThermostatDevice.A_LM_SWING)
          break
        case BAS_THERMOSTAT.LOUVER_HORIZONTAL:
          this.device.setLouverMode(BAS_API.ThermostatDevice.A_LM_HORIZONTAL)
          break
        case BAS_THERMOSTAT.LOUVER_VERTICAL:
          this.device.setLouverMode(BAS_API.ThermostatDevice.A_LM_VERTICAL)
          break
        case BAS_THERMOSTAT.LOUVER_OFF:
          this.device.setLouverMode(BAS_API.ThermostatDevice.A_LM_OFF)
          break
      }
    }

    this.setUiLouverMode(mode)
  }

  BasThermostat.prototype.updateTranslation = function () {

    var length, i, control

    this._syncUiFanSpeed()

    if (this.thermostatScheduler) {

      this.thermostatScheduler.updateTranslation()
    }

    if (BasUtil.isNEArray(this.controls)) {

      length = this.controls.length
      for (i = 0; i < length; i++) {

        control = this.controls[i]
        control.updateTranslation()
      }
    }
  }

  BasThermostat.prototype.updateTemperatureUnit = function () {

    var current = this.current.getTemperature(
      BasAppTemperature.getTemperatureUnit()
    )

    var desired = this.desired.getTemperature(
      BasAppTemperature.getTemperatureUnit()
    )

    this.roundedCurrent = Math.round(current)

    this.uiCurrent = BasUtil.formatTemperature(
      current,
      { precision: this.precision }
    )

    this.uiDesired = '' + Math.floor(this.desired.getTemperature(
      BasAppTemperature.getTemperatureUnit()
    ))
    const desiredFraction =
      BasUtil.getTemperatureFraction(desired, this.precision)
    this.uiDesiredFraction = desiredFraction ? '' + desiredFraction : ''

    this.syncSetpointRange()

    if (this.thermostatScheduler) {

      this.thermostatScheduler.updateTemperatureUnit()
    }
  }

  /**
   * @param {number} desired
   */
  BasThermostat.prototype.setUiDesired = function (desired) {

    this.desired.setTemperature(
      desired,
      BasAppTemperature.getTemperatureUnit()
    )

    this.uiDesired = '' + Math.floor(desired)
    const tempFraction = BasUtil.getTemperatureFraction(desired, this.precision)
    this.uiDesiredFraction = tempFraction > 0 ? '' + tempFraction : ''
  }

  /**
   * @param {number} current
   */
  BasThermostat.prototype.setUiCurrent = function (current) {

    this.current.setTemperature(
      BasUtil.isVNumber(current) ? current : 0,
      BasAppTemperature.getTemperatureUnit()
    )

    this.roundedCurrent = Math.round(current)

    this.uiCurrent = BasUtil.formatTemperature(
      current,
      { precision: this.precision }
    )
  }

  /**
   * @param {number} humidity
   */
  BasThermostat.prototype.setUiHumidity = function (humidity) {

    this.humidity = humidity

    this.uiHumidity = this.humidity + '%'
  }

  /**
   * @param {string} name
   */
  BasThermostat.prototype.setUiTitle = function (name) {
    this.uiTitle = name
  }

  /**
   * @param {string} activeMode
   */
  BasThermostat.prototype.setUiActiveMode = function (activeMode) {

    this.activeMode = activeMode
    this._syncActiveModeCss()
  }

  BasThermostat.prototype._syncActiveModeCss = function () {

    this.css[CSS_MODE_HEATING] =
      this.activeMode === BAS_THERMOSTAT.ACT_MODE_HEATING

    this.css[CSS_MODE_COOLING] =
      this.activeMode === BAS_THERMOSTAT.ACT_MODE_COOLING

    this.css[CSS_MODE_OFF] =
      this.activeMode === BAS_THERMOSTAT.ACT_MODE_OFF
  }

  /**
   * @param {string} activity
   */
  BasThermostat.prototype.setUiActivity = function (activity) {

    this.activity = activity

    this._syncActivityCss()
  }

  BasThermostat.prototype._syncActivityCss = function () {

    this.css[CSS_ACT_HEATING] =
      this.activity === BAS_THERMOSTAT.ACT_HEATING
    this.css[CSS_ACT_COOLING] =
      this.activity === BAS_THERMOSTAT.ACT_COOLING
  }

  /**
   * @param {string} fanSpeed
   */
  BasThermostat.prototype.setUiFanMode = function (fanSpeed) {

    this.fanSpeed = fanSpeed
    this._syncUiFanSpeed()
  }

  /**
   * @param {string} louverMode
   */
  BasThermostat.prototype.setUiLouverMode = function (louverMode) {

    this.louverMode = louverMode
  }

  BasThermostat.prototype._syncUiFanSpeed = function () {

    this.uiFanSpeed = ''

    switch (this.fanSpeed) {
      case BAS_THERMOSTAT.FAN_SPEED_AUTO:

        this.uiFanSpeed +=
          BasUtilities.translate(BAS_THERMOSTAT.FAN_SPEED_AUTO)

        break
      case BAS_THERMOSTAT.FAN_SPEED_LOW:

        this.uiFanSpeed +=
          BasUtilities.translate(BAS_THERMOSTAT.FAN_SPEED_LOW)

        break
      case BAS_THERMOSTAT.FAN_SPEED_MEDIUM:

        this.uiFanSpeed +=
          BasUtilities.translate(BAS_THERMOSTAT.FAN_SPEED_MEDIUM)

        break
      case BAS_THERMOSTAT.FAN_SPEED_HIGH:

        this.uiFanSpeed +=
          BasUtilities.translate(BAS_THERMOSTAT.FAN_SPEED_HIGH)

        break
      case BAS_THERMOSTAT.FAN_SPEED_OFF:
      default:

        this.uiFanSpeed +=
          BasUtilities.translate(BAS_THERMOSTAT.FAN_SPEED_OFF)

        break
    }
  }

  /**
   * @param {string} uuid
   */
  BasThermostat.prototype.setControlIncludeInScene = function (uuid) {

    var length, i, control

    if (BasUtil.isNEString(uuid)) {

      length = this.controls.length
      for (i = 0; i < length; i++) {

        control = this.controls[i]

        if (uuid === control.uuid) {

          control.includeInScene = true
        }
      }
    }

  }

  /**
   * @param {string} uuid
   * @param {boolean} active
   */
  BasThermostat.prototype.setControlActive = function (uuid, active) {

    var i, length, control

    if (BasUtil.isNEString(uuid) &&
      BasUtil.isBool(active)) {

      length = this.controls.length
      for (i = 0; i < length; i++) {

        control = this.controls[i]

        if (uuid === control.uuid) {

          control.toggle(active)
        }
      }
    }
  }

  BasThermostat.prototype.syncDevice = function () {

    var temp, set, controls, length, i

    this._clearControls()
    this._resetCss()

    if (this.device) {

      this.uuid = this.device.uuid
      this.setUiTitle(this.device.name)

      if (this.device.controls) {
        controls = this.device.controls

        length = controls.length
        for (i = 0; i < length; i++) {

          this.controls.push(new BasThermostatControl(
            controls[i],
            this
          ))
        }
      }

      this._syncControlOptionsCss()
      this.syncPrecision()

      if (this.device.allowsRead(
        BAS_API.ThermostatDevice.C_TEMPERATURE
      )) {
        temp = this.device.getTemperature()
        this.setUiCurrent(temp.getTemperature(
          BasAppTemperature.getTemperatureUnit()
        ))
        this.css[CSS_HAS_TEMPERATURE] = true
      }

      if (this.device.allowsRead(BAS_API.ThermostatDevice.C_SETPOINT)) {

        set = this.device.getSetpoint()
        this.setUiDesired(set.getTemperature(
          BasAppTemperature.getTemperatureUnit()
        ))

        this.css[CSS_HAS_SET_POINT] = true

        this.syncSetpointRange()
      }

      if (this.device.allowsRead(BAS_API.ThermostatDevice.C_HUMIDITY)) {

        this.setUiHumidity(Math.round(this.device.humidity * 100))
        this.css[CSS_HAS_HUMIDITY] = true
      }

      this.syncModeOptions()

      if (this.device.allowsRead(
        BAS_API.ThermostatDevice.C_THERMOSTAT_MODE
      )) {

        this.syncMode()
      }

      this.syncLouverOptions()

      if (this.device.allowsRead(
        BAS_API.ThermostatDevice.C_LOUVER_MODE
      )) {

        this.syncLouverMode()
      }

      if (this.device.allowsRead(
        BAS_API.ThermostatDevice.C_HEAT_COOL_MODE
      )) {

        this.syncActiveMode()
      }

      if (this.device.allowsRead(BAS_API.ThermostatDevice.C_FAN_MODE)) {

        this.syncFanMode()
        this.syncFanOptions()
      }

      this.css[CSS_CAN_MODE] =
        this.device.allowsWrite(
          BAS_API.ThermostatDevice.C_THERMOSTAT_MODE
        )

      this.css[CSS_CAN_LOUVER_MODE] =
        this.device.allowsWrite(
          BAS_API.ThermostatDevice.C_LOUVER_MODE
        )

      this.css[CSS_CAN_FAN] =
        this.device.allowsWrite(BAS_API.ThermostatDevice.C_FAN_MODE)

      this.css[CSS_CAN_SET_POINT] =
        this.device.allowsWrite(BAS_API.ThermostatDevice.C_SETPOINT)
      this.canSetPoint = this.css[CSS_CAN_SET_POINT]

      this.syncActivity()
    }
  }

  BasThermostat.prototype.syncScheduler = function () {

    this._resetSchedulerCss()

    if (this.device.allowsRead(BAS_API.ThermostatDevice.C_SCHEDULER) ||
      this.device.allowsRead(BAS_API.ThermostatDevice.C_SCHEDULER_V2)) {

      this.css[CSS_CAN_SCHEDULER] = true

      if (this.thermostatScheduler === null) {

        this.thermostatScheduler = new BasThermostatScheduler(
          this._basRoom,
          this,
          this.uuid
        )

      } else {

        this.thermostatScheduler.parse(this.device)
      }
    }
  }

  BasThermostat.prototype.syncModeOptions = function () {

    var options

    this.modeOptions = []

    if (this.device) {

      options = this.device.modeOptions

      if (options) {

        if (options.indexOf(BAS_API.ThermostatDevice.A_TM_AUTO) !== -1) {

          this.modeOptions.push(BAS_THERMOSTAT.MODE_AUTO)
        }

        if (options.indexOf(BAS_API.ThermostatDevice.A_TM_OFF) !== -1) {

          this.modeOptions.push(BAS_THERMOSTAT.MODE_OFF)
        }

        if (options.indexOf(BAS_API.ThermostatDevice.A_TM_HEATING) !== -1) {

          this.modeOptions.push(BAS_THERMOSTAT.MODE_HEATING)
        }

        if (options.indexOf(BAS_API.ThermostatDevice.A_TM_COOLING) !== -1) {

          this.modeOptions.push(BAS_THERMOSTAT.MODE_COOLING)
        }

        if (options.indexOf(BAS_API.ThermostatDevice.A_TM_DRYING) !== -1) {

          this.modeOptions.push(BAS_THERMOSTAT.MODE_DRYING)
        }

        if (options.indexOf(BAS_API.ThermostatDevice.A_TM_FAN_ONLY) !== -1) {

          this.modeOptions.push(BAS_THERMOSTAT.MODE_FAN_ONLY)
        }
      }
    }

    this._syncModeOptionsCss()
  }

  BasThermostat.prototype._syncModeOptionsCss = function () {

    this.css[CSS_HAS_MODE_OPTIONS] = (
      this.device &&
      this.device.allowsRead(
        BAS_API.ThermostatDevice.C_THERMOSTAT_MODE
      ) &&
      this.modeOptions.length > 0
    )
  }

  BasThermostat.prototype.syncLouverOptions = function () {

    var options

    this.louverOptions = []

    if (this.device) {

      options = this.device.louverOptions

      if (options) {

        if (options.indexOf(BAS_API.ThermostatDevice.A_LM_SWING) !== -1) {

          this.louverOptions.push(BAS_THERMOSTAT.LOUVER_SWING)
        }

        if (options.indexOf(BAS_API.ThermostatDevice.A_LM_OFF) !== -1) {

          this.louverOptions.push(BAS_THERMOSTAT.LOUVER_OFF)
        }

        if (options.indexOf(BAS_API.ThermostatDevice.A_LM_VERTICAL) !== -1) {

          this.louverOptions.push(BAS_THERMOSTAT.LOUVER_VERTICAL)
        }

        if (options.indexOf(BAS_API.ThermostatDevice.A_LM_HORIZONTAL) !== -1) {

          this.louverOptions.push(BAS_THERMOSTAT.LOUVER_HORIZONTAL)
        }

        if (options.indexOf(BAS_API.ThermostatDevice.A_LM_30_DEG) !== -1) {

          this.louverOptions.push(BAS_THERMOSTAT.LOUVER_30_DEG)
        }

        if (options.indexOf(BAS_API.ThermostatDevice.A_LM_45_DEG) !== -1) {

          this.louverOptions.push(BAS_THERMOSTAT.LOUVER_45_DEG)
        }

        if (options.indexOf(BAS_API.ThermostatDevice.A_LM_60_DEG) !== -1) {

          this.louverOptions.push(BAS_THERMOSTAT.LOUVER_60_DEG)
        }
      }
    }

    this._syncLouverOptionsCss()
  }

  BasThermostat.prototype._syncLouverOptionsCss = function () {

    this.css[CSS_HAS_LOUVER_OPTIONS] = (
      this.device &&
      this.device.allowsRead(
        BAS_API.ThermostatDevice.C_LOUVER_MODE
      ) &&
      this.louverOptions.length > 0
    )
  }

  BasThermostat.prototype._syncControlOptionsCss = function () {

    this.css[CSS_HAS_CONTOLS] =
      this.controls.length > 0
  }

  BasThermostat.prototype.syncFanOptions = function () {

    var options
    this.fanSpeedAutoOptions = []
    this.fanSpeedSpecificOptions = []

    if (this.device && this.device.fanOptions) {

      options = this.device.fanOptions

      if (options.indexOf(BAS_API.ThermostatDevice.A_FM_AUTO) !== -1) {

        this.fanSpeedAutoOptions
          .push(BAS_THERMOSTAT.FAN_SPEED_AUTO)
      }

      if (options.indexOf(BAS_API.ThermostatDevice.A_FM_OFF) !== -1) {

        this.fanSpeedAutoOptions
          .push(BAS_THERMOSTAT.FAN_SPEED_OFF)
      }

      if (options.indexOf(BAS_API.ThermostatDevice.A_FM_LOW) !== -1) {

        this.fanSpeedSpecificOptions
          .push(BAS_THERMOSTAT.FAN_SPEED_LOW)
      }

      if (options.indexOf(BAS_API.ThermostatDevice.A_FM_MID) !== -1) {

        this.fanSpeedSpecificOptions
          .push(BAS_THERMOSTAT.FAN_SPEED_MEDIUM)
      }

      if (options.indexOf(BAS_API.ThermostatDevice.A_FM_HIGH) !== -1) {

        this.fanSpeedSpecificOptions
          .push(BAS_THERMOSTAT.FAN_SPEED_HIGH)
      }
    }

    this._syncFanOptionsCss()
  }

  BasThermostat.prototype._syncFanOptionsCss = function () {

    this.css[CSS_HAS_FAN_OPTIONS] =
      this.fanSpeedAutoOptions.length > 0 ||
      this.fanSpeedSpecificOptions.length > 0
  }

  BasThermostat.prototype.syncMode = function () {

    var mode

    if (this.device) {

      mode = this.device.mode

      switch (mode) {
        case BAS_API.ThermostatDevice.A_TM_AUTO:
          this.mode = BAS_THERMOSTAT.MODE_AUTO
          break
        case BAS_API.ThermostatDevice.A_TM_OFF:
          this.mode = BAS_THERMOSTAT.MODE_OFF
          break
        case BAS_API.ThermostatDevice.A_TM_HEATING:
          this.mode = BAS_THERMOSTAT.MODE_HEATING
          break
        case BAS_API.ThermostatDevice.A_TM_COOLING:
          this.mode = BAS_THERMOSTAT.MODE_COOLING
          break
        case BAS_API.ThermostatDevice.A_TM_DRYING:
          this.mode = BAS_THERMOSTAT.MODE_DRYING
          break
      }

    } else {

      this.mode = BAS_THERMOSTAT.MODE_AUTO
    }

    this.syncModeCss()
  }

  BasThermostat.prototype.syncModeCss = function () {

    this.css[CSS_MODE_DRYING] =
      this.mode === BAS_THERMOSTAT.MODE_DRYING

    this.css[CSS_MODE_FAN_ONLY] =
      this.mode === BAS_THERMOSTAT.MODE_FAN_ONLY
  }

  BasThermostat.prototype.syncLouverMode = function () {

    var mode

    if (this.device) {

      mode = this.device.louverMode

      switch (mode) {
        case BAS_API.ThermostatDevice.A_LM_30_DEG:
          this.louverMode = BAS_THERMOSTAT.LOUVER_30_DEG
          break
        case BAS_API.ThermostatDevice.A_LM_45_DEG:
          this.louverMode = BAS_THERMOSTAT.LOUVER_45_DEG
          break
        case BAS_API.ThermostatDevice.A_LM_60_DEG:
          this.louverMode = BAS_THERMOSTAT.LOUVER_60_DEG
          break
        case BAS_API.ThermostatDevice.A_LM_SWING:
          this.louverMode = BAS_THERMOSTAT.LOUVER_SWING
          break
        case BAS_API.ThermostatDevice.A_LM_HORIZONTAL:
          this.louverMode = BAS_THERMOSTAT.LOUVER_HORIZONTAL
          break
        case BAS_API.ThermostatDevice.A_LM_VERTICAL:
          this.louverMode = BAS_THERMOSTAT.LOUVER_VERTICAL
          break
        case BAS_API.ThermostatDevice.A_LM_OFF:
          this.louverMode = BAS_THERMOSTAT.LOUVER_OFF
          break
        default:
          this.louverMode = ''
          break
      }

    } else {

      this.louverMode = ''
    }
  }

  BasThermostat.prototype.syncActiveMode = function () {

    var activeMode

    if (this.device) {

      activeMode = this.device.activeMode

      switch (activeMode) {
        case BAS_API.ThermostatDevice.A_TM_OFF:
          this.setUiActiveMode(BAS_THERMOSTAT.ACT_MODE_OFF)
          break
        case BAS_API.ThermostatDevice.A_TM_HEATING:
          this.setUiActiveMode(BAS_THERMOSTAT.ACT_MODE_HEATING)
          break
        case BAS_API.ThermostatDevice.A_TM_COOLING:
          this.setUiActiveMode(BAS_THERMOSTAT.ACT_MODE_COOLING)
          break
      }

    }
  }

  BasThermostat.prototype.syncFanMode = function () {

    var mode

    if (this.device) {

      mode = this.device.fanMode

      switch (mode) {
        case BAS_API.ThermostatDevice.A_FM_AUTO:
          this.setUiFanMode(BAS_THERMOSTAT.FAN_SPEED_AUTO)
          break
        case BAS_API.ThermostatDevice.A_FM_OFF:
          this.setUiFanMode(BAS_THERMOSTAT.FAN_SPEED_OFF)
          break
        case BAS_API.ThermostatDevice.A_FM_LOW:
          this.setUiFanMode(BAS_THERMOSTAT.FAN_SPEED_LOW)
          break
        case BAS_API.ThermostatDevice.A_FM_MID:
          this.setUiFanMode(BAS_THERMOSTAT.FAN_SPEED_MEDIUM)
          break
        case BAS_API.ThermostatDevice.A_FM_HIGH:
          this.setUiFanMode(BAS_THERMOSTAT.FAN_SPEED_HIGH)
          break
      }

    } else {

      this.setUiFanMode(BAS_THERMOSTAT.FAN_SPEED_OFF)
    }
  }

  BasThermostat.prototype.syncActivity = function () {

    var act = BAS_THERMOSTAT.ACT_IDLE

    if (this.device) {

      if (this.device.allowsRead(
        BAS_API.ThermostatDevice.C_COOLING_ACTIVE
      ) &&
        this.device.coolingActive) {

        act = BAS_THERMOSTAT.ACT_COOLING
      }

      if (this.device.allowsRead(
        BAS_API.ThermostatDevice.C_HEATING_ACTIVE
      ) &&
        this.device.heatingActive) {

        act = BAS_THERMOSTAT.ACT_HEATING
      }
    }

    this.setUiActivity(act)
  }

  BasThermostat.prototype.syncSetpointRange = function () {

    if (this.device) {

      if (this.device.allowsRead(BAS_API.ThermostatDevice.C_SETPOINT)) {

        this.setpointMin = this.device.setpointRange.minSetpoint.getTemperature(
          BasAppTemperature.getTemperatureUnit()
        )

        this.setpointMax = this.device.setpointRange.maxSetpoint.getTemperature(
          BasAppTemperature.getTemperatureUnit()
        )
      }
    }
  }

  BasThermostat.prototype.syncPrecision = function () {

    if (this.device && BasUtil.isPNumber(this.device.precision)) {
      this.precision = this.device.precision
    }
  }

  /**
   * @param {boolean} [force]
   */
  BasThermostat.prototype.toggleIncludeDesired = function (force) {

    this.includeInScene.desired =
      BasUtil.isBool(force) ? force : !this.includeInScene.desired
  }

  /**
   * @param {boolean} [force]
   */
  BasThermostat.prototype.toggleIncludeMode = function (force) {

    this.includeInScene.mode =
      BasUtil.isBool(force) ? force : !this.includeInScene.mode
  }

  /**
   * @param {boolean} [force]
   */
  BasThermostat.prototype.toggleIncludeFan = function (force) {

    this.includeInScene.fan =
      BasUtil.isBool(force) ? force : !this.includeInScene.fan
  }

  BasThermostat.prototype.canSetFanMode = function () {

    return this.css[CSS_CAN_FAN]
  }

  BasThermostat.prototype.canSetSetPoint = function () {

    return this.css[CSS_CAN_SET_POINT]
  }

  BasThermostat.prototype.hasSetPoint = function () {

    return this.css[CSS_HAS_SET_POINT]
  }

  BasThermostat.prototype.canSetMode = function () {

    return this.css[CSS_CAN_MODE]
  }

  BasThermostat.prototype.canSetLouverMode = function () {

    return this.css[CSS_CAN_LOUVER_MODE]
  }

  BasThermostat.prototype.setDeviceListeners = function () {

    this._clearDeviceListeners()

    if (this.device) {

      this._deviceListeners.push(BasUtil.setEventListener(
        this.device,
        BAS_API.ThermostatDevice.EVT_STATE,
        this._handleDeviceStateChange
      ))

      this._deviceListeners.push(BasUtil.setEventListener(
        this.device,
        BAS_API.ThermostatDevice.EVT_SCHEDULER,
        this._handleDeviceSchedulerChange
      ))

      this._deviceListeners.push(BasUtil.setEventListener(
        this.device,
        BAS_API.Device.EVT_CAPABILITIES,
        this._handleDeviceCapabilitiesChange
      ))
    }
  }

  BasThermostat.prototype._clearEventTimeout = function () {

    clearTimeout(this._eventTimeoutId)
  }

  BasThermostat.prototype._onDeviceState = function () {

    this._clearEventTimeout()
    this._eventTimeoutId = setTimeout(
      this._doSyncDeviceState,
      EVENT_DEBOUNCE
    )
  }

  BasThermostat.prototype._syncDeviceState = function () {

    this.syncDevice()

    // TODO: Avoid '$applyAsync' in factory. We should emit an event which
    //  is in turn handled by a controller.
    $rootScope.$applyAsync()
  }

  BasThermostat.prototype._syncDeviceScheduler = function () {

    this.syncScheduler()

    // TODO: Avoid '$applyAsync' in factory. We should emit an event which
    //  is in turn handled by a controller.
    $rootScope.$applyAsync()
  }

  BasThermostat.prototype._syncDeviceCapabilities = function () {

    // TODO: split 'syncDevice' into separate functions so we can sync device
    //  capabilities without having to process state again.
    this._syncDeviceState()
  }

  BasThermostat.prototype._resetCss = function () {

    this.css[CSS_HAS_TEMPERATURE] = false
    this.css[CSS_HAS_SET_POINT] = false
    this.css[CSS_HAS_HUMIDITY] = false
    this.css[CSS_MODE_OFF] = false
    this.css[CSS_MODE_HEATING] = false
    this.css[CSS_MODE_COOLING] = false
    this.css[CSS_ACT_HEATING] = false
    this.css[CSS_ACT_COOLING] = false
    this.css[CSS_HAS_FAN_OPTIONS] = false
    this.css[CSS_HAS_MODE_OPTIONS] = false
    this.css[CSS_CAN_FAN] = false
    this.css[CSS_CAN_SET_POINT] = false
    this.css[CSS_CAN_MODE] = false
    this.css[CSS_HAS_CONTOLS] = false
  }

  BasThermostat.prototype._resetSchedulerCss = function () {

    this.css[CSS_CAN_SCHEDULER] = false
  }

  /**
   * @private
   * @returns {?ThermostatDevice}
   */
  BasThermostat.prototype._getDevice = function () {
    if (this._basRoom &&
      this._basRoom.room &&
      BasUtil.isNEArray(this._basRoom.room.thermostats) &&
      BasUtil.isNEString(this.uuid)) {
      return CurrentBasCore.getDevice(this.uuid)
    }

    return null
  }

  BasThermostat.prototype._clearDeviceListeners = function () {

    BasUtil.executeArray(this._deviceListeners)
    this._deviceListeners = []
  }

  /**
   * Clears the controls
   *
   * @private
   */
  BasThermostat.prototype._clearControls = function clear () {

    var i, length, control

    length = this.controls.length

    for (i = 0; i < length; i++) {

      control = this.controls[i]

      if (BasUtil.isObject(control) &&
        BasUtil.isFunction(control.clear)) {

        control.clear()
      }
    }

    this.controls = []
  }

  BasThermostat.prototype.suspend = function () {

    this._clearDeviceListeners()
  }

  BasThermostat.prototype.clear = function () {

    this.suspend()
    this._clearControls()
    this.device = null
  }

  BasThermostat.prototype.destroy = function () {

    this._clearEventTimeout()
    this.clear()
  }

  return BasThermostat
}
