'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('lisaCtrl', [
    '$rootScope',
    '$scope',
    '$uiRouterGlobals',
    'BAS_ROOMS',
    'BAS_ROOM',
    'BAS_SOURCE',
    'BAS_LIGHT',
    'STATES',
    'BAS_STATE',
    'BAS_LISA',
    'ICONS',
    'BAS_PREFERENCES',
    'BAS_INTL',
    'BAS_TIME',
    'BAS_APP',
    'CurrentRoom',
    'BasState',
    'BasImageTrans',
    'BasTime',
    'BasCameras',
    'CurrentBasCore',
    'BasLisaUi',
    'Sources',
    'Weather',
    'BasPreferences',
    'BasAppDevice',
    'BasUtilities',
    'Swiper',
    lisaCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param $uiRouterGlobals
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_ROOM} BAS_ROOM
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {BAS_LIGHT} BAS_LIGHT
 * @param {STATES} STATES
 * @param {BAS_STATE} BAS_STATE
 * @param {BAS_LISA} BAS_LISA
 * @param {ICONS} ICONS
 * @param {BAS_PREFERENCES} BAS_PREFERENCES
 * @param {BAS_INTL} BAS_INTL
 * @param {BAS_TIME} BAS_TIME
 * @param {BAS_APP} BAS_APP
 * @param {CurrentRoom} CurrentRoom
 * @param {BasState} BasState
 * @param BasImageTrans
 * @param {BasTime} BasTime
 * @param {BasCameras} BasCameras
 * @param {CurrentBasCore} CurrentBasCore
 * @param {BasLisaUi} BasLisaUi
 * @param {Sources} Sources
 * @param {Weather} Weather
 * @param {BasPreferences} BasPreferences
 * @param {BasAppDevice} BasAppDevice
 * @param {BasUtilities} BasUtilities
 * @param Swiper
 */
function lisaCtrl (
  $rootScope,
  $scope,
  $uiRouterGlobals,
  BAS_ROOMS,
  BAS_ROOM,
  BAS_SOURCE,
  BAS_LIGHT,
  STATES,
  BAS_STATE,
  BAS_LISA,
  ICONS,
  BAS_PREFERENCES,
  BAS_INTL,
  BAS_TIME,
  BAS_APP,
  CurrentRoom,
  BasState,
  BasImageTrans,
  BasTime,
  BasCameras,
  CurrentBasCore,
  BasLisaUi,
  Sources,
  Weather,
  BasPreferences,
  BasAppDevice,
  BasUtilities,
  Swiper
) {
  var lisa = this

  var CSS_SHOW_MUSIC_INFO = 'lisa-show-music-info'
  var CSS_DISABLE_SWIPER = 'lisa-swiper-disable'

  var CSS_START_SHOW_TIME = 'lisa-start-show-time'
  var CSS_START_SHOW_WEATHER = 'lisa-start-show-weather'
  var CSS_START_SHOW_DATE = 'lisa-start-show-date'

  // Used to hide time/date opacity when the value is not up-to-date
  var CSS_START_HIDE_TIME = 'lisa-start-hide-time'

  var _swiper

  var _listeners = []

  var startSceneId = ''
  var currentSourceId = -1

  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  /**
   * @type {BasRooms}
   */
  lisa.rooms = BAS_ROOMS.ROOMS

  /**
   * @type {BAS_LISA}
   */
  lisa.BAS_LISA = BAS_LISA

  /**
   * @type {BAS_INTL}
   */
  lisa.BAS_INTL = BAS_INTL

  /**
   * @type {TBasLisaUi}
   */
  lisa.state = BasLisaUi.get()

  /**
   * @type {TCurrentRoomState}
   */
  lisa.currentRoom = CurrentRoom.get()

  /**
   * @type {TBasTime}
   */
  lisa.time = BasTime.get()

  /**
   * @type {TWeatherState}
   */
  lisa.weather = Weather.get()

  /**
   * @type {BasAppDeviceState}
   */
  lisa.basAppDeviceState = BasAppDevice.get()

  lisa.activePage = lisa.state.pages[BAS_LISA.F_START]

  lisa.uiStartSceneSubtitle = ''

  lisa.coverArtBit = new BasImageTrans({
    transitionType: BasImageTrans.TRANSITION_TYPE_FADE,
    debounceMs: 0,
    debounceMsNull: 0
  })
  lisa.musicPlayingIconBit = new BasImageTrans({
    transitionType: BasImageTrans.TRANSITION_TYPE_FADE_FAST,
    debounceMs: 0,
    debounceMsNull: 0,
    defaultImage: ICONS.music
  })
  lisa.css = {}
  lisa.css[CSS_START_HIDE_TIME] = false

  lisa.onPageTap = _onSlideClick
  lisa.onHomeTouchSurfaceClick = _onHomeTouchSurfaceClick
  lisa.onMusicIconTap = _onMusicIconTap
  lisa.uiOnParamsChanged = _uiOnParamsChanged

  init()

  function init () {

    $scope.$on('$destroy', _destroy)

    // Update the weather page
    Weather.update()

    _listeners.push($rootScope.$on(
      BAS_ROOM.EVT_SOURCE_CHANGED,
      _onSourceChanged
    ))

    _listeners.push($rootScope.$on(
      BAS_LISA.EVT_LISA_UPDATED,
      _onLisaUpdated
    ))

    _listeners.push($rootScope.$on(
      BAS_SOURCE.EVT_CURRENT_SONG,
      _onCurrentSongChanged
    ))

    _listeners.push($rootScope.$on(
      BAS_LIGHT.EVT_LIGHT_DEVICE_TOGGLE_UPDATED,
      _onLightDeviceUpdated
    ))

    _listeners.push($rootScope.$on(
      BAS_ROOM.EVT_SCENES_UPDATED,
      _onScenesUpdated
    ))

    _listeners.push($rootScope.$on(
      BAS_ROOMS.EVT_CURRENT_ROOM_CHANGED,
      _onCurrentRoomChanged
    ))

    _listeners.push($rootScope.$on(
      BAS_TIME.EVT_TIME_UPDATED,
      _onTimeUpdated
    ))

    _listeners.push($rootScope.$on(
      BAS_APP.EVT_PAUSE,
      _onPause
    ))

    _syncHomeScene()
    _syncStartPageWidget()
    _syncRegisterForSource()
    _syncCssOrder()

    // If we immediately sync the swiper, the page css is not yet rendered.
    //  Since swiper uses the visibility of the element to check if it should
    //  be a swipe element, doing this too soon wil result in an incorrect
    //  number of pages. Syncing the swiper after 2 frames will make sure it
    //  is called after css changes were rendered, resulting in a correct
    //  swiper page count.
    BasUtilities.waitForFrames(2, initSwiper)

    function initSwiper () {

      _setSwiper()
      _setView($uiRouterGlobals.params)

      $scope.$applyAsync()
    }
  }

  function _uiOnParamsChanged (params) {

    _setView(params)
  }

  function _onSlideClick () {

    var activePage

    if (_swiper) activePage = lisa.state.uiPages[_swiper.activeIndex]

    switch (activePage) {
      case BAS_LISA.F_START:
        break
      case BAS_LISA.F_SCENES:
        BasState.go(STATES.LISA_SCENES)
        break
      case BAS_LISA.F_THERMOSTAT:
        BasState.go(STATES.LISA_THERMOSTAT)
        break
      case BAS_LISA.F_MUSIC:
        BasState.go(STATES.LISA_MUSIC)
        break
      case BAS_LISA.F_LIGHTS:
        BasState.go(STATES.LISA_LIGHTS)
        break
      case BAS_LISA.F_SHADES:
        BasState.go(STATES.LISA_SHADES)
        break
      case BAS_LISA.F_CAMERA:
        BasCameras.goToCameras()
        break
      case BAS_LISA.F_INTERCOM:
        BasState.go(STATES.LISA_INTERCOM)
        break
    }
  }

  function _onHomeTouchSurfaceClick () {

    _handleHomePageTap()
  }

  function _onMusicIconTap () {

    if (_isPlayingMusic()) {

      BasState.go(STATES.LISA_MUSIC)
    }
  }

  function _syncHomeScene () {

    var _lightIsOn, _hasAllOff, _hasFavourite, scenes, room

    _lightIsOn = _isLightOn()
    _hasAllOff = false
    _hasFavourite = false
    room = CurrentRoom.getRoom()

    if (room && room.scenes && room.scenes.activateScene) {

      scenes = room.scenes

      _hasAllOff = BasUtil.isNEString(scenes.uiSceneOff)
      _hasFavourite = BasUtil.isNEString(scenes.uiQuadFavouriteScenes[0])

      if (_hasAllOff && _hasFavourite) {

        // Both off and favourite scene are available, display based
        //  on light status in room/
        lisa.uiStartSceneSubtitle = _lightIsOn
          ? scenes.scenes[scenes.uiSceneOff].name
          : scenes.scenes[scenes.uiQuadFavouriteScenes[0]].name

        startSceneId = _lightIsOn
          ? scenes.uiSceneOff
          : scenes.uiQuadFavouriteScenes[0]

      } else if (_hasAllOff) {

        // Only off scene available, always display off
        lisa.uiStartSceneSubtitle = scenes.scenes[scenes.uiSceneOff].name

        startSceneId = scenes.uiSceneOff

      } else if (_hasFavourite) {

        // Only favourite scene available, always display favourite
        lisa.uiStartSceneSubtitle =
          scenes.scenes[scenes.uiQuadFavouriteScenes[0]].name

        startSceneId = scenes.uiQuadFavouriteScenes[0]

      } else {

        // Favourite and off scene not available, show nothing
        lisa.uiStartSceneSubtitle = ''
        startSceneId = ''
      }

    } else {

      // Room or scenes not available, show nothing
      lisa.uiStartSceneSubtitle = ''
      startSceneId = ''
    }
  }

  function _syncCoverArt () {

    var basSource

    basSource = CurrentRoom.getBasSource()

    if (
      _shouldDisplayCoverArt() &&
      _isPlayingMusic() &&
      basSource
    ) {

      lisa.coverArtBit.track(basSource.bitBg)

    } else {

      lisa.coverArtBit.unTrack()
      lisa.coverArtBit.setImage(null)
    }

    lisa.coverArtBit.changeImageStyle(null, false)
  }

  function _syncStartPageWidget () {

    var option = BasPreferences.getStartPageWidget()

    lisa.css[CSS_START_SHOW_WEATHER] = false
    lisa.css[CSS_START_SHOW_TIME] = false
    lisa.css[CSS_START_SHOW_DATE] = false

    switch (option) {
      case BAS_PREFERENCES.START_PAGE_WIDGET_WEATHER:
        lisa.css[CSS_START_SHOW_WEATHER] = true
        break
      case BAS_PREFERENCES.START_PAGE_WIDGET_TIME_DATE:
        lisa.css[CSS_START_SHOW_DATE] = true
      // Deliberate fallthrough
      case BAS_PREFERENCES.START_PAGE_WIDGET_TIME:
        lisa.css[CSS_START_SHOW_TIME] = true
        break
    }
  }

  function _syncMusicInfo () {

    lisa.css[CSS_SHOW_MUSIC_INFO] = _shouldDisplayMusicIcon()
  }

  function _shouldDisplayCoverArt () {

    if (_swiper) {
      switch (lisa.state.uiPages[_swiper.activeIndex]) {
        case BAS_LISA.F_MUSIC:
        case BAS_LISA.F_START:

          return true
      }
    }

    return false
  }

  function _shouldDisplayMusicIcon () {

    return (
      _isPlayingMusic() &&
      _swiper &&
      lisa.state.uiPages[_swiper.activeIndex] === BAS_LISA.F_START
    )
  }

  /**
   * @private
   * @returns {boolean}
   */
  function _isPlayingMusic () {

    var basSource

    basSource = CurrentRoom.getBasSource()

    return basSource
      ? !basSource.paused
      : false
  }

  function _setView (params) {

    _setActivePage(_getPage(params))
  }

  /**
   * Syncs active page and indicator
   *
   * @private
   * @param page
   */
  function _setActivePage (page) {

    var index

    lisa.activePage = page

    if (_swiper) {

      if (BasUtil.isNEString(page)) {

        index = lisa.state.uiPages.indexOf(page)

        if (_swiper.activeIndex !== index && index !== -1) {

          _swiper.slideToLoop(index, 0)
        }
      }
    }
  }

  function _onLisaUpdated () {

    _syncCssOrder()
    BasUtilities.waitForFrames(2, _updateSwiper)
    $scope.$applyAsync()
  }

  /**
   * @private
   * @param {Object} _event
   * @param {string} roomId Room UUID
   */
  function _onSourceChanged (_event, roomId) {

    if (roomId === lisa.currentRoom.roomId) {

      _syncCoverArt()
      _syncMusicInfo()
      _syncRegisterForSource()
    }
  }

  /**
   * @private
   * @param {*} _event
   * @param {BasSource} basSource
   */
  function _onCurrentSongChanged (_event, basSource) {

    var _currentBasSource

    _currentBasSource = CurrentRoom.getBasSource()

    if (_currentBasSource && basSource && _currentBasSource.isSame(basSource)) {

      _syncCoverArt()
      _syncMusicInfo()
      $scope.$applyAsync()
    }
  }

  function _onLightDeviceUpdated () {

    _syncHomeScene()
  }

  /**
   * @private
   * @param {Object} _event
   * @param {BasRoom} basRoom
   */
  function _onScenesUpdated (_event, basRoom) {

    var room

    room = CurrentRoom.getRoom()

    if (room && basRoom && room.id === basRoom.id) {

      _syncHomeScene()
    }
  }

  function _onCurrentRoomChanged () {

    _syncHomeScene()
    BasUtilities.waitForFrames(2, _updateSwiper)
  }

  function _onTimeUpdated () {

    lisa.css[CSS_START_HIDE_TIME] = false
    $scope.$applyAsync()
  }

  function _onPause () {

    lisa.css[CSS_START_HIDE_TIME] = true
    $scope.$applyAsync()
  }

  function _syncRegisterForSource () {

    var basSource

    basSource = CurrentRoom.getBasSource()

    _unregisterForSource()

    if (basSource) {

      currentSourceId = basSource.getId()

      Sources.registerFor(BAS_SOURCE.COL_EVT_PLAYER, currentSourceId)
    }
  }

  function _unregisterForSource () {

    if (BasUtil.isPNumber(currentSourceId)) {

      Sources.unregisterFor(BAS_SOURCE.COL_EVT_PLAYER, currentSourceId)
      currentSourceId = -1
    }
  }

  function _setSwiper () {

    var _config

    _clearSwiper()

    _config = {}

    _config.pagination = {
      el: '.swiper-pagination',
      renderBullet: function (_index, className) {
        return '<span class="' + className + ' bas-page-bullet">' +
          '<div class="bas-page-bullet-color"></div>' +
          '</span>'
      },
      initialSlide: lisa.state.uiPages.indexOf(
        _getPage($uiRouterGlobals.params)
      )
    }

    _swiper = new Swiper(
      '.lisa-page-container',
      _config
    )

    _swiper.on('slideChange', _onSlideChange)
    _swiper.on('click', _onSlideClick)

    _syncSwiperDisabled()
    _syncCssOrder()
    _syncMusicInfo()
    _syncCoverArt()
  }

  function _updateSwiper () {

    if (_swiper) {

      _swiper.update()
      _syncSwiperDisabled()
      _syncCoverArt()
      _syncMusicInfo()
    }
  }

  function _onSlideChange () {

    var activePage

    _syncCoverArt()
    _syncMusicInfo()

    if (_swiper) {

      activePage = lisa.state.uiPages[_swiper.activeIndex]

      // Sync URL to swiper page
      BasState.go(
        STATES.LISA,
        {
          view: _getView(activePage)
        }
      )
    }
  }

  function _syncCssOrder () {

    var keys, length, i

    // Set all pages to display none
    keys = Object.keys(lisa.state.pages)
    length = keys.length
    for (i = 0; i < length; i++) {

      lisa.css[keys[i]] = {
        display: 'none'
      }
    }

    // Overwrite pages that should be shown with order css
    length = lisa.state.uiPages.length
    for (i = 0; i < length; i++) {
      lisa.css[lisa.state.uiPages[i]] = {
        order: i
      }
    }
  }

  function _syncSwiperDisabled () {

    lisa.css[CSS_DISABLE_SWIPER] = _swiper && _swiper.slides.length < 2
  }

  function _getPage (params) {

    switch (params.view) {
      case BAS_STATE.S_LISA_VIEW_LIGHTS:
        return BAS_LISA.F_LIGHTS
      case BAS_STATE.S_LISA_VIEW_SCENES:
        return BAS_LISA.F_SCENES
      case BAS_STATE.S_LISA_VIEW_MUSIC:
        return BAS_LISA.F_MUSIC
      case BAS_STATE.S_LISA_VIEW_SHADES:
        return BAS_LISA.F_SHADES
      case BAS_STATE.S_LISA_VIEW_THERMOSTAT:
        return BAS_LISA.F_THERMOSTAT
      case BAS_STATE.S_LISA_VIEW_CAMERAS:
        return BAS_LISA.F_CAMERA
      case BAS_STATE.S_LISA_VIEW_INTERCOM:
        return BAS_LISA.F_INTERCOM
      case BAS_STATE.S_LISA_VIEW_HOME:
      default:
        return BAS_LISA.F_START
    }
  }

  function _getView (page) {
    switch (page) {
      case BAS_LISA.F_LIGHTS:
        return BAS_STATE.S_LISA_VIEW_LIGHTS
      case BAS_LISA.F_SCENES:
        return BAS_STATE.S_LISA_VIEW_SCENES
      case BAS_LISA.F_MUSIC:
        return BAS_STATE.S_LISA_VIEW_MUSIC
      case BAS_LISA.F_SHADES:
        return BAS_STATE.S_LISA_VIEW_SHADES
      case BAS_LISA.F_THERMOSTAT:
        return BAS_STATE.S_LISA_VIEW_THERMOSTAT
      case BAS_LISA.F_CAMERA:
        return BAS_STATE.S_LISA_VIEW_CAMERAS
      case BAS_LISA.F_INTERCOM:
        return BAS_STATE.S_LISA_VIEW_INTERCOM
      case BAS_LISA.F_START:
      default:
        return BAS_STATE.S_LISA_VIEW_HOME
    }
  }

  function _handleHomePageTap () {

    var room

    room = CurrentRoom.getRoom()

    if (CurrentBasCore.has() && currentBasCoreState.core.isDemo()) {

      // Adjust dummy variable
      lisa.lightOn = !lisa.lightOn
      _syncHomeScene()

    } else if (
      room &&
      room.scenes &&
      room.scenes.activateScene &&
      BasUtil.isNEString(startSceneId)
    ) {
      room.scenes.activateScene(startSceneId)
      _syncHomeScene()
    }
  }

  function _isLightOn () {

    var room

    room = CurrentRoom.getRoom()

    if (CurrentBasCore.has() && currentBasCoreState.core.isDemo()) {

      // Demo scenes don't actually do anything so we use dummy variable
      return !!lisa.lightOn
    }

    if (room && room.lights && room.lights.areLightsActive) {

      return room.lights.areLightsActive()
    }

    return false
  }

  function _clearSwiper () {

    if (_swiper && _swiper.destroy) _swiper.destroy(true, false)
    _swiper = null
  }

  function _destroy () {

    BasUtil.executeArray(_listeners)
    _listeners = []

    _unregisterForSource()

    _clearSwiper()
  }
}
