'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('lisaShadeDetailsCtrl', [
    '$uiRouterGlobals',
    'BAS_STATE',
    'STATES',
    'CurrentRoom',
    'BasState',
    lisaShadeDetailsCtrl
  ])

/**
 * @param $uiRouterGlobals
 * @param {BAS_STATE} BAS_STATE
 * @param {STATES} STATES
 * @param {CurrentRoom} CurrentRoom
 * @param {BasState} BasState
 */
function lisaShadeDetailsCtrl (
  $uiRouterGlobals,
  BAS_STATE,
  STATES,
  CurrentRoom,
  BasState
) {
  var lisa = this

  var CSS_SHOW_EXTRA_OVERLAY = 'lisa-shade--show-extra'

  lisa.setShadePosition = setShadePosition
  lisa.toggleExtraOptions = toggleExtraOptions
  lisa.uiOnParamsChanged = uiOnParamsChanged

  lisa.css = {}
  lisa.css[CSS_SHOW_EXTRA_OVERLAY] =
    $uiRouterGlobals.params.view ===
    BAS_STATE.S_LISA_SHADE_DETAILS_VIEW_EXTRA

  init()

  function init () {

    _getShade()
  }

  function setShadePosition (position) {

    if (lisa.shade) {

      lisa.shade.position = position
      lisa.shade.changePosition()
    }
  }

  function toggleExtraOptions (force) {

    var view

    view = (
      BasUtil.isBool(force) ||
      $uiRouterGlobals.params.view !== BAS_STATE.S_LISA_SHADE_DETAILS_VIEW_EXTRA
    )
      ? BAS_STATE.S_LISA_SHADE_DETAILS_VIEW_EXTRA
      : ''

    BasState.go(
      STATES.LISA_SHADE_DETAIL,
      {
        view: view
      }
    )

    lisa.css[CSS_SHOW_EXTRA_OVERLAY] =
      view === BAS_STATE.S_LISA_LIGHT_DETAILS_VIEW_COLOR_PICKER
  }

  function uiOnParamsChanged (params) {

    lisa.css[CSS_SHOW_EXTRA_OVERLAY] =
      params.view === BAS_STATE.S_LISA_SHADE_DETAILS_VIEW_EXTRA
  }

  function _getShade () {

    var room

    room = CurrentRoom.getRoom()

    lisa.shade = (room && room.shades && room.shades.getShade)
      ? room.shades.getShade($uiRouterGlobals.params.shade)
      : null
  }
}
