'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasThermostatSchedulerPoint', [
    'BAS_API',
    'BAS_THERMOSTAT',
    'BAS_INTL',
    'BasSchedulerPoint',
    'BasAppTemperature',
    'BasIntl',
    basThermostatSchedulerPointFactory
  ])

/**
 * @param BAS_API
 * @param {BAS_THERMOSTAT} BAS_THERMOSTAT
 * @param {BAS_INTL} BAS_INTL
 * @param BasSchedulerPoint
 * @param {BasAppTemperature} BasAppTemperature
 * @param {BasIntl} BasIntl
 * @returns BasThermostatSchedulerPoint
 */
function basThermostatSchedulerPointFactory (
  BAS_API,
  BAS_THERMOSTAT,
  BAS_INTL,
  BasSchedulerPoint,
  BasAppTemperature,
  BasIntl
) {
  var CSS_MODE_ANY = 'bsch-point--mode-any'
  var CSS_MODE_HEATING = 'bsch-point--mode-heating'
  var CSS_MODE_COOLING = 'bsch-point--mode-cooling'
  var CSS_MODE_AUTO = 'bsch-point--mode-auto'

  /**
   * @constructor
   * @extends BasSchedulerPoint
   * @param {SetPoint} [setPoint]
   * @param {number} precision
   */
  function BasThermostatSchedulerPoint (setPoint, precision) {

    BasSchedulerPoint.call(this)

    /**
     * Number of seconds
     *
     * @type {number}
     */
    this.time = 0

    /**
     * @type {Temperature}
     */
    this.temperature = new BAS_API.Temperature()

    /**
     * @type {string}
     */
    this.mode = BAS_API.SetPoint.M_ANY

    /**
     * @type {number}
     */
    this.precision = precision

    this._resetModeCss()

    this.parse(setPoint)
  }

  BasThermostatSchedulerPoint.prototype =
    Object.create(BasSchedulerPoint.prototype)
  BasThermostatSchedulerPoint.prototype.constructor =
    BasThermostatSchedulerPoint

  /**
   * Lower x value comes first
   * Invalid points get a lower index
   *
   * @param {BasThermostatSchedulerPoint} p1
   * @param {BasThermostatSchedulerPoint} p2
   * @returns {number}
   */
  BasThermostatSchedulerPoint.compareX = function (p1, p2) {

    var diff

    if (BasUtil.isObject(p1) &&
      BasUtil.isVNumber(p1.x) &&
      BasUtil.isObject(p2) &&
      BasUtil.isVNumber(p2.x)) {

      diff = p1.x - p2.x

      if (p1.mode !== p2.mode && diff === 0) {

        if (p1.mode === BAS_API.SetPoint.M_ANY) return -1

        if (p1.mode === BAS_API.SetPoint.M_HEATING) {

          return p2.mode === BAS_API.SetPoint.M_ANY ? 1 : -1
        }

        if (p1.mode === BAS_API.SetPoint.M_COOLING) {

          return p2.mode === BAS_API.SetPoint.M_AUTO ? -1 : 1
        }

        if (p1.mode === BAS_API.SetPoint.M_AUTO) return 1
      }

      return diff

    } else if (BasUtil.isObject(p1) && BasUtil.isVNumber(p1.x)) {

      return 1

    } else if (BasUtil.isObject(p2) && BasUtil.isVNumber(p2.x)) {

      return -1
    }

    return 0
  }

  /**
   * @param {number} [x]
   * @param {number} [step]
   * @returns {string}
   */
  BasThermostatSchedulerPoint.prototype.getUiValueXFor = function (
    x,
    step
  ) {
    var _x, cleanValue, date

    _x = BasUtil.isVNumber(x) ? x : this.x

    cleanValue = BasUtil.isVNumber(step)
      ? Math.round(_x / step) * step
      : Math.round(_x)
    date = new Date(cleanValue * 1000)

    return BasIntl.dateToString(date, BAS_INTL.LOCALE_OPTION_SHORT, 'GMT')
  }

  /**
   * @param {number} y
   * @returns {string}
   */
  BasThermostatSchedulerPoint.prototype.getUiValueYFor = function (y) {

    return BasUtil.formatTemperature(
      y,
      {
        precision: this.precision,
        includeTrailingZeros: true
      }
    ) + '°'
  }

  /**
   * @param {number} x time in seconds
   */
  BasThermostatSchedulerPoint.prototype.setXValue = function (x) {

    this.time = this.x = x
  }

  /**
   * @param {number} y In degrees
   */
  BasThermostatSchedulerPoint.prototype.setYValue = function (y) {

    this.y = y

    this.temperature.setTemperature(
      this.y,
      BasAppTemperature.getTemperatureUnit()
    )
    this._syncUiValue()
  }

  /**
   * @param {BasSchedulerPointType} pointType
   */
  BasThermostatSchedulerPoint.prototype.setModeFromPointType =
    function (pointType) {

      switch (pointType.id) {
        case BAS_THERMOSTAT.MODE_HEATING:
          this.setMode(BAS_API.SetPoint.M_HEATING)
          break
        case BAS_THERMOSTAT.MODE_COOLING:
          this.setMode(BAS_API.SetPoint.M_COOLING)
          break
        case BAS_THERMOSTAT.MODE_AUTO:
          this.setMode(BAS_API.SetPoint.M_AUTO)
          break
      }
    }

  /**
   * @param {string} mode
   */
  BasThermostatSchedulerPoint.prototype.setMode = function (mode) {

    this.mode = mode
    this._syncMode()
  }

  /**
   * @param {SetPoint} setPoint
   */
  BasThermostatSchedulerPoint.prototype.parse = function (setPoint) {

    if (BasUtil.isObject(setPoint)) {

      this.time = setPoint.time
      this.temperature.setTemperature(
        setPoint.temperature,
        BasAppTemperature.getTemperatureUnit()
      )
      this.mode = setPoint.mode

      this._syncX()
      this._syncY()
      this._syncMode()
      this._syncUiValue()
    }
  }

  /**
   * @returns {number}
   */
  BasThermostatSchedulerPoint.prototype.getTemperature = function () {

    return this.temperature.getTemperature(
      BasAppTemperature.getTemperatureUnit()
    )
  }

  /**
   * @returns {SetPoint}
   */
  BasThermostatSchedulerPoint.prototype.getApiSetPoint = function () {

    var result

    result = new BAS_API.SetPoint()
    result.time = this.time
    result.temperature.setTemperature(this.temperature)
    result.mode = this.mode

    return result
  }

  BasThermostatSchedulerPoint.prototype.updateTemperatureUnit = function () {

    this._syncY()
    this._syncUiValue()
  }

  BasThermostatSchedulerPoint.prototype.updateTranslation = function () {

    // Empty
  }

  /**
   * @private
   */
  BasThermostatSchedulerPoint.prototype._syncX = function () {

    this.x = this.time
  }

  /**
   * @private
   */
  BasThermostatSchedulerPoint.prototype._syncY = function () {

    this.y = this.temperature.getTemperature(
      BasAppTemperature.getTemperatureUnit()
    )
  }

  /**
   * @private
   */
  BasThermostatSchedulerPoint.prototype._syncMode = function () {

    this._resetModeCss()

    switch (this.mode) {
      case BAS_API.SetPoint.M_ANY:
        this.css[CSS_MODE_ANY] = true
        break
      case BAS_API.SetPoint.M_HEATING:
        this.css[CSS_MODE_HEATING] = true
        break
      case BAS_API.SetPoint.M_COOLING:
        this.css[CSS_MODE_COOLING] = true
        break
      case BAS_API.SetPoint.M_AUTO:
        this.css[CSS_MODE_AUTO] = true
        break
    }
  }

  /**
   * @private
   */
  BasThermostatSchedulerPoint.prototype._syncUiValue = function () {

    const rounded = BasUtil.formatTemperature(
      this.getTemperature(),
      { precision: this.precision }
    )

    this.uiSimpleValue = '' + rounded
    this.uiValue = rounded + BAS_THERMOSTAT.DEGREE
  }

  /**
   * @returns {BasThermostatSchedulerPoint}
   */
  BasThermostatSchedulerPoint.prototype.clone = function () {

    var result

    result = new BasThermostatSchedulerPoint(undefined, this.precision)
    this.copyPropertiesTo(result)

    return result
  }

  /**
   * @param {Object} destination
   */
  BasThermostatSchedulerPoint.prototype.copyPropertiesTo =
    function (destination) {

      BasSchedulerPoint.prototype.copyPropertiesTo.call(
        this,
        destination
      )

      if (BasUtil.isObject(destination)) {

        destination.time = this.time
        destination.temperature = this.temperature.clone()
        destination.mode = this.mode
      }
    }

  BasThermostatSchedulerPoint.prototype._resetModeCss = function () {

    this.css[CSS_MODE_ANY] = false
    this.css[CSS_MODE_HEATING] = false
    this.css[CSS_MODE_COOLING] = false
    this.css[CSS_MODE_AUTO] = false
  }

  return BasThermostatSchedulerPoint
}
