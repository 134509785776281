'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('basSchedulerPoint', basSchedulerPointDirective)

/**
 * @returns basSchedulerPoint
 */
function basSchedulerPointDirective () {

  return {
    restrict: 'AE',
    template:
      '<div ng-bind="schedulerPoint.point.uiValue"></div>' +
      '<div class="bsche-point-balloon"></div>' +
      '<div class="bsche-point-label"></div>',
    controller: [
      '$element',
      '$scope',
      '$window',
      '$rootScope',
      'UI_HELPER',
      'BasUtilities',
      controller
    ],
    controllerAs: 'schedulerPoint',
    bindToController: {
      gridElementClass: '@?',
      removeElementClass: '@?',
      xMin: '<?',
      xMax: '<?',
      excludeXMax: '<?',
      xStep: '<?',
      yMin: '<?',
      yMax: '<?',
      yStep: '<?',
      point: '<',
      isXAllowed: '&?',
      changePoint: '&?',
      deletePoint: '&?'
    }
  }

  /**
   * @param $element
   * @param $scope
   * @param $window
   * @param $rootScope
   * @param {UI_HELPER} UI_HELPER
   * @param {BasUtilities} BasUtilities
   */
  function controller (
    $element,
    $scope,
    $window,
    $rootScope,
    UI_HELPER,
    BasUtilities
  ) {
    var schedulerPoint = this

    var CSS_BALLOON = 'bsche-point-balloon'
    var CSS_LABEL = 'bsche-point-label'

    var CSS_CAN_ANIMATE = 'bschp-point--can-animate'
    var CSS_CAN_ANIMATE_ALL = 'bschp-point--can-animate-all'
    var CSS_PRESSED = 'bschp--pressed'
    var CSS_SHOW_BALLOON = 'bschp--show-balloon'
    var CSS_SEMI_TRANSPARENT = 'bschp--semi-transparent'

    var CSS_REMOVE_SHOW = 'bsche-remove--show'
    var CSS_REMOVE_ACTIVE = 'bsche-remove--active'

    var TRANSITION_TIMEOUT_MS = 300

    var _element, _balloonElement, _txtBalloon, _labelElement, _txtLabel
    var _parentElement, _gridElement, _removeElement
    var _elementListeners, _globalMouseListeners
    var _listeners
    var _abortWaitFrames, _abortWaitFramesForScaleUp
    var _newTransitionTimeoutId, _removeTransitionTimeoutId

    var _activeListenerOptions = {
      capture: false,
      passive: false
    }
    var _passiveListenerOptions = {
      capture: false,
      passive: true
    }

    var _pointWidth, _pointHeight, _pointHalfHeight
    var _gridWidth, _gridHeight
    var _gridLeft, _gridRight, _gridTop, _gridBottom
    var _gridUnitHorizontal, _gridUnitVertical
    var _removeTop, _removeBottom
    var _pointXNormalized, _pointYNormalized
    var _pointXDenormalized, _pointYDenormalized
    var _pointTranslateX, _pointTranslateY, _pointScale
    var _startEventX, _startEventY
    var _startValueX, _startValueY
    var _horizontalLock, _verticalLock
    var _remove

    schedulerPoint.$postLink = _postLink
    schedulerPoint.$onChanges = _onChanges
    schedulerPoint.$onDestroy = _onDestroy

    init()

    function init () {

      _elementListeners = []
      _globalMouseListeners = []
      _listeners = []

      _pointWidth = 0
      _pointHeight = 0

      _pointXNormalized = 0
      _pointYNormalized = 0

      _pointXDenormalized = 0
      _pointYDenormalized = 0

      _pointTranslateX = 0
      _pointTranslateY = 0
      _pointScale = 1

      _horizontalLock = false
      _verticalLock = false

      _remove = false

      _listeners.push($rootScope.$on(
        UI_HELPER.EVT_RESIZE,
        _onResize
      ))
    }

    function _postLink () {

      _pointScale = 1

      _clearRemoveTransitionTimeout()
      _clearNewTransitionTimeout()
      _clearWaitFrames()
      _clearWaitFramesForScaleUp()

      _getElements()
      _setElementListeners()

      _element.classList.remove(
        CSS_CAN_ANIMATE,
        CSS_CAN_ANIMATE_ALL,
        CSS_PRESSED,
        CSS_SEMI_TRANSPARENT,
        CSS_SHOW_BALLOON
      )

      _processGridDimensions()
      _processRemovePlacement()

      if (schedulerPoint.point && schedulerPoint.point.new === true) {

        _pointScale = 0
      }

      _syncPositionFromPoint()
      _setTransform()
      _syncLabel()

      _abortWaitFrames = BasUtilities.waitForFrames(
        5,
        _onWaitFrames
      )
    }

    /**
     * @private
     * @param {Object} changes
     */
    function _onChanges (changes) {

      // Catch first changes event
      if (changes.gridElementClass) {

        // Empty

      } else {

        _pointScale = 1

        _element.classList.remove(
          CSS_CAN_ANIMATE,
          CSS_CAN_ANIMATE_ALL,
          CSS_PRESSED,
          CSS_SEMI_TRANSPARENT,
          CSS_SHOW_BALLOON
        )

        _clearRemoveTransitionTimeout()
        _clearNewTransitionTimeout()
        _clearWaitFrames()
        _clearWaitFramesForScaleUp()

        if (changes.point) {

          if (changes.point.currentValue.new === true) {

            schedulerPoint.point.new = false

            _pointScale = 0

            _abortWaitFramesForScaleUp = BasUtilities.waitForFrames(
              5,
              _onWaitFramesForScaleUp
            )
          }

          _syncLabel()
        }

        _syncPositionFromPoint()
        _setTransform()
      }
    }

    function _onWaitFrames () {

      _abortWaitFrames = null

      _processGridDimensions()
      _processRemovePlacement()

      if (schedulerPoint.point && schedulerPoint.point.new === true) {

        schedulerPoint.point.new = false

        _pointScale = 0

        _abortWaitFramesForScaleUp = BasUtilities.waitForFrames(
          5,
          _onWaitFramesForScaleUp
        )
      }

      _syncPositionFromPoint()
      _setTransform()
    }

    function _onWaitFramesForScaleUp () {

      _abortWaitFramesForScaleUp = null

      _element.classList.add(CSS_CAN_ANIMATE_ALL)

      _pointScale = 1

      _setTransform()

      _newTransitionTimeoutId = setTimeout(
        _onNewTransitionTimeout,
        TRANSITION_TIMEOUT_MS
      )
    }

    function _onNewTransitionTimeout () {

      _element.classList.remove(CSS_CAN_ANIMATE_ALL)
    }

    /**
     * @private
     * @param {number} x
     * @param {number} y
     */
    function _startMove (x, y) {

      _element.classList.remove(CSS_CAN_ANIMATE_ALL)
      _element.classList.add(CSS_CAN_ANIMATE)
      _element.classList.add(CSS_PRESSED)

      _startEventX = x
      _startEventY = y

      _startValueX = _pointXDenormalized
      _startValueY = _pointYDenormalized

      _remove = false

      _processGridDimensions()
      _processRemovePlacement()
    }

    /**
     * @private
     * @param {number} x
     * @param {number} y
     */
    function _move (x, y) {

      var _gridValueNormalized, _gridPointValueNormalized
      var _point, value, roundedValue, uiValue

      _remove = false

      if (!_horizontalLock && !_verticalLock) {

        if (Math.abs(x - _startEventX) > 10) {

          _horizontalLock = true

        } else if (Math.abs(y - _startEventY) > 10) {

          _verticalLock = true
        }

      } else {

        _element.classList.add(CSS_SHOW_BALLOON)
      }

      _point = schedulerPoint.point

      if (_horizontalLock) {

        if (x < _gridLeft) {

          _gridValueNormalized = 0

        } else if (x > _gridRight) {

          _gridValueNormalized = 1

        } else {

          _gridValueNormalized = (x - _gridLeft) / _gridWidth
        }

        _gridPointValueNormalized = _gridValueNormalized

        value = _deNormalizeX(_gridPointValueNormalized)
        roundedValue = _roundXValue(value)

        if (BasUtil.isFunction(schedulerPoint.isXAllowed)) {

          if (schedulerPoint.isXAllowed({
            point: _point,
            x: roundedValue
          })) {

            if (BasUtil.isNumber(schedulerPoint.xMax) &&
              roundedValue >= schedulerPoint.xMax &&
              schedulerPoint.excludeXMax === true) {

              return
            }

            _pointXNormalized = _gridPointValueNormalized
            _pointXDenormalized = value
            _pointTranslateX =
              _gridValueNormalized * 100 * _gridUnitHorizontal

            _setTransform(true)

            // TODO Future improvement: we don't need to get the
            //  ui value for each change, only when we know the
            //  label will change
            uiValue = _point.getUiValueXFor(
              _pointXDenormalized,
              schedulerPoint.xStep
            )

          } else {

            return
          }
        }

      } else if (_verticalLock) {

        if (_removeElement) {

          _removeElement.classList.add(CSS_REMOVE_SHOW)
        }

        if (y > _gridBottom) {

          _gridValueNormalized = _gridPointValueNormalized = 1

        } else if (y < (_removeTop + _pointHalfHeight)) {

          _gridValueNormalized = -1 *
            (_gridTop - (_removeTop + _pointHalfHeight)) /
            _gridHeight
          _gridPointValueNormalized = 0

        } else {

          _gridValueNormalized = (y - _gridTop) / _gridHeight

          _gridPointValueNormalized = y < _gridTop
            ? 0
            : _gridValueNormalized
        }

        _element.classList.toggle(
          CSS_SEMI_TRANSPARENT,
          y < _gridTop
        )

        _remove = y <= _removeBottom

        if (_removeElement) {

          _removeElement.classList.toggle(
            CSS_REMOVE_ACTIVE,
            _remove
          )
        }

        _pointYNormalized = 1 - _gridPointValueNormalized
        _pointYDenormalized = _deNormalizeY(_pointYNormalized)
        _pointTranslateY =
          _gridValueNormalized * 100 * _gridUnitVertical

        _setTransform(true)

        if (_point) {

          uiValue = _point.getUiValueYFor(
            _pointYDenormalized
          )
        }
      }

      _txtBalloon.nodeValue = uiValue || '-'
    }

    function _moveEnd () {

      var _point

      if (_removeElement) {

        _removeElement.classList.remove(
          CSS_REMOVE_ACTIVE,
          CSS_REMOVE_SHOW
        )
      }

      _horizontalLock = false
      _verticalLock = false

      _point = schedulerPoint.point

      if (_remove) {

        _element.classList.add(CSS_CAN_ANIMATE_ALL)
        _element.classList.remove(CSS_CAN_ANIMATE)

        _pointScale = 0

        _setTransform(true)

        _removeTransitionTimeoutId = setTimeout(
          _onRemoveTransitionTimeout,
          TRANSITION_TIMEOUT_MS
        )

      } else {

        _element.classList.remove(
          CSS_PRESSED,
          CSS_SEMI_TRANSPARENT,
          CSS_SHOW_BALLOON
        )

        _roundToStep()
        _setTransform(false)

        _element.classList.remove(CSS_CAN_ANIMATE)

        if (_startValueX !== _pointXDenormalized ||
          _startValueY !== _pointYDenormalized) {

          if (_point) {

            _point.setXValue(_pointXDenormalized)
            _point.setYValue(_pointYDenormalized)
          }

          _syncLabel()

          if (BasUtil.isFunction(schedulerPoint.changePoint)) {

            schedulerPoint.changePoint(
              {
                point: _point
              }
            )

            $scope.$applyAsync()
          }
        }
      }
    }

    function _onRemoveTransitionTimeout () {

      _element.classList.remove(
        CSS_PRESSED,
        CSS_SEMI_TRANSPARENT,
        CSS_SHOW_BALLOON,
        CSS_CAN_ANIMATE_ALL
      )

      if (BasUtil.isFunction(schedulerPoint.changePoint)) {

        schedulerPoint.deletePoint(
          {
            point: schedulerPoint.point
          }
        )

        $scope.$applyAsync()
      }
    }

    /**
     * @private
     * @param {number} value [0 - 1]
     * @returns {number}
     */
    function _deNormalizeX (value) {

      var _xMin, _xMax, range

      _xMin = schedulerPoint.xMin
      _xMax = schedulerPoint.xMax

      if (BasUtil.isVNumber(_xMin) && BasUtil.isVNumber(_xMax)) {

        range = _xMax - _xMin

        return value * range + _xMin
      }

      return value
    }

    /**
     * @private
     * @param {number} value [0 - 1]
     * @returns {number}
     */
    function _deNormalizeY (value) {

      var _yMin, _yMax, range

      _yMin = schedulerPoint.yMin
      _yMax = schedulerPoint.yMax

      if (BasUtil.isVNumber(_yMin) && BasUtil.isVNumber(_yMax)) {

        range = _yMax - _yMin

        return value * range + _yMin
      }

      return value
    }

    /**
     * @private
     * @param {number} x denormalized
     * @returns {number}
     */
    function _roundXValue (x) {

      var result, _xMin, _xMax, _xStep

      result = x

      _xStep = schedulerPoint.xStep

      if (BasUtil.isVNumber(_xStep)) {

        result = Math.round(result / _xStep) * _xStep
      }

      _xMin = schedulerPoint.xMin

      if (BasUtil.isVNumber(_xMin)) {

        if (result < _xMin) result = _xMin
      }

      _xMax = schedulerPoint.xMax

      if (BasUtil.isVNumber(_xMax)) {

        if (result > _xMax) result = _xMax
      }

      return result
    }

    function _roundToStep () {

      var _xMin, _xMax, _xStep, _nXStep, _yMin, _yMax, _yStep, _nYStep
      var range

      _xMin = schedulerPoint.xMin
      _xMax = schedulerPoint.xMax
      _xStep = schedulerPoint.xStep

      if (BasUtil.isVNumber(_xMin) &&
        BasUtil.isVNumber(_xMax) &&
        BasUtil.isVNumber(_xStep)) {

        range = _xMax - _xMin
        _nXStep = _xStep / range

        _pointXNormalized =
          Math.round(_pointXNormalized / _nXStep) * _nXStep
        _pointXDenormalized = _roundXValue(
          _deNormalizeX(_pointXNormalized)
        )
        _pointTranslateX =
          _pointXNormalized * 100 * _gridUnitHorizontal
      }

      _yMin = schedulerPoint.yMin
      _yMax = schedulerPoint.yMax
      _yStep = schedulerPoint.yStep

      if (BasUtil.isVNumber(_yMin) &&
        BasUtil.isVNumber(_yMax) &&
        BasUtil.isVNumber(_yStep)) {

        range = _yMax - _yMin
        _nYStep = _yStep / range

        _pointYNormalized =
          Math.round(_pointYNormalized / _nYStep) * _nYStep
        _pointYDenormalized = _deNormalizeY(_pointYNormalized)
        // 1 - because inverse y-axis for translate
        _pointTranslateY =
          (1 - _pointYNormalized) * 100 * _gridUnitVertical
      }
    }

    function _processGridDimensions () {

      var rect

      if (_element) {

        rect = _element.getBoundingClientRect()

        _pointWidth = rect.width
        _pointHeight = rect.height

        _pointHalfHeight = _pointHeight / 2
      }

      if (_gridElement) {

        rect = _gridElement.getBoundingClientRect()

        _gridWidth = rect.width
        _gridHeight = rect.height
        _gridLeft = rect.left
        _gridRight = rect.right
        _gridTop = rect.top
        _gridBottom = rect.bottom

        if (_pointWidth) {

          _gridUnitHorizontal = _gridWidth / _pointWidth
        }

        if (_pointHeight) {

          _gridUnitVertical = _gridHeight / _pointHeight
        }
      }
    }

    function _processRemovePlacement () {

      var rect

      if (_removeElement) {

        rect = _removeElement.getBoundingClientRect()

        _removeTop = rect.top
        _removeBottom = rect.bottom
      }
    }

    function _syncPositionFromPoint () {

      var _point

      _point = schedulerPoint.point

      if (_point) _calculatePosition(_point.x, _point.y)
    }

    /**
     * @private
     * @param x actual value (same unit as xMin, xMax)
     * @param y actual value (same unit as yMin, yMax)
     */
    function _calculatePosition (x, y) {

      var _x, _y, range, _xMin, _xMax, _yMin, _yMax

      if (BasUtil.isVNumber(x)) {

        _xMin = schedulerPoint.xMin
        _xMax = schedulerPoint.xMax

        _pointXDenormalized = x

        if (BasUtil.isVNumber(_xMin) && BasUtil.isVNumber(_xMax)) {

          _x = x
          if (_x < _xMin) _x = _xMin
          if (_x > _xMax) _x = _xMax

          range = _xMax - _xMin

          _pointXNormalized = (_x - _xMin) / range
          _pointTranslateX =
            _pointXNormalized *
            100 *
            _gridUnitHorizontal

        } else {

          _pointXNormalized = x
          _pointTranslateX = x * 100 * _gridUnitHorizontal
        }
      }

      if (BasUtil.isVNumber(y)) {

        _yMin = schedulerPoint.yMin
        _yMax = schedulerPoint.yMax

        _pointYDenormalized = y

        if (BasUtil.isVNumber(_yMin) && BasUtil.isVNumber(_yMax)) {

          _y = y
          if (_y < _yMin) _y = _yMin
          if (_y > _yMax) _y = _yMax

          range = _yMax - _yMin

          _pointYNormalized = (_y - _yMin) / range
          // 1 - because inverse y-axis for translate
          _pointTranslateY =
            (1 - _pointYNormalized) *
            100 *
            _gridUnitVertical

        } else {

          _pointYNormalized = y
          // 1 - because inverse y-axis for translate
          _pointTranslateY =
            ((1 - y) * 100) * _gridUnitVertical
        }
      }
    }

    /**
     * @private
     * @param {boolean} [use3d]
     */
    function _setTransform (use3d) {

      if (_element) {

        if (use3d) {

          _element.style.transform = 'translate3d(' +
            _pointTranslateX.toFixed(2) + '%, ' +
            _pointTranslateY.toFixed(2) + '%, 0)' +
            ' scale(' + _pointScale + ')'

        } else {

          _element.style.transform = 'translate(' +
            _pointTranslateX.toFixed(2) + '%, ' +
            _pointTranslateY.toFixed(2) + '%)' +
            ' scale(' + _pointScale + ')'
        }
      }
    }

    function _syncLabel () {

      var uiValue

      if (_txtLabel && schedulerPoint.point) {

        uiValue = schedulerPoint.point.getUiValueXFor(NaN, schedulerPoint.xStep)
        _txtLabel.nodeValue = uiValue || '-'
      }
    }

    function _onResize () {

      _processGridDimensions()
      _processRemovePlacement()
      _syncPositionFromPoint()
      _setTransform()
    }

    /**
     * @private
     * @param {TouchEvent} event
     */
    function _onTouchStart (event) {

      var touch

      touch = event.changedTouches[0]

      if (touch) {

        _startMove(touch.pageX, touch.pageY)
      }
    }

    /**
     * @private
     * @param {TouchEvent} event
     */
    function _onTouchMove (event) {

      var touch

      if (event.cancelable) {

        event.stopPropagation()
        event.preventDefault()
      }

      touch = event.changedTouches[0]

      if (touch) {

        _move(touch.pageX, touch.pageY)
      }
    }

    function _onTouchEnd () {

      _moveEnd()
    }

    function _onTouchCancel () {

      _moveEnd()
    }

    /**
     * @private
     * @param {MouseEvent} event
     */
    function _onMouseDown (event) {

      _setGlobalMouseListeners()

      _startMove(event.pageX, event.pageY)
    }

    /**
     * @private
     * @param {MouseEvent} event
     */
    function _onMouseMove (event) {

      _move(event.pageX, event.pageY)
    }

    function _onMouseUp () {

      _clearGlobalMouseListeners()
      _moveEnd()
    }

    function _setElementListeners () {

      _clearElementListeners()

      if (_element) {

        _elementListeners.push(BasUtil.setDOMListener(
          _element,
          'touchstart',
          _onTouchStart,
          _activeListenerOptions
        ))
        _elementListeners.push(BasUtil.setDOMListener(
          _element,
          'touchmove',
          _onTouchMove,
          _activeListenerOptions
        ))
        _elementListeners.push(BasUtil.setDOMListener(
          _element,
          'touchend',
          _onTouchEnd,
          _passiveListenerOptions
        ))
        _elementListeners.push(BasUtil.setDOMListener(
          _element,
          'touchcancel',
          _onTouchCancel,
          _passiveListenerOptions
        ))

        _elementListeners.push(BasUtil.setDOMListener(
          _element,
          'mousedown',
          _onMouseDown,
          _activeListenerOptions
        ))
      }
    }

    function _clearElementListeners () {

      BasUtil.executeArray(_elementListeners)
      _elementListeners = []
    }

    function _setGlobalMouseListeners () {

      _clearGlobalMouseListeners()

      _globalMouseListeners.push(BasUtil.setDOMListener(
        $window.document,
        'mousemove',
        _onMouseMove
      ))
      _globalMouseListeners.push(BasUtil.setDOMListener(
        $window.document,
        'mouseup',
        _onMouseUp
      ))
    }

    function _clearGlobalMouseListeners () {

      BasUtil.executeArray(_globalMouseListeners)
      _globalMouseListeners = []
    }

    function _getElements () {

      var elements

      _element = $element[0]

      if (_element) {

        _parentElement = _element ? _element.parentNode : null

        elements = _element.getElementsByClassName(CSS_BALLOON)

        if (elements && elements.length) {

          _balloonElement = elements[0]

          _txtBalloon = $window.document.createTextNode('-')

          _balloonElement.appendChild(_txtBalloon)
        }

        elements = _element.getElementsByClassName(CSS_LABEL)

        if (elements && elements.length) {

          _labelElement = elements[0]

          _txtLabel = $window.document.createTextNode('-')

          _labelElement.appendChild(_txtLabel)
        }
      }

      if (_parentElement) {

        if (schedulerPoint.gridElementClass) {

          elements = _parentElement.getElementsByClassName(
            schedulerPoint.gridElementClass
          )

          if (elements && elements.length) {

            _gridElement = elements[0]
          }

        } else {

          _gridElement = _parentElement
        }

        if (schedulerPoint.removeElementClass) {

          _parentElement = _parentElement.parentNode

          if (_parentElement) {

            _parentElement = _parentElement.parentNode
          }

          if (_parentElement) {

            elements = _parentElement.getElementsByClassName(
              schedulerPoint.removeElementClass
            )

            if (elements && elements.length) {

              _removeElement = elements[0]
            }
          }
        }
      }
    }

    function _clearNewTransitionTimeout () {

      clearTimeout(_newTransitionTimeoutId)
      _newTransitionTimeoutId = 0
    }

    function _clearRemoveTransitionTimeout () {

      clearTimeout(_removeTransitionTimeoutId)
      _removeTransitionTimeoutId = 0
    }

    function _clearWaitFrames () {

      BasUtil.execute(_abortWaitFrames)
      _abortWaitFrames = null
    }

    function _clearWaitFramesForScaleUp () {

      BasUtil.execute(_abortWaitFramesForScaleUp)
      _abortWaitFramesForScaleUp = null
    }

    function _onDestroy () {

      _clearElementListeners()
      _clearGlobalMouseListeners()

      _clearNewTransitionTimeout()
      _clearWaitFrames()
      _clearWaitFramesForScaleUp()
      _clearRemoveTransitionTimeout()

      BasUtil.executeArray(_listeners)
      _listeners = []
    }
  }
}
