'use strict'

var BasUtil = require('@basalte/bas-util')

var P = require('./parser_constants')

var Device = require('./device')

/**
 * @constructor
 * @extends Device
 * @param {TDevice} device
 * @param {Object} device.state
 * @param {Object} device.limit
 * @param {number} device.extraType
 * @param {BasCore} basCore
 */
function LightDevice (device, basCore) {

  Device.call(this, device, basCore)

  this._state = BasUtil.isObject(device[P.STATE])
    ? device[P.STATE]
    : {}
}

LightDevice.prototype = Object.create(Device.prototype)
LightDevice.prototype.constructor = LightDevice

// region Events

/**
 * @event LightDevice#EVT_ON
 * @param {boolean} on
 */

/**
 * @event LightDevice#EVT_BRIGHTNESS
 * @param {number} brightness
 */

/**
 * @event LightDevice#EVT_COLOR_TEMPERATURE
 * @param {number} colorTemperature
 */

/**
 * @event LightDevice#EVT_MODE
 * @param {string} mode
 */

/**
 * @event LightDevice#EVT_COLOR_HSB
 */

// endregion

/**
 * @constant {string}
 */
LightDevice.EVT_ON = 'evtLightDeviceOn'

/**
 * @constant {string}
 */
LightDevice.EVT_BRIGHTNESS = 'evtLightDeviceBrightness'

/**
 * @constant {string}
 */
LightDevice.EVT_MODE = 'evtLightDeviceMode'

/**
 * @constant {string}
 */
LightDevice.EVT_WHITE = 'evtLightDeviceWhite'

/**
 * @constant {string}
 */
LightDevice.EVT_COLOR_TEMPERATURE = 'evtLightDeviceColorTemperature'

/**
 * @constant {string}
 */
LightDevice.EVT_COLOR_HSB = 'evtLightDeviceColorHSB'

/**
 * Light types
 * From proto.be.basalte.config.LightingDevice.LightType
 *
 * @readonly
 * @enum {number}
 */
LightDevice.TYPE = {
  CUSTOM: 0,
  FLOOR_LAMP: 1,
  TABLE_LAMP: 2,
  CEILING_MOUNTED_FIXTURE: 3,
  WALL_MOUNTED_FIXTURE: 4,
  CHANDELIER: 5,
  LED: 6,
  LED_STRIP: 7,
  VIA: 8,
  PENDANT: 9,
  SPOT: 10,
  TRACK: 11,
  DOWN: 12,
  READING_LIGHT: 13,
  CABINET: 14,
  MIRROR: 15,
  DESK: 16,
  LAMPSHADE: 17,
  MOOD_LIGHT: 18,
  ART_LIGHT: 19,
  ACCENT_LIGHT: 20
}

/**
 * Reverse enum
 *
 * @readonly
 * @enum {string}
 */
LightDevice.TYPE_R = BasUtil.switchObjectKeyValue(LightDevice.TYPE)

/**
 * @constant {string}
 */
LightDevice.C_BRIGHTNESS = P.BRIGHTNESS

/**
 * @constant {string}
 */
LightDevice.C_WHITE = P.WHITE

/**
 * @constant {string}
 */
LightDevice.C_COLOR_TEMPERATURE = P.COLOR_TEMPERATURE

/**
 * @constant {string}
 */
LightDevice.C_COLOR = P.COLOR

/**
 * @constant {string}
 */
LightDevice.C_HUE = P.HUE

/**
 * @constant {string}
 */
LightDevice.C_SATURATION = P.SATURATION

/**
 * @constant {string}
 */
LightDevice.C_MODE = P.MODE

/**
 * @constant {string}
 */
LightDevice.C_ON = P.ON

/**
 * @constant {string}
 */
LightDevice.MODE_UNKNOWN = P.UNKNOWN

/**
 * @constant {string}
 */
LightDevice.MODE_COLOR = P.COLOR

/**
 * @constant {string}
 */
LightDevice.MODE_COLOR_TEMPERATURE = P.COLOR_TEMPERATURE

/**
 * @constant {string}
 */
LightDevice.MODE_WHITE = P.WHITE

/**
 * @name LightDevice#onState
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(LightDevice.prototype, 'onState', {
  get: function () {
    return this._state[LightDevice.C_ON] === true
  }
})

/**
 * @name LightDevice#brightness
 * @type {number}
 * @readonly
 */
Object.defineProperty(LightDevice.prototype, 'brightness', {
  get: function () {
    return BasUtil.isVNumber(this._state[LightDevice.C_BRIGHTNESS])
      ? this._state[LightDevice.C_BRIGHTNESS]
      : 0
  }
})

/**
 * @name LightDevice#white
 * @type {number}
 * @readonly
 */
Object.defineProperty(LightDevice.prototype, 'white', {
  get: function () {
    return BasUtil.isVNumber(this._state[LightDevice.C_WHITE])
      ? this._state[LightDevice.C_WHITE]
      : 0
  }
})

/**
 * @name LightDevice#colorTemperature
 * @type {number}
 * @readonly
 */
Object.defineProperty(LightDevice.prototype, 'colorTemperature', {
  get: function () {
    return BasUtil.isVNumber(this._state[LightDevice.C_COLOR_TEMPERATURE])
      ? this._state[LightDevice.C_COLOR_TEMPERATURE]
      : 0
  }
})

/**
 * @name LightDevice#hue
 * @type {number}
 * @readonly
 */
Object.defineProperty(LightDevice.prototype, 'hue', {
  get: function () {
    return BasUtil.isVNumber(this._state[LightDevice.C_HUE])
      ? this._state[LightDevice.C_HUE]
      : 0
  }
})

/**
 * @name LightDevice#saturation
 * @type {number}
 * @readonly
 */
Object.defineProperty(LightDevice.prototype, 'saturation', {
  get: function () {
    return BasUtil.isVNumber(this._state[LightDevice.C_SATURATION])
      ? this._state[LightDevice.C_SATURATION]
      : 0
  }
})

/**
 * @name LightDevice#mode
 * @type {string}
 * @readonly
 */
Object.defineProperty(LightDevice.prototype, 'mode', {
  get: function () {
    return BasUtil.isNEString(this._state[LightDevice.C_MODE])
      ? this._state[LightDevice.C_MODE]
      : LightDevice.MODE_UNKNOWN
  }
})

/**
 * Object that holds the limits for a property (if there are any)
 *
 * @name LightDevice#limit
 * @type {Object}
 * @readonly
 */
Object.defineProperty(LightDevice.prototype, 'limit', {
  get: function () {
    return this._attributes
  }
})

/**
 * Object that holds the limits for a color temperature (if there are any)
 *
 * @name LightDevice#limitColorTemperature
 * @type {?Object}
 * @readonly
 */
Object.defineProperty(LightDevice.prototype, 'limitColorTemperature', {
  get: function () {
    return BasUtil.isObject(
      this._attributes[LightDevice.C_COLOR_TEMPERATURE]
    )
      ? this._attributes[LightDevice.C_COLOR_TEMPERATURE]
      : null
  }
})

/**
 * Parse a LightDevice message
 *
 * @param {Object} msg
 * @param {TDeviceParseOptions} options
 * @returns {boolean}
 */
LightDevice.prototype.parse = function (msg, options) {

  var state, valid, changed
  var emit

  emit = true
  valid = Device.prototype.parse.call(this, msg, options)

  if (BasUtil.isObject(options)) {

    if (BasUtil.isBool(options.emit)) emit = options.emit
  }

  if (valid) {

    if (BasUtil.isObject(msg[P.STATE])) {

      state = msg[P.STATE]

      // On / Off
      if (BasUtil.safeHasOwnProperty(state, P.ON) &&
        state[P.ON] !== this._state[P.ON]) {

        this._state[P.ON] = state[P.ON]
        if (emit) this.emit(LightDevice.EVT_ON, this.onState)
      }

      // Brightness
      if (BasUtil.safeHasOwnProperty(state, P.BRIGHTNESS)) {

        this._state[P.BRIGHTNESS] = state[P.BRIGHTNESS]

        if (emit) {
          this.emit(LightDevice.EVT_BRIGHTNESS, this.brightness)
        }
      }

      // White
      if (BasUtil.safeHasOwnProperty(state, P.WHITE)) {

        this._state[P.WHITE] = state[P.WHITE]

        if (emit) {
          this.emit(LightDevice.EVT_WHITE, this.white)
        }
      }

      // Color temperature
      if (BasUtil.safeHasOwnProperty(state, P.COLOR_TEMPERATURE)) {

        this._state[P.COLOR_TEMPERATURE] =
          state[P.COLOR_TEMPERATURE]

        if (emit) {
          this.emit(
            LightDevice.EVT_COLOR_TEMPERATURE,
            this.colorTemperature
          )
        }
      }

      // Hue and saturation
      if (BasUtil.safeHasOwnProperty(state, P.HUE) &&
        BasUtil.safeHasOwnProperty(state, P.SATURATION) &&
        BasUtil.isNumber(state[P.HUE]) &&
        BasUtil.isNumber(state[P.SATURATION])) {

        changed = false

        if (state[P.HUE] !== this._state[P.HUE]) {

          changed = true
          this._state[P.HUE] = state[P.HUE]
        }

        if (state[P.SATURATION] !==
          this._state[P.SATURATION]) {

          changed = true
          this._state[P.SATURATION] = state[P.SATURATION]
        }

        if (changed && emit) {

          this.emit(LightDevice.EVT_COLOR_HSB)
        }
      }

      // Mode
      if (BasUtil.safeHasOwnProperty(state, P.MODE)) {

        this._state[P.MODE] = state[P.MODE]

        if (emit) {
          this.emit(LightDevice.EVT_MODE, this.mode)
        }
      }
    }
  }

  return valid
}

/**
 * Toggles the light on/off
 *
 * @param {boolean} [force]
 */
LightDevice.prototype.toggle = function (force) {

  var state = {}

  state[LightDevice.C_ON] = BasUtil.isBool(force)
    ? force
    : !this._state[LightDevice.C_ON]
  this.updateState(state)
}

/**
 * Set the light brightness
 *
 * @param {number} value Range: 0 - 1
 */
LightDevice.prototype.setBrightness = function (value) {

  var state = {}

  state[LightDevice.C_BRIGHTNESS] = value
  this.updateState(state)
}

/**
 * Set the light white
 *
 * @param {number} value Range: 0 - 1
 */
LightDevice.prototype.setWhite = function (value) {

  var state = {}

  state[LightDevice.C_WHITE] = value

  this.updateState(state)
}

/**
 * Sets the color temperature in Kelvin
 *
 * @param {number} value
 */
LightDevice.prototype.setColorTemperature = function (value) {

  var state = {}
  state[LightDevice.C_COLOR_TEMPERATURE] = value
  this.updateState(state)
}

/**
 * Sets the HSB color value
 *
 * @param {number} hue Range: 0 - 1
 * @param {number} saturation Range: 0 - 1
 * @param {number} [brightness] Range: 0 - 1
 */
LightDevice.prototype.setColorHSB = function (hue, saturation, brightness) {

  var state = {}

  state[LightDevice.C_HUE] = hue
  state[LightDevice.C_SATURATION] = saturation
  state[LightDevice.C_BRIGHTNESS] = BasUtil.isNumber(brightness)
    ? brightness
    : this._state[LightDevice.C_BRIGHTNESS]
  this.updateState(state)
}

/**
 * Sets the color mode
 *
 * @param {string} mode
 */
LightDevice.prototype.setColorMode = function (mode) {

  var state = {}

  state[LightDevice.C_MODE] = mode
  this.updateState(state)
}

module.exports = LightDevice
