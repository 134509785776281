{
  "12_hr": "12-годинний",
  "24_hr": "24-годинний",
  "24_hr_time": "24-годинний формат",
  "about": "Інформація",
  "account": "Обліковай запис",
  "account_exists": "Обліковий запис існує",
  "account_exists_l1": "Такий обліковий запис вже існує",
  "account_exists_l2": "Виберіть іншу адресу електронної пошти або увійдіть за допомогою цієї електронної адреси.",
  "account_not_confirmed": "Обліковий запис не підтверджено",
  "account_not_confirmed_l1": "Цей обліковий запис ще не підтверджено.",
  "account_not_confirmed_l2": "Перевірте свою електронну пошту на наявність електронного листа з підтвердженням.",
  "account_not_confirmed_l3": "Підтвердити цей обліковий запис?",
  "activate": "Активувати",
  "active": "Активні",
  "act_push": "НАТИСНУТИ",
  "adaptive_brightness": "Адаптивна яскравість",
  "add": "Додати",
  "add_favourite": "Додати улюблене",
  "address": "Адреса",
  "add_to_fav": "Додати до улюблених",
  "add_to_playlist": "Додати до плейлиста",
  "add_to_queue": "Додати до черги",
  "air_conditioning": "Кондиціонер",
  "air_pressure": "Тиск повітря",
  "alarm_at": "Будильник на",
  "alarm_missing_txt": "Не заповнено одне або кілька обов’язкових полів",
  "alarm_not_selected_l1": "Будильник не вибрано.",
  "alarm_not_selected_l2": "Встановіть будильник.",
  "alarms": "Будильники",
  "album": "Альбом",
  "albums": "Альбоми",
  "alert": "Попередження",
  "all": "Всі",
  "all_off": "Вимкнути усе",
  "all_off_txt_l1": "Ви збираєтеся вимкнути всі кімнати.",
  "all_off_txt_l2": "Ви впевнені, що хочете продовжити?",
  "all_on": "Увімкнути усе",
  "almost_done": "Майже закінчили.",
  "almost_done_txt_l1": "У вас ще немає улюблених плейлістів чи радіостанцій.",
  "almost_done_txt_l2": "Спершу додайте щось до своїх улюблених.",
  "already_linked": "Під'єднаний до Basalte Live",
  "already_verified": "Вже перевірено",
  "alt_power": "УВІМК/ВИМК",
  "alt_user": "Користувач",
  "application": "Застосунок",
  "application_version": "Версія застосунку",
  "app_update_required": "Необхідно оновити застосунок",
  "app_update_required_android_l2": "оновіть застосунок у Play Store.",
  "app_update_required_ios_l2": "оновіть застосунок у App Store.",
  "app_update_required_l2": "оновіть застосунок.",
  "artist": "Виконавець",
  "artists": "Виконавці",
  "attention": "Увага!",
  "audio_unavailable": "Аудіосистема недоступна",
  "audio_zones": "Аудіо зони",
  "author": "Автор",
  "back": "назад",
  "bass": "Бас",
  "BE": "Бельгія",
  "beta": "бета",
  "bluetooth": "Bluetooth",
  "bluetooth_pairing_txt": "Тепер ви можете підключити свій пристрій через Bluetooth.",
  "brightness": "Яскравість",
  "browse": "Перегляд",
  "browse_deezer": "Улюблені в Deezer",
  "browse_library": "Музична бібліотека",
  "browser_app_update_required_l2": "перезавантажте сторінку або оновіть сервер.",
  "browse_stations": "Радіостанції",
  "buffering": "буферизація",
  "building_building": "Будівля",
  "building_garage": "Гараж",
  "building_main_house": "Будинок",
  "building_pool_house": "Басейн",
  "building_stables": "Стайня",
  "cameras": "Камери",
  "cancel": "Скасувати",
  "categories": "Категорії",
  "category": "Категорія",
  "celsius": "за Цельсієм",
  "change_home": "Змінити будинок",
  "change_image": "Змінити зображення",
  "change_password": "Змінити пароль",
  "change_password_l1": "Ви збираєтеся змінити свій пароль.",
  "change_user": "Змінити профіль",
  "channel_number": "Номер каналу",
  "charts": "Діаграми",
  "check_for_updates": "Перевірити наявність оновлень",
  "checking": "перевірка",
  "checking_for_updates": "Перевірка оновлень",
  "choose_favourite": "Бажаєте вибрати улюбленого виконавця?",
  "choose_home": "Виберіть будинок",
  "choose_music": "Бажаєте вибрати музику?",
  "choose_preset": "Виберіть пресет",
  "choose_source": "Бажаєте вибрати джерело?",
  "clear": "Очистити",
  "cleared": "Очищено",
  "clear_queue_txt_l1": "Ви збираєтеся видалити чергу програвання.",
  "clear_queue_txt_l2": "Ви впевнені, що хочете продовжити?",
  "clear_selection": "Очистити обране",
  "clear_selection_txt_l1": "Ви збираєтеся видалити обране",
  "clear_selection_txt_l2": "Ви впевнені, що хочете продовжити?",
  "close": "Закрити",
  "closed": "Зачинено",
  "code": "Код підтвердження",
  "color": "Колір",
  "color_temperature": "Температура освітлення",
  "color_theme": "Колір теми",
  "color_white": "Білий",
  "comfort": "Затишок",
  "configure": "Налаштувати",
  "confirmation_code_sent": "Код підтвердження надіслано",
  "confirmation_code_sent_l1": "Код підтвердження надіслано на адресу:",
  "connect": "Підключити",
  "connected": "Підключений",
  "connecting": "Підключення",
  "connecting_no_active_servers": "Сервер не в мережі",
  "connecting_no_integrator_access": "На цьому сервері не ввімкнено Віддалене налаштування.",
  "connecting_not_authorized": "Не авторизовано для підключення до цього сервера.",
  "connecting_small": "підключення",
  "connecting_stop_and_retry_text": "Не вдалося підключитися до сервера. Повторна спроба через 10 секунд.",
  "connecting_stop_text": "Не вдалося підключитися до сервера.",
  "connecting_text": "Підключення...",
  "connecting_text_small": "підключення...",
  "connection_lost": "Немає підключення",
  "connection_lost_text": "Повторне підключення до сервера",
  "connection_unable": "Не вдається підключитися до вказаного сервера.",
  "connect_offline_server_l1": "Цей будинок під'єднаний, але, схоже, не на зв'язку",
  "connect_offline_server_l2": "Все одно спробувати підключитися?",
  "connect_server": "Підключитися до сервера",
  "consumption": "Споживання",
  "contact_support": "Зверніться до служби підтримки",
  "continue_anyway": "Все одно продовжити",
  "cover_art_background": "Обкладинка альбому",
  "current_password": "Поточний пароль",
  "current_temperature": "Поточний",
  "custom": "Користувацький",
  "date": "Дата",
  "day": "День",
  "day_friday": "П'ятниця",
  "day_monday": "Понеділок",
  "day_saturday": "Субота",
  "day_short2_friday": "ПТ",
  "day_short2_monday": "ПН",
  "day_short2_saturday": "СБ",
  "day_short2_sunday": "НД",
  "day_short2_thursday": "ЧТ",
  "day_short2_tuesday": "ВТ",
  "day_short2_wednesday": "СР",
  "day_sunday": "Неділя",
  "day_thursday": "Четвер",
  "day_tuesday": "Вівторок",
  "day_wednesday": "Середа",
  "debug": "Відлагоджувати",
  "deezer": "Deezer",
  "deezer_disconnect_txt": "Ви збираєтеся від'єднати обліковий запис Deezer від цього програвача.",
  "deezer_favourite": "Улюблені в Deezer",
  "deezer_flow": "Flow",
  "deezer_flow_description": "Ваш саундтрек",
  "deezer_flow_full": "Deezer Flow",
  "deezer_no_link": "Обліковий запис Deezer ще не під'єднаний",
  "deezer_oauth_error": "Помилка автентифікації Deezer",
  "default_rooms": "Кімнати за замовчуванням",
  "default_rooms_hint": "Щоб трансляція вдома працювала належним чином, переконайтеся, що принаймні 1 кімната вибрана як кімната за замовчуванням.",
  "default_rooms_info_l1": "Кімнати за замовчуванням для сторонніх аудіопотоків (наприклад, Spotify Connect).",
  "default_rooms_info_l2": "Якщо жодна кімната не підключена до потоку, активуйте вказані кімнати.",
  "default_rooms_spotify_hint": "Щоб Spotify Connect працював належним чином, переконайтеся, що принаймні 1 кімната вибрана як кімната за замовчуванням.",
  "default_rooms_spotify_warning_l1": "Встановіть принаймні одну кімнату за замовчуванням для кожного потоку,",
  "default_rooms_spotify_warning_l2": "щоб завершити налаштування.",
  "default_rooms_spotify_warning_title": "Spotify Connect підтримується",
  "default_view": "Вигляд за замовчуванням",
  "delay": "Затримка",
  "delay_seconds_b": "Затримка (секунди)",
  "delete": "Видалити",
  "demo_functionality_warning_txt": "Цей функціонал не підтримується в демонстраційному режимі.",
  "demo_location": "Гент",
  "demo_server_name": "Демо хата",
  "demo_title": "Демо",
  "demo_warning_txt": "Ви збираєтесь увійти в демо хату.",
  "detail": "подробиці",
  "device": "Пристрій",
  "devices": "Пристрої",
  "device_settings": "Налаштування пристрою",
  "device_volume": "Гучність пристрою",
  "dhcp": "DHCP",
  "direction_east": "Схід",
  "direction_east-northeast": "Схід-Північний Схід",
  "direction_east-southeast": "Схід-Південний Схід",
  "direction_north": "Північ",
  "direction_northeast": "Північний схід",
  "direction_north-northeast": "Північ-Північний Схід",
  "direction_north-northwest": "Північ-Північний Захід",
  "direction_northwest": "Північний Захід",
  "direction_short_east": "Сх",
  "direction_short_east-northeast": "Сх-Пн-Сх",
  "direction_short_east-southeast": "Сх-Пд-Сх",
  "direction_short_north": "Пн",
  "direction_short_northeast": "Пн-Сх",
  "direction_short_north-northeast": "Пн-Пн-Сх",
  "direction_short_north-northwest": "Пн-Пн-Зх",
  "direction_short_northwest": "Пн-Зх",
  "direction_short_south": "Пд",
  "direction_short_southeast": "Пд-Сх",
  "direction_short_south-southeast": "Пд-Пд-Сх",
  "direction_short_south-southwest": "Пд-Пд-Зх",
  "direction_short_southwest": "Пд-Зх",
  "direction_short_west": "Зх",
  "direction_short_west-northwest": "Зх-Пн-Зх",
  "direction_short_west-southwest": "Зх-Пд-Зх",
  "direction_south": "Південь",
  "direction_southeast": "Південний Схід",
  "direction_south-southeast": "Південь-Південний Схід",
  "direction_south-southwest": "Південь-Південний Захід",
  "direction_southwest": "Південний Захід",
  "direction_west": "Захід",
  "direction_west-northwest": "Захід-Північний Захід",
  "direction_west-southwest": "Захід-Південний Захід",
  "disband_group": "Розформувати групу",
  "dns": "DNS",
  "done": "Готово",
  "do_not_disturb": "Не турбувати",
  "do_not_disturb_off_l1": "Усі звукові сповіщення наразі увімкнено.",
  "do_not_disturb_on_l1": "Усі звукові сповіщення наразі вимкнено.",
  "do_not_show_again": "Більше не показувати",
  "door_phone": "Домофон",
  "doors": "Двері",
  "down": "Вниз",
  "downloading": "завантаження",
  "downloading_updates": "Завантаження оновлень",
  "drag_and_drop_images": "Виберіть зображення або перетягніть його сюди",
  "drag_to_reorder": "Перетягніть, щоб змінити порядок",
  "dsp": "DSP",
  "dsp_configuration": "Конфігурація DSP",
  "dsp_shortcut_l1": "Ви збираєтеся відкрити налаштування DSP.",
  "echo_cancellation": "Ехоподавлення",
  "echo_limit": "Ехо-ліміт",
  "edit": "Редагувати",
  "edit_dns": "Редагувати DNS",
  "edit_gateway": "Редагувати шлюз",
  "edit_ip_address": "Редагувати IP-адресу",
  "edit_subnet_mask": "Редагувати маску",
  "ellie_room": "Кімната Ellie",
  "ellie_system_update": "Оновлення системи на Ellie",
  "ellie_system_update_l1": "Щоб встановити оновлення системи, Ellie потрібно перезавантажити.",
  "ellie_update": "Оновлення Ellie",
  "email": "Email адреса",
  "empty": "порожній",
  "empty_queue": "Черга порожня",
  "empty_queue_txt_l1": "Схоже, у черзі немає пісень.",
  "empty_queue_txt_l2": "Додайте трохи.",
  "empty_song": "Пристрій відтворення зупинено",
  "empty_song_txt_l1": "Черга відтворення закінчилася.",
  "empty_song_txt_l2": "Натисніть «Відтворити» для перезапуску.",
  "empty_src": "Джерело не вибрано",
  "empty_src_txt_l1": "Схоже, ви не вибрали джерело.",
  "empty_src_txt_l2": "Спершу виберіть джерело.",
  "empty_zone": "Кімната не вибрана",
  "empty_zone_txt_l1": "Схоже, ви не вибрали кімнату.",
  "empty_zone_txt_l2": "Спершу виберіть кімнату.",
  "energy": "Енергоспоживання",
  "energy_meter": "Енерголічильник",
  "error": "Помилка",
  "external": "Зовнішній",
  "extra_permissions_required": "Для деяких функцій можуть знадобитися додаткові дозволи.",
  "ext_src": "Зовнішнє джерело",
  "fahrenheit": "по Фаренгейту",
  "failed": "Не вдалося",
  "fan": "Вентилятор",
  "fan_auto": "Авто",
  "fan_high": "Висока",
  "fan_low": "Низька",
  "fan_medium": "Середня",
  "fan_off": "ВИМК",
  "favourites": "Улюблені",
  "favourite_scenes_full": "Улюблені сцени заповнені",
  "favourite_scenes_full_sub": "Ви можете встановити до 4 улюблених сцен в кімнаті",
  "favourite_songs": "Улюблені пісні",
  "featured_playlists": "Рекомендовані плейлисти",
  "floor_attic": "Горище",
  "floor_basement": "Підвал",
  "floor_floor": "Поверх",
  "floor_garden": "Сад",
  "floor_heating": "Тепла підлога",
  "floor_lvl_0": "Цокольний поверх",
  "floor_lvl_1": "Перший поверх",
  "floor_lvl_2": "Другий поверх",
  "floor_lvl_3": "Третій поверх",
  "floor_lvl_4": "Четвертий поверх",
  "floor_lvl_5": "П'ятий поверх",
  "floor_lvl_6": "Шостий поверх",
  "floor_lvl_7": "Сьомий поверх",
  "floor_lvl_8": "Восьмий поверх",
  "floor_lvl_9": "Дев'ятий поверх",
  "floor_lvl_10": "Десятий поверх",
  "floor_lvl_11": "Одинадцятий поверх",
  "floor_lvl_12": "Дванадцятий поверх",
  "floor_lvl_13": "Тринадцятий поверх",
  "floor_lvl_14": "Чотирнадцятий поверх",
  "floor_lvl_15": "П'ятнадцятий поверх",
  "floor_lvl_16": "Шістнадцятий поверх",
  "floor_lvl_17": "Сімнадцятий поверх",
  "floor_lvl_18": "Вісімнадцятий поверх",
  "floor_lvl_19": "Дев'ятнадцятий поверх",
  "floor_lvl_20": "Двадцятий поверх",
  "forgot_password": "Забули пароль",
  "frequency": "Частота",
  "full_screen": "Повноекранний",
  "functions": "Функції",
  "gas": "Газ",
  "gateway": "Шлюз",
  "general": "Загальне",
  "generic_devices": "Пристрої",
  "generic_devices_fire": "Вогонь",
  "generic_devices_garden": "Сад",
  "generic_devices_socket": "Розетка",
  "genre": "Жанр",
  "genre_moods": "Жанр та Настрій",
  "genres": "Жанри",
  "groups": "Групи",
  "home": "Будинок",
  "humidity": "Вологість",
  "image_smaller_than_5mb": "Зображення має бути менше 5 Мб",
  "in": "В",
  "incorrect_album": "Альбом не знайдено",
  "incorrect_artist": "Виконавця не знайдено",
  "install": "Встановити",
  "installing": "встановлення",
  "install_update": "Встановити оновлення",
  "install_updates": "Встановити оновлення",
  "integrator": "Інтегратор",
  "integrator_access_allow": "Дозволити віддалене налаштування",
  "integrator_access_ask": "Попросіть власника увімкнути віддалене налаштування.",
  "integrator_access_disabled": "Віддалене налаштування відключено",
  "intercom": "Інтерком",
  "internal": "Внутрішній",
  "invalid_address": "Недійсна адреса",
  "invalid_code": "Недійсний код",
  "invalid_code_l1": "Наданий код неправильний",
  "invalid_code_l2": "Переконайтеся, що код правильний, або запросіть новий.",
  "invalid_input": "Невірне введення даних",
  "invalid_user": "Недійсний користувач",
  "invalid_user_l1": "Переконайтеся, що email адреса дійсна.",
  "ios_plist_string_NSCameraUsageDescription": "Камеру можна використовувати для фотографування кімнат",
  "ios_plist_string_NSLocalNetworkUsageDescription": "Доступ до локальної мережі можна використовувати для пошуку домашніх серверів, IP-камер, ...",
  "ios_plist_string_NSPhotoLibraryAddUsageDescription": "Бібліотеку фотографій можна використовувати для збереження фотографій кімнат",
  "ios_plist_string_NSPhotoLibraryUsageDescription": "Бібліотеку фотографій можна використовувати для вибору фотографій кімнат",
  "ip": "IP",
  "ip_address": "IP адреса",
  "join": "+ Додати",
  "join_alt": "+ Додати",
  "keep_awake": "Залиште iPad увімкненим",
  "keep_awake_device": "Залиште пристрій увімкненим",
  "kelvin": "Кельвін",
  "Key": "uk_UA",
  "knx_presets": "Задані налаштування KNX",
  "knx_presets_info": "Змініть існуючі налаштування KNX, щоб відтворити щось інше.",
  "language": "Мова",
  "language_system": "Мова пристрою",
  "last-7-days": "Останні 7 днів",
  "last_hour": "Остання година",
  "last_room": "Остання кімната",
  "last_view": "Останній перегляд",
  "learn_more": "Докладніше",
  "leave_group": "Покинути групу",
  "left": "Ліворуч",
  "library": "Бібліотека",
  "library_empty": "Бібліотека порожня",
  "library_scanning": "Сканування бібліотеки",
  "light_accent_light": "Акцентне світло",
  "light_art_light": "Художнє світло",
  "light_cabinet": "Підсвітка меблів",
  "light_ceiling_mounted_fixture": "Стельові світильники",
  "light_chandelier": "Люстра",
  "light_desk": "Над столом",
  "light_down": "Нижнє світло",
  "light_floor_lamp": "Торшер",
  "light_lampshade": "Абажур",
  "light_led": "LED підсвітка",
  "light_led_strip": "LED стрічка",
  "light_mirror": "Дзеркало",
  "light_mood_light": "Світло для настрою",
  "light_pendant": "Підвісний світильник",
  "light_reading_light": "Світло для читання",
  "lights": "Світло",
  "lights_off_all": "Вимкнути все світло",
  "light_spot": "Точковий світильник",
  "light_table_lamp": "Настільна лампа",
  "light_track": "Трек",
  "light_via": "Нічне світло",
  "light_wall_mounted_fixture": "Бра",
  "link": "Посилання",
  "link_deezer_info_l1": "Під'єднайте обліковий запис Deezer до потоку.",
  "link_deezer_info_l2": "Це дозволяє переглядати та відтворювати медіафайли Deezer у потоці.",
  "linked_homes": "Мої домівки",
  "linked_projects": "Мої проєкти",
  "link_home": "Відкрийте застосунок Basalte на локальному пристрої та додайте свою домівку у налаштуваннях.",
  "link_server": "Під'єднаний до Basalte Live",
  "link_server_full_sub": "Під'єднати цей проєкт до поточного облікового запису Basalte Live?",
  "link_spotify_info_l1": "Під'єднайте обліковий запис Spotify до потоку.",
  "link_spotify_info_l2": "Це дозволяє переглядати та відтворювати медіафайли Spotify у потоці.",
  "link_tidal_info_l1": "Під'єднайте обліковий запис TIDAL до потоку.",
  "link_tidal_info_l2": "Це дозволяє переглядати та відтворювати медіафайли TIDAL у потоці.",
  "lisa_order_hint": "Змінити порядок і видимість сторінок та плиток.",
  "lisa_system_update": "Lisa: оновлення системи",
  "lisa_system_update_l1": "Перезавантажте Lisa для оновлення системи.",
  "listen_in": "Слухати в",
  "liters": "Літрів",
  "live_adjustments": "Налаштування наживо",
  "loading": "Завантаження...",
  "local": "локальний",
  "location_back": "Позаду",
  "location_bottom": "Знизу",
  "location_center": "В центрі",
  "location_chair": "Крісло",
  "location_corner": "В куті",
  "location_desk": "Робочий стіл",
  "location_east": "Схід",
  "location_front": "Спереду",
  "location_high": "Високий",
  "location_island": "Острів",
  "location_left": "Зліва",
  "location_low": "Низький",
  "location_mid": "Середній",
  "location_niche": "Ніша",
  "location_north": "Північ",
  "location_right": "Зправа",
  "location_shelves": "Полиці",
  "location_south": "Південь",
  "location_stove": "Плита",
  "location_table": "Стіл",
  "location_top": "Зверху",
  "location_west": "Захід",
  "logged_in_as": "Ви увішли як",
  "log_out": "Вийти",
  "long-press-save-scene": "Довге натиснення для збереження сцени",
  "mac_address": "MAC-адреса",
  "manage": "Управління",
  "manual_conn": "Ручне підключення",
  "manual_text": "Будь ласка, введіть адресу потрібного сервера.",
  "menu": "Меню",
  "mf_device": "{count, plural, =0 {Пристрій} =1 {Пристрій} other {Пристроїв}}",
  "mf_feels_like": "Відчувається як {value}",
  "mf_minute": "{count} {count, plural, =1 {хвилина} other {хвилин}}",
  "mf_powered_by": "Розроблено {value}",
  "mf_second": "{count} {count, plural, =1 {секунда} other {секунд}}",
  "mf_server": "{count, plural, =0 {Сервер} =1 {Сервер} other {Серверів}}",
  "mf_song": "{count} {count, plural, =1 {пісня} other {пісень}}",
  "mf_source": "Джерело: {value}",
  "microphone_gain": "Підсилення мікрофона",
  "mix": "Перемішати",
  "mixed_sources": "Декілька джерел",
  "mixed_sources_txt_l1": "Ця група містить кімнати з кількома джерелами.",
  "mixed_sources_txt_l2": "Виберіть джерело.",
  "mixes": "Змішані",
  "mixes_small": "змішані",
  "mode": "Режим",
  "mode_auto": "Авто",
  "mode_cooling": "Охолодження",
  "mode_drying": "Осушення",
  "mode_fan_only": "Вентиляція",
  "mode_heating": "Нагрів",
  "mode_off": "Вимкнути",
  "month": "Місяць",
  "month_april": "Квітень",
  "month_august": "Серпень",
  "month_december": "Грудень",
  "month_february": "Лютий",
  "month_january": "Січень",
  "month_july": "Липень",
  "month_june": "Червень",
  "month_march": "Березень",
  "month_may": "Травень",
  "month_november": "Листопад",
  "month_october": "Жовтень",
  "month_september": "Вересень",
  "month_short_april": "Квіт.",
  "month_short_august": "Серп.",
  "month_short_december": "Груд.",
  "month_short_february": "Лют.",
  "month_short_january": "Січ.",
  "month_short_july": "Лип.",
  "month_short_june": "Черв.",
  "month_short_march": "Берез.",
  "month_short_may": "Трав.",
  "month_short_november": "Листоп.",
  "month_short_october": "Жовт.",
  "month_short_september": "Верес.",
  "mood": "Настрій",
  "moods": "Настрої",
  "music": "Музика",
  "music_choice": "Вибір музики",
  "music_go_to": "Перейти до музики",
  "music_is_active": "Музика активна",
  "music_is_playing": "Музика відтрворюється",
  "music_off": "вимкнути музику",
  "music_off_all": "Вимкнути усі кімнати",
  "music_off_all_rooms": "Вимкнути музику в усіх кімнатах",
  "music_off_current": "Вимкнути поточну кімнату",
  "music_off_current_room": "Вимкнути музику в цій кімнаті",
  "music_off_txt_l1": "Ви збираєтесь вимкнути одну або всі кімнати.",
  "music_off_txt_l2": "Ви впевнені, що бажаєте продовжити?",
  "music_on_current": "Увімкнути у цій кімнаті",
  "music_server": "Музичний сервер",
  "mute_area_on_call": "Вимкнення звуку під час дзвінка",
  "my_favorites": "Мої улюблені",
  "my_music": "Моя музика",
  "name": "Ім'я",
  "nds": "Пошук серверів",
  "nds_music": "Пошук музичних серверів",
  "nds_none": "Сервери не знайдено!",
  "nds_none_music": "Музичні сервери не знайдено!",
  "nds_text_l1": "Це може зайняти кілька секунд...",
  "nds_text_l2": "Переконайтеся, що пристрій підключено до домашньої мережі Wi-Fi.",
  "network": "Мережа",
  "network_input_invalid": "Всі поля повинні бути правильно заповнені",
  "never": "Ніколи",
  "new": "Новий",
  "new_alarm": "Новий будильник",
  "new_image": "Нове зображення",
  "new_password": "Новий пароль",
  "new_password_confirmation": "Підтвердження паролю",
  "new_playlist": "новий плейлист...",
  "new_releases": "Нові релізи",
  "new_releases_small": "нові релізи",
  "new_scene": "Новий сценарій",
  "new_schedule": "Новий розклад",
  "new_step": "Наступний крок",
  "next": "Наступний",
  "next_song": "наступна пісня",
  "no": "Ні",
  "no_active_rooms": "Немає активних кімнат",
  "no_all_off": "Не вимикати",
  "no_av": "Немає АВ сигналу",
  "no_configuration": "Конфігурація недоступна",
  "no_content_available": "Немає вмісту",
  "no_data": "Пусто",
  "no_deezer_dis": "Не відключати Deezer",
  "no_devices_capabilities": "Немає регульованих пристроїв",
  "no_doors_available": "Двері недоступні",
  "no_favourites": "Немає улюблених",
  "no_keep_it": "Ні, залишити",
  "no_lights_capabilities": "Немає регульованих світильників",
  "no_linked_homes": "Пов’язаних будинків не знайдено.",
  "none": "Жодного",
  "no_network": "Немає підключення до мережі",
  "no_preview_available": "Попередній перегляд недоступний",
  "no_projects": "Проєкти не знайдено.",
  "no_rooms": "Немає кімнат",
  "no_rooms_available": "Немає доступних кімнат",
  "no_room_selected": "кімната не вибрана",
  "no_rooms_listening": "Немає кімнат, де відтворюється це джерело",
  "no_scene": "Немає сцени",
  "no_scenes_favourites": "Немає улюблених сцен",
  "no_scenes_txt": "Виберіть сцени для відображення.",
  "no_search_results": "Результати пошуку відсутні",
  "no_servers": "Серверів не знайдено",
  "no_shades_capabilities": "Немає регульованих штор",
  "no_source": "- вимкнути -",
  "no_sources_available": "Немає доступних джерел",
  "no_steps": "Ця сцена не містить кроків",
  "not_allowed": "Не дозволено",
  "not_allowed_l1": "Ви не маєте на це дозволу",
  "notification": "Сповіщення",
  "notification_permission_missing": "Basalte Home не має дозволу надсилати сповіщення. Ви можете увімкнути сповіщення в системних налаштуваннях свого пристрою.",
  "notifications": "Сповіщення",
  "not_linked": "Не під'єднано",
  "not_now": "Не зараз",
  "not_set": "Не встановлено",
  "no_users_l1": "Користувачів не виявлено.",
  "no_users_l2": "Виберіть сервер з користувачами.",
  "now_playing": "Зараз відтворюється",
  "number_of_songs": "пісень",
  "off": "Вимк.",
  "offline_server": "Офлайн сервер",
  "offset_minutes": "Зсув (хвилин)",
  "ok": "ОК",
  "on": "увімкнути",
  "onix_black": "Чорний",
  "online": "онлайн",
  "only_png_jpeg_supported": "Підтримуються лише зображення PNG і JPEG",
  "open": "Відкрити",
  "open_close_door": "Двері",
  "open_close_gate": "Ворота",
  "open_close_window": "Вікно",
  "open_settings": "Відкрити налаштування",
  "open_spotify": "Відкрити Spotify",
  "open_spotify_l1": "Ви збираєтесь відкрити програму Spotify.",
  "open_state": "Відкритий",
  "or": "Або",
  "order_cameras_by_holding_and_draging": "Перетягніть камери в потрібному порядку.",
  "other": "Інше",
  "out": "Ззовні",
  "page_order": "Порядок сторінок/плиток",
  "pair": "Підключення",
  "password": "Пароль",
  "password_changed": "Пароль змінено",
  "password_confirmation": "Підтвердження паролю",
  "password_requirements": "Ваш пароль має містити принаймні 8 символів і містити принаймні одну велику та малу літери та одну цифру.",
  "permission_denied": "Доступ заборонено",
  "permission_denied_go_to_settings_l1": "Для належної роботи цієї функції потрібен дозвіл(и).",
  "permission_denied_go_to_settings_l2": "Надайте дозвіл(и) для цієї програми в налаштуваннях вашого пристрою, щоб увімкнути всі функції.",
  "photo_from_camera": "Сфотографувати",
  "photo_from_library": "Виберіть зображення з бібліотеки",
  "pick_room_function": "Оберіть кімнату та функцію",
  "play": "Відтворити",
  "playback_gain": "Підсилення відтворення",
  "playing_in": "відтворюється в",
  "playlist": "Плейлист",
  "playlists": "Плейлисти",
  "playlists_itunes": "Плейлисти iTunes",
  "playlists_l": "плейлисти",
  "playlists_local": "Локальні плейлисти",
  "playlists_shared": "Спільні плейлисти",
  "play_next": "Відтворити наступний",
  "play_now": "Відтворити зараз",
  "please_try_a_different_image": "Будь ласка, спробуйте інше зображення",
  "please_try_again": "Будь ласка, спробуйте ще раз",
  "popular": "Популярне",
  "position": "Позиція",
  "precipitation": "Опади",
  "previous": "Попередній",
  "problems_continue_integrator": "Якщо проблеми не зникають, зверніться до свого інтегратора.",
  "projects_network_error": "Не вдалося завантажити проєкти через помилку мережі.",
  "proximity_activation": "Активація датчика наближення",
  "proximity_far": "Далека",
  "proximity_medium": "Середня",
  "proximity_near": "Близька",
  "queue": "Черга",
  "radio": "Радіо",
  "radio_error": "Сталася помилка під час завантаження радіостанцій",
  "radio_stations": "Радіостанції",
  "radio_stations_other": "Інші Станції",
  "recently_played": "Нещодавно відтворені",
  "related_artists": "Подібні артисти",
  "reload": "Перезавантажити",
  "remember_me": "Запам'ятати мене",
  "remote": "Віддалений доступ",
  "remote_ellie_intercom_outdated": "Програмне забезпечення Ellie застаріло",
  "remote_ellie_intercom_outdated_l1": "Панель Ellie, на яку ви намагаєтесь подзвонити, потребує оновлення.",
  "remote_ellie_intercom_outdated_l2": "Повторіть спробу після оновлення.",
  "remove": "Видалити",
  "remove_alarm": "Видалити будильник",
  "remove_deezer_favourite": "Видалити улюблене Deezer",
  "remove_from_fav": "видалити з улюблених",
  "remove_schedule": "Видалити розклад",
  "remove_spotify_favourite": "Видалити улюблене Spotify",
  "remove_spotify_preset": "Видалити пресети Spotify",
  "remove_tidal_favourite": "Видалити улюблене TIDAL",
  "remove_txt_l1": "Ви збираєтеся видалити цей елемент.",
  "remove_txt_l2": "Ви впевнені, що бажаєте продовжити?",
  "rename": "перейменувати",
  "repeat": "Повторіть",
  "replace_queue": "Замінити чергу",
  "resend_code": "Надіслати код повторно",
  "resend_confirmation_code": "Повторно надіслати код підтвердження",
  "reset_image": "Скинути зображення",
  "reset_password": "Скинути пароль",
  "reset_password_l1": "Ви впевнені, що бажаєте скинути пароль?",
  "restart": "Перезапуск сервера",
  "restart_app": "Перезапустити програму?",
  "restarting_server": "Перезапуск сервера",
  "restart_music_server": "Перезапустити музичний сервер",
  "restart_music_server_txt_l1": "Ви збираєтесь перезапустити музичний сервер.",
  "restart_server": "Перезапустити сервер",
  "restart_server_txt_l1": "Ви збираєтесь перезапустити сервер.",
  "restart_server_txt_l2": "Програма підключиться автоматично.",
  "retrieving": "Отримання…",
  "retrieving_users_stop_text": "Не вдалося знайти користувачів, змінити сервер?",
  "retrieving_users_text": "Отримання користувачів...",
  "right": "Праворуч",
  "room": "Кімната",
  "room_attic": "Мансарда",
  "room_ball_room": "Бальна зала",
  "room_bar": "Бар",
  "room_basement": "Підвал",
  "room_bathroom": "Ванна кімната",
  "room_bedroom": "Спальня",
  "room_corridor": "Коридор",
  "room_dining_room": "Їдальня",
  "room_dressing_room": "Гардероб",
  "room_driveway": "Проїзд",
  "room_entry": "Вхід",
  "room_family_room": "Сімейна кімната",
  "room_foyer": "Фойє",
  "room_garage": "Гараж",
  "room_garden": "Сад",
  "room_guest_room": "Гостьова кімната",
  "room_gym": "Спортзал",
  "room_hallway": "Хол",
  "room_hobby_room": "Ігрова",
  "room_home_cinema": "Домашній кінотеатр",
  "room_home_office": "Кабінет",
  "room_images": "Зображення кімнати",
  "room_intercom_lost": "Зв'язок втрачено",
  "room_intercom_lost_sub": "З’єднання втрачено, спробуйте ще раз",
  "room_intercom_unavailable": "Кімната недоступна",
  "room_intercom_unavailable_sub": "Ця кімната зараз недоступна",
  "room_intercom_unreachable": "Кімната недоступна",
  "room_intercom_unreachable_sub": "Ця кімната зараз недоступна",
  "room_kitchen": "Кухня",
  "room_laundry_room": "Пральня",
  "room_library": "Бібліотека",
  "room_living_room": "Вітальня",
  "room_master_bedroom": "Головна спальня",
  "room_media_room": "Медіа кімната",
  "room_meeting_room": "Кімната для зустрічей",
  "room_nook": "Закуток",
  "room_nursery": "Дитяча",
  "room_pantry": "Комора",
  "room_parking_place": "Стоянка для машин",
  "room_patio": "Внутрішній дворик",
  "room_pool": "Басейн",
  "room_pool_house": "Будинок біля басейну",
  "room_rack": "Стійка",
  "room_room": "Кімната",
  "rooms": "Кімнати",
  "room_scene_no_functions": "Ця кімната не має функцій",
  "room_settings": "Налаштування кімнати",
  "room_spa": "Спа",
  "room_stable": "Стабільний",
  "room_staircase": "Сходи",
  "room_terrace": "Тераса",
  "room_toilet": "Туалет",
  "room_utility_room": "Підсобне приміщення",
  "room_wine_cellar": "Винний льох",
  "room_workshop": "Майстерня",
  "rotation": "Обертання",
  "save": "Зберегти",
  "save_as": "Зберегти як",
  "save_changes": "Зберегти зміни",
  "save_changes_l1": "Деякі зміни ще не збережено",
  "save_changes_l2": "Ви хочете зберегти їх зараз?",
  "save_deezer_favourite": "Зберегти улюблене Deezer",
  "save_spotify_favourite": "Зберегти улюблене Spotify",
  "save_spotify_preset": "Зберегти пресет Spotify",
  "save_tidal_favourite": "Зберегти улюблене TIDAL",
  "scene": "Сценарій",
  "scene_away": "Поза домом",
  "scene_cooking": "Приготування",
  "scene_dinner": "Вечеря",
  "scene_error": "Помилка сцени",
  "scene_evening": "Вечір",
  "scene_good_morning": "Доброго ранку",
  "scene_good_night": "Надобраніч",
  "scene_guests": "Гості",
  "scene_home": "Вдома",
  "scene_image_error": "Помилка зображення сцени",
  "scene_movie": "Фільм",
  "scene_not_available": "Немає доступних сцен.",
  "scene_not_selected_l1": "Сценарій не обрано",
  "scene_not_selected_l2": "Будь ласка, виберіть сценарій",
  "scene_party": "Вечірка",
  "scene_relax": "Відпочинок",
  "scenes": "Сценарії",
  "scene_select_or_new": "Будь ласка, створіть або виберіть сценарій",
  "scene_vacation": "Відпустка",
  "scene_welcome": "Вітання",
  "scene_workout": "Тренування",
  "schedule": "Таймер",
  "schedule_at": "Розклад на",
  "schedule_not_selected_l1": "Розклад не вибрано.",
  "schedule_not_selected_l2": "Виберіть розклад.",
  "scheduler": "Планувальник",
  "schedules": "Розклади",
  "schedule_select_or_new": "Будь ласка, створіть або оберіть розклад",
  "screen_orientation": "Орієнтація екрана",
  "search": "Пошук",
  "search_again": "Знайти знову",
  "search_ph": "пошук виконавця, пісні чи альбому...",
  "search_result": "Результати пошуку для",
  "security": "Безпека",
  "select_admin": "Виберіть профіль адміністратора, щоб продовжити процес оновлення",
  "select_home": "Виберіть свій будинок",
  "selection": "Вибір",
  "selection_all": "додати все до обраного",
  "select_photo": "Виберіть зображення",
  "select_server": "Виберіть сервер",
  "select_user": "Виберіть профіль для входу в систему",
  "serial_number": "Серійний номер",
  "server": "Сервер",
  "server_address": "Адреса сервера",
  "server_basalte_cloud_no_connection": "Сервер не може підключитися до Basalte Cloud",
  "server_exists_already": "Сервер вже існує!",
  "server_mac": "MAC-адреса сервера",
  "servers": "Сервери",
  "server_status": "Статус сервера",
  "server_update_info_l2": "оновіть сервер Basalte до останньої версії.",
  "server_update_required": "Потрібне оновлення сервера",
  "server_version": "Версія сервера",
  "set": "Встановити",
  "set_new_password": "Встановити новий пароль",
  "setpoint": "Уставка",
  "settings": "Налаштування",
  "shade_blind": "Рулонна штора",
  "shade_center": "Посередині",
  "shade_curtain": "Тюль",
  "shade_left": "Ліворуч",
  "shade_pool_shutter": "Накриття для басейну",
  "shade_right": "Праворуч",
  "shade_roman_blind": "Римська штора",
  "shades": "Штори",
  "shade_shade": "Штора",
  "shade_shutter": "Зовнішні жалюзі",
  "shade_solar_protection": "Сонцезахист",
  "shade_venetian_blind": "Жалюзі",
  "shade_vertical_blind": "Вертикальні жалюзі",
  "share": "поділитися",
  "shared_playlist": "Поділитися плейлистом",
  "show_album": "Показати альбом",
  "show_artist": "Показати артиста",
  "show_background_art": "Показувати фон",
  "show_more": "Показати більше…",
  "show_status_bar": "Показати панель стану iPad",
  "show_status_bar_device": "Показати панель стану",
  "sign_in": "Увійти",
  "sign_in_info_tidal_l1": "Введіть свої облікові дані для входу в TIDAL",
  "sign_up": "Зареєструватися",
  "single_room": "Кімната",
  "sleep": "Режим сну",
  "software_update": "Оновлення програмного забезпечення",
  "something_went_wrong": "Щось пішло не так",
  "songs": "Пісні",
  "sonos": "Sonos",
  "sonos_unreachable": "Не можу знайти Sonos",
  "sonos_unreachable_txt_l1": "Перевірте, чи ваш пристрій увімкнено та підключено.",
  "sound_settings": "Налаштування звуку",
  "source": "Джерело",
  "sources": "Джерела",
  "spotify": "Spotify",
  "spotify_beta": "Spotify (beta)",
  "spotify_connect": "Spotify Connect",
  "spotify_connect_instructions": "Spotify Connect",
  "spotify_connect_instructions_1": "Переконайтеся, що Asano S4 і пристрій із програмою Spotify підключені до однієї мережі.",
  "spotify_connect_instructions_2": "Виберіть потік Asano S4 зі списку пристроїв у додатку Spotify",
  "spotify_connect_instructions_body": "Ви можете слухати музику на своїх динаміках або телевізорі. Ви використовуєте свій пристрій із додатком Spotify як пульт дистанційного керування.",
  "spotify_connect_instructions_title": "Готовий слухати музику?",
  "spotify_disconnect_txt": "Ви збираєтеся відключити обліковий запис Spotify від цього програвача.",
  "spotify_free_user_txt": "Потрібна підписка Spotify Premium",
  "spotify_no_link": "Обліковий запис Spotify ще не під'єднано",
  "spotify_oauth_error": "Помилка автентифікації Spotify",
  "spotify_preset_error": "Помилка Spotify",
  "spotify_preset_error_l1": "Не вдалося завантажити попередні налаштування Spotify.",
  "spotify_preset_error_l2": "Переконайтеся, що дійсний обліковий запис Spotify підключався до цього потоку раніше.",
  "spotify_presets": "Налаштування Spotify",
  "start": "Старт",
  "start_page": "Стартова сторінка",
  "start_page_customisation": "Налаштування стартової сторінки",
  "start_time_date": "Час і дата",
  "startup_view": "Домашня сторінка",
  "startup_volume": "Початкова гучність",
  "start_weather": "Інформація про погоду",
  "state_active": "Активний",
  "state_armed": "Поставлено на охорону",
  "state_closed": "Зачинено",
  "state_disabled": "Вимкнено",
  "state_disarmed": "Знято з охорони",
  "state_down": "Вниз",
  "state_enabled": "Увімкнено",
  "state_false": "Хибно",
  "state_free": "Вільно",
  "state_high": "Високий",
  "state_in": "В",
  "state_inactive": "Неактивний",
  "state_left": "Ліворуч",
  "state_low": "Низький",
  "state_occupied": "Зайнято",
  "state_off": "Off",
  "state_on": "On",
  "state_opened": "Відкрито",
  "state_out": "Ззовні",
  "state_right": "Праворуч",
  "state_started": "Пуск",
  "state_stopped": "Стоп",
  "state_true": "Вірно",
  "state_up": "Вгору",
  "static": "Статичний",
  "stations": "Станції",
  "status": "Статус",
  "stop": "СТОП",
  "stream": "Потік",
  "streaming": "Потокова передача",
  "streaming_services": "Потоковий сервіс",
  "streams": "Потоки",
  "streams_beta": "Потоки (бета)",
  "subnet_mask": "Маска підмережі",
  "sun": "Сонце",
  "sunrise": "Схід сонця",
  "sunset": "Захід сонця",
  "swipe_to_pan": "Проведіть для переміщення",
  "switch_server": "Змінити сервер",
  "synchronise": "Сканувати зараз",
  "system": "Cистема",
  "system_setting": "Системні налаштування",
  "temperature": "Температура",
  "temperature_unit": "Температурна одиниця",
  "theme": "Тема",
  "thermostat": "Термостат",
  "this_week": "Цього тижня",
  "tidal": "TIDAL",
  "tidal_disconnect_txt": "Ви хочете відключитися від облікового запису TIDAL з цього пристрою.",
  "tidal_legacy_auth": "Необхідно ввійти в TIDAL",
  "tidal_legacy_auth_l1": "Щоб продовжити використання TIDAL, будь ласка, увійдіть знову.",
  "tidal_no_link": "Обліковий запис TIDAL ще не підключено",
  "tidal_oauth_error": "Помилка автентифікації TIDAL",
  "tidal_rising": "TIDAL Rising",
  "time": "Час",
  "time_format": "Формат часу",
  "timer": "Таймер",
  "timers": "Таймери",
  "title": "Назва",
  "today": "Сьогодні",
  "tomorrow": "Завтра",
  "top_tracks": "Топ-треки",
  "treble": "Високі частоти",
  "try_again": "Спробуйте знову",
  "try_again_later": "Спробуйте ще раз пізніше",
  "try_again_later_or_contact_support": "Спробуйте пізніше або зверніться до служби підтримки",
  "turned_off": "Вимкнено",
  "turned_on": "Увімкнено",
  "turn_off": "Вимкнути",
  "turn_on": "Увімкнути",
  "turn_on_default_rooms": "Увімкнути типові кімнати для цього джерела?",
  "turn_on_music": "Увімкніть музику",
  "turn_on_tv": "Увімкніть телевізор",
  "tv": "ТВ",
  "tv_off_current_room": "Вимкнути ТВ в цій кімнаті",
  "unavailable": "Недоступно",
  "unavailable_src": "Джерело недоступне",
  "unavailable_src_txt": "Поточне джерело недоступне.",
  "ungroup": "Розгрупувати",
  "unknown": "Невідомий",
  "unknown_account": "Невідомий обліковий запис",
  "unknown_account_l1": "Ця електронна адреса не є обліковим записом Basalte Live.",
  "unknown_av": "Невідомий AV-вхід",
  "unknown_src": "Невідоме джерело",
  "unknown_src_txt": "Поточне джерело невідоме цьому серверу.",
  "unlink": "Від’єднати",
  "unlink_server": "Від’єднатися від Basalte Live",
  "unlink_server_full_sub": "Вилучити цей проєкт із Basalte Live? Він більше не буде доступний за межами локальної мережі.",
  "unreachable": "Недоступний",
  "unshare": "скасувати спільний доступ",
  "unsupported_browser": "Непідтримуваний браузер",
  "unsupported_browser_l1": "Деякі функції можуть працювати неправильно.",
  "unsupported_browser_l2": "Для кращого використання використовуйте Chrome або Safari.",
  "unsupported_browser_live": "Цей браузер не підтримує Basalte Live. Спробуйте скористатися новою версією Chrome або Safari.",
  "up": "Вгору",
  "update": "Оновити",
  "update_available": "Оновити",
  "update_available_full": "Доступне оновлення",
  "update_info_l1": "Щоб забезпечити найліпше користування,",
  "update_library": "Повторне сканування музичної бібліотеки",
  "update_music_server": "Оновіть музичний сервер",
  "update_scene": "Оновлення сцени",
  "update_scene_l1": "Зберегти поточний стан цієї сцени.",
  "update_server": "Оновити сервер",
  "updating_server": "Оновлення сервера",
  "updating_server_info": "Не вимикайте сервер",
  "up_to_date": "Своєчасно",
  "username": "Ім'я користувача",
  "uv": "Ультрафіолетовий",
  "value_invalid": "Вказане значення параметра недійсне",
  "verify": "Перевірте",
  "verifying": "перевірка",
  "version": "Версія",
  "video_background": "Фонове відео",
  "video_go_to": "Перейти до ТВ",
  "video_is_active": "ТВ активний",
  "video_is_playing": "ТВ відображає",
  "video_unavailable": "ТВ або аудіосистема недоступна",
  "volume": "Гучність",
  "warning": "УВАГА",
  "water": "Вода",
  "weather": "Погода",
  "weather_daily_data_unavailable": "Наразі немає щоденної інформації про погоду",
  "weather_hourly_data_unavailable": "На даний час немає погодинної інформації про погоду.",
  "weather_information": "Інформація про погоду",
  "week": "Тиждень",
  "weekly": "Щотижня",
  "what": "Що",
  "what_music": "Яка музика",
  "when": "Коли",
  "where": "Де",
  "who": "Хто",
  "wind": "Вітер",
  "wrong_password": "Неправильний пароль",
  "wrong_password_txt": "Введений пароль не збігається з паролем користувача",
  "wrong_username_and_or_password": "Неправильне ім'я користувача та/або пароль",
  "year": "Рік",
  "yes": "Так",
  "yes_all_off": "Так, вимкнути все",
  "yes_cur_off": "Так, вимкнути поточне",
  "yes_deezer_dis": "Так, відключити акаунт Deezer",
  "yes_delete_it": "Так, видалити",
  "yesterday": "Вчора",
  "zone": "Кімната",
  "zones": "Кімнати"
}
