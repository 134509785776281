'use strict'

angular
  .module('basalteApp')
  .constant('BAS_CORE', {
    EVT_CORE_CONNECTED: 'evtBasCoreContainerConnected',
    EVT_CORE_CORE_CONNECTED: 'evtBasCoreContainerCoreConnected',
    EVT_CORE_CORE_V2_CONNECTED:
      'evtBasCoreContainerSubscriptionCoreConnected',
    EVT_CORE_VERSION: 'evtBasCoreContainerVersion',
    EVT_CORE_USER_CREATED: 'evtBasCoreContainerUserCreated',
    EVT_CORE_PROFILE_CREATED: 'evtBasCoreContainerProfileCreated',
    EVT_CORE_IS_ADMIN: 'evtBasCoreContainerIsAdmin',
    EVT_CORE_LIVE_INFO: 'evtBasCoreContainerLiveInfo',
    EVT_CORE_AV_SOURCES_RECEIVED:
      'evtBasCoreContaineravSourcesReceived',
    EVT_CORE_MUSIC_RECEIVED: 'evtBasCoreContainerMusicReceived',
    EVT_CORE_ROOMS_RECEIVED: 'evtBasCoreContainerRoomsReceived',
    EVT_CORE_DEVICES_UPDATED:
      'evtBasCoreContainerDevicesUpdated',
    EVT_CORE_CONNECTED_DEVICES_UPDATED:
      'evtBasCoreContainerConnectedDevicesUpdated',
    EVT_CORE_SERVER_DEVICES_UPDATED:
      'evtBasCoreContainerServerDevicesUpdated',
    EVT_CORE_CONNECTION_JWT_REVOKED:
      'evtBasCoreContainerConnectionForbidden',
    EVT_CORE_SYSTEM: 'evtBasCoreContainerSystem',
    EVT_CORE_MESSAGES_UPDATED: 'evtBasCoreContainerMessagesUpdated',
    EVT_CORE_CUSTOM_RADIOS: 'evtBasCoreContainerCustomRadios',
    EVT_CORE_CAMERAS_ORDER: 'evtBasCoreContainerCamerasOrder',
    EVT_CORE_OPEN_CLOSE_DEVICES_ORDER:
      'evtBasCoreContainerOpenCloseDevicesOrder',
    EVT_CORE_SCHEDULES_ORDER: 'evtBasCoreContainerSchedulesOrder',
    EVT_CORE_TIMERS_ORDER: 'evtBasCoreContainerTimersOrder',
    EVT_CORE_SCENES_ORDER: 'evtBasCoreContainerScenesOrder',
    EVT_CORE_SCENES_FAVOURITES: 'evtBasCoreContainerScenesFavourites',
    EVT_CORE_LIGHT_GROUP_ORDER: 'evtBasCoreContainerLightGroupOrder',
    EVT_CORE_LISA_TILES_ORDER: 'evtBasCoreContainerLisaTilesOrder',
    EVT_CORE_TIDAL_LEGACY_AUTH_DONT_ASK:
      'evtBasCoreContainerTidalLegacyAuthDontAsk',
    EVT_CORE_MUSIC_LIBRARY_HAS_CONTENT:
      'evtBasCoreContainerMusicLibraryHasContent',
    EVT_CORE_MUSIC_LIBRARY_SCANNING_CHANGED:
      'evtBasCoreContainerMusicLibraryScanningChanged',
    EVT_CORE_MUSIC_LIBRARY_CHANGED:
      'evtBasCoreContainerMusicLibraryChanged',

    CORE_UPDATE_S_NONE: 'basCoreUpdateStateNone',
    CORE_UPDATE_S_CHECKING: 'basCoreUpdateStateChecking',
    CORE_UPDATE_S_DOWNLOADING: 'basCoreUpdateStateDownloading',
    CORE_UPDATE_S_READY: 'basCoreUpdateStateReady',

    /**
     * @deprecated
     */
    EVT_CORE_HAS_UPDATE: 'evtBasCoreContainerHasUpdate'
  })
