'use strict'

var EventEmitter = require('@gidw/event-emitter-js')

var BasUtil = require('@basalte/bas-util')
var Capabilities = require('./capabilities')

var P = require('./parser_constants')
var CONSTANTS = require('./constants')

/**
 * @typedef {Object} TResponseError
 * @property {string} code
 * @property {string} reason
 * @property {*} [message]
 */

/**
 * @typedef {Object} TDevice
 * @property {string} uuid
 * @property {string} name
 * @property {number} basType
 * @property {string} type
 * @property {string} subType
 * @property {boolean} reachable
 */

/**
 * @typedef {Object} TDeviceMessage
 * @property {Object} device
 * @property {string} device.uuid
 */

/**
 * @typedef {Object} TDeviceParseOptions
 * @property {boolean} [emit = true] Allow events to be emitted
 * @property {boolean} [setDefaults] Always set property value
 */

/**
 * @typedef {Object<string, (Object | Array)>} TDeviceAttributes
 */

/**
 * @constructor
 * @extends EventEmitter
 * @mixes Capabilities.mixin
 * @param {TDevice} device
 * @param {BasCore} basCore
 */
function Device (device, basCore) {

  EventEmitter.call(this)

  this._basCore = basCore

  this._uuid = BasUtil.isNEString(device[P.UUID])
    ? device[P.UUID]
    : ''

  this._name = BasUtil.isNEString(device[P.NAME])
    ? device[P.NAME]
    : ''

  this._basType = BasUtil.isPNumber(device[P.BAS_TYPE])
    ? device[P.BAS_TYPE]
    : Device.BT.UNKNOWN

  this._type = BasUtil.isNEString(device[P.TYPE])
    ? device[P.TYPE]
    : ''

  this._subType = (
    BasUtil.isNEString(device[P.SUB_TYPE]) ||
    BasUtil.isPNumber(device[P.SUB_TYPE], true)
  )
    ? device[P.SUB_TYPE]
    : ''

  this._location = BasUtil.isPNumber(device[P.LOCATION], true)
    ? device[P.LOCATION]
    : Device.LOC.LOC_NONE

  this._reachable = BasUtil.isBool(device[P.REACHABLE])
    ? device[P.REACHABLE]
    : true

  this[CONSTANTS.CAPABILITIES] =
    new Capabilities(device[P.CAPABILITIES])

  /**
   * @type {TDeviceAttributes}
   */
  this._attributes = BasUtil.isObject(device[P.ATTRIBUTES])
    ? device[P.ATTRIBUTES]
    : {}

  /**
   * @type {boolean}
   */
  this._isDestroyed = false
}

Device.prototype = Object.create(EventEmitter.prototype)
Device.prototype.constructor = Device
BasUtil.mergeObjects(Device.prototype, Capabilities.mixin)

// region Events

/**
 * Reachable state has changed
 *
 * @event Device#EVT_REACHABLE
 * @param {boolean} reachable
 */

/**
 * Capabilities have changed
 *
 * @event Device#EVT_CAPABILITIES
 * @param {Object<string, string>} capabilities
 */

// endregion

/**
 * @constant {string}
 */
Device.EVT_REACHABLE = 'evtDeviceReachable'

/**
 * @constant {string}
 */
Device.EVT_NAME = 'evtDeviceName'

/**
 * @constant {string}
 */
Device.EVT_CAPABILITIES = 'evtDeviceCapabilities'

/**
 * @constant {string}
 */
Device.EVT_ATTRIBUTES = 'evtDeviceAttributes'

/**
 * Basalte device types
 * From proto.be.basalte.proto.DeviceType
 *
 * @readonly
 * @enum {number}
 */
Device.BT = {
  ID_UNKNOWN: 0,
  ID_SENTIDO_KNX: 65293,
  ID_SENTIDO_KNX_V3: 65328,
  ID_SENTIDO_KNX_V3_1: 65329,
  ID_AURO_KNX: 65025,
  ID_TACTO_KNX: 64802,
  ID_DESEO_KNX: 61459,
  ID_ASANO_A3: 61201,
  ID_ASANO_A4: 61202,
  ID_ASANO_P1: 64273,
  ID_ASANO_P4: 64529,
  ID_ASANO_P4A: 64530,
  ID_MMS_LINK: 4660,
  ID_B_LINK: 63761,
  ID_SERIAL_LINK: 63505,
  ID_C_LINK: 63249,
  ID_BO_SENSOR: 64017,
  ID_ASANO_S4: 62977,
  ID_CORE_MINI: 62978,
  ID_CORE_PLUS: 62979,
  ID_ASANO_N1: 62721,
  ID_ASANO_N3: 62723,
  ID_ASANO_M4: 62468,
  ID_ASANO_M3: 62469,
  ID_ASANO_D3: 62475,
  ID_ASANO_D4: 62476,
  ID_ASANO_B2: 62477,
  ID_ASANO_AMD450: 62480,
  ID_MIRO_REV_C: 62208,
  ID_MIRO: 62209,
  ID_DESEO_V2: 61952,
  ID_DESEO_V2_KNX: 61953,
  ID_DESEO_V2_RS485: 61954,
  ID_DESEO_V2_CRESNET: 61956,
  ID_SENTIDO_BEAM: 48640,
  ID_AURO_BEAM: 48656,
  ID_CHOPIN_BEAM: 48688,
  ID_BEAM_NODE: 48704,
  ID_BEAM_RELAY: 48720,
  ID_PENDEL_BEAM: 48752,
  ID_SPOT_BEAM: 48768,
  ID_LED_DRIVER_BEAM: 48784,
  ID_MIRO_BASE: 48800,
  ID_ELLIE: 57617,
  ID_LISA: 29018,
  ID_EXT_AMP: 23839,
  ID_MAX: 65535
}

/**
 * Reverse enum
 *
 * @readonly
 * @enum {string}
 */
Device.BT_R = BasUtil.switchObjectKeyValue(Device.BT)

/**
 * Device locations
 * From proto.be.basalte.config.Location
 *
 * @readonly
 * @enum {number}
 */
Device.LOC = {
  LOC_NONE: 0,
  LOC_NORTH: 1,
  LOC_EAST: 2,
  LOC_SOUTH: 3,
  LOC_WEST: 4,
  LOC_LEFT: 5,
  LOC_RIGHT: 6,
  LOC_TOP: 7,
  LOC_BOTTOM: 8,
  LOC_CENTER: 9,
  LOC_MID: 10,
  LOC_LOW: 11,
  LOC_HIGH: 12,
  LOC_FRONT: 13,
  LOC_BACK: 14,
  LOC_CORNER: 15,
  LOC_CHAIR: 16,
  LOC_TABLE: 17,
  LOC_DESK: 18,
  LOC_STOVE: 19,
  LOC_ISLAND: 20,
  LOC_SHELVES: 21,
  LOC_NICHE: 22
}

/**
 * Reverse enum
 *
 * @readonly
 * @enum {string}
 */
Device.LOC_R = BasUtil.switchObjectKeyValue(Device.LOC)

/**
 * @constant {string}
 */
Device.T_SERVER = P.SERVER

/**
 * @constant {string}
 */
Device.T_ELLIE = P.ELLIE

/**
 * @constant {string}
 */
Device.T_LISA = P.LISA

/**
 * @constant {string}
 */
Device.T_LENA = P.LENA

/**
 * @constant {string}
 */
Device.T_ADELANTE = P.ADELANTE

/**
 * @constant {string}
 */
Device.T_LIGHT = P.LIGHT

/**
 * @constant {string}
 */
Device.T_SHADE = P.WINDOW_TREATMENT

/**
 * @constant {string}
 */
Device.T_SCENE_CONTROLLER = P.SCENE_CONTROLLER

/**
 * @constant {string}
 */
Device.T_TIMER_CONTROLLER = P.TIMER

/**
 * @constant {string}
 */
Device.T_THERMOSTAT = P.THERMOSTAT

/**
 * @constant {string}
 */
Device.T_CAMERA = P.CAMERA

/**
 * @constant {string}
 */
Device.T_ENERGY = P.ENERGY

/**
 * @constant {string}
 */
Device.T_DOOR_PHONE_GATEWAY = P.DOOR_PHONE_GATEWAY

/**
 * @constant {string}
 */
Device.T_DOOR_PHONE = P.DOOR_PHONE

/**
 * @constant {string}
 */
Device.T_GENERIC = P.GENERIC

/**
 * @constant {string}
 */
Device.T_GENERIC_V2 = P.GENERIC_V2

/**
 * @constant {string}
 */
Device.T_OPEN_CLOSE = P.OPEN_CLOSE

/**
 * @constant {string}
 */
Device.T_WEATHER_STATION = P.WEATHER_STATION

/**
 * @constant {string}
 */
Device.T_ENERGY_METER = P.ENERGY_METER

/**
 * @param {number} typeID
 * @returns {string}
 */
Device.getDeviceType = function (typeID) {
  switch (typeID) {
    case Device.BT.ID_ASANO_A3:
      return 'Asano A3'
    case Device.BT.ID_ASANO_A4:
      return 'Asano A4'
    case Device.BT.ID_ASANO_D3:
      return 'Aalto D3'
    case Device.BT.ID_ASANO_D4:
      return 'Aalto D4'
    case Device.BT.ID_ASANO_M3:
      return 'Asano M3'
    case Device.BT.ID_ASANO_M4:
      return 'Asano M4'
    case Device.BT.ID_ASANO_N1:
      return 'Asano N1'
    case Device.BT.ID_ASANO_N3:
      return 'Asano N3'
    case Device.BT.ID_ASANO_P1:
      return 'Asano P1'
    case Device.BT.ID_ASANO_P4:
      return 'Asano P4'
    case Device.BT.ID_ASANO_P4A:
      return 'Asano P4A'
    case Device.BT.ID_ASANO_S4:
      return 'Core S4'
    case Device.BT.ID_CORE_MINI:
      return 'Core Mini'
    case Device.BT.ID_CORE_PLUS:
      return 'Core Plus'
    case Device.BT.ID_ELLIE:
      return 'Ellie'
    case Device.BT.ID_LISA:
      return 'Lisa'
    case Device.BT.ID_MIRO:
    case Device.BT.ID_MIRO_REV_C:
      return 'Miro'
    case Device.BT.ID_MIRO_BASE:
      return 'Miro base'
  }
  return ''
}

/**
 * Checks for known error responses.
 * Should be used in a Promise chain.
 *
 * @param {*} response
 * @returns {*}
 */
Device.handleResponse = function (response) {

  var value, error, errorResult

  if (BasUtil.isObject(response) && response[P.SUCCESS] === false) {

    /**
     * @type {TResponseError}
     */
    errorResult = {
      errorCode: '',
      reason: '',
      message: response
    }

    error = response[P.ERROR]

    if (BasUtil.isObject(error)) {

      // Error code

      value = error[P.ERROR_CODE]
      if (BasUtil.isNEString(value)) errorResult.errorCode = value

      // Error reason

      value = error[P.REASON]
      if (BasUtil.isNEString(value)) errorResult.reason = value
    }

    return Promise.reject(errorResult)
  }

  return response
}

/**
 * @name Device#uuid
 * @type {string}
 * @readonly
 */
Object.defineProperty(Device.prototype, 'uuid', {
  get: function () {
    return this._uuid
  }
})

/**
 * @name Device#name
 * @type {string}
 * @readonly
 */
Object.defineProperty(Device.prototype, 'name', {
  get: function () {
    return this._name
  }
})

/**
 * @name Device#basType
 * @type {number}
 * @readonly
 */
Object.defineProperty(Device.prototype, 'basType', {
  get: function () {
    return this._basType
  }
})

/**
 * @name Device#type
 * @type {string}
 * @readonly
 */
Object.defineProperty(Device.prototype, 'type', {
  get: function () {
    return this._type
  }
})

/**
 * @name Device#subType
 * @type {(string|number)}
 * @readonly
 */
Object.defineProperty(Device.prototype, 'subType', {
  get: function () {
    return this._subType
  }
})

/**
 * @name Device#location
 * @type {number}
 * @readonly
 */
Object.defineProperty(Device.prototype, 'location', {
  get: function () {
    return this._location
  }
})

/**
 * @name Device#reachable
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(Device.prototype, 'reachable', {
  get: function () {
    return this._reachable
  }
})

/**
 * @name Device#isDestroyed
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(Device.prototype, 'isDestroyed', {
  get: function () {
    return this._isDestroyed
  }
})

/**
 * Parse a device message
 *
 * @param {Object} msg
 * @param {TDeviceParseOptions} [options]
 * @returns {boolean}
 */
Device.prototype.parse = function (msg, options) {

  var valid, emit, value

  valid = BasUtil.isObject(msg) && msg[P.UUID] === this._uuid
  emit = true

  if (BasUtil.isObject(options)) {

    if (BasUtil.isBool(options.emit)) emit = options.emit
  }

  if (valid) {

    // Reachable

    value = msg[P.REACHABLE]

    if (BasUtil.isBool(value) &&
      this._reachable !== value) {

      this._reachable = value

      if (emit) this.emit(Device.EVT_REACHABLE, this._reachable)
    }

    // Name

    value = msg[P.NAME]

    if (BasUtil.isString(value) &&
      this._name !== value) {

      this._name = value

      if (emit) this.emit(Device.EVT_NAME, this._name)
    }

    // Capabilities

    if (this[CONSTANTS.CAPABILITIES].parse(msg[P.CAPABILITIES])) {

      if (emit) this.emit(Device.EVT_CAPABILITIES)
    }

    // Attributes

    value = msg[P.ATTRIBUTES]

    if (BasUtil.isObject(value)) {
      if (!BasUtil.isEqualObject(this._attributes, value)) {

        BasUtil.mergeObjectsDeep(this._attributes, value)

        if (emit) this.emit(Device.EVT_ATTRIBUTES, this._attributes)
      }
    }
  }

  return valid
}

/**
 * Send action to Shade device
 *
 * @param {string} action
 * @param {boolean} [active]
 */
Device.prototype.performAction = function (action, active) {

  var msg

  if (this._basCore) {

    msg = this._getBasCoreMessage()
    msg[P.DEVICE][P.ACTION] = action

    if (BasUtil.isBool(active)) {

      msg[P.DEVICE][P.ACTIVE] = active
    }

    this._basCore.send(msg)
  }
}

/**
 * Update a single or multiple properties
 *
 * @param {Object} newState
 */
Device.prototype.updateState = function (newState) {

  var msg

  if (this._basCore) {

    msg = this._getBasCoreMessage()
    msg[P.DEVICE][P.STATE] = newState

    this._basCore.send(msg)
  }
}

/**
 * Returns "labels" from attributes, if exists.
 *
 * @returns {?Object}
 * @since 2.7.9
 */
Device.prototype.getLabels = function () {

  if (BasUtil.isObject(this._attributes) &&
    BasUtil.isObject(this._attributes[P.LABELS])) {

    return this._attributes[P.LABELS]
  }

  return null
}

/**
 * Creates a template basCore message for this device
 *
 * @protected
 * @returns {TDeviceMessage}
 */
Device.prototype._getBasCoreMessage = function () {

  var msg = {}
  msg[P.DEVICE] = {}
  msg[P.DEVICE][P.UUID] = this._uuid

  return msg
}

Device.prototype.destroy = function () {

  this._basCore = null
  this.removeAllListeners()
  this._isDestroyed = true
}

module.exports = Device
