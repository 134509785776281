'use strict'

var BasUtil = require('@basalte/bas-util')

var P = require('./parser_constants')

var BasCrypto = require('./bas_crypto')

/**
 * Supported API versions can not have gaps (ex: [0, 2])
 *
 * @constant {number[]}
 */
exports.SUPPORTED_API_VERSIONS = [0, 1]

/**
 * @constant {number}
 */
exports.DEFAULT_API_VERSION = 0

/**
 * Gets the correct API version. For NaN returns 0.
 *
 * @param {number} apiVersion
 * @returns {number}
 * @since 3.0.0
 */
exports.getApiVersion = function (apiVersion) {

  return BasUtil.isVNumber(apiVersion) ? apiVersion : 0
}

/**
 * Returns a supported API version
 *
 * @returns {number}
 */
exports.getHighestSupportedApiVersion = function () {

  var versions = exports.SUPPORTED_API_VERSIONS.slice()

  // Biggest first
  versions.sort(_compareSmallerThen)

  return versions[0]
}

exports.getLowestSupportedApiVersion = function () {

  var versions = exports.SUPPORTED_API_VERSIONS.slice()

  // Smallest first
  versions.sort(_compareGreaterThen)

  return versions[0]
}

/**
 * Checks if the API version is supported.
 *
 * 0: Supported
 * negative: Server is newer
 * positive: App is newer
 *
 * @param {number} apiVersion Needs to be of type number
 * @returns {number}
 * @since 3.0.0
 */
exports.unsupportedApi = function (apiVersion) {

  var _apiVersion, highestSupportedApi

  _apiVersion = exports.getApiVersion(apiVersion)

  if (exports.SUPPORTED_API_VERSIONS.indexOf(_apiVersion) !== -1) {

    return 0
  }

  highestSupportedApi = exports.getHighestSupportedApiVersion()

  return _apiVersion > highestSupportedApi ? -1 : +1
}

/**
 * @param {number} volume Volume [0 - 100]
 * @returns {number}
 */
exports.convertToServerVolume = function (volume) {

  return Math.round(volume * 2.55)
}

/**
 * @param {number} volume
 * @returns {number} Volume [0 - 100]
 */
exports.convertFromServerVolume = function (volume) {

  return Math.round(volume / 2.55)
}

/**
 * @constant {BasUtil.BasVersion}
 */
exports.VERSION_DEFAULT_ROOMS = new BasUtil.BasVersion('1.3.1')

/**
 * @constant {BasUtil.BasVersion}
 */
exports.VERSION_REPLACE_NOW = new BasUtil.BasVersion('1.4.10')

/**
 * @constant {BasUtil.BasVersion}
 */
exports.VERSION_DESCRIBE_ZONE = new BasUtil.BasVersion('1.4.15')

/**
 * @constant {BasUtil.BasVersion}
 */
exports.VERSION_BASIC_ZONE_DSP = new BasUtil.BasVersion('1.5.0')

/**
 * @constant {BasUtil.BasVersion}
 */
exports.VERSION_SPOTIFY_WEB_API = new BasUtil.BasVersion('2.0.0')

/**
 * @constant {BasUtil.BasVersion}
 */
exports.VERSION_SYSTEM_PROPERTIES = new BasUtil.BasVersion('2.3.8')

/**
 * @constant {BasUtil.BasVersion}
 */
exports.VERSION_SPOTIFY_UNLINK = new BasUtil.BasVersion('2.4.0')

/**
 * @constant {BasUtil.BasVersion}
 */
exports.VERSION_REPEAT_MODE = new BasUtil.BasVersion('2.4.1')

/**
 * @constant {BasUtil.BasVersion}
 */
exports.VERSION_CHECKING_UPDATES = new BasUtil.BasVersion('2.3.14')

/**
 * @constant {BasUtil.BasVersion}
 */
exports.VERSION_SUNRISE_SET = new BasUtil.BasVersion('2.6.0')

/**
 * New
 * - Generic devices in scenes
 * - Shared Server Storage
 *
 * @constant {BasUtil.BasVersion}
 */
exports.VERSION_GENERIC_DEVICES_SCENE = new BasUtil.BasVersion('2.6.5')

/**
 * @constant {BasUtil.BasVersion}
 */
exports.VERSION_PROFILE = new BasUtil.BasVersion('2.7.0')

/**
 * @constant {BasUtil.BasVersion}
 */
exports.VERSION_ALARMS_ACCESS = new BasUtil.BasVersion('3.0.0')

/**
 * @constant {BasUtil.BasVersion}
 */
exports.VERSION_MUTE_AREA_ON_CALL = new BasUtil.BasVersion('3.1.0')

/**
 * @constant {BasUtil.BasVersion}
 */
exports.VERSION_BASALTE_LIVE = new BasUtil.BasVersion('3.3.0')

/**
 * New
 * - Custom room images support
 * - System Integrator info
 *
 * @constant {BasUtil.BasVersion}
 */
exports.VERSION_CUSTOM_ROOM_IMAGES = new BasUtil.BasVersion('4.1.0')

/**
 * @constant {BasUtil.BasVersion}
 */
exports.VERSION_CUSTOM_PROJECT_IMAGE = new BasUtil.BasVersion('5.1.7')

/**
 * @constant {BasUtil.BasVersion}
 */
exports.VERSION_CUSTOM_SCENE_IMAGE = new BasUtil.BasVersion('5.3.0')

/**
 * @constant {BasUtil.BasVersion}
 */
exports.VERSION_TIDAL_OAUTH = new BasUtil.BasVersion('5.3.4')

/**
 * - Contains the new AV API for Asano
 * - system has new property "asanoAvSupport"
 * - Zones have new property "audioOutputs"
 * - AudioOutputs have new properties "channelMode", "volume" and "muted"
 *
 * @constant {BasUtil.BasVersion}
 */
exports.VERSION_ASANO_AV = new BasUtil.BasVersion('6.2.0')

/**
 * @constant {BasVersion}
 */
exports.VERSION_QUICK_FAVOURITES = new BasUtil.BasVersion('6.3.1')

/**
 * New
 * - Av audio have new properties "dsp" and "reset"
 * - Able to reset Dsp values for a room
 * - Able to update Dsp values for a room
 * - System has new property "spotify"
 * - AudioSource has new streamingServices attribute "spotifyConnect"
 *
 * @constant {BasVersion}
 */
exports.VERSION_AV_AUDIO_DSP = new BasUtil.BasVersion('7.0.0')

/**
 * @constant {string}
 */
exports.TU_CELSIUS = 'celsius'

/**
 * @constant {string}
 */
exports.TU_FAHRENHEIT = 'fahrenheit'

/**
 * @constant {string}
 */
exports.TU_KELVIN = 'kelvin'

/**
 * @param {string} unit
 * @returns {boolean}
 */
exports.isTemperatureUnit = function (unit) {
  return (
    unit === exports.TU_CELSIUS ||
    unit === exports.TU_FAHRENHEIT ||
    unit === exports.TU_KELVIN
  )
}

/**
 * @constant {number}
 * @since 3.4.0
 */
exports.REPEAT_OFF = 0

/**
 * @constant {number}
 * @since 3.4.0
 */
exports.REPEAT_CURRENT_CONTEXT = 1

/**
 * @constant {number}
 * @since 3.4.0
 */
exports.REPEAT_CURRENT_TRACK = 2

/**
 * @param {number} repeatMode
 * @returns {boolean}
 * @since 3.4.0
 */
exports.isValidRepeatMode = function (repeatMode) {
  return (
    repeatMode === exports.REPEAT_OFF ||
    repeatMode === exports.REPEAT_CURRENT_CONTEXT ||
    repeatMode === exports.REPEAT_CURRENT_TRACK
  )
}

/**
 * @constant {string}
 */
exports.PATH_WS_MUSIC = '/music'

/**
 * @constant {string}
 */
exports.PATH_WS_API = '/api'

/**
 * @constant {string}
 */
exports.PATH_API = '/api'

/**
 * @constant {string}
 */
exports.PATH_WEB = '/web'

/**
 * @constant {string}
 */
exports.PATH_RESTART = '/restart'

/**
 * @constant {string}
 */
exports.PATH_IMAGES = '/images'

/**
 * @constant {string}
 */
exports.PATH_API_STATUS = exports.PATH_API + '/status'

/**
 * @constant {string}
 */
exports.PATH_API_LOGIN = exports.PATH_API + '/login'

/**
 * @constant {string}
 */
exports.PATH_API_VERSION = exports.PATH_API + '/version'

/**
 * @constant {string}
 */
exports.PATH_API_SUPPORTS = exports.PATH_API + '/supports'

/**
 * @constant {string}
 */
exports.PATH_API_USERS = exports.PATH_API + '/users'

/**
 * @constant {string}
 */
exports.PATH_API_UPDATE = exports.PATH_API + '/update'

/**
 * @constant {string}
 */
exports.PATH_API_PROJECT = exports.PATH_API + '/project'

/**
 * @constant {string}
 */
exports.PATH_API_ELLIE = exports.PATH_API + '/ellie'

/**
 * @constant {string}
 */
exports.PATH_API_DEVICE = exports.PATH_API + '/device'

/**
 * @constant {string}
 */
exports.PATH_API_RESTART = exports.PATH_API + exports.PATH_RESTART

/**
 * @constant {string}
 */
exports.PATH_API_LOGOUT = exports.PATH_API + '/logout'

/**
 * @constant {string}
 */
exports.PATH_API_FORECAST = exports.PATH_API + '/forecast'

/**
 * @constant {string}
 */
exports.PATH_API_IMAGES = exports.PATH_API + exports.PATH_IMAGES + '/avatar'

/**
 * @constant {string}
 */
exports.PATH_WEB_AVATAR = exports.PATH_WEB + '/avatar'

/**
 * @constant {string}
 */
exports.PATH_WEB_COVER = exports.PATH_WEB + '/cover'

/**
 * @constant {string}
 */
exports.PATH_WEB_COVER_THUMB = exports.PATH_WEB_COVER + '/thumb'

/**
 * @constant {string}
 */
exports.PATH_API_RADIOS = exports.PATH_API + '/radios'

/**
 * @constant {string}
 */
exports.H_RESTART_PATH = BasCrypto.sha256(exports.PATH_RESTART)

/**
 * @constant {string}
 */
exports.ERR_CREDENTIALS = 'errCredentials'

/**
 * Socket error
 *
 * @constant {string}
 */
exports.ERR_CONNECTION = 'errConnection'

/**
 * Core error
 *
 * @constant {string}
 */
exports.ERR_NO_CORE = 'errNoCore'

/**
 * @constant {string}
 */
exports.ERR_SOCKET_CREATION = 'errSocketCreation'

/**
 * @constant {string}
 */
exports.ERR_TIMEOUT = 'errTimeout'

/**
 * @constant {string}
 */
exports.ERR_NAME = 'errInvalidName'

/**
 * @constant {string}
 */
exports.ERR_UUID = 'errInvalidUuid'

/**
 * @constant {string}
 */
exports.ERR_RESULT = 'errInvalidResult'

/**
 * @constant {string}
 */
exports.ERR_UNSUPPORTED = 'errUnsupported'

/**
 * HTTP error (400)
 *
 * @constant {string}
 */
exports.ERR_BAD_REQUEST = 'errBadRequest'

/**
 * HTTP error (401)
 *
 * @constant {string}
 */
exports.ERR_UNAUTHORIZED = 'errUnauthorized'

/**
 * HTTP error (403)
 *
 * @constant {string}
 */
exports.ERR_FORBIDDEN = 'errForbidden'

/**
 * HTTP error (404)
 *
 * @constant {string}
 */
exports.ERR_NOT_FOUND = 'errNotFound'

/**
 * HTTP error
 *
 * @constant {string}
 */
exports.ERR_NETWORK = 'errNetwork'

/**
 * @constant {string}
 */
exports.ERR_INVALID_PARAMETERS = 'errInvalidParameters'

/**
 * @constant {string}
 */
exports.ERR_INVALID_RESPONSE = 'errInvalidResponse'

/**
 * @constant {string}
 */
exports.ERR_NO_PERMISSION = 'errNoPermission'

/**
 * @constant {string}
 */
exports.ERR_UNEXPECTED_ERROR = 'errUnexpectedError'

/**
 * @constant {string}
 */
exports.ERR_NOT_IMPLEMENTED = 'errNotImplemented'

/**
 * @constant {string}
 */
exports.ERR_QUEUE_EMPTY = 'errQueueEmpty'

/**
 * @constant {string}
 */
exports.ERR_UNREACHABLE = 'errUnreachable'

/**
 * @constant {string}
 */
exports.NO_CHANGES = 'No changes'

/**
 * @constant {TBasRequestOptions}
 */
exports.RETRY_OPTS_DEFAULT = {
  timeout: 1000,
  retries: 5,
  multiplier: 1.2,
  totalTimeout: 7500
}

/**
 * @constant {TBasRequestOptions}
 */
exports.RETRY_OPTS_LONG = {
  timeout: 5000,
  retries: 1,
  multiplier: 2,
  totalTimeout: 30000
}

/**
 * @constant {TBasRequestOptions}
 */
exports.RETRY_OPTS_LONGER = {
  timeout: 10000,
  retries: 1,
  multiplier: 2,
  totalTimeout: 50000
}

/**
 * @constant {TBasRequestOptions}
 */
exports.RETRY_OPTS_ONE_SHOT = {
  timeout: 10000,
  retries: 0,
  multiplier: 1,
  totalTimeout: 10000
}

/**
 * @constant {TBasRequestOptions}
 */
exports.RETRY_OPTS_ONE_SHOT_LONG = {
  timeout: 20000,
  retries: 0,
  multiplier: 1,
  totalTimeout: 20000
}

/**
 * @constant {TBasRequestOptions}
 */
exports.RETRY_OPTS_LONG_LOGIN = {
  timeout: 5000,
  retries: 2,
  multiplier: 1.5,
  totalTimeout: 26000
}

/**
 * @constant {string[]}
 */
exports.DAYS = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
]

/**
 * @constant {string[]}
 */
exports.PARSER_DAYS = [
  P.MONDAY,
  P.TUESDAY,
  P.WEDNESDAY,
  P.THURSDAY,
  P.FRIDAY,
  P.SATURDAY,
  P.SUNDAY
]

/**
 * @constant {number}
 */
exports.NO_SOURCE_ID = -9999

/**
 * @constant {string}
 */
exports.CORE_PROXY_SCHEME = 'coreProxy:'

/**
 * @constant {number}
 */
exports.SETPOINT_RANGE_MIN_DEFAULT = 5

/**
 * @constant {number}
 */
exports.SETPOINT_RANGE_MAX_DEFAULT = 30

/**
 * @constant {number}
 */
exports.NO_TRACK_NUMBER = -9999

/**
 * @constant {number}
 */
exports.NO_ID = -9999

/**
 * @constant {number}
 */
exports.NO_QUEUE_POSITION = -9999

/**
 * @constant {number}
 */
exports.CAPABILITIES = '_capabilities'

function _compareGreaterThen (a, b) {

  return a - b
}

function _compareSmallerThen (a, b) {

  return b - a
}
