'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('lisaOrderSettingsCtrl', [
    '$scope',
    '$rootScope',
    'BAS_LISA',
    'BasLisaUi',
    lisaOrderSettingsCtrl
  ])

/**
 * @param $scope
 * @param $rootScope
 * @param {BAS_LISA} BAS_LISA
 * @param {BasLisaUi} BasLisaUi
 */
function lisaOrderSettingsCtrl (
  $scope,
  $rootScope,
  BAS_LISA,
  BasLisaUi
) {
  var lisa = this

  var _listeners = []

  /**
   * @type {BAS_LISA}
   */
  lisa.BAS_LISA = BAS_LISA

  /**
   * @type {TBasLisaUi}
   */
  lisa.state = BasLisaUi.get()

  lisa.reorderLisaElement = reorderLisaElement
  lisa.beforeReorderLisaElement = beforeReorderLisaElement
  lisa.beforeWaitLisaElement = beforeWaitLisaElement
  lisa.toggleElement = toggleElement

  init()

  function init () {

    $scope.$on('$destroy', destroy)

    _listeners.push($rootScope.$on(
      BAS_LISA.EVT_LISA_UPDATED,
      _onLisaUpdated
    ))
  }

  function _onLisaUpdated () {

    $scope.$applyAsync()
  }

  function beforeReorderLisaElement (event, index) {

    if (lisa.state.tileOrder[index].value === BAS_LISA.F_START ||
      lisa.state.tileOrder[index].value === BAS_LISA.F_SETTINGS) {

      event.preventDefault()
    }
  }

  function reorderLisaElement (
    _event,
    spliceIndex,
    originalIndex
  ) {
    lisa.state.tileOrder.splice(
      spliceIndex,
      0,
      lisa.state.tileOrder.splice(
        originalIndex,
        1
      )[0]
    )

    saveToSharedStorage()
  }

  function beforeWaitLisaElement (event) {

    if (
      event &&
      event.target &&
      event.target.classList &&
      event.target.classList.contains &&
      event.target.classList.contains('instant') &&
      event.preventDefault
    ) {
      event.preventDefault()
    }
  }

  function saveToSharedStorage () {

    BasLisaUi.setTileOrder(lisa.state.tileOrder)
  }

  function toggleElement (lisaElement) {

    var index, length, i

    if (lisaElement.value === BAS_LISA.F_SETTINGS ||
      lisaElement.value === BAS_LISA.F_START) {

      return
    }

    length = lisa.state.tileOrder.length
    for (i = 0; i < length; i++) {

      if (lisa.state.tileOrder[i].value === lisaElement) {

        index = i
        break
      }
    }

    if (index !== -1) {

      lisa.state.tileOrder[i].showPage =
        !lisa.state.tileOrder[i].showPage

      saveToSharedStorage()
    }
  }

  function destroy () {

    BasUtil.executeArray(_listeners)
    _listeners = []
  }
}
