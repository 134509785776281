'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasAppHeaderControlsBack', [
    '$uiRouterGlobals',
    'STATES',
    'BAS_STATE',
    'UiHelper',
    'BasStateHistory',
    'CurrentBasCore',
    'CurrentRoom',
    'BasStateHelper',
    'BasAppDevice',
    'Logger',
    BasAppHeaderControlsBack
  ])

/**
 * @constructor
 * @param $uiRouterGlobals
 * @param {STATES} STATES
 * @param {BAS_STATE} BAS_STATE
 * @param {UiHelper} UiHelper
 * @param {BasStateHistory} BasStateHistory
 * @param {CurrentBasCore} CurrentBasCore
 * @param {CurrentRoom} CurrentRoom
 * @param {BasStateHelper} BasStateHelper
 * @param {BasAppDevice} BasAppDevice
 * @param {Logger} Logger
 */
function BasAppHeaderControlsBack (
  $uiRouterGlobals,
  STATES,
  BAS_STATE,
  UiHelper,
  BasStateHistory,
  CurrentBasCore,
  CurrentRoom,
  BasStateHelper,
  BasAppDevice,
  Logger
) {
  /**
   * @type {TBasHistoryState}
   */
  var basStateHistory = BasStateHistory.get()

  /**
   * @type {BasUi}
   */
  var basUi = UiHelper.get()

  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  this.handleBack = handleBack
  this.goToParentState = goToParentState

  /**
   * Triggers a transition to what the user perceives to be the previous state
   * Starting from the currentState
   *
   * @returns {Promise}
   */
  function handleBack () {

    var _currentState, length, offset, triedStates

    length = basStateHistory.history.length
    _currentState = $uiRouterGlobals.current.name

    offset = -1
    triedStates = []

    return _goBackFurther().catch(_onGoBackFurtherError)

    /**
     * Retry searching and triggering a transition
     * to what the user perceives to be the previous state
     * with a increase in depthOffset
     *
     * A retry is triggered if the transition is canceled
     * e.g. main.intercom state when there is no intercom call
     *
     * @returns {Promise}
     */
    function _goBackFurther () {

      var promise, previousState, previousStateName
      var secondPreviousState, secondPreviousStateName

      offset++

      previousState = basStateHistory.history[length - 2]
      previousStateName = previousState
        ? previousState.targetState().name()
        : ''

      secondPreviousState = basStateHistory.history[length - 3]
      secondPreviousStateName = secondPreviousState
        ? secondPreviousState.targetState().name()
        : ''

      if (offset < length) {

        // Increase the offset when using the back button to prevent a loop
        // e.g. Navigating (back button) from Settings page -> Schedules page ->
        // should go to Home page, instead of going back to Settings page
        if (
          (
            BasStateHelper.hasBaseState(previousStateName, STATES.SETTINGS) ||
            previousStateName === STATES.DOORS ||
            previousStateName === STATES.CAMERAS
          ) &&
          _currentState === secondPreviousStateName
        ) {

          offset++
        }

        promise = _handleBack(_currentState, offset)

        if (promise && promise.catch) return promise.catch(_goBackFurther)
      }

      return Promise.reject(new Error('could not go back further'))
    }

    function _onGoBackFurtherError () {

      // For some reason we have no state to go back to, but the back button
      //  should do something in all cases. We need to provide fallback states
      //  to go back to based on if you are in a main or a connect state, so
      //  there can be no context changes between main <> connect states.

      var fallbackState

      if (BasStateHelper.hasBaseState(_currentState, STATES.MAIN)) {

        fallbackState = STATES.MAIN

      } else if (BasStateHelper.hasBaseState(_currentState, STATES.CONNECT)) {

        fallbackState = STATES.CONNECT_DISCOVERY

      } else {

        // What other cases are there?
      }

      Logger.warn(
        'Back Controls - Nowhere to go back to! Falling back to ' +
        fallbackState
      )

      // Nowhere to go to! Go to fallback state
      return fallbackState
        ? CurrentRoom.go(fallbackState)
        : Promise.reject('No state to go back to')
    }

    /**
     * Searches and triggers a transition
     * to what the user perceives to be the previous state
     * starting from currentState and a specified depth offset
     *
     * @param currentState
     * @param {number} depthOffset
     * @returns {?Promise}
     */
    function _handleBack (currentState, depthOffset) {

      var past, room, view

      if (BasStateHelper.hasBaseState(currentState, STATES.CONNECT)) {

        switch (currentState) {

          case STATES.CONNECT_LIVE_LOGIN:
            return CurrentRoom.go(STATES.CONNECT_LIVE)

          case STATES.CONNECT_LIVE_REGISTER:
            return CurrentRoom.go(STATES.CONNECT_LIVE)

          case STATES.CONNECT_LIVE_FORGOT:
            return CurrentRoom.go(STATES.CONNECT_LIVE_LOGIN)

          case STATES.CONNECT_LIVE_VERIFY:
            return CurrentRoom.go(STATES.CONNECT_LIVE_REGISTER)

          case STATES.CONNECT_PROFILES:
            return CurrentRoom.go(STATES.CONNECT_DISCOVERY)
        }

        if (
          BasStateHelper.hasBaseState(currentState, STATES.DEVICE_SETTINGS)
        ) {

          // DEVICE SETTINGS

          if (
            !basUi.prop.wMedium &&
            currentState !== STATES.DEVICE_SETTINGS
          ) {

            // Just go up a level
            return goToParentState(currentState)
          }

          if (
            !basUi.prop.wMedium &&
            currentState.length > STATES.DEVICE_SETTINGS.length
          ) {

            past = BasStateHistory.getPastState(
              {
                baseState:
                  BasStateHelper.getStateWithDepth(
                    currentState,
                    STATES.DEVICE_SETTINGS,
                    1
                  ),
                depthOffset: depthOffset,
                excludedStates: triedStates
              }
            )

            return (
              past.targetState &&
              past.targetState.name() === STATES.DEVICE_SETTINGS
            )
              ? _saveAndGoTargetState(past.targetState)
              : CurrentRoom.go(STATES.DEVICE_SETTINGS)

          } else {

            past = BasStateHistory.getPastState(
              {
                baseState: STATES.DEVICE_SETTINGS,
                depthOffset: depthOffset,
                excludedStates: triedStates
              }
            )

            return past.targetState
              ? _saveAndGoTargetState(past.targetState)
              : CurrentRoom.go(STATES.CONNECT_DISCOVERY)
          }
        }
      } else if (
        BasStateHelper.hasBaseState(currentState, STATES.ROOMS) &&
        CurrentBasCore.isAudioOnly()
      ) {

        _goRoomsMusic()

      } else if (BasStateHelper.hasBaseState(currentState, STATES.ROOM)) {

        // ROOM

        if (BasStateHelper.hasBaseState(currentState, STATES.MUSIC)) {

          // ROOM - MUSIC

          if (
            !basUi.prop.wMedium &&
            BasStateHelper.hasBaseState(currentState, STATES.MUSIC_LIBRARY) &&
            currentState.length > STATES.MUSIC_LIBRARY.length
          ) {

            past = BasStateHistory.getPastState(
              {
                baseState:
                  BasStateHelper.getStateWithDepth(
                    currentState,
                    STATES.MUSIC_LIBRARY,
                    1
                  ),
                depthOffset: depthOffset,
                excludedStates: triedStates
              }
            )

            return (
              past.targetState &&
              past.targetState.name() === STATES.MUSIC_LIBRARY
            )
              ? _saveAndGoTargetState(past.targetState)
              : CurrentRoom.go(STATES.MUSIC_LIBRARY)

          } else {

            past = BasStateHistory.getPastState(
              {
                baseState: STATES.MUSIC,
                depthOffset: depthOffset,
                excludedStates: triedStates
              }
            )

            return past.targetState
              ? _saveAndGoTargetState(past.targetState)
              : _goToRoomFromSub()
          }

        } else if (
          BasStateHelper.hasBaseState(currentState, STATES.ROOM_SCENES)
        ) {

          // ROOM - SCENES

          if (
            !basUi.prop.wMedium &&
            currentState.length > STATES.ROOM_SCENES.length
          ) {

            past = BasStateHistory.getPastState(
              {
                baseState:
                  BasStateHelper.getStateWithDepth(
                    currentState,
                    STATES.ROOM_SCENES,
                    1
                  ),
                depthOffset: depthOffset,
                excludedStates: triedStates
              }
            )

            return (
              past.targetState &&
              past.targetState.name() === STATES.ROOM_SCENES
            )
              ? _saveAndGoTargetState(past.targetState)
              : CurrentRoom.go(STATES.ROOM_SCENES)

          } else {

            past = BasStateHistory.getPastState(
              {
                baseState: STATES.ROOM_SCENES,
                depthOffset: depthOffset,
                excludedStates: triedStates
              }
            )

            return past.targetState
              ? _saveAndGoTargetState(past.targetState)
              : _goToRoomFromSub()
          }

        } else if (
          BasStateHelper.hasBaseState(
            currentState,
            STATES.THERMOSTAT_DAY_SCHEDULER
          )
        ) {

          // ROOM - THERMOSTAT DAY SCHEDULER

          past = BasStateHistory.getPastState(
            {
              baseState: STATES.THERMOSTAT_DAY_SCHEDULER,
              depthOffset: depthOffset,
              excludedStates: triedStates
            }
          )

          return past.targetState
            ? _saveAndGoTargetState(past.targetState)
            : CurrentRoom.go(STATES.THERMOSTAT_WEEK_SCHEDULER)

        } else if (
          BasStateHelper.hasBaseState(
            currentState,
            STATES.THERMOSTAT_WEEK_SCHEDULER
          )
        ) {

          // ROOM - THERMOSTAT WEEK SCHEDULER

          past = BasStateHistory.getPastState(
            {
              baseState: STATES.THERMOSTAT_WEEK_SCHEDULER,
              depthOffset: depthOffset,
              excludedStates: triedStates
            }
          )

          return past.targetState
            ? _saveAndGoTargetState(past.targetState)
            : CurrentRoom.go(STATES.THERMOSTAT)

        } else if (currentState === STATES.ROOM) {

          // ROOM

          past = BasStateHistory.getPastState(
            {
              baseState: STATES.ROOM,
              depthOffset: depthOffset,
              excludedStates: triedStates
            }
          )

          return past.targetState
            ? _saveAndGoTargetState(past.targetState)
            : _goToRooms()

        } else {

          // ROOM SUB STATE

          past = BasStateHistory.getPastState(
            {
              baseState:
                BasStateHelper.getStateWithDepth(
                  currentState,
                  STATES.ROOM,
                  1
                ),
              depthOffset: depthOffset,
              excludedStates: triedStates
            }
          )

          return past.targetState
            ? _saveAndGoTargetState(past.targetState)
            : _goToRoomFromSub()
        }

      } else if (BasStateHelper.hasBaseState(currentState, STATES.SETTINGS)) {

        // SETTINGS

        if (
          !basUi.prop.wMedium &&
          currentState !== STATES.SETTINGS
        ) {

          // Just go up a level
          return goToParentState(currentState)
        }

        if (
          !basUi.prop.wMedium &&
          currentState.length > STATES.SETTINGS.length
        ) {

          past = BasStateHistory.getPastState(
            {
              baseState:
                BasStateHelper.getStateWithDepth(
                  currentState,
                  STATES.SETTINGS,
                  1
                ),
              depthOffset: depthOffset,
              excludedStates: triedStates
            }
          )

          return (
            past.targetState &&
            past.targetState.name() === STATES.SETTINGS
          )
            ? _saveAndGoTargetState(past.targetState)
            : CurrentRoom.go(STATES.SETTINGS)

        } else {

          past = BasStateHistory.getPastState(
            {
              baseState: STATES.SETTINGS,
              depthOffset: depthOffset,
              excludedStates: triedStates
            }
          )

          return past.targetState
            ? _saveAndGoTargetState(past.targetState)
            : _goToStart()
        }

      } else if (BasStateHelper.hasBaseState(currentState, STATES.SCENES)) {

        // HOME SCENES

        if (
          !basUi.prop.wMedium &&
          currentState.length > STATES.SCENES.length
        ) {

          past = BasStateHistory.getPastState(
            {
              baseState:
                BasStateHelper.getStateWithDepth(
                  currentState,
                  STATES.SCENES,
                  1
                ),
              depthOffset: depthOffset,
              excludedStates: triedStates
            }
          )

          return (
            past.targetState &&
            past.targetState.name() === STATES.SCENES
          )
            ? _saveAndGoTargetState(past.targetState)
            : CurrentRoom.go(STATES.SCENES)

        } else {

          past = BasStateHistory.getPastState(
            {
              baseState: STATES.SCENES,
              depthOffset: depthOffset,
              excludedStates: triedStates
            }
          )

          return past.targetState
            ? _saveAndGoTargetState(past.targetState)
            : _goToStart()
        }

      } else if (
        BasStateHelper.hasBaseState(currentState, STATES.ENERGY_DETAIL)
      ) {

        // ENERGY DETAIL

        past = BasStateHistory.getPastState(
          {
            baseState: STATES.ENERGY_DETAIL,
            depthOffset: depthOffset,
            excludedStates: triedStates
          }
        )

        return past.targetState
          ? _saveAndGoTargetState(past.targetState)
          : CurrentRoom.go(STATES.ENERGY)

      } else if (BasStateHelper.hasBaseState(currentState, STATES.SCHEDULES)) {

        // SCHEDULES

        if (
          !basUi.prop.wMedium &&
          currentState.length > STATES.SCHEDULES.length
        ) {

          past = BasStateHistory.getPastState(
            {
              baseState:
                BasStateHelper.getStateWithDepth(
                  currentState,
                  STATES.SCHEDULES,
                  1
                ),
              depthOffset: depthOffset,
              excludedStates: triedStates
            }
          )

          return (
            past.targetState &&
            past.targetState.name() === STATES.SCHEDULES
          )
            ? _saveAndGoTargetState(past.targetState)
            : CurrentRoom.go(STATES.SCHEDULES)

        } else {

          past = BasStateHistory.getPastState(
            {
              baseState: STATES.SCHEDULES,
              depthOffset: depthOffset,
              excludedStates: triedStates
            }
          )

          return past.targetState
            ? _saveAndGoTargetState(past.targetState)
            : _goToStart()
        }

      } else if (BasStateHelper.hasBaseState(currentState, STATES.TIMERS)) {

        // TIMERS

        if (
          !basUi.prop.wMedium &&
          currentState.length > STATES.TIMERS.length
        ) {

          past = BasStateHistory.getPastState(
            {
              baseState:
                BasStateHelper.getStateWithDepth(
                  currentState,
                  STATES.TIMERS,
                  1
                ),
              depthOffset: depthOffset,
              excludedStates: triedStates
            }
          )

          return (
            past.targetState &&
            past.targetState.name() === STATES.TIMERS
          )
            ? _saveAndGoTargetState(past.targetState)
            : CurrentRoom.go(STATES.TIMERS)

        } else {

          past = BasStateHistory.getPastState(
            {
              baseState: STATES.TIMERS,
              depthOffset: depthOffset,
              excludedStates: triedStates
            }
          )

          return past.targetState
            ? _saveAndGoTargetState(past.targetState)
            : _goToStart()
        }
      } else if (
        BasStateHelper.hasBaseState(currentState, STATES.CALL_HISTORY_DETAIL)
      ) {

        if (basUi.prop.wMedium) {
          return CurrentRoom.go(STATES.HOME, {
            view: BAS_STATE.S_HOME_VIEW_OPTIONS
          })
        }

        past = BasStateHistory.getPastState(
          {
            baseState: STATES.CALL_HISTORY_DETAIL,
            depthOffset: depthOffset,
            excludedStates: triedStates
          }
        )

        return past.targetState
          ? _saveAndGoTargetState(past.targetState)
          : CurrentRoom.go(STATES.CALL_HISTORY)

      } else if (
        BasStateHelper.hasBaseState(currentState, STATES.CAMERAS_DETAIL)
      ) {

        // CAMERA DETAIL

        past = BasStateHistory.getPastState(
          {
            baseState: STATES.CAMERAS_DETAIL,
            depthOffset: depthOffset,
            excludedStates: triedStates
          }
        )

        return past.targetState
          ? _saveAndGoTargetState(past.targetState)
          : CurrentRoom.go(STATES.CAMERAS)

      } else if (BasStateHelper.hasBaseState(currentState, STATES.LISA)) {

        // LISA

        room = CurrentRoom.getRoom()
        view = $uiRouterGlobals.params.view

        if (currentState === STATES.LISA_LIGHT_DETAIL) {

          // When in color picker or settings view, go to default view
          if (
            view === BAS_STATE.S_LISA_LIGHT_DETAILS_VIEW_SETTINGS ||
            view === BAS_STATE.S_LISA_LIGHT_DETAILS_VIEW_COLOR_PICKER
          ) {

            return CurrentRoom.go(STATES.LISA_LIGHT_DETAIL, { view: '' })
          }

        } else if (currentState === STATES.LISA_SHADE_DETAIL) {

          // When in settings view, go to default view
          if (view === BAS_STATE.S_LISA_SHADE_DETAILS_VIEW_EXTRA) {

            return CurrentRoom.go(STATES.LISA_SHADE_DETAIL, { view: '' })
          }

        } else if (currentState === STATES.LISA_MUSIC) {

          // When in favourites view, go to default view
          if (view === BAS_STATE.S_LISA_MUSIC_VIEW_FAVOURITES) {

            if (
              room &&
              room.music &&
              room.music.isSourceEmpty &&
              !room.music.isSourceEmpty()) {

              return CurrentRoom.go(STATES.LISA_MUSIC, { view: '' })
            }
          }

        } else if (currentState === STATES.LISA_THERMOSTAT) {

          // When in settings view, go to default view (slider)
          if (view === BAS_STATE.S_LISA_THERMOSTAT_VIEW_SETTINGS) {

            if (room.thermostats?.thermostats[0]?.hasSetPoint()) {
              return CurrentRoom.go(STATES.LISA_THERMOSTAT, { view: '' })
            }
          }
        }

        past = BasStateHistory.getPastState(
          {
            baseState: BasStateHelper.getStateWithDepth(
              currentState,
              STATES.LISA,
              2
            ),
            depthOffset: depthOffset,
            excludedStates: triedStates
          }
        )

        return past.targetState
          ? _saveAndGoTargetState(past.targetState)
          : CurrentRoom.go(STATES.LISA)

      } else if (
        BasStateHelper.hasBaseState(currentState, STATES.MAIN) &&
        currentState.length > STATES.MAIN.length
      ) {

        // MAIN SUB STATES

        past = BasStateHistory.getPastState(
          {
            baseState: BasStateHelper.getStateWithDepth(
              currentState,
              STATES.MAIN,
              1
            ),
            depthOffset: depthOffset,
            excludedStates: triedStates
          }
        )

        // Try past.targetState
        // Fallback to rooms if currentState is home page
        // Fallback on goToStart
        if (past.targetState) {

          return _saveAndGoTargetState(past.targetState)

        } else if (currentState === STATES.HOME) {

          return _goToRooms()

        } else {

          return _goToStart()
        }
      }
    }

    function _saveAndGoTargetState (targetState) {

      triedStates.push(targetState)

      return _goTargetState(targetState)
    }
  }

  /**
   * Triggers a transition to the parent state of the currentState
   *
   * @param currentState
   * @returns {Promise}
   */
  function goToParentState (currentState) {

    return _goToParentState(
      BasUtil.isNEString(currentState)
        ? currentState
        : $uiRouterGlobals.current.name
    )

    function _goToParentState (state) {

      var idx, newState

      idx = state.lastIndexOf('.')

      if (idx > -1) {

        newState = state.slice(0, idx)

        return CurrentRoom.go(newState)
          .catch(onTransitionError)
      }

      return Promise.reject('No non-abstract parent state to go to')

      /**
       *
       * @param {Rejection} rejection
       * @returns {*}
       */
      function onTransitionError (rejection) {

        // Only handle rejections with 'error' type (6) and where the detail
        //  message includes 'abstract'
        if (
          rejection.type === 6 &&
          rejection.detail &&
          BasUtil.isNEString(rejection.detail.message) &&
          BasUtil.isNEString(rejection.detail.message) &&
          rejection.detail.message.indexOf('type: 4') > -1 &&
          rejection.detail.message.indexOf('abstract') > -1
        ) {
          // Transition to abstract state, try going up another state
          return _goToParentState(newState)
        }

        return Promise.reject(rejection)
      }
    }
  }

  function _goToRoomFromSub () {

    return CurrentBasCore.isAudioOnly()
      ? currentBasCoreState.core.core.singleAudioRoomId
        ? null
        : _goRoomsMusic()
      : CurrentRoom.go(STATES.ROOM)
  }

  function _goToStart () {

    if (BasAppDevice.isLisa()) {

      return CurrentRoom.go(STATES.LISA)

    } else if (CurrentBasCore.hasCore()) {

      if (CurrentBasCore.hasHomePage()) return CurrentRoom.go(STATES.HOME)

      if (CurrentBasCore.isAudioOnly()) {

        return currentBasCoreState.core.core.singleAudioRoomId
          ? CurrentRoom.go(STATES.MUSIC_PLAYER)
          : _goRoomsMusic()
      }

      return CurrentRoom.go(STATES.ROOMS)

    } else {

      return CurrentRoom.go(STATES.ROOMS)
    }
  }

  function _goRoomsMusic () {

    if (
      !CurrentBasCore.hasCore() ||
      !(
        currentBasCoreState.core.core.singleRoomId &&
        currentBasCoreState.core.core.singleAudioRoomId
      )
    ) {
      return CurrentRoom.go(
        STATES.ROOMS,
        {
          view: BAS_STATE.S_ROOMS_VIEW_MUSIC
        }
      )
    }
  }

  function _goToRooms () {

    return CurrentBasCore.isAudioOnly()
      ? _goRoomsMusic()
      : CurrentRoom.go(STATES.ROOMS)
  }

  function _goTargetState (targetState) {

    var newOptions, options, targetName, targetParams

    // Do not completely copy the original transition options
    //
    // Bug: start in home go to scheduler and go back with back button
    // URL did not update, not passing the original options fixes the problem

    newOptions = {}

    options = targetState.options()
    targetName = targetState.name()
    targetParams = targetState.params()
    if (BasUtil.isNEObject(options.custom)) newOptions.custom = options.custom

    // TODO: When we go back to our call history detail view we want
    // to show the previous selected call history entry. Even when the user
    // scrolled to the next 15 entries. Check ticket: CAP-1187
    if (targetName === STATES.CALL_HISTORY_DETAIL) {
      targetName = STATES.CALL_HISTORY
      targetParams = {}
    }

    return CurrentRoom.go(
      targetName,
      targetParams,
      newOptions
    )
  }
}
