'use strict'

angular
  .module('basalteApp')
  .constant('BAS_GENERIC_DEVICE', {
    TYPE_V1: 'generic',
    TYPE_V2: 'generic_v2',
    NAME_TYPE_SOCKET: 'NAME_TYPE_SOCKET',
    NAME_TYPE_GARDEN: 'NAME_TYPE_GARDEN',
    NAME_TYPE_FIRE: 'NAME_TYPE_FIRE'
  })
